import { apiHooks } from 'react-api-query';

import { createBoxApi } from '../utils/programming/createBoxApi';

export const boxApi = createBoxApi();

export const {
  useApiQuery: useBoxApiQuery,
  useApiMutation: useBoxApiMutation,
} = apiHooks(boxApi);
