import React, { useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';

import { Button } from '../components/common/Button';
import { FullScreenDialog } from '../components/common/FullScreenDialog';
import { Logo } from '../components/common/Logo';
import { Stack } from '../components/common/Stack';
import { ErrorMessage } from '../components/form/ErrorMessage';
import { Form } from '../components/form/Form';
import { InputField } from '../components/form/InputField';
import { useFormSubmission } from '../hooks/useFormSubmission';
import { useTranslation } from '../I18nProvider';
import { getErrorMessage, isStatus, resetPassword } from '../utils/api';

type FormValues = {
  password: string;
};

export function ResetPassword() {
  const t = useTranslation();
  const params = useParams();
  const [error, setError] = useState();
  const [submitting, setSubmitting] = useState(false);
  const navigate = useNavigate();
  const { form, register } = useFormSubmission<FormValues>({
    async onSubmit({ password }) {
      setSubmitting(true);
      try {
        await resetPassword({ password, token: params.token! });
        navigate('/login');
      } catch (err) {
        if (isStatus(err, 404) || isStatus(err, 403)) {
          setError(getErrorMessage(err));
          setSubmitting(false);
        }
      }
    },
  });

  return (
    <FullScreenDialog>
      <Logo />
      <Form {...form}>
        <Stack>
          <p>{t.resetPasswordText}</p>
          <InputField
            register={register}
            name="password"
            type="password"
            placeholder={t.password}
            required
          />
          {error && (
            <Stack>
              <ErrorMessage>{error}</ErrorMessage>{' '}
              <Button as={Link} to="/forgot-password" variant="primary">
                {t.requestNewLink}
              </Button>
            </Stack>
          )}
          {!error && (
            <Button variant="primary" disabled={submitting}>
              {t.resetPassword}
            </Button>
          )}
        </Stack>
      </Form>
    </FullScreenDialog>
  );
}
