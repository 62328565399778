import { FieldValues, UseFormSetError } from 'react-hook-form';

type Errors<T> = {
  errors: Partial<Record<keyof T, string>>;
};
export function visitErrors<T extends FieldValues>(
  { errors }: Errors<T>,
  setError: UseFormSetError<T>
) {
  for (const field in errors) {
    const message = errors[field];
    if (message) setError(field as any, { message });
  }
}

export function handleError<T extends FieldValues>(
  err: any,
  setError: UseFormSetError<T>
) {
  if (err && typeof err === 'object' && 'data' in err) {
    visitErrors(err.data, setError);
    return true;
  }
  return false;
}
