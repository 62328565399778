import { format, formatDistance } from 'date-fns';
import { de, enAU } from 'date-fns/locale';
import _ from 'lodash';

import { useI18n } from '../I18nProvider';

// TODO: Improve with mapping once rebase on develop
const getLocale = (locale: string) => (_.startsWith(locale, 'de') ? de : enAU);

function applyIfRequired(
  date: Date | string | number | null | undefined,
  fn: (d: Date) => string
) {
  if (!date) {
    return '';
  }

  const d = new Date(date);
  if (d.toString() === 'Invalid Date') {
    return '';
  }

  return fn(d);
}

export function useDateFormat() {
  const i18n = useI18n();

  const formatDate = (date?: Date | string | number) =>
    applyIfRequired(date, (d) =>
      format(d, 'P', { locale: getLocale(i18n.locale) })
    );

  const formatTime = (date?: Date | string | number) =>
    applyIfRequired(date, (d) =>
      format(d, 'p', { locale: getLocale(i18n.locale) })
    );

  const formatDateTime = (date?: Date | string | number) =>
    applyIfRequired(date, (d) =>
      format(d, 'Pp', { locale: getLocale(i18n.locale) })
    );

  const formatRelative = (date?: string | number) =>
    applyIfRequired(date, (d) =>
      formatDistance(d, new Date(), {
        locale: getLocale(i18n.locale),
        addSuffix: true,
      })
    );

  return {
    formatDate,
    formatTime,
    formatDateTime,
    formatRelative,
  };
}
