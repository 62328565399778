import React, {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from 'react';

import { defaults, getUser, LoginResult, NormalisedUser } from './utils/api';

type UserState = {
  user: NormalisedUser | null;
  setUser: (user: NormalisedUser | null) => void;
};
const UserStateContext = createContext<UserState | undefined>(undefined);

type Props = {
  children: ReactNode;
};
export function UserProvider({ children }: Props) {
  const [user, setUser] = useState<NormalisedUser | null>();
  useEffect(() => {
    getUser()
      .then(setUser)
      .catch((err) => {
        console.log(err);
        setUser(null);
      });
  }, []);
  if (user === undefined) return <></>;
  return (
    <UserStateContext.Provider value={{ user, setUser }}>
      {children}
    </UserStateContext.Provider>
  );
}

export function useUserState() {
  const state = useContext(UserStateContext);
  if (!state) throw new Error('Missing UserProvider');
  return state;
}

export function useUser() {
  const { user } = useUserState();
  return user;
}

export function useSetLogin() {
  const { setUser } = useUserState();
  return (result: LoginResult | null) => {
    if (result) {
      const { user, token } = result;
      window.localStorage.setItem('token', token);
      defaults.headers = {
        authorization: token,
      };
      setUser(user);
    } else {
      window.localStorage.removeItem('token');
      setUser(null);
    }
  };
}
