import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { Button } from '../../components/common/Button';
import { Center } from '../../components/common/Center';
import { WarningIcon } from '../../components/common/Icons';
import { Stack } from '../../components/common/Stack';
import { useTranslation } from '../../I18nProvider';

const IconContainer = styled.div`
  width: 100px;
  height: 100px;
  position: relative;
  margin-top: 10px;
`;

const Icon = styled(WarningIcon)`
  width: 100%;
  height: 100%;
`;

type Props = {
  navigateTo: string;
};

export function SomethingWentWrong({ navigateTo }: Props) {
  const t = useTranslation();

  return (
    <Stack>
      <div>{t.somethingWentWrong}</div>
      <Center>
        <IconContainer>
          <Icon />
        </IconContainer>
      </Center>
      <Button as={Link} variant="primary" to={navigateTo}>
        {t.back}
      </Button>
    </Stack>
  );
}
