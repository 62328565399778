import React, { useMemo } from 'react';
import styled from 'styled-components';

import { Row } from '../../components/common/Row';
import { Stack } from '../../components/common/Stack';
import {
  Overline,
  SectionHeading,
  TextXs,
} from '../../components/common/typography';
import { useTranslation } from '../../I18nProvider';
import { borderRadius, color, space } from '../../tokens';

const colors = ['#019E9D', '#FFE028', '#B90933'];

const gradients = [
  ['#0AB790', '#59CD72', '#96DB53', '#C6E03E'],
  ['#FDB619', '#F18420', '#E15F28', '#D2422E'],
];

const gap = gradients[0].length / 2;

const Header = styled(Row)`
  color: ${color.heavyCardText};
  background: ${color.line};
  border-radius: ${borderRadius.inner} ${borderRadius.inner} 0 0;
  padding: ${space[1]} ${space[3]};
`;

const Scale = styled.div`
  background: ${color.cardBackground};
  color: ${color.heavyCardText};
  padding: ${space[3]};
  border-radius: 0 0 ${borderRadius.inner} ${borderRadius.inner};
`;

const Tick = styled.div`
  border-radius: 2px;
  height: 2px;
  flex: auto;
`;

type Props = {
  title: string;
  bars: number;
  min: number;
  max: number;
  step1: number;
  step2: number;
  value: number;
  unit?: string;
  inverse?: boolean;
};

export function ScaleCard({
  title,
  bars,
  min,
  max,
  step1,
  step2,
  value,
  unit,
  inverse,
}: Props) {
  const t = useTranslation();
  const range = max - min;
  const grad = useMemo(() => {
    const [color1, color2, color3] = inverse
      ? colors.slice().reverse()
      : colors;
    const [grad1, grad2] = inverse
      ? gradients.map((g) => g.slice().reverse()).reverse()
      : gradients;

    const sec1 = Math.round((step1 / range) * bars) - gap;
    const sec2 = Math.max(
      1,
      Math.round((step2 / range) * bars) - sec1 - 3 * gap
    );
    const sec3 = bars - sec1 - sec2 - 4 * gap;

    const grad = Array(bars);

    grad.fill(color2);
    grad.fill(color1, 0, sec1);
    grad.splice(sec1, 4, ...grad1);
    grad.splice(sec1 + sec2 + 2 * gap, 2 * gap, ...grad2);
    grad.fill(color3, bars - sec3);
    return grad;
  }, [bars, range, step1, step2, inverse]);

  const clampedValue = Math.max(min, Math.min(max, value));
  const peekIndex = Math.round((clampedValue / range) * (bars - 1));
  const getTickHeight = (index: number) => {
    if (index === peekIndex) return 14;
    if (index === peekIndex - 1 || index === peekIndex + 1) return 12;
    return 2;
  };
  return (
    <Stack gap="0">
      <Header gap="1ch">
        <SectionHeading>{title}</SectionHeading>
        <Overline>
          {value} {unit}
        </Overline>
      </Header>
      <Scale>
        <Row justify="spread">
          <TextXs>
            {min} {unit}
          </TextXs>
          <TextXs>
            {max} {unit}
          </TextXs>
        </Row>
        <Row gap="2px" nowrap align="top">
          {grad.map((backgroundColor, i) => (
            <Tick
              key={i}
              style={{
                backgroundColor,
                height: getTickHeight(i),
                opacity: getTickHeight(i) > 2 ? 1 : 0.4,
              }}
            />
          ))}
        </Row>
      </Scale>
    </Stack>
  );
}
