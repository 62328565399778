import React from 'react';
import styled, { keyframes } from 'styled-components';

import { color } from '../../tokens';
import { Loader } from './Loader';

const appear = keyframes`
  to { opacity: 1; }
`;
export const Container = styled.div`
  display: grid;
  place-items: center;
  height: 4.875rem;
  color: ${color.line};
  opacity: 0;
  animation: 150ms linear 700ms forwards ${appear};
`;

export function Loading() {
  return (
    <Container>
      <Loader />
    </Container>
  );
}
