import React, { ReactNode, useEffect } from 'react';
import { Link } from 'react-router-dom';

import { Hint } from '../../components/common/Hint';
import { IconButton } from '../../components/common/IconButton';
import { WarningIcon } from '../../components/common/Icons';
import { Loading } from '../../components/common/Loading';
import { Page } from '../../components/common/Page';
import { Stack } from '../../components/common/Stack';
import { DataGrid } from '../../components/DataGrid';
import {
  boxApi,
  useBoxApiMutation,
  useBoxApiQuery,
} from '../../hooks/useBoxApi';
import { StringTranslationKey } from '../../i18n';
import { useTranslation } from '../../I18nProvider';
import {
  DriveParameterNames,
  getBoxSummary,
  LearnParameterNames,
} from '../../utils/programming/BoxApi';
import {
  parameterKeyMap,
  ParameterKeys,
} from '../../utils/programming/parameterKeyMap';
import { DriveParameterCard } from './DriveParameterCard';
import { DriveParameterSection } from './DriveParameterSection';

type sectionParameterKeys = {
  title: StringTranslationKey;
  params: ParameterKeys[];
};
export const sections: sectionParameterKeys[] = [
  {
    title: 'doorMovementParameter',
    params: [
      parameterKeyMap[DriveParameterNames.OpenSpeed],
      parameterKeyMap[DriveParameterNames.CloseSpeed],
      parameterKeyMap[DriveParameterNames.NudggingSpeed],
      parameterKeyMap[DriveParameterNames.BrakePar],
      parameterKeyMap[DriveParameterNames.PositionOut],
      parameterKeyMap[DriveParameterNames.CloseHoldForce],
      parameterKeyMap[DriveParameterNames.Acceleration],
      parameterKeyMap[DriveParameterNames.OpenHoldForce],
      parameterKeyMap[DriveParameterNames.CloseForcePoti],
    ],
  },
  {
    title: 'couplerMovementParameter',
    params: [
      parameterKeyMap[DriveParameterNames.CouplerSpeed],
      parameterKeyMap[DriveParameterNames.CouplerWitdh],
    ],
  },
  {
    title: 'reopenTimeParameter',
    params: [parameterKeyMap[DriveParameterNames.ReopenTime]],
  },
];

export function DriveParameters() {
  const t = useTranslation();

  const driveStatus = useBoxApiQuery({
    method: 'getDriveStatus',
    cacheTime: 0,
  });
  const driveConnected = !!driveStatus.data?.drive_info.login_status;

  const connectDrive = useBoxApiMutation('connectDrive', {
    onSuccess: driveStatus.update,
    retry: 0,
  });

  const shouldReconnect =
    !driveConnected && !driveStatus.isLoading && !connectDrive.isLoading;

  useEffect(() => {
    if (shouldReconnect) {
      console.warn('Drive is disconnected - attempting to connect');
      connectDrive();
    }
  }, [shouldReconnect]);

  const isLockInput = useBoxApiQuery({
    method: 'isLockInput',
    enabled: driveConnected,
    cacheTime: 0,
    retry: 1,
  });

  // const dipSwitchPositions = useBoxApiQuery({
  //   method: 'getDipSwitchPositions',
  //   enabled: driveConnected,
  //   cacheTime: 0,
  //   retry: 1,
  // });

  const learnParameter = useBoxApiQuery({
    method: 'getLearnParameter',
    enabled: driveConnected,
  });

  const driveParameters = useBoxApiQuery({
    method: 'getDriveParameters',
    enabled: driveConnected,
    cacheTime: 0,
    retry: 1,
  });

  useEffect(() => {
    const handleLockInputChange = isLockInput.update;
    boxApi.onLockInputChange(handleLockInputChange);

    // const handleDipSwitchUpdate = dipSwitchPositions.update;
    // boxApi.onDipSwitchPositionsChange(handleDipSwitchUpdate);

    const handleLearnParameterUpdate = learnParameter.update;
    boxApi.onLearnParameterChange(handleLearnParameterUpdate);

    return () => {
      boxApi.offLockInputChange(handleLockInputChange);
      // boxApi.offDipSwitchPositionsChange(handleDipSwitchUpdate);
      boxApi.offLearnParameterChange(handleLearnParameterUpdate);
    };
  }, [driveConnected]);

  const isEditingEnabled = isLockInput.data === true;

  const getExtendedBoxSummary = () => ({
    ...getBoxSummary(driveStatus.data),
    doorCycleCounter: `${
      learnParameter.data?.[LearnParameterNames.CycleCount]
    }`,
    doorPosition: `${learnParameter.data?.[LearnParameterNames.DoorPosition]}m`,
  });

  // console.log({
  //   driveConnected: driveConnected,
  //   'driveStatus.loading': driveStatus.isLoading,
  //   // 'connectDrive.isLoading': connectDrive.isLoading,
  // });

  let content: ReactNode;

  if (!driveStatus.data || !driveParameters.data) {
    content = <Loading />;
  } else {
    content = (
      <Stack>
        <DataGrid data={getExtendedBoxSummary()} />
        {!isEditingEnabled && <Hint>{t.programmingDisabledHint}</Hint>}
        {sections.map((s, count) => (
          <DriveParameterSection key={count} title={t[s.title]}>
            {s.params.map((p) => {
              const dp = driveParameters.data[p.id];

              return (
                <DriveParameterCard
                  key={p.id}
                  keys={p}
                  param={dp}
                  disabled={!isEditingEnabled}
                />
              );
            })}
          </DriveParameterSection>
        ))}
      </Stack>
    );
  }

  return (
    <Page
      pageTitle={t.programmingTool}
      subHeadline={t.parameter}
      backLink="/"
      nextLink={
        <IconButton as={Link} to="../errors">
          <WarningIcon />
        </IconButton>
      }
    >
      {content}
    </Page>
  );
}
