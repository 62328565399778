import React from 'react';
import {
  FieldPath,
  FieldValues,
  RegisterOptions,
  UseFormRegister,
  UseFormRegisterReturn,
} from 'react-hook-form';

import { Dropdown, DropdownProps } from './Dropdown';
import { FormField } from './FormField';

type Props<T extends FieldValues> = Omit<
  DropdownProps,
  'value' | keyof UseFormRegisterReturn
> & {
  name: FieldPath<T>;
  label?: string;
  required?: boolean | string;
  register: UseFormRegister<T>;
  options?: RegisterOptions<T>;
};

export function DropdownField<T extends FieldValues>({
  register,
  name,
  required,
  options,
  ...props
}: Props<T>) {
  return (
    <FormField name={name} required={required}>
      <Dropdown {...props} {...register(name, { ...options, required })} />
    </FormField>
  );
}
