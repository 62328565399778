import React from 'react';
import { Controller, useForm } from 'react-hook-form';

import { Button } from '../../components/common/Button';
import { Stack } from '../../components/common/Stack';
import { Toggle } from '../../components/form/Toggle';
import { ToggleButton } from '../../components/form/ToggleButton';
import { useApiMutation } from '../../hooks/useApi';
import { useTranslation } from '../../I18nProvider';
import { FullInstallationControl, RestartArgs } from '../../utils/api';
import { getFloorPreset, range } from '../../utils/results';

type FormValues = {
  carModified: boolean;
  floors: boolean[];
};

type Props = {
  installationControl: FullInstallationControl;
  onSubmit: (config: RestartArgs) => void;
};
export function RestartForm({ installationControl, onSubmit }: Props) {
  const t = useTranslation();
  const updateStaticData = useApiMutation('updateStaticData', {});

  const { control, register, handleSubmit, watch } = useForm<FormValues>({
    defaultValues: {
      carModified: false,
      floors: getFloorPreset(installationControl.floor_results),
    },
  });

  const carModified = watch('carModified', false);

  return (
    <form
      onSubmit={handleSubmit(async (data) => {
        const { sameEsn } = await updateStaticData(installationControl.lift_id);
        if (!sameEsn) {
          if (confirm(t.noInstallationControlFound)) {
            onSubmit(restartArgs(data));
          }
        } else {
          onSubmit(restartArgs(data));
        }
      })}
    >
      <Stack>
        <p>{t.selectModifiedFloors}</p>
        <div>
          <Toggle {...register('carModified')}>{t.carDoorModified}</Toggle>
          {!carModified &&
            range(installationControl.lift.floors).map((i) => (
              <Controller
                key={i}
                name={`floors.${i}`}
                control={control}
                render={({ field: { onChange, value } }) => (
                  <ToggleButton onChange={onChange} value={value}>
                    {i}
                  </ToggleButton>
                )}
              />
            ))}
        </div>
        <Button variant="primary">{t.start}</Button>
      </Stack>
    </form>
  );
}

function restartArgs(values: FormValues) {
  const floors = values.floors
    .map((on, i) => (values.carModified || on ? i : false))
    .filter((f) => f !== false) as number[];
  return { floors };
}
