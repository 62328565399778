import * as Sentry from '@sentry/react';
import {
  QueryCache,
  QueryClient,
  QueryClientProvider,
} from '@tanstack/react-query';
import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';

import { AppRoutes } from './AppRoutes';
import { AppErrorBoundary } from './components/AppErrorBoundary';
import { I18nProvider } from './I18nProvider';
import { LicensesProvider } from './LicensesProvider';
import { OfflineProvider } from './OfflineProvider';
import { UserProvider } from './UserProvider';
import { VersionProvider } from './VersionProvider';

const queryClient = new QueryClient({
  queryCache: new QueryCache({
    onError: (error) => {
      Sentry.captureException(error);
    },
  }),
  defaultOptions: {
    queries: {
      retry: (failureCount: number, error: any) =>
        (!error.status || error?.status >= 500) && failureCount < 3,

      useErrorBoundary: true,
      staleTime: 0,
    },
    mutations: {
      useErrorBoundary: false,
      retry: 0,
    },
  },
});

function App() {
  return (
    <OfflineProvider>
      <QueryClientProvider client={queryClient}>
        <VersionProvider>
          <UserProvider>
            <I18nProvider>
              <LicensesProvider>
                <Router>
                  <AppErrorBoundary>
                    <AppRoutes />
                  </AppErrorBoundary>
                </Router>
              </LicensesProvider>
            </I18nProvider>
          </UserProvider>
        </VersionProvider>
      </QueryClientProvider>
    </OfflineProvider>
  );
}

export default App;
