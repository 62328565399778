import React, { ReactNode } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { useLocation } from 'react-router-dom';

import { useUser } from '../UserProvider';
import { ErrorScreen } from './ErrorScreen';

type Props = {
  children?: ReactNode;
};

export function AppErrorBoundary({ children }: Props) {
  const location = useLocation();
  const user = useUser();
  return (
    <ErrorBoundary
      fallbackRender={({ error, resetErrorBoundary }) => (
        <ErrorScreen error={error} resetErrorBoundary={resetErrorBoundary} />
      )}
      resetKeys={[location.pathname, user]}
    >
      {children}
    </ErrorBoundary>
  );
}
