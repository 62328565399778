import React from 'react';

import { Chart } from '../../components/chart';
import { DataPoint } from '../../components/chart/math-2d';
import { useTranslation } from '../../I18nProvider';
import { DoorData } from '../../utils/boxData';

const color = {
  speed: '#1d8b8f',
  position: '#8953BB',
  force: '#93D3D8',
};

type Props = {
  data: DoorData;
};
export function DoorCycleGraph({ data }: Props) {
  const t = useTranslation();
  const { frames, maxPos, maxSpeed } = data;

  const points = (yProp: string): DataPoint[] =>
    frames
      .map((frame) => ({ x: frame.x, y: frame[yProp] || NaN }))
      .filter((point) => Number.isFinite(point.y));

  const axisConfig = {
    left: {
      color: color.position,
      tickFormat: (t: d3.NumberValue) =>
        (Number(t ?? 0) * maxPos).toFixed(2).replace(/-/, '- '),
      helpers: true,
    },
    right: {
      color: color.speed,
      tickFormat: (t: d3.NumberValue) =>
        (Number(t ?? 0) * maxSpeed).toFixed(2).replace(/-/, '- '),
      helpers: false,
    },
    bottom: {
      tickFormat: (t: d3.NumberValue) => `${t}s`,
      helpers: true,
    },
  };

  return (
    <Chart
      aspectRatio={3 / 2}
      aspectRatioLandscape={2.4}
      margin={{ top: 0, right: 40, bottom: 30, left: 40 }}
      axisConfig={axisConfig}
      data={[
        {
          name: `${t.position} (m)`,
          color: color.position,
          points: points('doorposition'),
        },
        {
          name: `${t.speed} (m/s)`,
          color: color.speed,
          points: points('doorspeed'),
          yAxis: 'right',
        },
      ]}
    />
  );
}
