import React, { ReactNode, useContext, useEffect } from 'react';

import { useLocalStorage } from './hooks/useLocalStorage';
import type { Translations } from './i18n';
import * as messages from './i18n';
import { useUser } from './UserProvider';
import { UserLocale } from './utils/appApi';
import { Locale } from './utils/types';

const [preferredLocale] = navigator.languages;
const preferDe = preferredLocale?.toLowerCase().startsWith('de');

const defaultLocale: UserLocale = preferDe ? 'de' : 'en';

type I18n = {
  locale: UserLocale;
  translations: Translations;
};

const I18nContext = React.createContext<I18n | undefined>(undefined);

type Props = {
  children: ReactNode;
};
export function I18nProvider({ children }: Props) {
  const locale = useDetermineLocale();

  return (
    <I18nContext.Provider
      value={{
        locale: locale,
        translations: messages[locale as Locale],
      }}
    >
      {children}
    </I18nContext.Provider>
  );
}

function useDetermineLocale() {
  const [locale, setLocale] = useLocalStorage('locale', defaultLocale);

  const user = useUser();
  useEffect(() => {
    if (user) {
      setLocale(user.locale);
    }
  }, [user?.locale]);

  return locale;
}

export function useI18n() {
  const i18n = useContext(I18nContext);
  if (!i18n) throw new Error('Missing I18nProvider');
  return i18n;
}

export function useTranslation() {
  return useI18n().translations;
}
