const cyan2 = '#00989c';
const yellow1 = '#ebac31';
const red2 = '#9e0038';
const red1 = '#ffe0e6';
const white = '#fcfcfc';
const grey = '#4a4a4a';
const black = '#141414';

const neutral1 = '#F3F3F7';
const neutral2 = '#dee0ed';
const neutral3 = '#808293';
const neutral4 = '#545663';

export const color = {
  primary: cyan2,
  background: white,
  text: black,
  textOnPrimary: white,
  input: neutral1,
  inputText: black,
  inputErrorBackground: red1,
  line: neutral2,
  label: grey,
  errorText: red2,
  cardBackground: neutral1,
  heavyCardText: neutral4,
  cardText: neutral3,
  success: cyan2,
  info: neutral3,
  warning: yellow1,
  error: red2,
  fatal: red2,
};

export const space = {
  1: '0.25rem', // Gap in Card between title and text
  1.5: '0.375rem', // 6 // Hint icon gap
  2: '0.5rem', // 8 IconCard Icon padding, Page top row gap
  2.5: '0.625rem', // 10 // Hint padding, result panel title padding
  3: '0.75rem', // 12 Root + Dialog Padding + top card padding, header x padding
  4: '1rem', // card x padding, main x padding, dialog gap
  5: '1.25rem', // main y padding, sidebar
  7: '1.75rem', // Header top,
  11: '2.75rem', //Burger --> move to size? also 56px / 2.857!
};

export const borderRadius = {
  inner: '4px', // 4 (Hint Box)
  default: '6px', // 8 (Button)
  outer: '16px', // 16 (Dialog)
};

export const fontSize = {
  xxs: '0.625rem', // 10
  xs: '0.75rem', // 12 (e.g. Hint)
  base: '1rem',
  lg: '1.125rem', // Page Title
  xl: '1.3rem', //Header
  icon: '1.5em', //Icons
};

export const lineHeight = {
  none: '1',
  tight: '1.25',
  snug: '1.375',
  normal: '1.5',
  relaxed: '1.625',
  loose: '2',
  3: '.75rem',
  4: '1rem',
  5: '1.25rem',
  6: '1.5rem',
  7: '1.75rem',
  8: '2rem',
  9: '2.25rem',
  10: '2.5rem',
};

export const fontWeight = {
  normal: '400',
  medium: '500',
  bold: '700',
};
