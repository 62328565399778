import React from 'react';
import { Link } from 'react-router-dom';
import styled, { keyframes } from 'styled-components';

import { Button } from '../../components/common/Button';
import { Center } from '../../components/common/Center';
import { GearIcon } from '../../components/common/Icons';
import { Stack } from '../../components/common/Stack';
import { useTranslation } from '../../I18nProvider';

const rotate = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

const GearContainer = styled.div`
  width: 100px;
  height: 100px;
  position: relative;
  margin-top: 10px;
`;

const RotatingGearIcon = styled(GearIcon)`
  width: 100%;
  height: 100%;
  transform-origin: 40% 40%;
  animation: ${rotate} 5s infinite linear;
`;

type Props = {
  showButtons?: boolean;
};

export function ServiceUnavailable({ showButtons = true }: Props) {
  const t = useTranslation();
  const reload = () => window.location.reload();

  return (
    <Stack>
      <div>{t.preventiveMaintenanceUnavailable}</div>
      <Center>
        <GearContainer>
          <RotatingGearIcon />
        </GearContainer>
      </Center>
      {showButtons && (
        <Stack>
          <Button as={Link} variant="primary" to="../">
            {t.back}
          </Button>
          <Button variant="secondary" onClick={reload}>
            {t.reload}
          </Button>
        </Stack>
      )}
    </Stack>
  );
}
