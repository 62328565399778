import { DataPoint, lerp, lerp2, sqrLength2, sub2 } from './math-2d';

// https://en.wikipedia.org/wiki/Centripetal_Catmull%E2%80%93Rom_spline

const remap = (a: number, b: number, c: DataPoint, d: DataPoint, u: number) => {
  return lerp2(c, d, (u - a) / (b - a));
};

const getKnotInterval = (a: DataPoint, b: DataPoint, alpha: number) => {
  return Math.pow(sqrLength2(sub2(a, b)), 0.5 * alpha);
};

export class CatmullRomSpline {
  /**
   * Construct a Catmull Rom Spline
   *
   * @param p0 point 0
   * @param p1 point 1
   * @param p2 point 2
   * @param p3 point 3
   * @param alpha 0. for the uniform spline, .5 for centripetal spline, 1. for chordal spline
   */
  constructor(
    public p0: DataPoint,
    public p1: DataPoint,
    public p2: DataPoint,
    public p3: DataPoint,
    public alpha = 0.5
  ) {}

  /**
   * Evaluates a point at the given t value
   * @param t a number value between [0..1]
   */
  getPoint(t: number) {
    const { p0, p1, p2, p3, alpha } = this;
    const k0 = 0;
    const k1 = getKnotInterval(p0, p1, alpha);
    const k2 = getKnotInterval(p1, p2, alpha) + k1;
    const k3 = getKnotInterval(p2, p3, alpha) + k2;
    const u = lerp(k1, k2, t);

    const A1 = remap(k0, k1, p0, p1, u);
    const A2 = remap(k1, k2, p1, p2, u);
    const A3 = remap(k2, k3, p2, p3, u);
    const B1 = remap(k0, k2, A1, A2, u);
    const B2 = remap(k1, k3, A2, A3, u);
    return remap(k1, k2, B1, B2, u);
  }
}
