import React from 'react';
import styled from 'styled-components';

import { borderRadius, color, space } from '../../tokens';

type ToggleFilterParams = {
  leftToggleText: string;
  rightToggleText: string;
  leftIsActive: boolean;
  setLeftIsActive: Function;
};

const Button = styled.button`
  border-radius: ${borderRadius.default};
  padding: ${space[2.5]};
  width: 50%;
  float: left;
  justify-content: center;
  align-content: center;
`;

const Container = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  background: ${color.line};
  border-radius: ${borderRadius.default};
  margin-bottom: ${space[4]};
`;

export function ToggleFilter({
  leftIsActive,
  setLeftIsActive,
  leftToggleText,
  rightToggleText,
}: ToggleFilterParams) {
  const handleLeftClick = () => {
    if (!leftIsActive) setLeftIsActive((current: boolean) => !current);
  };

  const handleRightClick = () => {
    if (leftIsActive) setLeftIsActive((current: boolean) => !current);
  };

  return (
    <Container>
      <Button
        onClick={handleLeftClick}
        style={{
          background: leftIsActive ? color.primary : 'transparent',
          border: leftIsActive ? `1px solid ${color.primary}` : 'none',
          color: leftIsActive ? color.background : color.label,
        }}
      >
        {leftToggleText}
      </Button>
      <Button
        onClick={handleRightClick}
        style={{
          background: leftIsActive ? 'transparent' : color.primary,
          border: leftIsActive ? 'none' : `1px solid ${color.primary}`,
          color: leftIsActive ? color.label : color.background,
        }}
      >
        {rightToggleText}
      </Button>
    </Container>
  );
}
