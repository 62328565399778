import React from 'react';
import { useParams } from 'react-router-dom';

import { Page } from '../components/common/Page';
import { Stack } from '../components/common/Stack';
import { DataGrid } from '../components/DataGrid';
import { useApiQuery } from '../hooks/useApi';

export function AdminCompanyBoxes() {
  const params = useParams();
  const companyId = parseInt(params.id!);

  const boxes = useApiQuery('getBoxes', { companyId });

  return (
    <Page header="Admin" backLink="../" pageTitle="Boxes">
      <Stack>
        {boxes.data?.map(({ id, lift }) => (
          <DataGrid
            key={id}
            data={{
              box_id: id,
              building: lift?.building.name,
              lift: lift?.name,
            }}
          ></DataGrid>
        ))}
      </Stack>
    </Page>
  );
}
