import React from 'react';

import {
  ExpandableContainer,
  SimpleContainer,
} from '../../components/common/Container';
import { Loader } from '../../components/common/Loader';
import { Stack } from '../../components/common/Stack';
import { StatusType } from '../../components/common/StatusIcon';
import { space } from '../../tokens';
import { CarResult, FloorResult } from '../../utils/appApi';
import { arrayLength } from '../../utils/results';
import { DoorCycleError } from './DoorCycleError';
import { ResultCard } from './ResultCard';

const getStatus = (data?: FloorResult | CarResult | null): StatusType => {
  if (!data) {
    return 'info';
  }

  const isError = !!(arrayLength(data.failed) > 0 || !!data.statusCode);

  return isError ? 'error' : 'success';
};

export function ResultPanel({
  title,
  details,
  data,
  loading,
  tipContext,
}: {
  title: string;
  details?: string;
  data?: FloorResult | CarResult | null;
  loading: boolean;
  tipContext?: any;
}) {
  const status = getStatus(data);
  const failure = status === 'error';

  if (!data)
    return <Fallback title={title} loading={loading} status={status} />;

  return (
    <ExpandableContainer
      title={title}
      titleAttr={'cycle' in data ? `${data.cycle}` : ''}
      details={details}
      status={status}
      expanded={failure}
    >
      {data.statusCode ? (
        <DoorCycleError statusCode={data.statusCode} />
      ) : (
        data.data && (
          <Stack gap={space[1]}>
            {Object.entries(data.data).map(([label, value], i) => {
              // Exclude pulley_is_touching_belt and misalignment_cd_vs_ld_sill_gap labels from being displayed
              if (
                label === 'pulley_is_touching_belt' ||
                label === 'misalignment_cd_vs_ld_sill_gap'
              ) {
                return;
              }
              return (
                <ResultCard
                  key={i}
                  label={label}
                  value={value}
                  failed={(data.failed as any).includes(label)}
                  tipContext={tipContext}
                />
              );
            })}
          </Stack>
        )
      )}
    </ExpandableContainer>
  );
}

function Fallback({
  title,
  loading,
  status,
}: {
  title: string;
  loading: boolean;
  status: StatusType;
}) {
  return (
    <SimpleContainer
      title={title}
      status={status}
      icon={loading && <Loader />}
    ></SimpleContainer>
  );
}
