import React from 'react';

import { Center } from '../../components/common/Center';
import { Stack } from '../../components/common/Stack';
import deactivated from '../../components/lift/deactivated.svg';
import { useTranslation } from '../../I18nProvider';

export function NoInstallationControlFound() {
  const t = useTranslation();
  return (
    <Stack>
      <div>{t.noInstallationControlFound}</div>
      <Center>
        <img src={deactivated} />
      </Center>
    </Stack>
  );
}
