import React, {
  MouseEventHandler,
  ReactNode,
  useEffect,
  useState,
} from 'react';
import styled from 'styled-components';

import { borderRadius, color, space } from '../../tokens';
import { ChevronRightIcon } from './Icons';
import { Stack } from './Stack';
import { StatusType } from './StatusIcon';
import { CardTitle, Overline } from './typography';

const Container = styled.div`
  border-radius: ${borderRadius.default};
`;

const Title = styled.div<{
  expanded?: boolean;
  status: StatusType;
}>`
  position: relative;
  overflow: hidden;
  color: ${color.textOnPrimary};
  background: ${(p) => color[p.status]};
  border-radius: ${(p) =>
    p.expanded === true
      ? `${borderRadius.default} ${borderRadius.default} 0 0`
      : borderRadius.default};
  transition: all 200ms;
  user-select: none;
  display: flex;
  align-items: center;
  min-height: 44px;
  padding: ${space[2.5]} ${space[2]} ${space[2.5]} ${space[3]};
  cursor: ${(p) => (p.onClick ? 'pointer' : 'inherit')};
`;

const Icon = styled.div`
  margin-left: auto;
`;

const StatusBar = styled.div<{
  status: StatusType;
}>`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: ${borderRadius.default};
  background: ${(p) => color[p.status]};
`;

const Arrow = styled.div<{ expanded?: boolean }>`
  transition: transform 200ms;
  transform: ${(p) => (p.expanded ? 'rotate(90deg)' : '')};
  transform-origin: center;
`;

const Panel = styled.div<{ expanded?: boolean }>`
  overflow: hidden;
  max-height: ${(p) => (p.expanded ? '100vh' : 0)};
  transition: max-height 200ms;
  border-radius: 0 0 ${borderRadius.default} ${borderRadius.default};
`;

type BaseContainerPros = {
  title: string | ReactNode;
  titleAttr?: string;
  details?: string;
  onClick?: MouseEventHandler;
  status?: StatusType;
  decentStatus?: boolean;
  icon?: ReactNode;
  expanded?: boolean;
  children?: ReactNode;
};

export function SimpleContainer({
  title,
  titleAttr,
  details,
  onClick,
  expanded = false,
  status = 'success',
  decentStatus = false,
  icon,
  children,
}: BaseContainerPros) {
  const isExpanded = expanded !== undefined ? expanded : !!children;
  return (
    <Container>
      <Title
        onClick={onClick}
        status={decentStatus ? 'info' : status}
        expanded={isExpanded}
        className={'foo'}
      >
        {decentStatus && <StatusBar status={status} />}
        <Stack gap={space[1]}>
          <CardTitle title={titleAttr}>{title}</CardTitle>
          {details && <Overline>{details}</Overline>}
        </Stack>
        <Icon>{icon}</Icon>
      </Title>
      {children}
    </Container>
  );
}

type ExpandableContainerProps = BaseContainerPros & {
  children: ReactNode;
};
export function ExpandableContainer({
  title,
  titleAttr,
  details,
  status = 'success',
  decentStatus,
  icon,
  expanded,
  children,
}: ExpandableContainerProps) {
  const [isExpanded, setExpanded] = useState<boolean>();
  useEffect(() => {
    if (expanded && isExpanded === undefined) {
      setExpanded(true);
    }
  }, [expanded]);

  const toggle = () => {
    setExpanded(!isExpanded);
  };

  return (
    <SimpleContainer
      onClick={toggle}
      expanded={isExpanded}
      status={status}
      decentStatus={decentStatus}
      title={title}
      titleAttr={titleAttr}
      details={details}
      icon={<Arrow expanded={isExpanded}>{icon || <ChevronRightIcon />}</Arrow>}
    >
      <Panel expanded={isExpanded}>{children}</Panel>
    </SimpleContainer>
  );
}
