import React, { ReactNode } from 'react';
import styled from 'styled-components';

import { borderRadius, color, space } from '../../tokens';
import { Row } from './Row';
import { Stack } from './Stack';
import { StatusIcon, StatusType } from './StatusIcon';
import { CardSubTitle, CardTitle, TextXs } from './typography';

const Container = styled(TextXs)<{ status: StatusType }>`
  display: flex;
  justify-content: space-between;
  border-radius: ${borderRadius.default};
  background: ${color.cardBackground};
  color: ${color.cardText};
  & ${CardTitle} {
    color: ${color.heavyCardText};
    margin-top: 1px;
  }
  img {
    width: 70px;
    object-fit: cover;
    border-radius: 0 ${borderRadius.default} ${borderRadius.default} 0;
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-self: stretch;
  & > * + * {
    margin-top: 0.5em;
  }
`;

const ChildContent = styled.div`
  flex: 1;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-self: stretch;
  justify-content: center;
`;

const Text = styled(Row)`
  display: flex;
  flex-wrap: nowrap;
  padding: ${space[2]} ${space[3]};
  min-height: 60px;
  & > svg {
    flex-shrink: 0;
  }
`;

type Props = {
  status: StatusType;
  title: string;
  subTitle?: string;
  children?: ReactNode;
  image?: string | null;
  onClick?: () => void;
};
export function ErrorCard({
  status,
  title,
  subTitle,
  image,
  onClick,
  children,
}: Props) {
  return (
    <Container status={status} onClick={onClick}>
      <Text align="top">
        <StatusIcon status={status} />
        <Content>
          <CardTitle>
            {title}
            {subTitle && <CardSubTitle>{subTitle}</CardSubTitle>}
          </CardTitle>
          <ChildContent>
            <Stack>{children}</Stack>
          </ChildContent>
        </Content>
      </Text>
      {image && <img src={image} />}
    </Container>
  );
}
