import React, { ReactNode } from 'react';
import styled from 'styled-components';

import { Translations } from '../i18n';
import { useTranslation } from '../I18nProvider';
import { borderRadius, color, fontSize, space } from '../tokens';
import { Row } from './common/Row';
import { StatusIcon, StatusType } from './common/StatusIcon';
import { SectionHeading, TextXs } from './common/typography';

const Table = styled.table`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: ${space[4]};
  color: ${color.heavyCardText};
  background: ${color.cardBackground};
  border-radius: ${borderRadius.default};
  & ${SectionHeading} {
    margin-bottom: ${space[2]};
  }
  tbody {
    color: ${color.cardText};
    display: flex;
    flex-direction: column;
  }

  tr {
    display: flex;
    flex-wrap: wrap;
  }
`;

const TableHeader = styled.th`
  flex: 1;
  display: block;
  text-align: left;
  font-weight: normal;
`;
const Value = styled.td`
  text-align: right;
  align-items: center;
  white-space: nowrap;
`;

const UpdateButton = styled.div`
  background-color: ${color.primary};
  border: none;
  color: ${color.background};
  padding: 6px 16px;
  text-align: center;
  text-decoration: none;
  display: block;
  font-size: ${fontSize.xs};
  border-radius: 3px;
  cursor: pointer;
  margin-top: ${space[3]};
  width: 100%;
`;

type Props = {
  title?: string;
  icon?: ReactNode;
  data: Record<string, ReactNode>;
  showButton?: boolean;
  onButtonClick?: any;
};

export function DataGrid({
  icon,
  title,
  data,
  showButton,
  onButtonClick,
}: Props) {
  const t = useTranslation();

  return (
    <Table>
      {title && (
        <SectionHeading as="caption">
          <Row>
            {icon}
            <span>{title}</span>
          </Row>
        </SectionHeading>
      )}
      <tbody>
        {Object.entries(data)
          .filter(([_, value]) => value !== null && value !== '')
          .map(([label, value], i) => (
            <TextXs as="tr" key={i}>
              <TableHeader>{(t as any)[label] ?? label}</TableHeader>
              <Value>
                {format(value, t)} {units[label]}{' '}
                <RatingStatus label={label} value={value} />
              </Value>
            </TextXs>
          ))}
      </tbody>
      {showButton && (
        <tfoot>
          <tr>
            <td style={{ width: '100%' }}>
              <UpdateButton onClick={onButtonClick}>
                {t.updateStaticData}
              </UpdateButton>
            </td>
          </tr>
        </tfoot>
      )}
    </Table>
  );
}

function format(value: ReactNode, t: Translations) {
  if (value === null) return '';
  if (typeof value === 'number') return value;
  if (typeof value === 'boolean') return value ? t.yes : t.no;
  if (typeof value === 'string' && value in t)
    return t[value as keyof typeof t];
  return value;
}

const ratings: Record<string, (x: number) => StatusType> = {
  powerReductionFactor: (x: number) =>
    x >= 0.9 ? 'success' : x >= 0.6 ? 'warning' : 'error',
  motorTemperature: (x: number) =>
    x <= 70 ? 'success' : x <= 90 ? 'warning' : 'error',
  encoderTemperature: (x: number) =>
    x <= 50 ? 'success' : x <= 70 ? 'warning' : 'error',
  ambientTemperature: (x: number) =>
    x <= 50 ? 'success' : x <= 70 ? 'warning' : 'error',
};

function RatingStatus({ label, value }: { label: string; value: unknown }) {
  const fn = ratings[label];
  if (fn && typeof value === 'string') {
    return <StatusIcon size="1em" status={fn(parseInt(value))} />;
  }
  return null;
}

const units: Record<string, string> = {
  motorTemperature: '°C',
  encoderTemperature: '°C',
  ambientTemperature: '°C',
  doorwidth: 'm',
  couplerwidth: 'm',
};
