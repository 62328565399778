import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { BuildingForm } from '../components/building/BuildingForm';
import { BuildingHeader } from '../components/building/BuildingHeader';
import { IconButton } from '../components/common/IconButton';
import { DeleteIcon } from '../components/common/Icons';
import { Page } from '../components/common/Page';
import { useApiMutation, useApiQuery } from '../hooks/useApi';
import { useTranslation } from '../I18nProvider';
import { BuildingArgs, updateBuilding } from '../utils/api';

export function EditBuilding() {
  const t = useTranslation();
  const params = useParams();
  const id = parseInt(params.buildingId!);
  const deleteBuilding = useApiMutation('deleteBuilding');
  const query = useApiQuery('getBuilding', id);

  const navigate = useNavigate();
  const onSubmit = async (values: BuildingArgs) => {
    await updateBuilding(id, values);
    navigate('..');
  };

  const deleteButton = (
    <IconButton
      disabled={deleteBuilding.isLoading}
      title={t.delete}
      onClick={async () => {
        if (confirm(t.confirmDeleteBuilding)) {
          await deleteBuilding(id);
          navigate('/');
        }
      }}
    >
      <DeleteIcon />
    </IconButton>
  );

  return (
    <Page
      pageTitle={t.editBuilding}
      backLink=".."
      header={<BuildingHeader id={id} action={deleteButton} />}
    >
      <BuildingForm
        building={query.data}
        onSubmit={onSubmit}
        onCancel={() => navigate('..')}
        showNameInputField={true}
      />
    </Page>
  );
}
