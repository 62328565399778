import React, { useState } from 'react';

import { Button } from '../../components/common/Button';
import { Center } from '../../components/common/Center';
import { Stack } from '../../components/common/Stack';
import { useTranslation } from '../../I18nProvider';
import closeDoor from './closeDoor.svg';
import numberPad from './numberPad.svg';

type Props = {
  floor: string;
  onCloseDoor: () => Promise<void>;
  onContinue: () => void;
};
export function TestInstructions({ floor, onCloseDoor, onContinue }: Props) {
  const [doorClosed, setDoorClosed] = useState(false);
  if (doorClosed) return <CallFloors onProceed={onContinue} />;
  return (
    <CloseDoor
      floor={floor}
      onProceed={async () => {
        await onCloseDoor();
        setDoorClosed(true);
      }}
    />
  );
}

function CloseDoor({
  floor,
  onProceed,
}: {
  floor: string;
  onProceed: () => void;
}) {
  const t = useTranslation();
  const [isDisabled, setIsDisabled] = useState(false);

  const onButtonClick = () => {
    setIsDisabled(true);
    onProceed();
  };

  return (
    <Stack gap="3em">
      <p>{t.closeDoorInstructions(floor)}</p>
      <Center>
        <img src={closeDoor} />
      </Center>
      <Button variant="primary" onClick={onButtonClick} disabled={isDisabled}>
        {t.doorIsClosed}
      </Button>
    </Stack>
  );
}

function CallFloors({ onProceed }: { onProceed: () => void }) {
  const t = useTranslation();
  return (
    <Stack gap="3em">
      <p>{t.callFloorsInstruction}</p>
      <Center>
        <img src={numberPad} />
      </Center>
      <Button variant="primary" onClick={onProceed}>
        {t.continue}
      </Button>
    </Stack>
  );
}
