import styled, { keyframes } from 'styled-components';

const loading = keyframes`
  0%,
  80%,
  100% {
    box-shadow: 0 0, 0 0;
  }
  40% {
    box-shadow: 0 -0.5rem, 0 0.5rem;
  }
`;

export const Loader = styled.div`
  color: currentColor;
  text-indent: -9999rem;
  position: relative;
  transform: translateZ(0);
  animation-delay: -0.16s;
  margin: 0 0.75rem;
  &,
  ::before,
  ::after {
    background: currentColor;
    animation: ${loading} 1s infinite ease-in-out;
    width: 0.3rem;
    height: 0.5rem;
  }
  ::before,
  ::after {
    position: absolute;
    content: '';
    top: 0;
    margin: 0 0.15rem;
  }
  ::before {
    right: 100%;
    animation-delay: -0.32s;
  }
  :after {
    left: 100%;
  }
`;
