import React, { ReactNode } from 'react';
import styled from 'styled-components';

import { borderRadius, color, space } from '../../tokens';
import { WarningIcon } from './Icons';
import { TextXs } from './typography';

const Box = styled.div`
  display: flex;
  border: 1px solid ${color.error};
  border-radius: ${borderRadius.default};
  background: ${color.cardBackground};
  color: ${color.cardText};
  padding: ${space[2.5]};
  svg {
    flex-shrink: 0;
    margin-right: ${space[1.5]};
    color: ${color.error};
  }
`;

export function ErrorHint({ children }: { children: ReactNode }) {
  return (
    <Box>
      <WarningIcon />
      <TextXs>{children}</TextXs>
    </Box>
  );
}
