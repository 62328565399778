import { FloorResult } from './api';

export function range(length: number) {
  return Array.from(Array(length).keys());
}

export function arrayLength(obj: unknown) {
  return Array.isArray(obj) ? obj.length : 0;
}

export function getFloorPreset(floors: FloorResult[]) {
  const preset: boolean[] = [];
  floors.forEach((floor) => {
    preset[floor.floor] = arrayLength(floor.failed) > 0 || !!floor.statusCode;
  });
  return preset;
}
