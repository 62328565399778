import React from 'react';

import { Translations } from './en';

export const fr: Translations = {
  aborted: 'Annulé',
  accelerationDescription:
    'Facteur d’accélération utilisé pour le calcul de la courbe.',
  accelerationNote:
    'Ce facteur doit être suffisamment élevé pour atteindre les vitesses réglées, sinon la porte n’atteindra pas la vitesse souhaitée.',
  accelerationTitle: 'Accélération',
  acceptTerms: 'J’accepte les conditions d’utilisation',
  add: 'Ajouter',
  addBuilding: 'Ajouter un bâtiment',
  addLift: 'Ajouter un ascenseur',
  addressHint:
    'Saisissez le nom d’une entreprise ou d’un bâtiment pour obtenir des suggestions d’adresses et d’images.',
  adjustCameraSettings:
    'Vous pouvez également ajuster les paramètres de la caméra dans le menu déroulant.',
  admin: 'Admin',
  alreadyHasSubcontractorsError:
    "En tant que sous-traitant, vous ne pouvez pas avoir de sous-traitants. Veuillez supprimer les sous-traitants existants avant d'accepter cette invitation.",
  all: 'Tous',
  allBuildings: 'Tous les bâtiments',
  allBuildingsSubscription: 'De tous les bâtiments',
  alphabetical: 'Alphabétique',
  ambientTemperature: 'Température de la boîte',
  assetNumber: 'ID d’ascenseur',
  assetNumberHint:
    'Veuillez saisir l’ID (c’est-à-dire le numéro d’installation/d’équipement) de l’ascenseur si vous l’avez sous la main.',
  auto: 'Automatique',
  back: 'Retour',
  backToDashboard: 'Retour au tableau de bord',
  belt_tension: 'Tension de la courroie',
  belt_tension_actions:
    'Réglez la tension de la courroie comme décrit dans le manuel d’installation.',
  beltclamp: 'Pince à courroie',
  belt_tension_description: 'La tension de la courroie est trop élevée/faible.',
  bottom: 'bas',
  bottomToTop: 'De bas en haut',
  boxLinkedToOtherLift: (lift: string, building: string) =>
    `La boîte est liée à ${lift} dans ${building}. Continuer?`,
  boxNotReady: 'La boîte sélectionnée n’est pas prête.',
  boxOfflineHint: `Si vous souhaitez mettre à jour l'un des boîtiers énumérés ci-dessous veuillez d'abord le connecter à l'internet.`,
  boxScannedNoIdFound: 'Boîte scannée, mais aucun ID trouvé.',
  boxSoftware: 'Logiciel',
  boxSoftwareStatusError:
    "Le statut des boîtes n'a pas pu être récupéré : veuillez vérifier la connexion Internet de la boîte et réessayez. Si le problème persiste, contactez votre représentant Wittur.",
  boxSoftwareTitle: 'Box Software',
  boxSoftwareUpdateError:
    'Échec de la mise à jour du logiciel de la boîte : veuillez vérifier la connexion Internet de la boîte et réessayez. Si le problème persiste, contactez votre représentant Wittur.',
  box_id: 'ID de boîte',
  brakeParDescription:
    'En raison du réglage de ce paramètre, le retard et la dérive vers la fin d’ouverture/fermeture sont influencés (préréglé sur « 5 »).',
  brakeParNote:
    'La plage de réglage possible est « 0 »... « 9 », mais le premier frein est atteint avec le paramètre « 0 » et le dernier retard est atteint avec le paramètre « 9 ».',
  brakeParTitle: 'Paramètre de freinage',
  buffer: 'Tampon',
  building: 'Bâtiment',
  buildingHasNoElevator: "Ce bâtiment n'a pas d'ascenseurs.",
  buildingName: 'Nom',
  buildings: 'Bâtiments',
  buildingsWithoutValidAddress: 'Bâtiments sans adresse valide',
  bushingClose: 'Manchon fermé',
  bushingOpen: 'Manchon ouvert',
  cablechain: 'Chaîne de câble',
  cableTensorSpring: 'Ressort de tension du câble',
  callFloorsInstruction: (
    <>
      {' '}
      Ouvrez la porte et <b>appelez tous les autres étages</b>. Sortez de la
      cabine et cliquez sur «Continuer».
    </>
  ),
  cameraMode: 'Mode caméra',
  cancel: 'Annuler',
  car: 'Cabine',
  carDoor: 'Porte de la cabine',
  carDoorCounter:
    "Compteur de cycles de porte de l'électronique de porte précédente",
  carDoorModified: 'La porte de la cabine a été modifiée',
  cd_counter_rollers_installation: 'Contre-rouleaux',
  cd_counter_rollers_installation_actions:
    '`Pour chaque porte palière, vérifiez si les galets peuvent être tournés facilement à la main et si leur distance au rail n’est pas trop grande. Si tel est le cas, desserrez les galets et réajustez-les afin qu’ils soient le plus près possible du rail, mais sans le toucher, et qu’ils puissent toujours être tournés facilement à la main. Si vous devez démonter le coupleur pour accéder aux galets, suivez les instructions de montage.`',
  cd_counter_rollers_installation_description:
    'Les contre-rouleaux CD ne sont pas installés correctement.',
  cd_roller: 'Galet CD',
  chooseFloorOrCardoor: 'Choisissez Étage / Cardoor',
  choosePartNumber: 'Choisissez le numéro de pièce',
  chooseReason: 'Choisissez une raison (facultatif)',
  chooseStartingFloorInstructions:
    'Veuillez sélectionner l’étage de départ de la course de contrôle. L’étage de départ peut être soit l’étage supérieur, soit l’étage inférieur.',
  clearDriveErrors: 'Effacer',
  close: 'Fermer',
  closeDoorInstructions: (floor: string) => (
    <>
      {' '}
      Dirigez-vous vers l’étage <b>{floor}</b>. Assurez-vous que la porte de la
      cabine est fermée avant de continuer.
    </>
  ),
  closeForcePotiDescription:
    "Définir la limite de force utilisée pour l'ouverture et la fermeture de la porte.",
  closeForcePotiNote:
    "Réglable par potentiomètre sur l'électronique. Sur les nouveaux systèmes électroniques comme le MIDIV2, le réglage peut se faire ici en mode de conduite de service si nécessaire (il n'est pas nécessaire de le régler dans les applications standard en raison de l'auto-apprentissage).",
  closeForcePotiTitle: 'Limite de la force de fermeture',
  closeHoldForceDescription: 'Force appliquée en fin de fermeture.',
  closeHoldForceNote:
    'Cette force doit être comprise entre 50 N et 70 N. Sachez qu’une force trop faible entraînera des ouvertures de coupleur indésirables. Une force trop élevée chauffera inutilement le moteur et pourra le faire fonctionner en mode de réduction.',
  closeHoldForceTitle: 'Force de fin de fermeture (maintien)',
  closeSpeedDescription:
    'Vitesse maximale de la porte dans le sens de fermeture. La courbe de fermeture est calculée pour atteindre cette vitesse réglée si possible.',
  closeSpeedNote:
    'La vitesse réelle de la porte est également liée aux commutateurs DIP de vitesse des entraînements. Le réglage sur la vitesse 4 signifie 100 % de la valeur 2.',
  closeSpeedTitle: 'Vitesse de fermeture',
  closing_device_failure: 'Dispositif de fermeture',
  closing_device_failure_actions:
    '`Vérifiez si tous les composants du dispositif de fermeture de la porte palière fonctionnent correctement (Spirator, ressort de fermeture et poids de fermeture). La porte palière doit pouvoir se fermer d’elle-même à partir de n’importe quelle position de porte.`',
  closing_device_failure_description:
    'Le dispositif de fermeture n’est pas installé correctement. La force nécessaire pour fermer la porte est trop grande.',
  companyName: "Nom de l'entreprise",
  completed: 'Terminé',
  componentDetails: 'Détails du composant',
  componentDetailsHint:
    "Pour certains composants, plusieurs options de numéro de pièce doivent être sélectionnées. Il s'agit d'informations importantes pour l'utilisation de la fonction de maintenance préventive. Veuillez cliquer sur les éléments répertoriés pour sélectionner la pièce réellement installée.",
  confirm: 'Confirmer',
  confirmDeleteBuilding: 'Voulez-vous vraiment supprimer ce bâtiment ?',
  confirmDeleteErrorHistory:
    "Voulez-vous vraiment supprimer cet historique d'erreurs?",
  confirmDeleteFavoriteBuilding:
    'Voulez-vous vraiment supprimer ce bâtiment de vos favoris ?',
  confirmDeleteLift: 'Voulez-vous vraiment supprimer cet ascenseur ?',
  confirmInvite: 'Confirmer et Connecter',
  continue: 'Continuer',
  contractorBuilding: 'Bâtiment du contractant',
  contractorBuildings: 'Bâtiments du contractant',
  contractors: 'Contractants',
  couplerelease: 'Déblocage du coupleur',
  couplerFriction: 'Friction du coupleur',
  couplerMovementParameter: 'Paramètre de mouvement du sabre',
  couplerSpeedDescription:
    'Cette valeur définit la vitesse maximale de la courroie, dans la zone définie du coupleur.',
  couplerSpeedTitle: 'Vitesse du coupleur',
  couplerWitdhDescription:
    'Cette valeur définit la distance de la courroie dans la zone du coupleur. Cette valeur résulte de la procédure d’apprentissage.',
  couplerWitdhTitle: 'Largeur du coupleur',
  currentVersion: 'Version actuelle',
  couplerwidth: 'Largeur du coupleur',
  date: 'Date',
  delete: 'Supprimer',
  deleteErrorHistory: "Supprimer l'historique des erreurs",
  directConnection: (
    <>
      <p>
        {' '}
        Connectez-vous au WIFI de la boîte ELEVATORSENSE en scannant le code QR
        imprimé sur la boîte avec l’application appareil photo de votre
        téléphone.
      </p>
      <p>
        {' '}
        Une fois connecté, revenez à cet écran et appuyez sur le bouton{' '}
        <b>Continuer</b> ci-dessous.
      </p>
    </>
  ),
  disconnectProgrammingTool: 'Déconnecter la fonction "Wireless Programming',
  divpulley: 'Divpulley',
  documentation: 'Documentation',
  doorContact: 'Contact de porte',
  doorCycleCounter: 'Compteur de cycles de porte',
  doorCycleError1: 'Aucune donnée de cycle de porte n’a été reçue.',
  doorCycleError2: 'Le mouvement du cycle de la porte a été perturbé.',
  doorCycleError4: 'Une réouverture de la porte a été détectée.',
  doorCycleError5: 'Une refermeture de la porte a été détectée.',
  doorCycleError7: 'Aucun cycle de porte valide n’a été reçu.',
  doorCycles: 'Cycles de la porte',
  doorFriction: 'Friction de la porte',
  doorInstallationDate: "Date d'installation de la porte",
  doorIsClosed: 'Oui, la porte est fermée',
  doorModel: 'Modèle de porte',
  doorModelNotSupported:
    "Le modèle de porte sélectionné n'est pas disponible pour la maintenance préventive.",
  doorMovementParameter: 'Paramètre de mouvement de la porte',
  doorPosition: 'Position de la porte',
  doorSerialNumber: 'Numéro de série de la porte',
  doorType: 'Type de porte',
  doorwidth: 'Largeur d’ouverture de la porte',
  downloadCertificate: 'Télécharger le certificat',
  downloadLicense: 'Télécharger toutes les licences au format CSV',
  downloadRequestedExtendedWarranty: `Télécharger toutes les demandes d'extension de garantie`,
  drive_motor_noise: 'Bruit du moteur d’entraînement',
  driveesn: 'ESN',
  drivesoftware: 'Micrologiciel',
  editBuilding: 'Modifier un bâtiment',
  editLift: 'Modifier un ascenseur',
  elecBoard: 'Carte électronique',
  elevatorIdNotFound: (
    <>
      Pour bénéficier d'une extension de garantie, la série de portes doit être
      introduite dans les paramètres de l'ascenseur.
      <b>Cliquez ici pour accéder aux réglages.</b>
    </>
  ),
  elevatorInstallationDate: "Date d'installation de l'ascenseur",
  elevatorUsageType: "Type d'utilisation de l'ascenseur",
  email: 'Courriel',
  encoderTemperature: 'Température du capteur rotatif',
  error: 'Erreur',
  errorDescription: 'Description de l’erreur',
  errorEarlierThanInstallationDate:
    "Il n'est pas possible d'entrer une date antérieure à la date d'installation.",
  errorInvalid: 'Veuillez saisir une valeur valide.',
  errorInvalidOperation: 'Opération invalide',
  errorMessages: 'Messages d’erreur',
  errorMessagesDescription: 'Affichage des messages d’erreur enregistrés.',
  errorNotFound: 'Ressource introuvable',
  errorRetryCamera:
    "Impossible d'accéder à la caméra. Nouvelle tentative, la page va se recharger...",
  errorRequired: 'Ce champ est obligatoire.',
  errorUnknownOrForbiddenBox: `La boîte n'est pas disponible. Veuillez contacter votre service Wittur si vous avez besoin d'aide.`,
  errorUnableToAccessCamera:
    "Impossible d'accéder à la caméra après plusieurs tentatives. Veuillez actualiser la page ou vérifier les autorisations de la caméra.",
  errors: 'Erreurs',
  errorsEmpty: 'Pas de messages.',
  errorsEmptyHint:
    'Veuillez vérifier vos filtres et éventuellement activer des types supplémentaires.',
  extendWarranty: 'Extension de la garantie',
  fatalError: 'Erreur fatale',
  fatalErrors: 'Erreurs fatales',
  favorite: 'Favori',
  favorites: 'Favoris',
  figureAdjustment: 'Réglage',
  figureAugustaEVO: 'Exemple Augusta EVO',
  figureClosingWeight: 'Poids de fermeture',
  figureEco: 'ECO+',
  figureHydra: 'Exemple Hydra',
  figureMidi: 'MIDI/SUPRAV2',
  figureNotOk: 'Pas OK',
  figureOk: 'OK',
  figureSGV: 'Exemple SGV',
  figureSpirator: 'Spirator',
  figureSpring: 'Ressort de fermeture',
  firstName: 'Prénom',
  floor: 'Étage',
  floors: 'Étages',
  followingBoxScanned: 'La boîte suivante a été scannée',
  forgotPassword: 'Mot de passe oublié ?',
  forgotPasswordText:
    'Saisissez votre adresse électronique afin de réinitialiser votre mot de passe.',
  groundFloor: 'Rez-de-chaussée',
  home: 'Accueil',
  incompleteElevatorSettings:
    "Afin d'utiliser la maintenance préventive, nous avons besoin de plus d'informations sur cet ascenseur. Veuillez vous rendre dans les paramètres de votre ascenseur et remplir les informations manquantes.",
  initialized: 'Initialisé',
  installationControl: 'Contrôle d’installation',
  installationControlDescription:
    'Effectuez des vérifications automatiques pour détecter les problèmes d’installation courants.',
  installationControlExecutedBy: 'Exécuté par',
  installationControlHeadline: 'Dernier état d’installation',
  installationControlHistory: 'Historique des courses',
  installationControlHistoryEmpty: 'Aucune course d’installation enregistrée',
  installationControlInstructions: 'Afficher les instructions',
  installationControlSubheadline: 'Résumé de toutes les courses',
  installationDateErrorMessage:
    "Il est impossible de saisir une date d'installation de porte antérieure à la date d'installation de l'ascenseur",
  instantSubscription: 'Notification instantanée',
  instantSubscriptionExplanation: (
    <>
      La <b>notification instantanée</b> est une notification immédiate par
      courriel dès qu’une erreur fatale s’est produite sur un ascenseur. Les
      erreurs fatales sont des erreurs qui peuvent entraîner le
      dysfonctionnement immédiat de l’ascenseur.
    </>
  ),
  invitationExpired: 'Le lien d’invitation n’est plus valide.',
  inviteAsManager: 'L’utilisateur peut inviter d’autres utilisateurs.',
  inviteCompanyWithSubcontractorsError:
    "L'entreprise que vous essayez d'inviter a déjà des sous-traitants et ne peut pas être ajoutée en tant que sous-traitant. Ils doivent supprimer les sous-traitants existants ou choisir une autre entreprise.",
  inviteCoworkers: 'Inviter des collègues',
  inviteSubcontractor: 'Inviter un sous-traitant',
  inviteToSignUpMessage: (companyName: string) =>
    `L'entreprise ${companyName} vous a invité à vous inscrire sur ElevatorSense en tant que sous-traitant. Veuillez remplir le formulaire pour vous inscrire.`,
  inviteToBeSubcontractorMessage: (companyName: string) =>
    `${companyName} vous a invité à être leur sous-traitant dans ElevatorSense.`,
  ipaddress: 'Adresse IP',
  joinText: (name: string) => (
    <>
      Veuillez vous inscrire pour rejoindre l’organisation <i>{name}</i>.
    </>
  ),
  landingDoor: 'Porte palière - Étage',
  landing_lock_roller_adjustment:
    'Réglage du rouleau de verrouillage de palier',
  landingCarDoorSpareParts: 'Pièces de rechange de porte palière/cabine',
  lastError: 'Dernière erreur',
  lastExchangeDate: "Dernière date d'échange",
  lastMaintenanceDate: 'Date de la dernière maintenance',
  lastMaintenanceScope: 'Portée de la dernière maintenance',
  lastMaintenanceScopeHint:
    "Sélectionnez l'étendue de la maintenance recommandée par Wittur qui a été effectivement effectuée à la dernière date de maintenance.",
  lastName: 'Nom de famille',
  lastTimeDoorElectronicsChange:
    "Date de remplacement de l'électronique de la porte",
  latestSoftwarePackVersion: (version: string) =>
    `Version du logiciel : ${version}`,
  ld_counter_rollers_installation: 'Contre-galets',
  ld_counter_rollers_installation_actions:
    '`Pour chaque porte palière, vérifiez si les galets peuvent être tournés facilement à la main et si leur distance au rail n’est pas trop grande. Si tel est le cas, desserrez les galets et réajustez-les afin qu’ils soient le plus près possible du rail, mais sans le toucher, et qu’ils puissent toujours être tournés facilement à la main. Si vous devez démonter le coupleur pour accéder aux galets, suivez les instructions de montage.`',
  ld_counter_rollers_installation_description:
    'Les contre-galets LD ne sont pas installés correctement.',
  ld_roller: 'Galet LD',
  left: 'Gauche',
  licenseRequired:
    'Vous n’avez pas acheté de licence valide pour ce produit. Veuillez contacter votre représentant commercial Wittur pour acheter ce produit ElevatorSense.',
  lift: 'Ascenseur',
  liftMovementDistance: 'Mouvement de l’ascenseur',
  liftSetup: 'Configuration de l’ascenseur',
  liftSetupComplete: 'Configuration terminée.',
  liftSetupError: 'Les données correspondantes sont incomplètes ou manquantes.',
  liftSetupError40:
    'Le mouvement de l’ascenseur ne correspond pas à la course de configuration.',
  liftSetupIncomplete: 'Configuration incomplète.',
  liftSetupPending:
    "Veuillez effectuer l'exécution de l'installation pour la configuration initiale d'ElevatorSense sur votre ascenseur. Sans l'exécution de la configuration, vous ne pourrez pas utiliser certaines fonctionnalités des produits ElevatorSense ou les résultats des produits ElevatorSense peuvent différer. Cliquez ici pour effectuer la configuration.",
  liftSetupRepeat:
    'La course de configuration n’a pas réussi. Veuillez recommencer l’analyse en tenant compte de la description du processus.',
  lifts: 'Ascenseurs',
  linkBox: 'Associer la boîte',
  linkToWebshop: (link: string) => (
    <>
      Si vous avez une boîte ElevatorSense et que vous souhaitez activer une
      licence, cliquez{' '}
      <a href={link} className="clickable-link">
        ici
      </a>
      .
    </>
  ),
  loading: 'Chargement',
  login: 'Connexion',
  loginText: 'Veuillez vous connecter avec votre compte.',
  logout: 'Déconnexion',
  mandatory: 'Effectuer le contrôle de remplacement',
  manageAccess: 'Gérer l’Accès',
  manageElevators: 'Gérer les ascenseurs',
  manageSharedBuildingsAndElevator: 'Gérer les bâtiments/ascenseurs partagés',
  manual: 'Manuel',
  maxElectronics: 'Ascenseurs max.',
  maxUsage: 'Utilisation max.',
  members: 'Membres',
  misalignment_cd_vs_ld: 'Alignement porte/sabre',
  misalignment_cd_vs_ld_actions:
    'Réglez les galets de verrouillage de la porte palière. Le jeu de fonctionnement entre les aubes du sabre et les galets doit être le même à gauche et à droite.',
  misalignment_cd_vs_ld_description:
    'Le sabre de porte de cabine est mal aligné avec les galets de verrouillage de la porte palière.',
  misalignment_cd_vs_ld_sill_gap: 'Écart de seuil',
  misalignment_cd_vs_ld_sill_gap_actions:
    'L’écart du seuil à l’extrémité gauche et à l’extrémité droite de l’ouverture de la porte n’est pas égal. Si cela se produit sur tous les paliers, réglez la position du seuil de la porte de la cabine.',
  misalignment_cd_vs_ld_sill_gap_description:
    'La porte palière n’est pas parallèle à la porte de la cabine.',
  mode: 'Mode',
  monitoring: 'Surveillance',
  motor: 'Moteur',
  motorTemperature: 'Température du moteur',
  myBuildingsSubscription: 'De mes favoris uniquement',
  name: 'Nom',
  networkQuality: 'Qualité du réseau',
  newInstallationControl: 'Nouveau contrôle d’installation',
  next: 'Suivant',
  nextMaintenanceDate: 'Prochaine date de maintenance',
  no: 'Non',
  notAuthorizedToConfirmInviteError:
    "Vous n'êtes pas autorisé à confirmer cette invitation, car elle n'est pas destinée à votre utilisateur connecté.",
  noBoxLinked:
    'L’ascenseur n’est pas encore lié à une boîte ELEVATORSENSE. Afin d’utiliser toutes les fonctionnalités de l’application, veuillez scanner le code QR sur la boîte ou saisir manuellement l’identifiant de la boîte.',
  noBuildingsVisible:
    "Aucun bâtiment n'est visible dans la vue actuelle de la carte. Essayez d'ajuster la carte pour voir plus de bâtiments.",
  noComponentsFound: 'Aucun composant trouvé pour ce sol',
  noFavorite: 'Pas un favori',
  noFavoritesChoosen:
    'Vous n’avez pas encore sélectionné de bâtiments favoris. Naviguez jusqu’à votre bâtiment et cliquez sur l’icône en forme d’étoile pour l’ajouter à vos bâtiments favoris.',
  noInstallationControlFound:
    "L'ESN de l'électronique de porte ne correspond pas à l'ESN du dernier cycle de contrôle de l'installation. Veuillez vérifier si vous êtes connecté au bon ascenseur.",
  noSubcontractors: 'Aucun sous-traitant trouvé.',
  noSubcontractorMessage:
    "Aucun sous-traitant n'est actuellement lié à votre entreprise. Pour en établir un, envoyez une invitation.",
  noSubscription: 'Aucun',
  note: 'Remarque',
  noQRCodeDetected: `Aucun code QR détecté pour l'instant. Veuillez déplacer la caméra afin que le code QR soit bien mis au point et entièrement visible dans le champ de vision.`,
  nudgingSpeedDescription:
    'Vitesse de la porte en condition de Nudging. La courbe d’ouverture/fermeture est calculée pour atteindre cette vitesse réglée si le Nudging est actif.',
  nudgingSpeedNote:
    'La vitesse réelle de la porte est également liée aux commutateurs DIP de vitesse des entraînements. Le réglage sur la vitesse 4 signifie 100 % de la valeur 3.',
  nudgingSpeedTitle: 'Vitesse de Nudging',
  obsolete: 'Obsolète',
  offline: 'Offline',
  offlineConnection:
    'Vous êtes actuellement hors ligne, mais vous pouvez utiliser l’outil de programmation.',
  ok: 'Mantenimiento periódico',
  online: 'Online',
  openHoldForceDescription: 'Force appliquée en fin d’ouverture.',
  openHoldForceNote:
    'Cette force doit être comprise entre 50 N et 60 N. Sachez qu’une force trop faible entraînera des fermetures et des ouvertures indésirables. Une force de fin d’ouverture trop élevée chauffera inutilement le moteur et pourra le faire fonctionner en mode de réduction.',
  openHoldForceTitle: 'Force de fin d’ouverture (maintien)',
  openSpeedDescription:
    'Vitesse maximale de la porte dans le sens d’ouverture. La courbe d’ouverture est calculée pour atteindre cette vitesse réglée si possible.',
  openSpeedNote:
    'La vitesse réelle de la porte est également liée aux commutateurs DIP de vitesse des entraînements. Le réglage sur la vitesse 4 signifie 100 % de la valeur 1.',
  openSpeedTitle: 'Vitesse d’ouverture',
  otherReason: 'Autre raison',
  overdue: 'En retard',
  parameter: 'Paramètre',
  partInformation: 'Informations sur la Pièce',
  partIsStillOk: 'La pièce est toujours OK',
  partNumber: 'Numéro de Pièce',
  partName: 'Nom de la Pièce',
  password: 'Mot de passe',
  passwordResetSent:
    'Nous vous avons envoyé un courriel contenant des instructions pour réinitialiser votre mot de passe. Veuillez vérifier votre boîte de réception.',
  pendingInvites: 'Invitations en attente',
  pendingUserInvites: 'Invitations utilisateur en attente',
  pendingSubcontractorInvites: 'Invitations de sous-traitant en attente',
  planningAndAlerts: 'Planification et alertes',
  position: 'Position',
  positionOutDescription:
    'En saisissant ce paramètre, le relais de position (lié au FPC) peut être réglé pour commuter à n’importe quelle position de porte. Le réglage standard est 0 (désactivé).',
  positionOutNote:
    'En cas de réglage de ce paramètre sur une autre valeur, le relais commute lorsqu’il atteint la position. Cette fonction n’est presque utilisée que dans les cages avec des cabines pré-mobiles.',
  positionOutTitle: 'Position de sortie',
  postpone: 'Reporter',
  powerReductionFactor: 'Facteur de réduction de puissance',
  prev: 'Précédent',
  preventiveMaintenance: 'Maintenance préventive',
  preventiveMaintenanceDescription:
    "Remplacez les pièces de rechange de votre porte d'ascenseur avant qu'elles ne se cassent.",
  preventiveMaintenanceUnavailable:
    "Les données saisies sont complètes ! ElevatorSense va maintenant calculer le calendrier d'entretien en fonction de l'utilisation de votre ascenseur. Ce processus peut prendre quelques minutes.",
  priority: 'Priorité',
  product: 'Produit',
  programmingDisabledHint:
    'La programmation de l’électronique est actuellement désactivée par le matériel. Assurez-vous que le commutateur DIP 1 est activé pour permettre la programmation de l’appareil.',
  programmingErrorConnection: (
    <>
      <p>
        We are discovering troubles with the connection to ELEVATORSENSE box.
      </p>
      <p>
        Please make sure to switch off your "mobile data connection" and ensure
        you are connected to the WIFI of the ELEVATORSENSE box only.
      </p>
      <p>
        Afterwards hit the <b>continue</b> button below.
      </p>
    </>
  ),
  programmingErrorNoPopup:
    'Impossible d’ouvrir la fenêtre. Assurez-vous que les fenêtres contextuelles ne sont pas bloquées par votre navigateur.',
  programmingErrorRequest:
    'Demande échouée. Veuillez vous assurer que votre appareil est connecté au réseau WIFI de la boîte ELEVATORSENSE.',
  programmingTool: 'Programmation sans fil',
  programmingToolDescription:
    'Configuration facile de l’électronique de porte.',
  pulley_is_touching_belt: 'Poulie',
  pulley_is_touching_belt_actions:
    'Réglez l’alignement de la courroie de manière à ce que la courroie ne touche pas les bords de la bride de la poulie.',
  pulley_is_touching_belt_description:
    'La bride de la poulie touche la courroie.',
  readyToRunTest: 'Prêt à exécuter l’essai.',
  realtimeMonitoringDescription:
    'Visualisez les données des capteurs et les graphiques de mouvement de porte en temps réel.',
  realtimeMonitoringTitle: 'Diagnostic en temps réel',
  recommended: 'Planifier la vérification du remplacement',
  recommendedActions: 'Actions recommandées',
  recommendedDateOfExchange: 'Date de remplacement recommandée Vérification',
  reload: 'Recharger',
  remove: 'Retirer',
  reopenTimeDescription:
    'Le paramètre de réouverture définit le délai de sécurité après le mouvement de réouverture automatique en position de fin d’ouverture, avant de refermer la porte.',
  reopenTimeNote:
    'Cette fonction est utilisée en cas de procédure de réouverture déclenchée par l’électronique de la porte et non par la commande principale de l’ascenseur.',
  reopenTimeParameter: 'Paramètre de temps de réouverture',
  reopenTimeTitle: 'Temps de réouverture',
  repeatFloor:
    'Veuillez répéter le processus de contrôle de l’installation pour cet étage!',
  repeatWholeRun: (
    <>
      Veuillez répéter le processus de contrôle d’installation <b>complet</b>!
    </>
  ),
  replaced: 'Remplacé',
  replacementWasPostponed: 'Le remplacement a été reporté le :',
  requestNewLink: 'Demander un nouveau lien',
  resetPassword: 'Réinitialiser le mot de passe',
  resetPasswordText: 'Veuillez saisir un nouveau mot de passe',
  revoke: 'Révoquer',
  right: 'Droite',
  rollerBumper: 'Roller Butoir',
  rollerSynchronisationRope: 'Câble de synchronisation du roller',
  runs: 'Courses',
  save: 'Enregistrer',
  scan: 'Balayer',
  scanned: 'Scanné',
  scanAgain: 'Scanner à nouveau',
  searchPlaces: 'Rechercher des lieux',
  select: 'Sélectionner',
  selectCameraMode: 'Sélectionner le mode caméra',
  selectDoorModel: 'Sélectionnez le modèle de porte',
  selectDoorType: 'Sélectionnez le type de porte',
  selectModifiedFloors:
    'Veuillez sélectionner les étages sur lesquels des modifications ont été apportées depuis la dernière course d’essai. Les étages sur lesquels des erreurs se sont produites lors de la dernière course sont présélectionnés.',
  selectPartsHint:
    "Veuillez vérifier quelle pièce est installée et cliquez sur l'image correspondante.",
  send: 'Envoyer',
  sendResults: 'Envoyer les résultats',
  sendTo: 'Envoyez les résultats aux adresses électroniques suivantes:',
  sensorData: 'Données du capteur',
  serviceDriveSwitch: 'Service Drive Switch',
  settings: 'Paramètres',
  share: 'Partager',
  shareBody: (link: string) => `Afficher le résultat de l’essai:\n${link}`,
  shareResults: 'Partager les résultats',
  shareSubject: 'Résultat ELEVATORSENSE',
  shoe: 'Patin',
  showPassedTests: 'Afficher les essais réussis...',
  showTerms: 'Afficher les conditions d’utilisation',
  signUp: 'S’inscrire',
  skid: 'Patin',
  slideingshoe_failure: 'Dysfonctionnement du patin coulissant',
  slideingshoes_usage: 'Utilisation des patins coulissants',
  somethingWentWrong: "Un problème s'est produit. Veuillez réessayer.",
  sortBy: 'Trier par',
  sparePartDoesNotFit: 'La pièce de rechange ne convient pas',
  sparePartNotAvailable: 'Pièce de rechange non disponible',
  speed: 'Vitesse',
  start: 'Démarrer',
  startNewTestRun: 'Nouvelle course d’essai',
  started: 'En cours',
  starting_floor: 'Étage de départ',
  status: 'Statut',
  subcontractorNotFound: 'Sous-traitant non trouvé',
  subcontractors: 'Sous-traitants',
  subcontractorManagement: 'Gestion des sous-traitants',
  subscriptionHint:
    'Vous recevrez des notifications par courriel uniquement si vous avez acheté les produits appropriés pour votre boîte ElevatorSense.',
  subscriptionText:
    'Veuillez choisir les notifications par courriel que vous souhaitez recevoir.',
  subscriptions: 'Notifications',
  summarySubscription: 'Rapport quotidien',
  summarySubscriptionExplanation: (
    <>
      Le <b>rapport quotidien</b> comprend un courriel avec un aperçu de tous
      les bâtiments et ascenseurs sélectionnés avec une indication du nombre
      d’erreurs
    </>
  ),
  temperature: 'Température',
  testCompleted: 'Essai terminé.',
  testResults: 'Résultats de l’essai',
  time: 'Heure',
  tooHigh: 'Trop haut',
  tooLoose: 'Trop lâche',
  tooLow: 'Trop bas',
  tooTight: 'Trop serré',
  top: 'haut',
  topToBottom: 'De haut en bas',
  touching: 'en contact',
  transformer: 'Transformateur',
  truckRoller: 'Chariot Roller',
  truckRollerInferior: 'Galet inférieur chariot',
  truckRollerSuperior: 'Galet superior chariot',
  twisted_cd_panels: 'Panneaux CD torsadés',
  twisted_ld_panels: 'Panneaux torsadés',
  unlinkBox: 'Dissocier la boîte',
  unreadNotifications: (n: number) =>
    `${n} notification non lue${n === 1 ? '' : 's'}`,
  unshare: 'Annuler le partage',
  update: 'Mise à jour',
  updateRequired: 'Mise à jour requise',
  updateStaticData: 'Mettre à jour les données',
  updating: 'Mise à jour...',
  upToDate: 'Mise à jour',
  usage: 'Utilisation',
  usedElectronics: 'Ascenseurs utilisés',
  validFrom: 'Valable à partir de',
  validUntil: 'Valable jusqu’à',
  vibration: 'Vibration',
  viewErrorLog: 'Afficher le journal des erreurs',
  waitWhileInitializingTest:
    'Veuillez patienter pendant l’initialisation de l’essai...',
  waitingForData: 'En attente de l’arrivée de nouvelles données',
  waitingForTestResults: 'En attente des résultats de l’essai...',
  warning: 'Avertissement',
  warnings: 'Avertissements',
  warrantyExtensionRequestedOn: (date: string) =>
    `L'extension de garantie a été demandée le ${date}`,
  whyNotReplaced: "Pourquoi le composant n'a- t - il pas été remplacé?",
  wifiScanned: 'Le WIFI a été scanné',
  years: 'années',
  yes: 'Oui',
  zero_position: 'Position zéro',
  zero_position_description: 'La position zéro n’est pas correcte.',
  zero_position_eco_actions:
    '`Procédure ECO+ : 1) Réglez S1DIP1 sur ON (mode Service). 2) Fermez la porte à la main (position zéro). 3) Appuyez sur le bouton d’apprentissage pendant >10 s (Longlearn). 4) Ouvrez et fermez la porte à l’aide du bouton d’entraînement de service. 5) Maintenez enfoncés les boutons à l’extrémité de la porte ouverte et à l’extrémité de la porte fermée pendant >2 s. Résultat : Le voyant d’état doit s’éteindre après 2 cycles.`',
  zero_position_midi_actions:
    ' `Procédure MIDI/SUPRAV2 : Fermez la porte à la main (position zéro). Suivez les instructions du pictogramme.`',
  yourBuilding: 'Votre bâtiment',
  yourBuildings: 'Vos bâtiments',
};
