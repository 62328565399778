import { defaults, logout as apiLogout } from './appApi';

export * from './appApi';

defaults.baseUrl = '/api';

function setDefaults() {
  const token = window.localStorage.getItem('token');
  const lang = window.localStorage.getItem('lang');
  defaults.headers = {
    authorization: token ?? undefined,
    'accept-language': lang ? JSON.parse(lang) : undefined,
  };
}

setDefaults();
window.addEventListener('storage', setDefaults);

export async function logout() {
  await apiLogout();
  defaults.headers = {};
  window.localStorage.removeItem('token');
}

export function isStatus(err: any, code: number) {
  return err?.status === code;
}

export function getErrorMessage(err: any) {
  return err?.data?.message ?? err?.message;
}
