import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { Button } from '../components/common/Button';
import { FullScreenDialog } from '../components/common/FullScreenDialog';
import { Logo } from '../components/common/Logo';
import { Overlay } from '../components/common/Overlay';
import { Row } from '../components/common/Row';
import { Stack } from '../components/common/Stack';
import { Form } from '../components/form/Form';
import { ToggleField } from '../components/form/ToggleField';
import { useApiQuery } from '../hooks/useApi';
import { useFormSubmission } from '../hooks/useFormSubmission';
import { useToggle } from '../hooks/useToggle';
import { useTranslation } from '../I18nProvider';
import { useUser } from '../UserProvider';
import {
  confirmSubcontractorInvite,
  ConfirmSubcontractorInviteArgs,
  isStatus,
} from '../utils/api';
import { Login } from './Login';
import { Terms } from './Terms';

export function ConfirmSubcontractorInvite() {
  const { token } = useParams();
  const t = useTranslation();
  const invite = useApiQuery('getSubcontractorInvite', token!);
  const user = useUser();
  const [isLoggedIn, setIsLoggedIn] = useState(!!user);
  const [showLoginOverlay, toggleLoginOverlay] = useToggle(!user);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const navigate = useNavigate();

  useEffect(() => {
    if (user && !isLoggedIn) {
      setIsLoggedIn(true);
      toggleLoginOverlay();
    }
  }, [user, isLoggedIn, toggleLoginOverlay]);

  const { form, register } = useFormSubmission<ConfirmSubcontractorInviteArgs>({
    async onSubmit(data) {
      try {
        await confirmSubcontractorInvite(token!, data);
        navigate('/');
      } catch (err: any) {
        if (isStatus(err, 422)) {
          setErrorMessage(t.alreadyHasSubcontractorsError);
        }
        if (isStatus(err, 403)) {
          setErrorMessage(t.notAuthorizedToConfirmInviteError);
        }
        throw err;
      }
    },
  });

  return (
    <FullScreenDialog>
      <Logo />
      {showLoginOverlay && (
        <Overlay onClose={toggleLoginOverlay} showCancelButton={false}>
          <Login />
        </Overlay>
      )}
      {!showLoginOverlay && invite.isSuccess && isLoggedIn ? (
        <Form {...form}>
          <Stack>
            <p>
              {t.inviteToBeSubcontractorMessage(
                invite.data.invitingCompany.name || ''
              )}
            </p>
            {errorMessage && <p style={{ color: 'red' }}>{errorMessage}</p>}
            <ToggleField register={register} name="acceptTerms">
              {t.acceptTerms}
            </ToggleField>
            <Terms />
            <Button variant="primary" type="submit">
              {t.confirmInvite}
            </Button>
          </Stack>
        </Form>
      ) : (
        !showLoginOverlay && <Row justify="center">{t.invitationExpired}</Row>
      )}
    </FullScreenDialog>
  );
}
