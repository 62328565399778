import NoSleep from 'nosleep.js';
import React, { useEffect } from 'react';

import { Button } from '../../components/common/Button';
import { Stack } from '../../components/common/Stack';
import { useTranslation } from '../../I18nProvider';
import { getCompanyLicenses } from '../../utils/api';

type ConnectApiProps = {
  connectApi: () => Promise<boolean>;
};

const noSleep = new NoSleep();
const fetchLicenses = async () => {
  try {
    const result = await getCompanyLicenses();
    window.localStorage.setItem('licenses', JSON.stringify(result));
  } catch (error) {
    console.error('Failed to fetch licenses:', error);
  }
};

export function ConnectApi({ connectApi }: ConnectApiProps) {
  const t = useTranslation();

  useEffect(() => {
    fetchLicenses();
  }, []);

  const connect = async () => {
    noSleep.enable();
    await connectApi();
  };

  return (
    <Stack>
      {t.directConnection}
      <Button variant="primary" onClick={connect}>
        {t.continue}
      </Button>
    </Stack>
  );
}
