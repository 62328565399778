import React, {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from 'react';

import { useUser } from './UserProvider';
import { getCompanyLicenses } from './utils/api';
import { License } from './utils/types';

type LicenseState = License[] | null;
const LicenceStateContext = createContext<LicenseState | undefined>(undefined);

type Props = {
  children: ReactNode;
};
export function LicensesProvider({ children }: Props) {
  const [licenses, setLicenses] = useState<License[] | null>();

  const user = useUser();
  useEffect(() => {
    if (user) {
      getCompanyLicenses()
        .then((result) => {
          setLicenses(result);
          window.localStorage.setItem('licenses', JSON.stringify(result));
        })
        .catch((err) => {
          // NOTE: Fetch licenses from local storage as fallback
          const item = window.localStorage.getItem('licenses');
          if (item) {
            const parsed = JSON.parse(item);
            setLicenses(parsed);
          }
        });
    } else {
      window.localStorage.removeItem('licenses');
    }
  }, [user]);

  return (
    <LicenceStateContext.Provider value={licenses}>
      {children}
    </LicenceStateContext.Provider>
  );
}

export const useLicenses = () => useContext(LicenceStateContext);
