import React, { ReactNode } from 'react';
import { FieldValues, FormProvider, UseFormReturn } from 'react-hook-form';

type Props<T extends FieldValues> = UseFormReturn<T> & {
  children: ReactNode;
  onSubmit: (e?: React.BaseSyntheticEvent) => Promise<void>;
};
export function Form<T extends FieldValues>({
  children,
  onSubmit,
  ...form
}: Props<T>) {
  return (
    <FormProvider {...form}>
      <form onSubmit={onSubmit}>{children}</form>
    </FormProvider>
  );
}
