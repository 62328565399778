import styled, { css } from 'styled-components';

import { space } from '../../tokens';

const alignment = {
  left: css`
    justify-content: flex-start;
  `,
  center: css`
    justify-content: center;
  `,
};

type Props = {
  align?: keyof typeof alignment;
};
export const HorizontalScroll = styled.div<Props>`
  margin: 0 calc(-1 * ${space[4]});
  padding: 0 ${space[4]} ${space[4]};
  display: flex;
  align-items: center;
  overflow-x: auto;
  & > * + * {
    margin-left: ${space[3]};
  }
  ${({ align }) => alignment[align ?? 'left']}
`;
