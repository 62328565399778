import { useErrorHandler } from 'react-error-boundary';
import {
  FieldValues,
  SubmitHandler,
  useForm,
  UseFormProps,
} from 'react-hook-form';

import { handleError } from '../utils/form';

type Props<T extends FieldValues> = UseFormProps<T> & {
  onSubmit: SubmitHandler<T>;
};

export function useFormSubmission<T extends FieldValues>({
  onSubmit,
  ...props
}: Props<T>) {
  const form = useForm<T>(props);
  const { handleSubmit, setError } = form;
  const errorHandler = useErrorHandler();

  //REVISIT do we need a useCallback here?
  const submitHandler = handleSubmit(async (values) => {
    try {
      await onSubmit(values);
    } catch (err: any) {
      if (!handleError(err, setError)) {
        // use the global error handler as fallback:
        errorHandler(err);
      }
    }
  });
  return {
    form: {
      ...form,
      onSubmit: submitHandler,
    },
    ...form,
  };
}
