import React, { forwardRef, InputHTMLAttributes, ReactNode } from 'react';
import styled from 'styled-components';

const Label = styled.label`
  display: flex;
  align-items: flex-start;
  cursor: pointer;
  padding: 0.5em 0;
`;

const Content = styled.div`
  margin-left: 0.5rem;
  margin-top: 0.2rem;
`;

const Input = styled.input`
  position: absolute;
  opacity: 0;
  z-index: -1;
  user-select: none;
  & + div {
    position: relative;
    flex-shrink: 0;
    height: 1.75rem;
    width: 56px;
    border: 2px solid #6d7191;
    border-radius: 3px;
    background-color: #6d7191;
    transition: background-color 0.5s;
    user-select: none;
    -webkit-appearance: none;
    -webkit-tap-highlight-color: transparent;
    -webkit-touch-callout: none;
    margin: 0 0 0 10px;
    ::after {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      width: 32px;
      height: 100%;
      background-color: #fff;
      border-radius: 1px;
      transition: margin 0.1s;
    }
  }
  :checked + div {
    border-color: #1d8b8f;
    background-color: #1d8b8f;
    ::after {
      margin-left: 20px;
    }
  }
`;

export type ToggleProps = InputHTMLAttributes<HTMLInputElement> & {
  children?: ReactNode;
};

export const Toggle = forwardRef<HTMLInputElement, ToggleProps>(
  ({ children, ...props }, ref) => (
    <Label>
      <Input {...props} ref={ref} type="checkbox" />
      <div />
      {children && <Content>{children}</Content>}
    </Label>
  )
);
