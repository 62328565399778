import React, { useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';

import { Card } from '../../components/common/Card';
import { Hint } from '../../components/common/Hint';
import { IconButton } from '../../components/common/IconButton';
import { ChevronRightIcon, EditIcon } from '../../components/common/Icons';
import { Page } from '../../components/common/Page';
import { Spacer } from '../../components/common/Spacer';
import { Stack } from '../../components/common/Stack';
import { LiftHeader } from '../../components/lift/LiftHeader';
import { useApiQuery } from '../../hooks/useApi';
import { useTranslation } from '../../I18nProvider';
import { PerformSetUpRun } from './PerformSetUpRun';

export function ComponentDetails() {
  const params = useParams();
  const liftId = parseInt(params.id!);
  const buildingId = parseInt(params.buildingId!);
  const t = useTranslation();
  const query = useApiQuery('getComponentDetails', liftId);
  const navigate = useNavigate();
  const lift = useApiQuery('getLift', liftId);
  const isPmSetUp = lift.data?.isPmSetUp;

  useEffect(() => {
    if (query.data?.length === 0) {
      setTimeout(() => {
        navigate(`../../floor`);
      }, 10);
    }
  });

  return (
    <Page
      header={
        <LiftHeader
          id={liftId}
          action={
            <IconButton as={Link} to="../">
              <EditIcon />
            </IconButton>
          }
        />
      }
      pageTitle={t.componentDetails}
      onBack={() => navigate('../')}
    >
      {!isPmSetUp && (
        <PerformSetUpRun
          linkTo={`/building/${buildingId}/lift/${liftId}/setup`}
        />
      )}
      {isPmSetUp && (
        <Stack>
          <Hint>{t.componentDetailsHint}</Hint>
          <Spacer />
          {query.data?.map((component: any) => {
            return (
              <Card
                key={component.componentPositionName}
                navigateTo={component.componentPositionName}
                title={component.componentPositionName}
                icon={<ChevronRightIcon />}
              >
                <></>
              </Card>
            );
          })}
        </Stack>
      )}
    </Page>
  );
}
