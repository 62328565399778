import React, { useEffect, useState } from 'react';
import BarcodeScannerComponent from 'react-barcode-scanner-webcam';
import { useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';

import { IconButton } from '../components/common/IconButton';
import { CloseIcon } from '../components/common/Icons';
import { Dropdown } from '../components/form/Dropdown';
import { extractBoxId } from '../components/lift/extractBoxId';
import { useTranslation } from '../I18nProvider';
import { color, fontSize } from '../tokens';
import { getErrorMessage, isStatus, updateLift } from '../utils/api';
import * as api from '../utils/api';

const MAX_RETRIES = 3;
const RETRY_MS = 500;
const NO_QR_CODE_TIMEOUT_MS = 3000;
const RETRY_COUNT_KEY = 'retryCount';

enum QRCodeType {
  WIFI = 'WIFI',
  PC = 'PC',
}

const Container = styled.div`
  position: fixed;
  z-index: 1;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: 0;
  background: ${color.primary};
  display: flex;
  flex-direction: column;
  overflow: auto;
`;

const Close = styled(IconButton)`
  position: fixed;
  top: 0;
  right: 0;
  padding: 10px;
`;

const Circle = styled.div`
  color: ${color.primary};
  background: ${color.background};
  border-radius: 50%;
`;

const Wrapper = styled.div`
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
  position: relative;
`;

const ScannedTextBox = styled.div`
  position: absolute;
  bottom: 5%;
  left: 50%;
  transform: translateX(-50%);
  text-align: center;
  background-color: rgba(0, 0, 0, 0.85);
  color: ${color.background};
  padding: 20px;
  width: 80%;
  max-width: 400px;
  border-radius: 10px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
`;

const NoQRCodeMessage = styled.div`
  position: absolute;
  top: 20%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 90%;
  max-width: 600px;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${color.background};
  background-color: rgba(0, 0, 0, 0.7);
  padding: 20px;
  border-radius: 10px;
  font-size: ${fontSize.base};
  text-align: center;
  word-wrap: break-word;
  overflow: hidden;
`;

const Button = styled.button`
  background-color: ${color.primary};
  color: ${color.background};
  border: none;
  padding: 10px 20px;
  margin: 10px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: ${color.primary};
  }
`;

const DropdownContainer = styled.div`
  padding: 20% 10% 0% 10%;
`;

const QRCodeScannerPage = () => {
  const t = useTranslation();
  const navigate = useNavigate();
  const { id } = useParams();
  const [devices, setDevices] = useState<MediaDeviceInfo[]>([]);
  const [selectedDeviceId, setSelectedDeviceId] = useState<string | null>(null);
  const [scannedText, setScannedText] = useState<string | null>(null);
  const [displayText, setDisplayText] = useState<string | null>(null);
  const [noQRCode, setNoQRCode] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [submitting, setSubmitting] = useState(false);

  const getRetryCount = () => {
    return parseInt(localStorage.getItem(RETRY_COUNT_KEY) || '0', 10);
  };

  const setRetryCount = (count: number) => {
    localStorage.setItem(RETRY_COUNT_KEY, count.toString());
  };

  const resetRetryCount = () => {
    localStorage.removeItem(RETRY_COUNT_KEY);
  };

  useEffect(() => {
    const getCameras = async () => {
      try {
        const videoInputDevices =
          await navigator.mediaDevices.enumerateDevices();

        /**
         * - The `videoInputDevices` array contains information about available media input devices.
         * - Each object in the array can have the following properties:
         *   - **deviceId:** A unique identifier for the device.
         *   - **kind:** The type of media device (e.g., "videoinput", "audioinput", or "audiooutput").
         *   - **label:** A human-readable description of the device (e.g., "camera2 2, facing back").
         *   - **groupId:** A group identifier for related devices.
         *
         * - Example of `videoInputDevices` array:
         *   [
         *     {
         *       deviceId: "d31daafbefceed108f1738c830c6f1942eed76bedbffc03322bd63b1c85fa008",
         *       kind: "videoinput",
         *       label: "camera2 2, facing back",
         *       groupId: "e9eaa5ad62dd3e636a2c21ffd5431611f5e642d63309758d0740aee127faea3b"
         *     },
         *     {
         *       deviceId: "3655baac6c6f3b40c850cdc45a7c856a8b58c72df4f3b1dacd0d7d1ea35e9d49",
         *       kind: "videoinput",
         *       label: "camera2 0, facing front",
         *       groupId: "e83bd37ba0b2f7682f2c66fdcf2a5b6ffc34892015350094b93b4263a5b21891"
         *     },
         *     {
         *       deviceId: "",
         *       kind: "audioinput",
         *       label: "",
         *       groupId: "abcd1234"
         *     }
         *   ]
         *
         * - We are filtering for devices with `kind === 'videoinput'` and excluding front-facing cameras
         *   by checking if the `label` contains "front".
         */

        const videoDevices = videoInputDevices.filter(
          (device) =>
            device.kind === 'videoinput' &&
            !device.label.toLowerCase().includes('front')
        );
        setDevices(videoDevices);
        if (videoDevices.length > 0) {
          // Select the first camera option by default
          setSelectedDeviceId(videoDevices[0].deviceId);
        }
      } catch (err) {
        console.error('Error getting video input devices:', err);
        setError(t.errorUnableToAccessCamera);
      }
    };

    getCameras();
  }, []);

  const handleError = (err: any) => {
    console.error(err);
    const currentRetryCount = getRetryCount();

    if (currentRetryCount < MAX_RETRIES) {
      setError(
        `${t.errorRetryCamera} (${currentRetryCount + 1}/${MAX_RETRIES})`
      );

      setRetryCount(currentRetryCount + 1);

      // Retry camera access after RETRY_MS
      setTimeout(() => {
        window.location.reload();
      }, RETRY_MS);
    } else {
      setError(t.errorUnableToAccessCamera);
      resetRetryCount();
    }
  };

  const handleCancel = () => {
    resetRetryCount();
    navigate(-1);
  };

  const confirmIfBoxIsLinkedToAnotherLift = (box: any) => {
    // If the box is already linked to another lift, ask for confirmation to proceed
    if (box?.lift) {
      return confirm(
        t.boxLinkedToOtherLift(box.lift.name, box.lift.building.name)
      );
    }
    // Return true if the box is not linked to any lift
    return true;
  };

  const updateLiftWithBox = async (box_id: string) => {
    await updateLift(parseInt(id!), { box_id });
    setError(null);
  };

  const handleBoxLinking = async (box_id: string) => {
    setSubmitting(true);
    try {
      const box = await api.getBox(box_id);

      // If the box is not found or the user doesn't confirm, exit early.
      if (!box || !confirmIfBoxIsLinkedToAnotherLift(box)) {
        return;
      }

      await updateLiftWithBox(box_id);

      // Navigate back to the lift page after successfully linking the box.
      navigate(-1);
    } catch (err) {
      if (isStatus(err, 403)) {
        setError(getErrorMessage(err));
      }
      if (isStatus(err, 404)) {
        setError(t.errorUnknownOrForbiddenBox);
      }
      // Other unexpected errors are ignored as they should trigger the ErrorBoundary.
    } finally {
      setSubmitting(false);
    }
  };

  const onScan = (text: any) => {
    const box_id = extractBoxId(text);
    if (box_id) {
      handleBoxLinking(box_id);
    }
  };

  useEffect(() => {
    if (!scannedText) {
      const timer = setTimeout(() => {
        setNoQRCode(true);
      }, NO_QR_CODE_TIMEOUT_MS);

      return () => clearTimeout(timer);
    } else {
      setNoQRCode(false);
    }
  }, [scannedText]);

  const handleScan = (text: string) => {
    /**
     * - **WIFI Pattern:** Text starting with `WIFI` indicates Wi-Fi network details (e.g., `WIFI:T:WPA;S:SSID;P:password;H:;; `).
     * - **Box Pattern:** Text starting with `PC` indicates a box with an ID (e.g., `PC:10745658A01;HW:0003;NM:WTMS2407230048;CH:AAAABDB;`).
     * - **Default:** For other texts, it sets a generic scanned message.
     */
    let message = '';
    if (text.startsWith(QRCodeType.WIFI)) {
      message = t.wifiScanned;
    } else if (text.startsWith(QRCodeType.PC)) {
      // Extracting the box ID from a string that starts with 'PC' and includes 'NM:<boxid>;'
      const match = text.match(/NM:(.*?);/);
      if (match && match[1]) {
        message = `${t.followingBoxScanned}: ${match[1]}`;
      } else {
        message = t.boxScannedNoIdFound;
      }
    } else {
      message = `${t.scanned}: ${text}`;
    }
    setDisplayText(message);
    setScannedText(text);
  };

  const resetScanner = () => {
    setScannedText(null);
    setDisplayText(null);
    setError(null);
    setSubmitting(false);
  };

  return (
    <Container>
      <DropdownContainer>
        {devices.length > 1 && (
          <Dropdown
            label={t.selectCameraMode}
            value={selectedDeviceId || ''}
            onChange={(e) => setSelectedDeviceId(e.target.value)}
          >
            {devices.map((device, index) => (
              <option key={device.deviceId} value={device.deviceId}>
                {`${t.cameraMode} ${index + 1}`}
              </option>
            ))}
          </Dropdown>
        )}
      </DropdownContainer>
      <Wrapper>
        {selectedDeviceId && (
          <BarcodeScannerComponent
            width="100%"
            height="400px"
            facingMode="environment"
            videoConstraints={
              selectedDeviceId
                ? { deviceId: selectedDeviceId }
                : { facingMode: 'environment' }
            }
            onUpdate={(_err, result) => {
              if (result) {
                const text = result.getText();
                handleScan(text);
              }
            }}
            onError={handleError}
          />
        )}

        {displayText && (
          <ScannedTextBox>
            <p>{displayText}</p>
            {!displayText.startsWith(QRCodeType.WIFI) && (
              <Button
                disabled={submitting}
                onClick={() => onScan(scannedText!)}
              >
                {t.confirm}
              </Button>
            )}
            <Button disabled={submitting} onClick={resetScanner}>
              {t.scanAgain}
            </Button>
          </ScannedTextBox>
        )}
        {!scannedText && noQRCode && (
          <NoQRCodeMessage>
            {t.noQRCodeDetected}
            {devices.length > 1 && ` ${t.adjustCameraSettings}`}
          </NoQRCodeMessage>
        )}
        {error && <NoQRCodeMessage>{error}</NoQRCodeMessage>}
        <Close onClick={handleCancel}>
          <Circle>
            <CloseIcon size="32px" />
          </Circle>
        </Close>
      </Wrapper>
    </Container>
  );
};

export { QRCodeScannerPage };
