import React from 'react';
import {
  FieldPath,
  FieldValues,
  RegisterOptions,
  UseFormRegister,
  UseFormRegisterReturn,
} from 'react-hook-form';

import { FormField } from './FormField';
import { Toggle, ToggleProps } from './Toggle';

type Props<T extends FieldValues> = Omit<
  ToggleProps,
  'value' | keyof UseFormRegisterReturn
> & {
  name: FieldPath<T>;
  label?: string;
  register: UseFormRegister<T>;
  options?: RegisterOptions<T>;
};

export function ToggleField<T extends FieldValues>({
  name,
  label,
  register,
  options,
  ...props
}: Props<T>) {
  return (
    <FormField name={name} label={label}>
      <Toggle {...props} {...register(name, options)} />
    </FormField>
  );
}
