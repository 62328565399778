import React from 'react';
import styled from 'styled-components';

import logotype from './logotype.svg';
import witturTriangleLogo from './wittur_triangle_logo.svg';

const Logo = styled.img`
  max-width: 40vw;
  margin: auto;
`;

const TriangleLogo = styled.img`
  max-width: 80px;
  position: absolute;
  right: 0px;
  top: 0px;
`;

export function CommonHeader() {
  return (
    <>
      <Logo src={logotype} />
      {/* <ErrorStatus /> */}
      <TriangleLogo src={witturTriangleLogo} />
    </>
  );
}
