import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { useDateFormat } from '../../hooks/useDateFormat';
import { useTranslation } from '../../I18nProvider';
import { space } from '../../tokens';
import { Box, Building, ErrorType } from '../../utils/api';
import { BoxError, Lift } from '../../utils/types';
import { replaceMapKey } from '../map/placeUtils';
import { ErrorCard } from './ErrorCard';
import { Hint } from './Hint';
import { Stack } from './Stack';
import { StatusType } from './StatusIcon';

const CardLink = styled(Link)`
  text-decoration: none;
`;

export type FullBoxError = BoxError & {
  box?: Box & {
    lift?: Lift & {
      building?: Building;
    };
  };
};

type ErrorListProps = {
  errors: FullBoxError[];
  showFilterHint?: boolean;
};

export function ErrorList({ errors, showFilterHint = false }: ErrorListProps) {
  const t = useTranslation();
  const dateFormat = useDateFormat();
  const { formatDate, formatTime } = dateFormat;

  return (
    <Stack gap={space[2]}>
      {errors.length === 0 && (
        <>
          {showFilterHint && <Hint>{t.errorsEmptyHint}</Hint>}
          <p>{t.errorsEmpty}</p>
        </>
      )}
      {errors.map((error, i) => {
        const lift = error.box?.lift;
        const building = lift?.building;
        const buildingId = lift?.building_id;
        const statusType = mapErrorType(error.type);

        return (
          <CardLink
            key={i}
            to={
              lift && buildingId
                ? `/building/${buildingId}/lift/${lift.id}`
                : ''
            }
          >
            <ErrorCard
              status={statusType}
              title={error.message}
              // subTitle={formatRelative(err.date)}
              image={replaceMapKey(building?.photo)}
            >
              <div>
                <b>{formatDate(error.date)}</b> {formatTime(error.date)}
              </div>
              {lift && (
                <div>
                  {lift?.name} {building && <span>– {building.name}</span>}
                </div>
              )}
            </ErrorCard>
          </CardLink>
        );
      })}
    </Stack>
  );
}

const statusMap: Record<ErrorType, StatusType> = {
  fatalerror: 'fatal',
  error: 'error',
  warning: 'warning',
  info: 'success',
};

const mapErrorType = (type: string): StatusType => statusMap[type as ErrorType];
