export interface DataPoint {
  x: number;
  y: number;
}

/**
 * Linear interpolation
 * @param x a random number
 * @param y another random number
 * @param a value between [0..1]
 * @returns linear interpolated value
 */
export const lerp = (x: number, y: number, a: number) => x * (1 - a) + y * a;

/**
 * Add two points
 * @param a
 * @param b
 * @returns sum of the points
 */
export const add2 = (a: DataPoint, b: DataPoint) => ({
  x: a.x + b.x,
  y: a.y + b.y,
});

/**
 * Subtract two points
 * @param a a random point
 * @param b another random point
 * @returns (a - b)
 */
export const sub2 = (a: DataPoint, b: DataPoint) => ({
  x: a.x - b.x,
  y: a.y - b.y,
});

/**
 * Calculate dot product
 * @param a a random point
 * @param b another random point
 * @returns dot product of a and b
 */
export const dot2 = (a: DataPoint, b: DataPoint) => a.x * b.x + a.y * b.y;

/**
 * Calculate the length of a point
 * @param a given point
 * @returns length of the point
 */
export const length2 = (a: DataPoint) => Math.hypot(a.x, a.y);

/**
 * Calculate the squared length of a point
 * @param a given point
 * @returns squared length, saving a sqrt operation
 */
export const sqrLength2 = (a: DataPoint) => a.x ** 2 + a.y ** 2;

/**
 * Calculate linear interpolation between two points
 * @param x a random point
 * @param y another random point
 * @param a interpolation value between [0..1]
 * @returns interpolated point
 */
export const lerp2 = (x: DataPoint, y: DataPoint, a: number) => ({
  x: lerp(x.x, y.x, a),
  y: lerp(x.y, y.y, a),
});
