import { useQuery } from '@tanstack/react-query';
import React from 'react';
import { Link, useParams } from 'react-router-dom';

import { IconButton } from '../../components/common/IconButton';
import { WarningIcon } from '../../components/common/Icons';
import { Loading } from '../../components/common/Loading';
import { Page } from '../../components/common/Page';
import { Stack } from '../../components/common/Stack';
import { LiftHeader } from '../../components/lift/LiftHeader';
import { useTranslation } from '../../I18nProvider';
import { getDoorCycles, isStatus } from '../../utils/api';
import { appendNewData, DoorData, getLastCycleDate } from '../../utils/boxData';
import { ActivateTrial } from './ActivateTrial';
import { PurchaseLicense } from './PurchaseLicense';
import { RealtimeData } from './RealtimeData';

export function Monitoring() {
  const t = useTranslation();
  const params = useParams();
  const id = parseInt(params.id!);

  const query = useQuery(
    ['doorcycles', id],
    async (): Promise<DoorData[]> =>
      appendNewData(
        await getDoorCycles(id, { since: getLastCycleDate(query.data) }),
        query.data
      ),
    {
      refetchInterval: (_data, query) => (query.state.error ? false : 5000),
      useErrorBoundary: false,
      cacheTime: 0,
    }
  );
  if (isStatus(query.error, 500)) {
    throw query.error;
  }
  if (isStatus(query.error, 404)) {
    // to see the error messsage in error boundary
    throw query.error;
  }

  const isTrialEnabled = isStatus(query.error, 402);
  const isLicenseInvalid = isStatus(query.error, 403);

  return (
    <Page
      header={<LiftHeader id={id} />}
      pageTitle={t.realtimeMonitoringTitle}
      backLink="../../"
      nextLink={
        isTrialEnabled || isLicenseInvalid ? null : (
          <IconButton as={Link} to="../errors">
            <WarningIcon />
          </IconButton>
        )
      }
    >
      <Stack>
        {query.isLoading && <Loading />}
        {query.isSuccess && <RealtimeData liftId={id} data={query.data} />}
        {isTrialEnabled && (
          <ActivateTrial liftId={id} onActivate={query.refetch} />
        )}
        {isLicenseInvalid && <PurchaseLicense />}
      </Stack>
    </Page>
  );
}
