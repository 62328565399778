import React, { useState } from 'react';
import styled from 'styled-components';

import { CheckCircleIcon } from '../../components/common/Icons';
import { Row } from '../../components/common/Row';
import { Stack } from '../../components/common/Stack';
import { SectionHeading } from '../../components/common/typography';
import { useTranslation } from '../../I18nProvider';
import { tips } from '../../tips';
import { borderRadius, color, fontSize, fontWeight, space } from '../../tokens';
import { Tip } from './Tip';

export const greenToRed = [
  '#01999D',
  '#019E9D',
  '#01A59B',
  '#01AC99',
  '#0AB790',
  '#2BBE84',
  '#4AC877',
  '#6BD26A',
  '#8CDC5D',
  '#A6DF4D',
  '#B7E347',
  '#C6E03E',
  '#D1DD39',
  '#DBDA34',
  '#FFE028',
  '#F18420',
  '#C62C31',
  '#9B0033',
];
const [green] = greenToRed;
const redToGreen = greenToRed.slice().reverse();
export const fullSpectrum = redToGreen.concat(green, greenToRed);

function isFullSpectrum(key: string) {
  return key !== 'closing_device_failure' && key !== 'pulley_is_touching_belt';
}

function getSpectrum(key: string) {
  return isFullSpectrum(key) ? fullSpectrum : greenToRed;
}

function numericValue(value: number | boolean) {
  if (typeof value === 'number') return value;
  return value ? 100 : 0;
}

function scaleValue(key: string, value: number) {
  if (isFullSpectrum(key)) {
    return (value + 100) / 200;
  }
  return value / 100;
}

function getPeekIndex(key: string, value: number | boolean) {
  return Math.round(
    scaleValue(key, numericValue(value)) * (getSpectrum(key).length - 1)
  );
}

function getTickHeight(key: string, index: number, value: number | boolean) {
  const peekIndex = getPeekIndex(key, value);
  if (index === peekIndex) return 14;
  if (index === peekIndex - 1 || index === peekIndex + 1) return 12;
  return 2;
}

const Card = styled.div`
  background: ${color.cardBackground};
  color: ${color.heavyCardText};
  padding: ${space[3]};
  border-radius: ${borderRadius.inner};
  :first-child {
    border-radius: 0 0 ${borderRadius.inner} ${borderRadius.inner};
  }
`;

export const Tick = styled.div`
  border-radius: 2px;
  height: 2px;
  flex: auto;
`;

const Instructions = styled.button`
  font-size: ${fontSize.xxs};
  font-weight: ${fontWeight.medium};
  text-transform: uppercase;
  border: none;
  padding: 0;
`;

type Props = {
  label: string;
  value: number | boolean;
  failed: boolean;
  tipContext?: any;
};
export function ResultCard({ label, value, failed, tipContext }: Props) {
  const t = useTranslation();
  const [showTips, setShowTips] = useState(false);
  const tip = (tips as any)[label];
  return (
    <Card onClick={() => setShowTips(true)}>
      {failed && tip && showTips && (
        <Tip
          error={label}
          content={tip}
          tipContext={tipContext}
          onClose={() => setShowTips(false)}
        />
      )}
      <Stack gap={space[1]}>
        <Row justify="spread" align="bottom">
          <SectionHeading>{(t as any)[label] ?? label} </SectionHeading>
          {failed ? (
            tip && (
              <Instructions>{t.installationControlInstructions}</Instructions>
            )
          ) : (
            <CheckCircleIcon style={{ color: color.success }} />
          )}
        </Row>
        <Row gap="2px" nowrap align="top">
          {!isFullSpectrum(label) && (
            <Tick
              style={{
                flexBasis: '50%',
                backgroundColor: color.cardText,
                opacity: 0.2,
              }}
            />
          )}
          {getSpectrum(label).map((hex, i) => (
            <Tick
              key={i}
              style={{
                backgroundColor: hex,
                height: getTickHeight(label, i, value),
                opacity: getTickHeight(label, i, value) > 2 ? 1 : 0.4,
              }}
            />
          ))}
        </Row>
      </Stack>
    </Card>
  );
}
