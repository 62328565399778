import { MockBoxApi } from './MockBoxApi';
import { WebRtcBoxApi } from './WebRtcBoxApi';
import { WebRtcBridge } from './WebRtcBridge';

export function createBoxApi() {
  const bridgeUrl = import.meta.env.VITE_PROGRAMMING_BRIDGE_URL;

  if (!bridgeUrl) {
    return new MockBoxApi();
  }

  // NOTE: Temporary stun server for dev testing
  const stunServer = import.meta.env.VITE_PROGRAMMING_STUN_SERVER;
  let iceServers;
  if (stunServer) {
    iceServers = [{ urls: stunServer }];
  }

  const bridge = new WebRtcBridge(bridgeUrl, iceServers);
  return new WebRtcBoxApi(bridge);
}
