import React, { ReactNode } from 'react';

import { Button } from '../../components/common/Button';
import { Loading } from '../../components/common/Loading';
import { Page } from '../../components/common/Page';
import { Stack } from '../../components/common/Stack';
import { useBoxApiMutation } from '../../hooks/useBoxApi';
import { en, TranslatableErrorKey } from '../../i18n';
import { useTranslation } from '../../I18nProvider';
import { PurchaseLicense } from './PurchaseLicense';

type Props = {
  goBack: () => void;
  error: any;
  resetError?: () => void;
};
export function ErrorPage({ goBack, error, resetError }: Props) {
  const t = useTranslation();

  const connectApi = useBoxApiMutation('connectApi');
  const continueButton = (
    <Button
      variant="primary"
      onClick={async () => {
        resetError && resetError();
        await connectApi();
      }}
      disabled={connectApi.isLoading}
    >
      {t.continue}
    </Button>
  );

  const backButton = <Button onClick={goBack}>{t.back}</Button>;

  let errorContent: ReactNode = (
    <>
      {t.directConnection}
      {continueButton}
    </>
  );

  if (!connectApi.isLoading) {
    const { code } = error;
    // REVIST: Can this be improved somwhow?
    if (code in en) {
      const message = t[error.code as TranslatableErrorKey];
      errorContent = (
        <>
          {typeof message === 'string' ? <p>{message}</p> : message}
          {continueButton}
          {backButton}
        </>
      );
    } else if (code === 'noLicense') {
      errorContent = <PurchaseLicense />;
    }
  }

  const pageTitle = connectApi.isLoading ? t.programmingTool : t.error;

  return (
    <Page pageTitle={pageTitle} onBack={goBack}>
      {connectApi.isLoading && <Loading />}
      {!connectApi.isLoading && <Stack>{errorContent}</Stack>}
    </Page>
  );
}
