const BASE_IMAGE_URL = '/api/images/component-parts/';
const PLACEHOLDER_IMAGE = '/api/images/component-parts/placeholder.jpg';

export const getImageUrl = (partNumber: string) => {
  return partNumber !== ''
    ? `${BASE_IMAGE_URL}${partNumber}.jpg`
    : PLACEHOLDER_IMAGE;
};

export const getFallbackImageUrl = () => {
  return PLACEHOLDER_IMAGE;
};
