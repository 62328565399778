import { MarkerClusterer } from '@googlemaps/markerclusterer';
import { useEffect, useState } from 'react';

import { useMap } from './GoogleMap';

interface MarkerProps extends google.maps.MarkerOptions {
  onClick?: () => void;
}

export function Marker({ onClick, ...props }: MarkerProps) {
  const [marker, setMarker] = useState<google.maps.Marker>();
  const map = useMap();
  const [markerCluster, setMarkerCluster] = useState<MarkerClusterer | null>(
    null
  );

  useEffect(() => {
    if (!markerCluster && map) {
      setMarkerCluster(new MarkerClusterer({ map }));
    }
  }, [map, markerCluster]);

  useEffect(() => {
    if (!marker && markerCluster) {
      const newMarker = new google.maps.Marker({
        ...props,
        map,
        icon: {
          path: 'M9 22C9 22 18 16 18 9C18 4.02944 13.9706 0 9 0C4.02944 0 0 4.02944 0 9C0 16 9 22 9 22ZM12 9C12 10.6569 10.6569 12 9 12C7.34315 12 6 10.6569 6 9C6 7.34315 7.34315 6 9 6C10.6569 6 12 7.34315 12 9Z',
          fillColor: '#009A9E',
          fillOpacity: 1,
          strokeWeight: 0,
          rotation: 0,
          scale: 1.5,
          anchor: new google.maps.Point(9, 22),
        },
      });

      if (onClick) {
        newMarker.addListener('click', onClick);
      }

      setMarker(newMarker);
      markerCluster.addMarker(newMarker);
    }

    return () => {
      if (marker) {
        marker.setMap(null);
      }
    };
  }, [marker, markerCluster]);

  useEffect(() => {
    if (marker) {
      marker.setOptions(props);
    }
  }, [marker, props]);

  return null;
}
