import React, {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from 'react';

import { boxApi } from './hooks/useBoxApi';

const OfflineContext = createContext(false);

type Props = {
  children: ReactNode;
};
export function OfflineProvider({ children }: Props) {
  const isOffline = useIsOffline();
  const isApiConnected = useIsApiConnected();
  return (
    <OfflineContext.Provider value={isOffline || isApiConnected}>
      {children}
    </OfflineContext.Provider>
  );
}

function useIsOffline() {
  const [isOffline, setIsOffline] = useState(!navigator.onLine);
  const setOnline = () => setIsOffline(false);
  const setOffline = () => setIsOffline(true);
  useEffect(() => {
    window.addEventListener('offline', setOffline);
    window.addEventListener('online', setOnline);
    return () => {
      window.removeEventListener('offline', setOffline);
      window.removeEventListener('online', setOnline);
    };
  }, []);
  return isOffline;
}

function useIsApiConnected() {
  const [isConnectedToBoxApi, setIsConnectedToBoxApi] = useState(
    boxApi.isApiConnected()
  );

  useEffect(() => {
    const handler = (connected: boolean) => setIsConnectedToBoxApi(connected);
    boxApi.onConnectionChange(handler);

    return () => {
      boxApi.offConnectionChange(handler);
    };
  }, []);

  return isConnectedToBoxApi;
}

export const useOffline = () => useContext(OfflineContext);
