import React from 'react';
import { useParams } from 'react-router-dom';

import { useApiMutation, useApiQuery } from '../../hooks/useApi';
import { useTranslation } from '../../I18nProvider';
import { space } from '../../tokens';
import { Button } from '../common/Button';
import { Card } from '../common/Card';
import { Hint } from '../common/Hint';
import { Loader } from '../common/Loader';
import { Page } from '../common/Page';
import { Spacer } from '../common/Spacer';
import { Stack } from '../common/Stack';

export function ManageSharedElevators() {
  const { subcontractorId, buildingId } = useParams<{
    subcontractorId: string;
    buildingId: string;
  }>();
  const t = useTranslation();

  const parsedSubcontractorId = subcontractorId ? Number(subcontractorId) : 0; // Default to 0 to avoid undefined
  const parsedBuildingId = buildingId ? Number(buildingId) : 0; // Default to 0 to avoid undefined

  const liftsQuery = useApiQuery(
    'getLiftsWithShareStatus',
    parsedSubcontractorId,
    parsedBuildingId
  );

  const shareLiftMutation = useApiMutation('shareLiftWithSubcontractor', {
    onSuccess: liftsQuery.invalidate,
  });
  const unshareLiftMutation = useApiMutation('unshareLiftWithSubcontractor', {
    onSuccess: liftsQuery.invalidate,
  });

  if (parsedSubcontractorId === 0 || parsedBuildingId === 0) {
    return <p>{t.subcontractorNotFound}</p>;
  }

  const handleToggleLifts = (isShared: boolean, liftId: number) => {
    if (isShared) {
      unshareLiftMutation(parsedSubcontractorId, liftId);
    } else {
      shareLiftMutation(parsedSubcontractorId, { liftId });
    }
  };

  if (liftsQuery.isLoading) {
    return <Loader />;
  }

  return (
    <Page pageTitle={t.manageElevators} backLink="../..">
      <Stack gap={space[4]}>
        {liftsQuery.data && liftsQuery.data.length === 0 && (
          <Hint>{t.buildingHasNoElevator}</Hint>
        )}
        {liftsQuery.data?.map((lift: any) => (
          <div key={lift.id}>
            <Card key={lift.id} title={lift.name}>
              <Stack>
                {lift.box_id}
                <Spacer padding={space[1]} />
                <Button
                  variant={lift.isShared ? 'secondary' : 'primary'}
                  size="small"
                  onClick={() => handleToggleLifts(lift.isShared, lift.id)}
                >
                  {lift.isShared ? t.unshare : t.share}
                </Button>
              </Stack>
            </Card>
          </div>
        ))}
      </Stack>
    </Page>
  );
}
