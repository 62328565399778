import { CatmullRomSpline } from './catmull-rom-spline';
import { DataPoint } from './math-2d';

/**
 * Interpolates a set of datapoints via the help of chaining CatMull-Rom splines
 *
 * @param data set of data points. If number of points are less than 2, no interpolation is done
 * @param resolution number of interpolations per spline.
 * @returns more datapoints.
 */

export const interpolatePoints = (data: DataPoint[], resolution = 5) => {
  const splines = [];
  if (data.length < 2) {
    return data;
  }
  // to interpolate the complete curve, we need additional data points for the start/end points
  const start = { x: data[0].x - 10, y: data[0].y };
  const end = { x: data[data.length - 1].x + 10, y: data[data.length - 1].y };
  for (let i = -1; i < data.length - 2; i++) {
    const p0 = data[i] || start;
    const p1 = data[i + 1];
    const p2 = data[i + 2];
    const p3 = data[i + 3] || end;
    splines.push(new CatmullRomSpline(p0, p1, p2, p3));
  }
  const interpolatedData = Array.prototype.concat.apply(
    [],
    splines.map((spline) => {
      return Array.from({ length: resolution + 1 }, (_, i) =>
        spline.getPoint(i / resolution)
      );
    })
  );
  return interpolatedData;
};
