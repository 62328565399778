import React from 'react';
import styled, { css } from 'styled-components';

import { borderRadius, space } from '../../tokens';
import { replaceMapKey } from '../map/placeUtils';

const Gallery = styled.div`
  column-width: calc(50vw - ${space[3]} - ${space[2]});
  column-gap: ${space[2]};
`;

const Figure = styled.figure<{ selected?: boolean }>`
  margin-bottom: ${space[2]};
  position: relative;
  border-radius: ${borderRadius.default};
  overflow: hidden;
  ${(p) =>
    p.selected &&
    css`
      ::after {
        display: block;
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' height='24' width='24'%3E%3Cpath fill='white' d='M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8zm3.88-11.71L10 14.17l-1.88-1.88a.996.996 0 1 0-1.41 1.41l2.59 2.59c.39.39 1.02.39 1.41 0L17.3 9.7a.996.996 0 0 0 0-1.41c-.39-.39-1.03-.39-1.42 0z'/%3E%3C/svg%3E")
            center no-repeat,
          linear-gradient(
            180deg,
            rgba(0, 152, 156, 0.75) 0%,
            rgba(14, 90, 92, 0.75) 100%
          );
      }
    `}
`;

interface Props {
  images: string[];
  value?: string | null;
  onChange: (value: string) => void;
}

export function ImageSelect({ images, value, onChange }: Props) {
  return (
    <Gallery>
      {images.map((url) => (
        <Figure
          key={url}
          selected={url === value}
          onClick={() => onChange(url)}
        >
          <img src={replaceMapKey(url)} />
        </Figure>
      ))}
    </Gallery>
  );
}
