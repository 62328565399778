import React from 'react';
import { useParams } from 'react-router-dom';

import { Button } from '../components/common/Button';
import { FullScreenDialog } from '../components/common/FullScreenDialog';
import { Logo } from '../components/common/Logo';
import { Row } from '../components/common/Row';
import { Stack } from '../components/common/Stack';
import { Form } from '../components/form/Form';
import { InputField } from '../components/form/InputField';
import { ToggleField } from '../components/form/ToggleField';
import { useApiQuery } from '../hooks/useApi';
import { useFormSubmission } from '../hooks/useFormSubmission';
import { useTranslation } from '../I18nProvider';
import { useSetLogin } from '../UserProvider';
import { acceptInvite, AcceptInviteArgs } from '../utils/api';
import { Terms } from './Terms';

export function AcceptInvite() {
  const { token } = useParams();
  const t = useTranslation();
  const setLogin = useSetLogin();
  const invite = useApiQuery('getInvite', token!);

  const { form, register } = useFormSubmission<AcceptInviteArgs>({
    async onSubmit(data) {
      const res = await acceptInvite(token!, data);
      setLogin(res);
    },
  });

  return (
    <FullScreenDialog>
      <Logo />
      {invite.isSuccess ? (
        <Form {...form}>
          <Stack>
            <p>{t.joinText(invite.data.company.name)}</p>
            <InputField
              register={register}
              placeholder={t.firstName}
              name="firstName"
              type="text"
              // maxLength={50}
              required
            />
            <InputField
              register={register}
              placeholder={t.lastName}
              name="lastName"
              type="text"
              // maxLength={50}
              required
            />
            <InputField
              register={register}
              placeholder={t.password}
              name="password"
              type="password"
              // minLength={6}
              required
            />
            <ToggleField register={register} name="acceptTerms">
              {t.acceptTerms}
            </ToggleField>
            <Terms />
            <Button variant="primary">{t.signUp}</Button>
          </Stack>
        </Form>
      ) : (
        <Row justify="center">{t.invitationExpired}</Row>
      )}
    </FullScreenDialog>
  );
}
