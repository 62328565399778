import React, { ReactNode } from 'react';
import styled from 'styled-components';

import {
  MandatoryIcon,
  OkIcon,
  RecommendedIcon,
} from '../../components/common/Icons';
import { Row } from '../../components/common/Row';
import { Spacer } from '../../components/common/Spacer';
import { SectionHeading, TextXs } from '../../components/common/typography';
import { Translations } from '../../i18n';
import { useTranslation } from '../../I18nProvider';
import { borderRadius, color, space } from '../../tokens';

export const Table = styled.table`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: ${space[4]};
  color: ${color.heavyCardText};
  background: ${color.cardBackground};
  border-radius: ${borderRadius.default};
  & ${SectionHeading} {
    margin-bottom: ${space[2]};
  }
  tbody {
    color: ${color.cardText};
    display: flex;
    flex-direction: column;
  }

  tr {
    display: flex;
    flex-wrap: wrap;
  }
`;

export const TableHeader = styled.th`
  flex: 1;
  display: block;
  text-align: left;
  font-weight: normal;
`;
export const Value = styled.td`
  text-align: right;
  align-items: center;
  white-space: nowrap;
`;

type Props = {
  title?: string;
  icon?: ReactNode;
  data: Record<string, ReactNode>;
  showButton?: boolean;
  onButtonClick?: any;
};

export function PriorityGrid({ icon, title, data }: Props) {
  const t = useTranslation();

  return (
    <Table>
      {title && (
        <SectionHeading as="caption">
          <Row>
            {icon}
            <span>{title}</span>
          </Row>
        </SectionHeading>
      )}
      <tbody>
        {Object.entries(data)
          .filter(([_, value]) => value !== null && value !== '')
          .map(([label, value], i) => (
            <TextXs as="tr" key={i}>
              {label === t.recommended ? (
                <td>
                  <RecommendedIcon color={color.warning} />
                </td>
              ) : label === t.ok ? (
                <td>
                  <OkIcon color={color.success} />
                </td>
              ) : label === t.mandatory ? (
                <td>
                  <MandatoryIcon color={color.error} />
                </td>
              ) : null}
              <Spacer padding={space[1]} />
              <TableHeader>{(t as any)[label] ?? label}</TableHeader>
              <Value>{format(value, t)}</Value>
            </TextXs>
          ))}
      </tbody>
    </Table>
  );
}

function format(value: ReactNode, t: Translations) {
  if (value === null) return '';
  if (typeof value === 'number') return value;
  if (typeof value === 'boolean') return value ? t.yes : t.no;
  if (typeof value === 'string' && value in t)
    return t[value as keyof typeof t];
  return value;
}
