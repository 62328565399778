import React from 'react';

import { color as colors } from '../../tokens';
import {
  BellIcon,
  CheckCircleIcon,
  DangerIcon,
  IconProps,
  WarningIcon,
} from './Icons';

export type StatusType = 'success' | 'info' | 'warning' | 'error' | 'fatal';

const icons = {
  success: CheckCircleIcon,
  info: BellIcon,
  warning: WarningIcon,
  error: WarningIcon,
  fatal: DangerIcon,
} as const;

type Props = IconProps & {
  status: StatusType;
  color?: string;
};
export function StatusIcon({ status, color, ...props }: Props) {
  const Icon = icons[status];
  return <Icon style={{ color: color ?? colors[status] }} {...props} />;
}
