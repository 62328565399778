import React from 'react';
import { useNavigate } from 'react-router-dom';

import { BuildingForm } from '../components/building/BuildingForm';
import { Page } from '../components/common/Page';
import { useTranslation } from '../I18nProvider';
import { addBuilding, BuildingArgs } from '../utils/api';

export function AddBuilding() {
  const t = useTranslation();

  const navigate = useNavigate();
  const onSubmit = async (values: BuildingArgs) => {
    const { id } = await addBuilding(values);
    navigate(`./${id}`);
  };

  return (
    <Page pageTitle={t.addBuilding} backLink="/">
      <BuildingForm onSubmit={onSubmit} onCancel={() => navigate('/')} />
    </Page>
  );
}
