import React from 'react';
import styled from 'styled-components';

import { color, fontSize, fontWeight } from '../../tokens';

const Container = styled.div<{ color?: string }>`
  background-color: ${(props) => props.color || color.success};
  border-radius: 3px;
  padding: 10px;
  display: inline-box;
`;

const Title = styled.p`
  color: ${color.background};
  font-size: ${fontSize.xs};
  font-weight: ${fontWeight.bold};
  padding-right: 5px;
`;

const Text = styled.p`
  color: ${color.background};
  font-size: ${fontSize.xs};
  font-weight: ${fontWeight.normal};
`;

interface Props {
  title: string;
  text: string;
  color?: string;
}

export function InfoContainer({ title, text, color }: Props) {
  return (
    <Container color={color}>
      <Title>{title}</Title>
      <Text>{text}</Text>
    </Container>
  );
}
