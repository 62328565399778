import React from 'react';

import { ErrorCard } from '../../components/common/ErrorCard';
import { useTranslation } from '../../I18nProvider';

type Props = {
  statusCode: number;
};

export function DoorCycleError({ statusCode }: Props) {
  const t = useTranslation();
  const key = `doorCycleError${statusCode}`;
  const title = (t as any)[key] ?? `Error ${statusCode}`;
  return (
    <ErrorCard status="warning" title={title}>
      {statusCode === 7 ? t.repeatWholeRun : t.repeatFloor}
    </ErrorCard>
  );
}
