import React from 'react';
import Geosuggest from 'react-geosuggest';
import styled from 'styled-components';

import { useTranslation } from '../../I18nProvider';
import { shadow } from '../../styles';
import { borderRadius, color, fontSize } from '../../tokens';
import { Address, getAddress } from './placeUtils';

const StyledGeosuggest = styled(Geosuggest)`
  background: ${color.input};
  color: ${color.inputText};
  font-size: ${fontSize.base};
  border-radius: ${borderRadius.default};
  .geosuggest__input {
    width: 100%;
    border: none;
    background: none;
    outline: none;
    line-height: 1;
    padding: 1rem 0.5rem;
  }
  .geosuggest__suggests {
    ${shadow}
    background: ${color.background};
    margin: 0;
    padding: 0;
    list-style-type: none;
    border-top: 1px solid ${color.line};
    &--hidden {
      max-height: 0;
      overflow: hidden;
      border-top: none;
    }
  }
  .geosuggest__item {
    padding: 0.75rem 0.5rem;
    font-size: ${fontSize.xs};
    &--active {
      color: ${color.primary};
    }
  }
`;

type Props = {
  initialInput?: string;
  onChange: (value: Address) => void;
  onRawInputChange: (input: string) => void;
};

export function AddressInput({
  initialInput,
  onChange,
  onRawInputChange,
}: Props) {
  const t = useTranslation();

  return (
    <StyledGeosuggest
      placeholder={t.searchPlaces}
      initialValue={initialInput}
      onSuggestSelect={(suggest) => {
        if (suggest) {
          onChange(getAddress(suggest.gmaps as google.maps.places.PlaceResult));
        }
      }}
      autoActivateFirstSuggest
      autoComplete="off"
      placeDetailFields={['address_components', 'name', 'photos']}
      onChange={(e) => onRawInputChange(e)}
    />
  );
}
