import React, { Suspense } from 'react';

import { Button } from '../components/common/Button';
import { Loading } from '../components/common/Loading';
import { useToggle } from '../hooks/useToggle';
import { useTranslation } from '../I18nProvider';

const TermsDialog = React.lazy(() => import('./TermsDialog'));

export function Terms() {
  const t = useTranslation();
  const [showOverlay, toggleOverlay] = useToggle();
  return (
    <>
      <Button type="button" onClick={toggleOverlay}>
        {t.showTerms}
      </Button>
      {showOverlay && (
        <div>
          <Suspense fallback={<Loading />}>
            <TermsDialog onClose={toggleOverlay} />
          </Suspense>
        </div>
      )}
    </>
  );
}
