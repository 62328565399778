import React from 'react';
import { Link } from 'react-router-dom';

import { Button } from '../../components/common/Button';
import { Center } from '../../components/common/Center';
import { Stack } from '../../components/common/Stack';
import deactivated from '../../components/lift/deactivated.svg';
import { useTranslation } from '../../I18nProvider';

type Props = {
  linkTo: string;
};

export function IncompleteElevatorSettings({ linkTo }: Props) {
  const t = useTranslation();
  return (
    <Stack>
      <div>{t.incompleteElevatorSettings}</div>
      <Center>
        <img src={deactivated} />
      </Center>
      <Button as={Link} variant="primary" to={linkTo}>
        {t.settings}
      </Button>
    </Stack>
  );
}
