import React, { ReactNode } from 'react';

import { useApiQuery } from '../../hooks/useApi';
import { AppHeader } from '../common/AppHeader';
import { replaceMapKey } from '../map/placeUtils';
import icon from './lift.svg';

type Props = {
  id: number;
  action?: ReactNode;
};
export function LiftHeader({ id, action }: Props) {
  const { data: lift } = useApiQuery('getLift', id);
  const props = lift?.building.photo
    ? {
        background: replaceMapKey(lift.building.photo),
      }
    : {
        image: icon,
      };
  return (
    <>
      <AppHeader
        {...props}
        title={lift?.name}
        subTitle={lift?.building.name}
        action={action}
      ></AppHeader>
      {/* {<LiftNotificationStatus id={id} />} */}
    </>
  );
}
