import { Status, Wrapper } from '@googlemaps/react-wrapper';
import React, { ReactElement, ReactNode } from 'react';

import { Loading } from '../common/Loading';

function render(status: Status): ReactElement {
  if (status === Status.LOADING) return <Loading />;
  return <div>Failed to load Google Maps API</div>;
}

interface Props {
  children: ReactNode;
}
export function MapsApi({ children }: Props) {
  return (
    <Wrapper
      apiKey={import.meta.env.VITE_GOOGLE_API_KEY}
      render={render}
      libraries={['places']}
    >
      {children}
    </Wrapper>
  );
}
