import React from 'react';
import styled from 'styled-components';

import { useApiMutation } from '../hooks/useApi';
import { useFormSubmission } from '../hooks/useFormSubmission';
import { useToggle } from '../hooks/useToggle';
import { useTranslation } from '../I18nProvider';
import { space } from '../tokens';
import { Button } from './common/Button';
import { FullScreenDialog } from './common/FullScreenDialog';
import { Overlay } from './common/Overlay';
import { Stack } from './common/Stack';
import { CardTitle } from './common/typography';
import { Form } from './form/Form';
import { InputField } from './form/InputField';

const Title = styled(CardTitle)`
  margin: ${space[3]} auto ${space[1]};
`;

type FormValues = {
  email: string;
};

function InviteSubcontractorDialog({
  onClose,
  onSuccess,
}: {
  onClose: () => void;
  onSuccess?: () => void;
}) {
  const t = useTranslation();
  const invite = useApiMutation('inviteSubcontractor', {
    onSuccess,
  });

  const { form, register } = useFormSubmission<FormValues>({
    async onSubmit({ email }) {
      await invite({ email });
      onClose();
    },
  });

  return (
    <Overlay onClose={onClose}>
      <FullScreenDialog>
        <Title>{t.inviteSubcontractor}</Title>
        <Form {...form}>
          <Stack>
            <InputField
              register={register}
              name="email"
              placeholder={t.email}
              required
            />
            <Button type="submit" variant="primary" disabled={invite.isLoading}>
              {t.send}
            </Button>
            <Button type="button" onClick={onClose}>
              {t.cancel}
            </Button>
          </Stack>
        </Form>
      </FullScreenDialog>
    </Overlay>
  );
}

type Props = {
  onSuccess?: () => void;
};
export function InviteSubcontractor(props: Props) {
  const t = useTranslation();
  const [showOverlay, toggleOverlay] = useToggle();
  return (
    <>
      <Button onClick={toggleOverlay}>{t.inviteSubcontractor}</Button>
      {showOverlay && (
        <InviteSubcontractorDialog {...props} onClose={toggleOverlay} />
      )}
    </>
  );
}
