import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

import { useApiMutation } from '../../hooks/useApi';
import { useOffline } from '../../OfflineProvider';
import { color } from '../../tokens';
import { useUserState } from '../../UserProvider';
import { UserLocale } from '../../utils/appApi';

export type SelectedLanguage = {
  locale: UserLocale;
  name: string;
};

export const languages: SelectedLanguage[] = [
  { locale: 'de', name: 'Deutsch' },
  { locale: 'en', name: 'English' },
  { locale: 'it', name: 'Italiano' },
  { locale: 'fr', name: 'Français' },
  { locale: 'es', name: 'Español' },
];

const Wrapper = styled.div`
  position: relative;
`;

const Selector = styled.div`
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 5px;
  color: ${color.primary};
  cursor: pointer;
  padding: 5px;
  width: 40px;
  text-align: center;
`;

const Dropdown = styled.div`
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  position: absolute;
  bottom: calc(100% + 5px);
  width: 150px;
  z-index: 1;
  right: 0;
`;

const Option = styled.div`
  color: ${color.primary};
  cursor: pointer;
  padding: 10px 10px;

  &:hover {
    background-color: #f5f5f5;
  }
`;

const LanguageDropdown = () => {
  const offline = useOffline();
  const [isOpen, setIsOpen] = useState(false);
  const { user, setUser } = useUserState();

  const [selectedLanguage, setSelectedLanguage] = useState({
    locale: user?.locale || 'en',
    name: languages.find((l) => l.locale === user?.locale)?.name || 'English',
  });
  const dropdownRef = useRef<HTMLDivElement>(null);
  const selectorRef = useRef<HTMLDivElement>(null);

  const updateUser = useApiMutation('updateUser', {
    onSuccess: (user) => setUser(user),
  });

  const updateUserLocale = (locale: UserLocale) => {
    if (offline && user) {
      return setUser({ ...user, locale });
    }
    return updateUser({ locale });
  };

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const selectLanguage = (language: SelectedLanguage) => {
    setSelectedLanguage(language);
    setIsOpen(false);
    updateUserLocale(language.locale);
  };

  const handleOutsideClick = (event: any) => {
    if (
      dropdownRef.current &&
      !dropdownRef.current.contains(event.target) &&
      !selectorRef.current?.contains(event.target)
    ) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    window.addEventListener('click', handleOutsideClick);
    window.addEventListener('touchend', handleOutsideClick);
    return () => {
      window.removeEventListener('click', handleOutsideClick);
      window.removeEventListener('touchend', handleOutsideClick);
    };
  }, []);

  return (
    <Wrapper>
      <Selector
        ref={selectorRef}
        onClick={(e) => {
          e.stopPropagation();
          toggleDropdown();
        }}
      >
        {selectedLanguage.locale.toUpperCase()}
      </Selector>
      {isOpen && (
        <Dropdown ref={dropdownRef}>
          {languages.map((language: SelectedLanguage) => (
            <Option
              key={language.locale}
              onClick={() => selectLanguage(language)}
            >
              {language.name} ({language.locale.toUpperCase()})
            </Option>
          ))}
        </Dropdown>
      )}
    </Wrapper>
  );
};

export default LanguageDropdown;
