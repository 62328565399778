import React from 'react';
import styled from 'styled-components';

import { Stack } from '../../components/common/Stack';
import { useTranslation } from '../../I18nProvider';
import { borderRadius, color, fontWeight, space } from '../../tokens';
import { FullInstallationControl } from '../../utils/api';

const Container = styled.div`
  border-radius: ${borderRadius.default};
`;

const Title = styled.div<{
  expanded?: boolean;
  status: 'error' | 'success';
}>`
  color: ${color.textOnPrimary};
  background: ${(p) => color[p.status]};
  border-radius: ${borderRadius.default} ${borderRadius.default} 0 0;
  transition: all 200ms;
  font-weight: ${fontWeight.bold};
  user-select: none;
  display: flex;
  align-items: center;
  padding: ${space[2.5]} ${space[2]};
`;

const Panel = styled.div`
  border-radius: 0 0 ${borderRadius.default} ${borderRadius.default};
  background: ${color.cardBackground};
  color: ${color.cardText};
  padding: ${space[3]};
`;

type Props = {
  installationControl: FullInstallationControl;
};
export function SetupPanels({ installationControl }: Props) {
  const t = useTranslation();
  const { floor_results, status, lift } = installationControl;
  return (
    <Stack>
      {floor_results.map(({ floor, movementDistance, setupRunStatusCode }) => {
        const status = setupRunStatusCode ?? 0;
        const error =
          (t as any)[`liftSetupError${setupRunStatusCode}`] ?? t.liftSetupError;
        return (
          <Container key={floor}>
            <Title status={status > 0 ? 'error' : 'success'}>
              {t.floor} {floor}
            </Title>
            <Panel>
              {status > 0 ? (
                error
              ) : (
                <div>
                  {t.liftMovementDistance}: {movementDistance ?? 0} m
                </div>
              )}
            </Panel>
          </Container>
        );
      })}
      {status === 'completed' && (
        <Container>
          <Title status={lift.isSetUp ? 'success' : 'error'}>
            {lift.isSetUp ? t.liftSetup : t.liftSetupIncomplete}
          </Title>
          <Panel>
            {lift.isSetUp ? t.liftSetupComplete : t.liftSetupRepeat}
          </Panel>
        </Container>
      )}
    </Stack>
  );
}
