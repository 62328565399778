import React, { useRef, useState } from 'react';
import { Link } from 'react-router-dom';

import { Button } from '../../components/common/Button';
import { ErrorHint } from '../../components/common/ErrorHint';
import { Loading } from '../../components/common/Loading';
import { Stack } from '../../components/common/Stack';
import { useApiMutation } from '../../hooks/useApi';
import { useDateFormat } from '../../hooks/useDateFormat';
import { useTranslation } from '../../I18nProvider';
import { isStatus } from '../../utils/api';
import {
  FullInstallationControl,
  getInstallationCertificate,
} from '../../utils/appApi';
import { ResultPanels } from './ResultPanels';

type Props = {
  installationControl: FullInstallationControl;
  query: any;
};
export function ShowResults({ installationControl, query }: Props) {
  const t = useTranslation();
  const { lift, status, failures, share_key, warrantyExtensionRequestedOn } =
    installationControl;
  const shareUrl = `${window.location.origin}/shared/${lift.id}/${share_key}`;
  const [isLoading, setIsLoading] = useState(false);
  const [showGoToSettings, setShowGoToSettings] = useState(false);
  const errorHintRef = useRef<any>(null);
  const { formatDateTime } = useDateFormat();

  const extendWarranty = useApiMutation('extendWarranty', {
    onSuccess: () => {
      query.refetch();
    },
    onError: (err) => {
      if (isStatus(err, 403)) {
        setShowGoToSettings(true);
        errorHintRef.current?.scrollIntoView({
          behavior: 'smooth',
          block: 'start',
        });
      }
    },
  });
  const onWarrantyExtend = () => {
    extendWarranty(lift.id);
  };

  return (
    <Stack>
      {isLoading ? (
        <Loading />
      ) : (
        <>
          <ResultPanels installationControl={installationControl} />
          <Button
            as={Link}
            variant="secondary"
            to={status === 'obsolete' ? 'new' : 'restart'}
          >
            {t.startNewTestRun}
          </Button>

          {status === 'completed' && (
            <Button
              as="a"
              variant="secondary"
              target="_blank"
              href={`mailto:?subject=${encodeURIComponent(
                t.shareSubject
              )}&body=${encodeURIComponent(t.shareBody(shareUrl))}`}
              onClick={(e: React.MouseEvent) => {
                if ('share' in navigator) {
                  navigator.share({
                    title: t.shareSubject,
                    url: shareUrl,
                  });
                  e.preventDefault();
                }
              }}
            >
              {t.shareResults}
            </Button>
          )}

          {status === 'completed' && failures === 0 && (
            <Button
              as="a"
              variant="secondary"
              target="_blank"
              download="Certificate.pdf"
              onClick={async (e: React.MouseEvent) => {
                setIsLoading(true);
                const link = e.target as HTMLAnchorElement;
                if (!link.href) {
                  e.preventDefault();
                  const data = await getInstallationCertificate(lift.id);
                  const binaryString = window.atob(data);
                  const length = binaryString.length;
                  const bytes = new Uint8Array(length);
                  for (let i = 0; i < length; i++) {
                    bytes[i] = binaryString.charCodeAt(i);
                  }

                  const blob = new Blob([bytes.buffer], {
                    type: 'application/pdf',
                  });
                  const fileURL = URL.createObjectURL(blob);

                  const userAgent = window.navigator.userAgent;
                  const isIOS = /iPhone|iPad|iPod/i.test(userAgent);

                  if (isIOS) {
                    setTimeout(() => {
                      window.open(fileURL, '_blank');
                    });
                  } else {
                    link.href = fileURL;
                    link.click();
                    window.open(fileURL, '_blank');
                  }
                  setIsLoading(false);
                }
              }}
            >
              {t.downloadCertificate}
            </Button>
          )}
          {status === 'completed' &&
            failures === 0 &&
            !warrantyExtensionRequestedOn && (
              <Button
                type="button"
                variant="secondary"
                onClick={onWarrantyExtend}
              >
                {t.extendWarranty}
              </Button>
            )}
          {warrantyExtensionRequestedOn && (
            <Button type="button" variant="secondary" disabled={true}>
              {t.warrantyExtensionRequestedOn(
                formatDateTime(warrantyExtensionRequestedOn)
              )}
            </Button>
          )}
          {showGoToSettings && (
            <div ref={errorHintRef}>
              <Link to={'../edit'}>
                <ErrorHint>{t.elevatorIdNotFound}</ErrorHint>
              </Link>
            </div>
          )}
        </>
      )}
    </Stack>
  );
}
