import { TranslatableErrorKey } from '../../i18n';
import { DriveError, DriveErrorName } from './driveErrorMap';

export type ApiType = 'DRIVE_API' | 'HOST_API';

export class TranslatableError extends Error {
  constructor(
    message: string,
    public readonly code: TranslatableErrorKey,
    public readonly cause?: any
  ) {
    super(message);
  }
}

export class RequestError extends TranslatableError {
  constructor(
    message: string,
    public readonly api: ApiType,
    public readonly method: string,
    public readonly url: string,
    public readonly payload?: object,
    cause?: any
  ) {
    super(message, cause?.code || 'programmingErrorRequest', cause);
  }
}
export class CodeRequestError extends RequestError {
  constructor(api: ApiType, method: string, url: string, payload?: object) {
    super('Request timeout error', api, method, url, payload);
  }
}
export class RequestTimeoutError extends RequestError {
  constructor(api: ApiType, method: string, url: string, payload?: object) {
    super('Request timeout error', api, method, url, payload);
  }
}

export type BoxConfig = {
  readonly deviceName: string;
  readonly CanProtocol: string;
  readonly hw_acceleration_sensor: string;
  readonly hw_pressure_sensor: string;
  readonly hw_humidity_sensor: string;
  readonly LogLevel: string;
};

export type DriveVersion = {
  readonly filename: string;
  readonly version: number;
  readonly subversion: number;
  readonly subsubversion: number;
  readonly date: string;
  readonly author: string;
  readonly valid: boolean;
  readonly changelog: string;
};

export type DriveInfo = {
  readonly login_status: boolean;
  readonly sw_version: string;
  readonly ESN_string: string;
  readonly lock_input: boolean;
  readonly UPEI_info: string;
};

export type DriveParameterValue = {
  readonly max?: number;
  readonly min?: number;
  unit: string;
  value: number;
  writeable: boolean;
};

export type DriveStatus = {
  readonly cur_version: DriveVersion;
  readonly drive_info: DriveInfo;
};

export enum LearnParameterNames {
  CycleCount = 'CycleCount',
  DoorPosition = 'DoorPosition',
}

export type LearnParameter = {
  [LearnParameterNames.CycleCount]: number;
  [LearnParameterNames.DoorPosition]: number;
  closeforce: number;
  learnedCouplerwidth: number;
  learnedDoorwidth: number;
};

export enum DriveParameterNames {
  // REVISIT: We could simplify this again and remove the two here.
  //          Also the split in ReadonlyDriveParameter and EditableDriveParameter
  //          could be removed then. Since they are still in current Dashboard
  //          responses present we will keep them here for now.
  CycleCount = 'CycleCount',
  DoorPosition = 'DoorPosition',

  OpenSpeed = 'OpenSpeed',
  CloseSpeed = 'CloseSpeed',
  NudggingSpeed = 'NudggingSpeed',
  BrakePar = 'BrakePar',
  PositionOut = 'PositionOut',
  CloseHoldForce = 'CloseHoldForce',
  Acceleration = 'Acceleration',
  OpenHoldForce = 'OpenHoldForce',
  CloseForcePoti = 'CloseForcePoti',

  CouplerSpeed = 'CouplerSpeed',
  CouplerWitdh = 'CouplerWitdh',

  ReopenTime = 'ReopenTime',
}

export type DriveParameterName = keyof typeof DriveParameterNames;
export type ReadonlyDriveParameter = 'CycleCount' | 'DoorPosition';
export type EditableDriveParameter = Exclude<
  DriveParameterName,
  ReadonlyDriveParameter
>;
export type EditableDriveParameterName = Exclude<
  DriveParameterNames,
  ReadonlyDriveParameter
>;

export type DriveParameters = Record<DriveParameterName, DriveParameterValue>;

export type DriveParameterFormValue = {
  name: DriveParameterName;
  value: number;
};

export type BoxApiConnectionHandler = (
  connected: boolean
) => Promise<void> | void;

export type BoxApiLockInputChangeHandler = (
  lockInput: boolean
) => Promise<void> | void;

export type BoxApiDipSwitchPositionsHandler = (
  dipSwitchPositions: Record<string, boolean>
) => Promise<void> | void;

export type BoxApiLearnParameterChangeHandler = (
  learnParameter: LearnParameter
) => Promise<void> | void;

export type BoxApiUpsmartErrorListChangeHandler = (
  upsmartErrors: DriveError[]
) => Promise<void> | void;

export interface BoxApi {
  isApiConnected(): boolean;
  connectApi(): Promise<boolean>;
  disconnectApi(): Promise<boolean>;
  onConnectionChange(handler: BoxApiConnectionHandler): void;
  offConnectionChange(handler: BoxApiConnectionHandler): void;

  getBoxConfig(): BoxConfig | undefined;
  setToken(token: string): void;

  connectDrive(): Promise<DriveStatus>;
  disconnectDrive(): Promise<DriveStatus>;
  getDriveStatus(): Promise<DriveStatus>;

  isLockInput(): Promise<boolean>;
  onLockInputChange(handler: BoxApiLockInputChangeHandler): void;
  offLockInputChange(handler: BoxApiLockInputChangeHandler): void;

  // getDipSwitchPositions(): Promise<Record<string, boolean>>;
  // onDipSwitchPositionsChange(handler: BoxApiDipSwitchPositionsHandler): void;
  // offDipSwitchPositionsChange(handler: BoxApiDipSwitchPositionsHandler): void;

  getDriveParameters(): Promise<DriveParameters>;
  setDriveParameter(name: DriveParameterName, value: number): Promise<void>;

  getLearnParameter(): Promise<LearnParameter>;
  onLearnParameterChange(handler: BoxApiLearnParameterChangeHandler): void;
  offLearnParameterChange(handler: BoxApiLearnParameterChangeHandler): void;

  getDriveErrors(): Promise<DriveErrorName[]>;
  clearDriveErrors(): Promise<void>;

  getUpsmartErrors(): Promise<DriveError[]>;
  onUpsmartErrorListChange(handler: BoxApiUpsmartErrorListChangeHandler): void;
  offUpsmartErrorListChange(handler: BoxApiUpsmartErrorListChangeHandler): void;
}

export const getBoxSummary = (driveStatus: DriveStatus | undefined) => {
  if (!driveStatus) {
    return {};
  }

  const { drive_info: d, cur_version: v } = driveStatus;
  return {
    driveesn: d.ESN_string,
    drivesoftware: d.sw_version,
    boxSoftware: `v${v.version}.${v.subversion}.${v.subsubversion} (${v.date})`,
  };
};
