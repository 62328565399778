import React, { Fragment, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

import { HorizontalScroll } from '../../components/common/HorizontalScroll';
import { Loader } from '../../components/common/Loader';
import { Stack } from '../../components/common/Stack';
import { TextXs } from '../../components/common/typography';
import { useDateFormat } from '../../hooks/useDateFormat';
import { useTranslation } from '../../I18nProvider';
import { color, fontSize, fontWeight, space } from '../../tokens';
import { DoorData } from '../../utils/boxData';
import { DoorCycleCard } from './DoorCycleCard';
import { DoorCycleGraph } from './DoorCycleGraph';
import { SensorData } from './SensorData';

const GraphContainer = styled.div`
  background: ${color.background};
  color: ${color.text};
  display: flex;
  flex-direction: column;
  justify-content: center;
  aspect-ratio: 3 / 2;
  padding: 0;
  margin: 0;

  @media (orientation: landscape) {
    aspect-ratio: 2.4;
  }
`;

const DaySeparator = styled.div`
  position: relative;
  width: 1px;
  padding-left: 1px;
  background: ${color.line};
  margin-top: -1.3em;
  align-self: stretch;
`;

const Day = styled.div`
  position: absolute;
  top: 0;
  left: 1ch;
  color: ${color.cardText};
  font-size: ${fontSize.xs};
  font-weight: ${fontWeight.bold};
`;

const Waiting = styled.div`
  color: ${color.cardText};
  display: flex;
  align-items: center;
  white-space: nowrap;
  opacity: 0.6;
`;

const StyledHorizontalScroll = styled(HorizontalScroll)`
  padding-top: ${space[7]};
`;

type Props = {
  liftId: number;
  data: DoorData[];
};
export function RealtimeData({ liftId, data }: Props) {
  const t = useTranslation();
  const formatDate = useDateFormat().formatDate;
  const [selected, setSelected] = useState<DoorData | undefined>();

  const follow = useRef(true);
  const last = useRef<DoorData>();

  // Auto-select last cycle if follow mode is on
  useEffect(() => {
    if (data.length >= 1) {
      last.current = data[data.length - 1];
      if (follow.current) setSelected(last.current);
    }
  }, [data]);

  // Enable follow mode if last cycle is selected
  useEffect(() => {
    follow.current = !!selected && selected === last.current;
  }, [selected]);

  return (
    <Stack>
      <GraphContainer>
        {selected ? <DoorCycleGraph data={selected} /> : <Loader />}
      </GraphContainer>
      <StyledHorizontalScroll>
        {data.map((d, i) => {
          const date = formatDate(d.startTime);
          const lastDate = formatDate(data[i - 1]?.startTime);
          return (
            <Fragment key={d.cycle}>
              {date !== lastDate && (
                <DaySeparator>
                  <Day>{date}</Day>
                </DaySeparator>
              )}
              <DoorCycleCard
                data={d}
                active={d.cycle === selected?.cycle}
                onClick={() => setSelected(d)}
              />
            </Fragment>
          );
        })}
        <Waiting>
          <TextXs>{t.waitingForData}</TextXs>
          <div>
            <Loader />
          </div>
        </Waiting>
      </StyledHorizontalScroll>
      {selected && <SensorData data={selected} />}
      {/* <LiftErrorStatus id={liftId} /> */}
    </Stack>
  );
}
