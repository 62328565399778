import React from 'react';
import { Link } from 'react-router-dom';

import { useApiQuery } from '../../hooks/useApi';
import { useTranslation } from '../../I18nProvider';
import { Hint } from '../common/Hint';

type Props = {
  id: number;
  linkTo: string;
};
export function LiftSetupStatus({ id, linkTo }: Props) {
  const t = useTranslation();
  const { data: lift } = useApiQuery('getLift', id);
  if (!lift || lift.isSetUp) return null;
  return (
    <Link to={linkTo}>
      <Hint>{t.liftSetupPending}</Hint>
    </Link>
  );
}
