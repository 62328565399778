import _ from 'lodash';

import {
  ApiType,
  BoxApi,
  BoxApiConnectionHandler,
  // BoxApiDipSwitchPositionsHandler,
  BoxApiLearnParameterChangeHandler,
  BoxApiLockInputChangeHandler,
  BoxApiUpsmartErrorListChangeHandler,
  DriveParameterName,
  DriveParameters,
  DriveStatus,
  LearnParameter,
  RequestError,
} from './BoxApi';
import { DriveError, DriveErrorName } from './driveErrorMap';
import { trigger } from './trigger';
import { BridgeRequestMethod, WebRtcBridge } from './WebRtcBridge';

export class WebRtcBoxApi implements BoxApi {
  private driveStatus?: DriveStatus;

  private lockInput = false;
  private lockInputChangeHandler: BoxApiLockInputChangeHandler[] = [];

  // private dipSwitchPositions: Record<string, boolean> = {};
  // private dipSwitchPositionsHandler: BoxApiDipSwitchPositionsHandler[] = [];

  private learnParameter: LearnParameter = {
    CycleCount: 0,
    DoorPosition: 0,
    closeforce: 0,
    learnedCouplerwidth: 0,
    learnedDoorwidth: 0,
  };
  private driveParameterChangeHandler: BoxApiLearnParameterChangeHandler[] = [];

  private upsmartErrorList: DriveError[] = [];
  private upsmartErrorListChangeHandler: BoxApiUpsmartErrorListChangeHandler[] =
    [];

  private token?: string;

  constructor(private bridge: WebRtcBridge) {
    bridge.onBoxUpdates((update) => {
      if (this.lockInput !== update?.drive_info?.lock_input) {
        this.lockInput = update.drive_info.lock_input;
        trigger(this.lockInputChangeHandler, this.lockInput);
      }

      // if (!_.isEqual(update.Dipswitches, this.dipSwitchPositions)) {
      //   this.dipSwitchPositions = update.Dipswitches;
      //   trigger(this.dipSwitchPositionsHandler, this.dipSwitchPositions);
      // }

      if (!_.isEqual(update.learn_parameter, this.learnParameter)) {
        this.learnParameter = update.learn_parameter;
        trigger(this.driveParameterChangeHandler, this.learnParameter);
      }

      const upsmartErrorList = update.learn_EVENTS.UPSMART_ERROR_LIST;
      if (!_.isEqual(upsmartErrorList, this.upsmartErrorList)) {
        this.upsmartErrorList = handleLegacyUpsmartList(upsmartErrorList);
        trigger(this.upsmartErrorListChangeHandler, this.upsmartErrorList);
      }
    });
  }

  isApiConnected(): boolean {
    return this.bridge.isConnected();
  }

  async connectApi(): Promise<boolean> {
    await this.bridge.connect();
    return this.bridge.isConnected();
  }

  async disconnectApi(): Promise<boolean> {
    this.bridge.close();
    return this.bridge.isConnected();
  }

  onConnectionChange(handler: BoxApiConnectionHandler): void {
    this.bridge.onConnectionChange(handler);
  }

  offConnectionChange(handler: BoxApiConnectionHandler): void {
    this.bridge.offConnectionChange(handler);
  }

  getBoxConfig() {
    return this.bridge.getBoxConfig();
  }

  setToken(token?: string) {
    this.token = token;
  }

  private async request(
    api: ApiType,
    method: BridgeRequestMethod,
    endpoint: string,
    payload?: object
  ) {
    return this.bridge.request(api, method, endpoint, payload, this.token);
  }

  private async ensureConnected(connect = true) {
    if (this.driveStatus == null) {
      await this.getDriveStatus();
    }

    if (this.driveStatus?.drive_info.login_status === connect) {
      return this.driveStatus;
    }

    const response = await this.request('DRIVE_API', 'GET', '/index/_connect');
    const status = JSON.parse(response);
    this.driveStatus = status;
    return status;
  }

  async connectDrive(): Promise<DriveStatus> {
    return this.ensureConnected(true);
  }

  async disconnectDrive(): Promise<DriveStatus> {
    return this.ensureConnected(false);
  }

  async getDriveStatus(): Promise<DriveStatus> {
    const response = await this.request(
      'DRIVE_API',
      'GET',
      '/learn/_learn_window_loaded'
    );

    const status = JSON.parse(response);
    this.driveStatus = status;
    return status;
  }

  async isLockInput(): Promise<boolean> {
    const response = await this.getDriveStatus();
    this.lockInput = response.drive_info.lock_input;
    return this.lockInput;
  }

  onLockInputChange(handler: BoxApiLockInputChangeHandler) {
    this.lockInputChangeHandler.push(handler);
  }

  offLockInputChange(handler: BoxApiLockInputChangeHandler) {
    this.lockInputChangeHandler = this.lockInputChangeHandler.filter(
      (h) => h !== handler
    );
  }

  // async getDipSwitchPositions(): Promise<Record<string, boolean>> {
  //   // NOTE: Updates are too slow for inital load and also not being pushed
  //   //       for reloads. This is why we fetch the positions initially.
  //   if (Object.keys(this.dipSwitchPositions).length !== 8) {
  //     const response = await this.request(
  //       'DRIVE_API',
  //       'GET',
  //       '/learn/_par_update'
  //     );
  //     const parsed = JSON.parse(response);
  //     // If the dashboard started fresh and is connected - the first request does
  //     // not return the proper response. We therefor throw here and have the
  //     // query retried until this behaviour changes on the API side.
  //     if (Object.keys(parsed.Dipswitches || {}).length !== 8) {
  //       throw new RequestError(
  //         'DIPSW not initialised correctly',
  //         'DRIVE_API',
  //         'GET',
  //         '/learn/_par_update'
  //       );
  //     }
  //     this.dipSwitchPositions = parsed.Dipswitches;
  //   }

  //   return this.dipSwitchPositions;
  // }

  // onDipSwitchPositionsChange(handler: BoxApiDipSwitchPositionsHandler) {
  //   this.dipSwitchPositionsHandler.push(handler);
  // }

  // offDipSwitchPositionsChange(handler: BoxApiDipSwitchPositionsHandler) {
  //   this.dipSwitchPositionsHandler = this.dipSwitchPositionsHandler.filter(
  //     (h) => h !== handler
  //   );
  // }

  async getDriveParameters(): Promise<DriveParameters> {
    const response = await this.request('DRIVE_API', 'GET', '/index/_par_read');
    const params = JSON.parse(response);

    // If the dashboard started fresh and is connected - the first request does
    // not return the proper response. We therefor throw here and have the
    // query retried until this behaviour changes on the API side.
    if (params.OpenSpeed.unit === '[fault]') {
      throw new RequestError(
        'API not connected to the drive properly',
        'DRIVE_API',
        'GET',
        '/index/_par_read'
      );
    }

    return params;
  }

  async setDriveParameter(
    name: DriveParameterName,
    value: number
  ): Promise<void> {
    const payload = {
      ValName: `set_${name}`,
      value,
    };

    const response = await this.request(
      'DRIVE_API',
      'POST',
      '/index/_setValue_card',
      payload
    );

    if (JSON.parse(response).state !== 'OK') {
      throw new Error('Something wen wrong');
    }
  }

  async getLearnParameter(): Promise<LearnParameter> {
    return this.learnParameter;
  }

  onLearnParameterChange(handler: BoxApiLearnParameterChangeHandler) {
    this.driveParameterChangeHandler.push(handler);
  }

  offLearnParameterChange(handler: BoxApiLearnParameterChangeHandler) {
    this.driveParameterChangeHandler = this.driveParameterChangeHandler.filter(
      (h) => h !== handler
    );
  }

  async getDriveErrors(): Promise<DriveErrorName[]> {
    const response = await this.request(
      'DRIVE_API',
      'GET',
      '/learn/_load_ERROR_History'
    );
    return JSON.parse(response).ErrorHistory;
  }

  async clearDriveErrors(): Promise<void> {
    await this.request('DRIVE_API', 'GET', '/learn/_load_ERROR_History_CLR');
  }

  async getUpsmartErrors(): Promise<DriveError[]> {
    const response = await this.request(
      'DRIVE_API',
      'GET',
      '/learn/_par_update'
    );
    const parsed = JSON.parse(response);
    const upsmartErrorList = parsed?.learn_EVENTS?.UPSMART_ERROR_LIST || [];
    this.upsmartErrorList = handleLegacyUpsmartList(upsmartErrorList);
    return this.upsmartErrorList;
  }

  onUpsmartErrorListChange(handler: BoxApiUpsmartErrorListChangeHandler) {
    this.upsmartErrorListChangeHandler.push(handler);
  }

  offUpsmartErrorListChange(handler: BoxApiUpsmartErrorListChangeHandler) {
    this.upsmartErrorListChangeHandler =
      this.upsmartErrorListChangeHandler.filter((h) => h !== handler);
  }
}

// FIXME: Remove once Georg has updated the API
function handleLegacyUpsmartList(list: any[]) {
  if (list.length > 0 && typeof list[0] === 'string') {
    let parsed: any[] = [];
    try {
      parsed = list.map((i) => JSON.parse(i));
    } catch (err: any) {
      console.warn('Having legacy upsmart error list', list);
    }
    return parsed;
  }
  return list;
}
