import * as React from 'react';
import styled from 'styled-components';

import { color, space } from '../../tokens';
import { Row } from '../common/Row';

const Input = styled.input.attrs({ type: 'number' })`
  padding: 4px 5px;
  border: 1px solid #bbb;
  border-radius: 3px;
  // REVISIT: Without it FF renders the field too wide and wraps the row.
  //          Like this it might be too limited though for big numbers.
  width: 5rem;
`;

const Slider = styled.input.attrs({ type: 'range' })`
  -webkit-appearance: none;
  -moz-appearance: none;
  outline: 0;
  height: 12px;
  border-radius: 40px;
  background: ${color['info']};
  margin-right: ${space[5]};
  flex: 1;

  ::-webkit-slider-thumb {
    -webkit-appearance: none;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    box-shadow: 0px 0px 4px 2px rgba(0, 0, 0, 0.3);
    background: ${color.primary};
    cursor: ew-resize;
    transition: background 0.3s ease-in-out;
  }

  ::-moz-range-thumb {
    width: 30px;
    height: 30px;
    -moz-appearance: none;
    background: ${color.primary};
    border-radius: 50%;
    box-shadow: 0px 0px 4px 2px rgba(0, 0, 0, 0.3);
    cursor: ew-resize;
    transition: background 0.3s ease-in-out;
  }
`;

const SliderContainer = styled.div`
  display: 'flex';
  padding: ${space[4]} 0;
`;

export type InputSliderProps = {
  min: number;
  max: number;
  step: number;
  value: number;
  onChange: (value: number) => void;
  unit?: string;
};
export function InputSlider({
  min,
  max,
  step,
  value,
  onChange,
  unit,
}: InputSliderProps) {
  return (
    <SliderContainer>
      <Row justify="center">
        <Slider
          min={min}
          max={max}
          step={step}
          value={value}
          onChange={(ev) => onChange(Number(ev.target.value))}
        />

        <Input
          value={value}
          onChange={(ev) => onChange(Number(ev.target.value))}
          step={step}
          min={min}
          max={max}
        />

        {unit && <div>{unit}</div>}
      </Row>
    </SliderContainer>
  );
}
