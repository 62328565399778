import { useMutation } from '@tanstack/react-query';
import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { Page } from '../../components/common/Page';
import { LiftHeader } from '../../components/lift/LiftHeader';
import { useApiQuery } from '../../hooks/useApi';
import { useTranslation } from '../../I18nProvider';
import { isStatus, RestartArgs, restartMeasurement } from '../../utils/api';
import { Activate } from './Activate';
import { NoInstallationControlFound } from './NoInstallationControlFound';
import { PurchaseLicense } from './PurchaseLicense';
import { RestartForm } from './RestartForm';
import { SomethingWentWrong } from './SomethingWentWrong';
import { TestInstructions } from './TestInstructions';

export function Restart() {
  const t = useTranslation();
  const navigate = useNavigate();
  const params = useParams();
  const id = parseInt(params.id!);
  const { data: installationControl } = useApiQuery(
    'getInstallationControl',
    id
  );
  const status = useApiQuery('getProductStatus', id);
  const [config, setConfig] = useState<RestartArgs>();
  const restartMutation = useMutation((config: RestartArgs) =>
    restartMeasurement(id, config)
  );

  const isTrialEnabled = status.isSuccess && !status.data.TRIAL;
  const hasICTRLicense = !!status.data?.INST_CTRL;

  if (!installationControl || !status.isFetched) return null;
  return (
    <Page
      header={<LiftHeader id={id} />}
      pageTitle={t.startNewTestRun}
      backLink=".."
    >
      {!hasICTRLicense && isTrialEnabled ? (
        <PurchaseLicense />
      ) : isStatus(restartMutation.error, 403) ? (
        <NoInstallationControlFound />
      ) : restartMutation.error ? (
        <SomethingWentWrong navigateTo="../" />
      ) : config ? (
        <TestInstructions
          floor={installationControl.starting_floor}
          onCloseDoor={async () => {
            await restartMutation.mutateAsync(config);
          }}
          onContinue={() => navigate('..')}
        />
      ) : status.data?.INST_CTRL ? (
        <RestartForm
          installationControl={installationControl}
          onSubmit={setConfig}
        />
      ) : (
        <Activate liftId={id} onActivate={status.refetch} />
      )}
    </Page>
  );
}
