import React from 'react';
import {
  Control,
  FieldPath,
  FieldValues,
  useController,
  UseControllerProps,
} from 'react-hook-form';

import { FormField } from './FormField';
import { InputSlider, InputSliderProps } from './InputSlider';

type Props<T extends FieldValues> = Omit<
  InputSliderProps,
  'value' | 'onChange'
> &
  UseControllerProps<T> & {
    name: FieldPath<T>;
    label?: string;
    required?: boolean | string;
    control: Control<T>;
  };

export function InputSliderField<T extends FieldValues>({
  name,
  label,
  control,
  required,
  rules,
  ...props
}: Props<T>) {
  const { field } = useController({
    name,
    control,
    rules,
  });
  const { value, onChange } = field;

  return (
    <FormField name={name} label={label} required={required}>
      <InputSlider {...props} value={value as number} onChange={onChange} />
    </FormField>
  );
}
