import React from 'react';
import { FallbackProps } from 'react-error-boundary';
import { Link, Navigate } from 'react-router-dom';

import { useTranslation } from '../I18nProvider';
import { getErrorMessage } from '../utils/api';
import { Button } from './common/Button';
import { Page } from './common/Page';
import { Stack } from './common/Stack';

export function ErrorScreen({ error }: FallbackProps) {
  const t = useTranslation();
  const status = (error as any)?.status;
  const isNotFound = status === 404;
  if (status === 401 || status === 403) {
    return <Navigate to="/" />;
  }

  const reload = () => window.location.reload();

  return (
    <Page
      header={t.error}
      pageTitle={isNotFound ? t.errorNotFound : t.errorInvalidOperation}
      backLink="/"
    >
      <Stack>
        <pre style={{ whiteSpace: 'normal' }}>
          <code>{getErrorMessage(error)}</code>
        </pre>
        <Button as={Link} variant="primary" to="/">
          {t.back}
        </Button>
        <Button variant="secondary" onClick={reload}>
          {t.reload}
        </Button>
      </Stack>
    </Page>
  );
}
