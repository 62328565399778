import React, { ReactNode } from 'react';

import { useApiQuery } from '../../hooks/useApi';
import { AppHeader } from '../common/AppHeader';
import { replaceMapKey } from '../map/placeUtils';
import icon from './building.svg';

type Props = {
  id: number;
  action?: ReactNode;
};

export function BuildingHeader({ id, action }: Props) {
  const { data: building } = useApiQuery('getBuilding', id);
  const props = building?.photo
    ? {
        background: replaceMapKey(building.photo),
      }
    : {
        image: icon,
      };
  return (
    <>
      <AppHeader
        {...props}
        title={building?.name}
        subTitle={[building?.street, building?.streetNumber]
          .filter(Boolean)
          .join(' ')}
        action={action}
      />
      {/* {<BuildingNotificationStatus id={id} />} */}
    </>
  );
}
