import { StringTranslationKey } from '../../i18n';
import { DriveParameterNames, EditableDriveParameterName } from './BoxApi';

export type ParameterKeys = {
  id: EditableDriveParameterName;
  title: StringTranslationKey;
  text?: StringTranslationKey;
  note?: StringTranslationKey;
};

export const parameterKeyMap: Record<
  EditableDriveParameterName,
  ParameterKeys
> = {
  [DriveParameterNames.OpenSpeed]: {
    id: DriveParameterNames.OpenSpeed,
    title: 'openSpeedTitle',
    text: 'openSpeedDescription',
    note: 'openSpeedNote',
  },
  [DriveParameterNames.CloseSpeed]: {
    id: DriveParameterNames.CloseSpeed,
    title: 'closeSpeedTitle',
    text: 'closeSpeedDescription',
    note: 'closeSpeedNote',
  },
  [DriveParameterNames.NudggingSpeed]: {
    id: DriveParameterNames.NudggingSpeed,
    title: 'nudgingSpeedTitle',
    text: 'nudgingSpeedDescription',
    note: 'nudgingSpeedNote',
  },
  [DriveParameterNames.BrakePar]: {
    id: DriveParameterNames.BrakePar,
    title: 'brakeParTitle',
    text: 'brakeParDescription',
    note: 'brakeParNote',
  },
  [DriveParameterNames.PositionOut]: {
    id: DriveParameterNames.PositionOut,
    title: 'positionOutTitle',
    text: 'positionOutDescription',
    note: 'positionOutNote',
  },
  [DriveParameterNames.CloseHoldForce]: {
    id: DriveParameterNames.CloseHoldForce,
    title: 'closeHoldForceTitle',
    text: 'closeHoldForceDescription',
    note: 'closeHoldForceNote',
  },
  [DriveParameterNames.Acceleration]: {
    id: DriveParameterNames.Acceleration,
    title: 'accelerationTitle',
    text: 'accelerationDescription',
    note: 'accelerationNote',
  },
  [DriveParameterNames.OpenHoldForce]: {
    id: DriveParameterNames.OpenHoldForce,
    title: 'openHoldForceTitle',
    text: 'openHoldForceDescription',
    note: 'openHoldForceNote',
  },
  [DriveParameterNames.CloseForcePoti]: {
    id: DriveParameterNames.CloseForcePoti,
    title: 'closeForcePotiTitle',
    text: 'closeForcePotiDescription',
    note: 'closeForcePotiNote',
  },
  [DriveParameterNames.CouplerSpeed]: {
    id: DriveParameterNames.CouplerSpeed,
    title: 'couplerSpeedTitle',
    text: 'couplerSpeedDescription',
  },
  [DriveParameterNames.CouplerWitdh]: {
    id: DriveParameterNames.CouplerWitdh,
    title: 'couplerWitdhTitle',
    text: 'couplerWitdhDescription',
  },
  [DriveParameterNames.ReopenTime]: {
    id: DriveParameterNames.ReopenTime,
    title: 'reopenTimeTitle',
    text: 'reopenTimeDescription',
    note: 'reopenTimeNote',
  },
};
