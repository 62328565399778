import React from 'react';
import { Link, useParams } from 'react-router-dom';
import styled from 'styled-components';

import { BuildingHeader } from '../components/building/BuildingHeader';
import { Button } from '../components/common/Button';
import { Card } from '../components/common/Card';
import { IconButton } from '../components/common/IconButton';
import {
  EditIcon,
  StarIconFilled,
  StarIconOutline,
} from '../components/common/Icons';
import { Loading } from '../components/common/Loading';
import { Page } from '../components/common/Page';
import { Stack } from '../components/common/Stack';
import { StatusType } from '../components/common/StatusIcon';
import { useApiMutation, useApiQuery } from '../hooks/useApi';
import { useTranslation } from '../I18nProvider';
import { space } from '../tokens';
import { InstallationControl } from '../utils/appApi';
import { Lift } from '../utils/types';

const Container = styled.div`
  display: flex;
  @media (min-width: 380px) {
    gap: ${space[7]};
  }
`;

export function Building() {
  const t = useTranslation();
  const params = useParams();
  const id = parseInt(params.buildingId!);
  const query = useApiQuery('getBuilding', id);
  const isFavorite = !!query.data?.isFavorite;

  const addFavorite = useApiMutation('addFavoriteBuilding', {
    onSuccess: () => {
      query.refetch();
    },
  });
  const removeFavorite = useApiMutation('removeFavoriteBuilding', {
    onSuccess: () => {
      query.refetch();
    },
  });

  return (
    <Page
      backLink="/"
      pageTitle={t.lifts}
      header={
        <BuildingHeader
          id={id}
          action={
            <Container>
              <IconButton
                disabled={query.isLoading}
                title={isFavorite ? t.favorite : t.noFavorite}
                onClick={async () => {
                  isFavorite && confirm(t.confirmDeleteFavoriteBuilding)
                    ? removeFavorite(id)
                    : addFavorite(id);
                }}
              >
                {isFavorite ? <StarIconFilled /> : <StarIconOutline />}
              </IconButton>
              <IconButton as={Link} to="edit">
                <EditIcon />
              </IconButton>
            </Container>
          }
        />
      }
    >
      <Stack>
        {query.isLoading && <Loading />}
        {query.data?.lifts.map((lift) => (
          <Card
            key={lift.id}
            title={lift.name}
            navigateTo={`./lift/${lift.id}`}
            status={getStatus(lift)}
          >
            {lift.box_id}
          </Card>
        ))}
        <Button as={Link} to="lift" variant="primary">
          {t.addLift}
        </Button>
      </Stack>
    </Page>
  );
}

function getStatus(
  lift: Lift & { installationControl: InstallationControl }
): StatusType {
  //Disable color coding for now. Users might not have a license but the setup run returned errors.
  /* if (lift.installationControl?.failures) return 'error';
  if (lift.installationControl?.status === 'completed') return 'success'; */
  return 'info';
}
