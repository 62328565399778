import { useQueryClient } from '@tanstack/react-query';
import React from 'react';

import { Button } from '../../components/common/Button';
import { Row } from '../../components/common/Row';
import { Stack } from '../../components/common/Stack';
import { DropdownField } from '../../components/form/DropdownField';
import { Form } from '../../components/form/Form';
import { useApiQuery } from '../../hooks/useApi';
import { useFormSubmission } from '../../hooks/useFormSubmission';
import { useTranslation } from '../../I18nProvider';
import {
  initInstallationControl,
  InstallationControlArgs,
} from '../../utils/api';

const doorModels = [
  { value: 'HYDRA PLUS (CD)_SM.2.000029.EN', displayName: 'Hydra' },
  { value: 'FINELINE_SM.2.004910.EN', displayName: 'Fineline' },
  { value: 'PEGASUS PLUS (CD)_SM.2.005796.E', displayName: 'Pegasus' },
  { value: 'AUGUSTA_EVO_SM.2.003372.EN', displayName: 'Augusta' },
];

type Props = {
  liftId: number;
  onSubmit: () => void;
};
export function NewForm({ liftId, onSubmit }: Props) {
  const t = useTranslation();
  const queryClient = useQueryClient();
  const lift = useApiQuery('getLift', liftId);
  const hasDoorModel = doorModels.find((doorModel) => {
    return doorModel.value === lift.data?.doorModel;
  });

  const { form, register } = useFormSubmission<InstallationControlArgs>({
    async onSubmit(values) {
      await initInstallationControl(liftId, values);
      await queryClient.removeQueries(['getInstallationControl', liftId]);
      onSubmit();
    },
  });

  return (
    <Form {...form}>
      <Stack>
        <p>{t.chooseStartingFloorInstructions}</p>
        <DropdownField
          register={register}
          name="starting_floor"
          label={`${t.starting_floor} *`}
          required={true}
        >
          <option></option>
          <option value="bottom">{t.bottom}</option>
          <option value="top">{t.top}</option>
        </DropdownField>
        {!hasDoorModel && (
          <DropdownField
            register={register}
            name="door_model"
            label={`${t.doorModel} *`}
            required={true}
          >
            <option></option>
            {doorModels.map((doorModel) => {
              return (
                <option value={doorModel.value} key={doorModel.value}>
                  {doorModel.displayName}
                </option>
              );
            })}
          </DropdownField>
        )}
        <Row>
          <Button variant="primary" disabled={form.formState.isSubmitting}>
            {t.continue}
          </Button>
        </Row>
      </Stack>
    </Form>
  );
}
