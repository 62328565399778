import React, { Fragment, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';

import { Button } from '../../components/common/Button';
import { Hint } from '../../components/common/Hint';
import { ImageCarousel } from '../../components/common/ImageCarousel';
import { InfoContainer } from '../../components/common/InfoContainer';
import { Loading } from '../../components/common/Loading';
import { Page } from '../../components/common/Page';
import { Row } from '../../components/common/Row';
import { Spacer } from '../../components/common/Spacer';
import { Stack } from '../../components/common/Stack';
import { Dropdown } from '../../components/form/Dropdown';
import { Form } from '../../components/form/Form';
import { LiftHeader } from '../../components/lift/LiftHeader';
import { useApiQuery } from '../../hooks/useApi';
import { useFormSubmission } from '../../hooks/useFormSubmission';
import { useTranslation } from '../../I18nProvider';
import { color, space } from '../../tokens';
import { Part, updateComponentParts } from '../../utils/appApi';

const Image = styled(({ isSelected, ...rest }) => <img {...rest} />)<{
  isSelected: boolean;
}>`
  max-width: 100%;
  max-height: 30vh;
  align-self: center;
  border: ${({ isSelected }) =>
    isSelected ? `${space[1]} solid ${color.primary}` : 'none'};
`;

export function SelectParts() {
  const params = useParams();
  const liftId = parseInt(params.id!);
  const componentPositionName = params.componentPositionName!;
  const t = useTranslation();
  const navigate = useNavigate();
  const query = useApiQuery('getComponentParts', liftId, componentPositionName);
  const [selectedPart, setSelectedPart] = useState<string>(t.choosePartNumber);

  const [isSubmitting, setIsSubmitting] = useState(false);
  const { form, register } = useFormSubmission({
    async onSubmit() {
      setIsSubmitting(true);
      if (selectedPart === t.choosePartNumber) return;
      const partName =
        query.data?.parts.find((part: Part) => part.partNumber === selectedPart)
          ?.partName || '';

      await updateComponentParts(liftId, componentPositionName, {
        partNumber: selectedPart,
        partName,
      });
      setIsSubmitting(false);
      navigate('../');
    },
  });

  return (
    <Page
      header={<LiftHeader id={liftId} />}
      pageTitle={
        query.isSuccess
          ? `${t.select} ${query.data?.componentPositionName}`
          : ''
      }
      backLink="../"
    >
      {query.isLoading && <Loading />}
      {query.isSuccess && (
        <>
          <Hint>{t.selectPartsHint}</Hint>
          <Spacer />
          <Stack gap={space[3]}>
            {query.data?.parts.map((part: Part, i: number) => {
              const imageSources = part.imageSources || [];
              const imageCount = imageSources.length;

              return (
                <Fragment key={part.partNumber}>
                  <InfoContainer
                    title={`${i + 1}.`}
                    text={`${part.partName} (${part.partNumber})`}
                  />
                  {imageCount > 1 ? (
                    <ImageCarousel
                      images={imageSources}
                      isSelected={part.partNumber === selectedPart}
                      partNumber={part.partNumber}
                      setSelectedPart={setSelectedPart}
                    />
                  ) : (
                    <Image
                      src={imageSources[0]}
                      isSelected={part.partNumber === selectedPart}
                      onClick={() => setSelectedPart(part.partNumber)}
                    />
                  )}
                </Fragment>
              );
            })}
          </Stack>
          <Form {...form}>
            <Stack>
              <Spacer />
              <Dropdown
                register={register}
                name="parts"
                label={t.choosePartNumber}
                value={selectedPart}
                onChange={(e) => {
                  setSelectedPart(e.target.value);
                }}
              >
                {selectedPart && (
                  <option value={selectedPart}>{selectedPart}</option>
                )}
                {query.data?.parts.map((part: Part) => {
                  if (part.partNumber === selectedPart) return null;
                  return (
                    <option value={part.partNumber} key={part.partNumber}>
                      {part.partNumber}
                    </option>
                  );
                })}
              </Dropdown>
              <Row gap=".5rem">
                <Button as={Link} to="../" variant="secondary">
                  {t.cancel}
                </Button>
                <Button variant="primary" disabled={isSubmitting}>
                  {t.continue}
                </Button>
              </Row>
            </Stack>
          </Form>
        </>
      )}
    </Page>
  );
}
