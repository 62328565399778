import { useMutation } from '@tanstack/react-query';
import React, { useEffect, useState } from 'react';
import { Link, Navigate, useParams } from 'react-router-dom';

import { IconButton } from '../../components/common/IconButton';
import { ClockIcon } from '../../components/common/Icons';
import { Loading } from '../../components/common/Loading';
import { Page } from '../../components/common/Page';
import { Stack } from '../../components/common/Stack';
import { LiftHeader } from '../../components/lift/LiftHeader';
import { useApiQuery } from '../../hooks/useApi';
import { useTranslation } from '../../I18nProvider';
import { isStatus } from '../../utils/api';
import { startInstallationControl } from '../../utils/appApi';
import { Activate } from './Activate';
import { PollResults } from './PollResults';
import { PurchaseLicense } from './PurchaseLicense';
import { ShowResults } from './ShowResults';
import { SomethingWentWrong } from './SomethingWentWrong';
import { TestInstructions } from './TestInstructions';

export function Installation() {
  const t = useTranslation();
  const params = useParams();
  const id = parseInt(params.id!);
  const query = useApiQuery(
    {
      method: 'getInstallationControl',
      useErrorBoundary: false,
    },
    id
  );

  const [status, setStatus] = useState('');
  const startMutation = useMutation(() => startInstallationControl(id));

  useEffect(() => {
    if (query.isSuccess) {
      setStatus(query.data.status);
    }
  }, [query]);

  const isTrialEnabled = isStatus(query.error, 402);
  const isLicenseInvalid = isStatus(query.error, 403);
  const isInstallationControlNotFound = isStatus(query.error, 404);
  if (isStatus(query.error, 500)) {
    throw query.error;
  }

  return (
    <Page
      header={<LiftHeader id={id} />}
      pageTitle={t.installationControlHeadline}
      subHeadline={t.installationControlSubheadline}
      backLink={`/building/${params.buildingId}/lift/${id}`}
      nextLink={
        <IconButton as={Link} to="./history">
          <ClockIcon />
        </IconButton>
      }
    >
      {startMutation.error && <SomethingWentWrong navigateTo="./new" />}
      {query.isLoading ? (
        <Loading />
      ) : !startMutation.error && query.isSuccess ? (
        <Stack>
          {status === 'initialized' && (
            <TestInstructions
              floor={query.data.starting_floor}
              onCloseDoor={async () => {
                await startMutation.mutateAsync();
              }}
              onContinue={query.invalidate}
            />
          )}
          {status === 'started' && (
            <PollResults id={id} onFinish={query.invalidate} />
          )}
          {(status === 'completed' ||
            status === 'aborted' ||
            status === 'obsolete') && (
            <ShowResults installationControl={query.data} query={query} />
          )}
        </Stack>
      ) : isTrialEnabled ? (
        <Activate liftId={id} onActivate={query.refetch} />
      ) : isLicenseInvalid ? (
        <PurchaseLicense />
      ) : isInstallationControlNotFound ? (
        <Navigate to="new" />
      ) : null}
    </Page>
  );
}
