import React, { useState } from 'react';
import { useParams } from 'react-router-dom';

import { Page } from '../components/common/Page';
import { Stack } from '../components/common/Stack';
import { DataGrid } from '../components/DataGrid';
import { Dropdown } from '../components/form/Dropdown';
import { useApiQuery } from '../hooks/useApi';
import { LicenseOrder } from '../utils/appApi';

export function AdminCompanyLicenses() {
  const params = useParams();
  const companyId = parseInt(params.id!);

  const [order, setOrder] = useState<LicenseOrder>('box');
  const licenses = useApiQuery('getLicenses', { companyId, order });

  return (
    <Page header="Admin" backLink="../" pageTitle="Licenses">
      <Stack>
        <Dropdown
          label="Sort by"
          value={order}
          onChange={(e) => setOrder(e.target.value as any)}
        >
          <option value="box">Box ID</option>
          <option value="date">Date</option>
          <option value="expiry">Expiration</option>
        </Dropdown>
        {licenses.data?.map(
          ({
            id,
            box_id,
            product,
            validFrom,
            validUntil,
            maxUsage,
            usage,
            maxElectronics,
            electronics,
          }) => (
            <DataGrid
              key={id}
              data={{
                box_id,
                product,
                validFrom: new Date(validFrom).toLocaleDateString('de'),
                validUntil: new Date(validUntil).toLocaleDateString('de'),
                maxUsage,
                usage,
                maxElectronics,
                usedElectronics: electronics.length,
              }}
            ></DataGrid>
          )
        )}
      </Stack>
    </Page>
  );
}
