import React from 'react';
import styled from 'styled-components';

import { HorizontalScroll } from '../../components/common/HorizontalScroll';
import { ToggleButton } from '../../components/form/ToggleButton';
import { TranslationKey } from '../../i18n';
import { useTranslation } from '../../I18nProvider';
import { ErrorType } from '../../utils/appApi';

const StyledToggleButton = styled(ToggleButton)`
  & > * {
    background-color: red;
    padding: 0.2em 0.5em;
  }
`;

const ERROR_TYPES = ['fatalerror', 'error', 'warning'] as ErrorType[];

const getTranslationKey = (type: ErrorType): TranslationKey => {
  switch (type) {
    case 'fatalerror':
      return 'fatalErrors';
    case 'error':
      return 'errors';
    case 'warning':
      return 'warnings';
  }
  throw new Error('Unmapped error type');
};

type ErrorFilterProps = {
  enabledErrorTypes?: ErrorType[];
  onChange?: (updatedFilter: ErrorType[]) => void;
  disabled?: boolean;
};
export function ErrorFilter({
  enabledErrorTypes = ERROR_TYPES,
  onChange = () => {},
  disabled,
}: ErrorFilterProps) {
  const t = useTranslation();

  const isTypeEnabled = (t: ErrorType) =>
    !!enabledErrorTypes.find((l) => l === t);

  const toggleType = (t: ErrorType) => {
    const index = enabledErrorTypes.findIndex((c) => c === t);

    let newErrorTypes;
    if (index >= 0) {
      newErrorTypes = [
        ...enabledErrorTypes.slice(0, index),
        ...enabledErrorTypes.slice(index + 1),
      ];
    } else {
      newErrorTypes = [...enabledErrorTypes, t];
    }

    onChange(newErrorTypes);
  };

  return (
    <HorizontalScroll align="center">
      {ERROR_TYPES.map((type) => (
        <StyledToggleButton
          key={type}
          value={isTypeEnabled(type)}
          onChange={() => toggleType(type)}
          disabled={disabled}
        >
          {t[getTranslationKey(type)]}
        </StyledToggleButton>
      ))}
    </HorizontalScroll>
  );
}
