import styled from 'styled-components';

import { color } from '../../tokens';
import arrow from './SelectArrow.svg';

export const Select = styled.select`
  padding: 10px 36px 10px 12px;
  color: ${color.inputText};
  background: url(${arrow}) 100% / 24px no-repeat ${color.input};
  border: none;
  border-radius: 8px;
  cursor: pointer;
  appearance: none;
  ::-ms-expand {
    display: none; /* remove default arrow in IE 10 + 11 */
  }
`;
