import React from 'react';

import { Button } from '../../components/common/Button';
import { Center } from '../../components/common/Center';
import { Stack } from '../../components/common/Stack';
import deactivated from '../../components/lift/deactivated.svg';
import { useApiMutation } from '../../hooks/useApi';

type Props = {
  liftId: number;
  onActivate: () => any;
};
export function Activate({ liftId, onActivate }: Props) {
  const startTrial = useApiMutation('startTrial', { onSuccess: onActivate });
  return (
    <Stack>
      <div>Installation Control is currently disabled for this elevator.</div>
      <Center>
        <img src={deactivated} />
      </Center>
      <Button
        variant="primary"
        disabled={startTrial.isLoading}
        onClick={() => startTrial(liftId, 'INST_CTRL')}
      >
        Start Trial
      </Button>
    </Stack>
  );
}
