import React, { forwardRef } from 'react';
import styled, { css } from 'styled-components';

import { borderRadius, color, fontSize } from '../../tokens';
import { TextXs } from '../common/typography';

const FloatingLabel = styled.div`
  position: absolute;
  top: 0.5rem;
  left: 0.5rem;
  font-size: ${fontSize.base};
  color: ${color.primary};
  transition-duration: 300ms;
  transform: translateY(50%);
`;

const Label = styled.label<{ invalid?: boolean }>`
  position: relative;
  background: ${color.input};
  border-radius: ${borderRadius.default};
  padding: 0;
  & > input {
    border-radius: ${borderRadius.default};
    background: transparent !important;
    color: ${color.inputText};
    width: 100%;
    font-size: ${fontSize.base};
    padding: 1.5rem 0.5rem 0.5rem 0.5rem;
    border: none;
    outline: none;
    ::placeholder {
      opacity: 0;
    }
  }

  ${(p) =>
    p.invalid &&
    css`
      background: ${color.inputErrorBackground};
      border-color: ${color.errorText};
      box-shadow: inset 0 0 0 2px ${color.errorText};
      & > input + ${FloatingLabel} {
        color: ${color.errorText};
      }
    `}

  & > input:focus + ${FloatingLabel},
  & > input:not(:placeholder-shown) + ${FloatingLabel},
  /* fix for last pass autofill */
  & > input[style] + ${FloatingLabel} {
    transform: translateY(0);
    font-size: ${fontSize.xs};
  }
`;

const Hint = styled(TextXs)`
  margin-top: -0.2rem;
  padding: 0 0.5rem 0.5rem;
  color: ${color.cardText};
`;

export type InputProps = JSX.IntrinsicElements['input'];

export const Input = forwardRef<HTMLInputElement, InputProps>(
  ({ children, className, style, ...props }, ref) => (
    <Label
      className={className}
      style={style}
      invalid={!!props['aria-invalid']}
    >
      <input {...props} ref={ref} />
      <FloatingLabel>{props.placeholder}</FloatingLabel>
      {children && <Hint>{children}</Hint>}
    </Label>
  )
);
