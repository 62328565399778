import { useQuery } from '@tanstack/react-query';
import React, { useState } from 'react';

import { Button } from '../../components/common/Button';
import { Loading } from '../../components/common/Loading';
import { Stack } from '../../components/common/Stack';
import { useApiMutation } from '../../hooks/useApi';
import { useTranslation } from '../../I18nProvider';
import { getInstallationControl } from '../../utils/appApi';
import { SetupPanels } from './SetupPanels';

type Props = {
  id: number;
  onFinish: () => any;
};
export function PollSetupProgress({ id, onFinish }: Props) {
  const [completed, setCompleted] = useState(false);
  const t = useTranslation();
  const query = useQuery(
    ['installation', id],
    async () => {
      const ic = await getInstallationControl(id);
      if (ic.status === 'aborted') {
        onFinish();
      } else if (ic.status === 'completed') {
        setCompleted(true);
      }
      return ic;
    },
    {
      refetchInterval: completed ? false : 2000,
    }
  );
  const abort = useApiMutation('abortInstallationControl', {
    onSuccess: query.refetch,
  });
  return query.data ? (
    <Stack>
      {!completed && <Loading />}
      <SetupPanels installationControl={query.data} />
      {completed ? (
        <Button onClick={onFinish}>{t.continue}</Button>
      ) : (
        <Button onClick={() => abort(id)}>{t.cancel}</Button>
      )}
    </Stack>
  ) : null;
}
