import React, { ReactNode, useEffect } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';

import { Loading } from '../../components/common/Loading';
import { Page } from '../../components/common/Page';
import {
  boxApi,
  useBoxApiMutation,
  useBoxApiQuery,
} from '../../hooks/useBoxApi';
import { useTranslation } from '../../I18nProvider';
import { ConnectApi } from './ConnectApi';
import { ErrorPage } from './ErrorPage';
import { ProgrammingErrorBoundary } from './ProgrammingErrorBoundary';
import { ValidLicenseProvider } from './ValidLicenseProvider';

function PageContent({ children }: { children: ReactNode }) {
  const t = useTranslation();
  return (
    <Page pageTitle={t.programmingTool} backLink="/">
      {children}
    </Page>
  );
}

export function Programming() {
  const isApiConnected = useBoxApiQuery('isApiConnected');
  const connectApi = useBoxApiMutation('connectApi');
  const navigate = useNavigate();

  useEffect(() => {
    const handleUpdate = isApiConnected.update;
    boxApi.onConnectionChange(handleUpdate);
    return () => {
      boxApi.offConnectionChange(handleUpdate);
    };
  }, [isApiConnected]);

  let content: ReactNode;

  if (connectApi.isLoading) {
    content = (
      <PageContent>
        <Loading />
      </PageContent>
    );
  } else if (!isApiConnected.data) {
    content = (
      <PageContent>
        <ConnectApi connectApi={connectApi} />
      </PageContent>
    );
  } else {
    content = <Outlet />;
  }

  return (
    <>
      {connectApi.isError && !isApiConnected.data ? (
        <ErrorPage error={connectApi.error} goBack={() => navigate('/')} />
      ) : (
        <ProgrammingErrorBoundary>
          <ValidLicenseProvider>{content}</ValidLicenseProvider>
        </ProgrammingErrorBoundary>
      )}
    </>
  );
}
