import React, { ReactNode } from 'react';
import styled, { css } from 'styled-components';

import { color, space } from '../../tokens';

const StyledButton = styled.button<{ active: boolean; disabled?: boolean }>`
  display: inline-block;
  font-size: inherit;
  font-family: inherit;
  font-weight: 700;
  border: none;
  border-radius: 10rem;
  border: 1px solid ${color.line};
  padding: ${space[2]} ${space[3]} ${space[1.5]} ${space[3]};
  color: ${color.cardText};
  background: ${color.background};
  margin: 0.4rem;
  cursor: pointer;
  ${(p) =>
    p.active &&
    css`
      box-shadow: 0 0 0 2px ${color.primary};
      color: ${color.primary};
      border-color: ${color.primary};
    `}
  ${(p) =>
    p.disabled &&
    css`
      box-shadow: 0 0 0 1px ${color.cardBackground};
      color: ${color.cardBackground};
      border-color: ${color.cardBackground};
    `}
`;

type Props = {
  value: boolean;
  disabled?: boolean;
  onChange: (value: boolean) => void;
  children: ReactNode;
};
export function ToggleButton({ value, disabled, onChange, children }: Props) {
  return (
    <StyledButton
      type="button"
      active={value}
      disabled={disabled}
      onClick={() => onChange(!value)}
    >
      {children}
    </StyledButton>
  );
}
