import React, { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import { borderRadius } from '../../tokens';
import { Building } from '../../utils/api';
import { GoogleMap } from './GoogleMap';
import { MapsApi } from './MapsApi';
import { Marker } from './Marker';

type DebouncedFunction<T extends (...args: any[]) => void> = (
  ...args: Parameters<T>
) => void;

function debounce<T extends (...args: any[]) => void>(
  func: T,
  wait: number
): DebouncedFunction<T> {
  let timeout: any;

  return function (this: any, ...args: Parameters<T>): void {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const context = this;
    if (timeout) {
      clearTimeout(timeout);
    }
    timeout = setTimeout(() => func.apply(context, args), wait);
  } as DebouncedFunction<T>;
}

interface Props {
  buildings: Building[];
  allIsActive: boolean;
  onVisibleBuildingsChange: (visibleBuildings: Building[]) => void;
}

export function BuildingsMap({
  buildings,
  allIsActive,
  onVisibleBuildingsChange,
}: Props) {
  const navigate = useNavigate();
  const selectedBuildings = !allIsActive
    ? buildings.filter((building: any) => building.isFavorite)
    : buildings;

  const debouncedBoundsChange = debounce((bounds: google.maps.LatLngBounds) => {
    const visibleBuildings = selectedBuildings.filter((building) => {
      const position = getPosition(building);
      return position && bounds.contains(position);
    });
    onVisibleBuildingsChange(visibleBuildings);
  }, 300);

  const handleBoundsChange = useCallback(debouncedBoundsChange, [
    selectedBuildings,
    onVisibleBuildingsChange,
  ]);

  if (!selectedBuildings.some(getPosition)) {
    return null;
  }

  return (
    <MapsApi>
      <GoogleMap
        maxZoom={15}
        onBoundsChange={handleBoundsChange}
        style={{
          width: '100%',
          height: '300px',
          borderRadius: borderRadius.default,
        }}
      >
        {selectedBuildings.map((building) => {
          const position = getPosition(building);
          return (
            position && (
              <Marker
                key={building.id}
                position={position}
                onClick={() => navigate(`/building/${building.id}`)}
              />
            )
          );
        })}
      </GoogleMap>
    </MapsApi>
  );
}

function getPosition(building: Building) {
  const { latitude, longitude } = building;
  return latitude && longitude ? { lat: latitude, lng: longitude } : null;
}
