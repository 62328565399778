import styled, { css } from 'styled-components';

import { borderRadius, color, fontWeight } from '../../tokens';

const disabledStyle = css`
  opacity: 0.5;
  cursor: auto;
`;

const variants = {
  primary: css`
    background: ${color.primary};
    border-color: ${color.primary};
    color: ${color.textOnPrimary};
  `,
  secondary: css`
    background: transparent;
    border-color: ${color.line};
    color: ${color.primary};
  `,
};

const sizes = {
  large: css`
    line-height: 3em;
  `,
  small: css`
    line-height: 2em;
  `,
  xs: css`
    line-height: 1.25em;
  `,
};

const minWidths = {
  large: css`
    min-width: 6.25rem;
  `,
  small: css`
    min-width: 0.25rem;
  `,
};

type Props = {
  disabled?: boolean;
  variant?: keyof typeof variants;
  size?: keyof typeof sizes;
  minWidth?: keyof typeof minWidths;
};

export const Button = styled.button<Props>`
  display: inline-block;
  flex-shrink: 0;
  font-family: inherit;
  font-size: inherit;
  font-weight: ${fontWeight.bold};
  text-decoration: none;
  text-align: center;
  border: 1px solid transparent;
  border-radius: ${borderRadius.default};
  min-width: 6.25rem; //TODO minWidth.button?
  cursor: pointer;
  padding: 0.2em 1.5em;
  ${({ minWidth }) => minWidths[minWidth ?? 'large']}
  ${({ size }) => sizes[size ?? 'large']}
  ${({ variant }) => variants[variant ?? 'secondary']}
  ${({ disabled }) => disabled && disabledStyle}
`;
