import React, { useState } from 'react';
import { Link, useParams } from 'react-router-dom';

import { Button } from '../../components/common/Button';
import { ErrorList } from '../../components/common/ErrorList';
import { IconButton } from '../../components/common/IconButton';
import { ChartIcon } from '../../components/common/Icons';
import { Page } from '../../components/common/Page';
import { Stack } from '../../components/common/Stack';
import { LiftHeader } from '../../components/lift/LiftHeader';
import { useApiMutation, useApiQuery } from '../../hooks/useApi';
import { useTranslation } from '../../I18nProvider';
import { isStatus } from '../../utils/api';
import { ErrorType } from '../../utils/appApi';
import { ActivateTrial } from './ActivateTrial';
import { ErrorFilter } from './ErrorFilter';
import { PurchaseLicense } from './PurchaseLicense';

export function Errors() {
  const t = useTranslation();
  const params = useParams();
  const id = parseInt(params.id!);
  const deleteLiftErrors = useApiMutation('deleteLiftErrors');

  const initialErrorTypes = ['fatalerror', 'error', 'warning'] as ErrorType[];

  const [errorTypes, setErrorTypes] = useState(initialErrorTypes);

  const query = useApiQuery('getLiftErrors', id, { types: errorTypes });
  const isTrialEnabled = isStatus(query.error, 402);
  const isLicenseInvalid = isStatus(query.error, 403);

  return (
    <Page
      pageTitle={t.errorMessages}
      header={<LiftHeader id={id} />}
      backLink={'../../'}
      nextLink={
        <IconButton as={Link} to="../monitoring">
          <ChartIcon />
        </IconButton>
      }
    >
      {isLicenseInvalid ? (
        <PurchaseLicense />
      ) : isTrialEnabled ? (
        <ActivateTrial liftId={id} onActivate={query.refetch} />
      ) : (
        <Stack gap="0">
          <ErrorFilter
            enabledErrorTypes={errorTypes}
            onChange={(errorTypes) => {
              setErrorTypes(errorTypes.length ? errorTypes : initialErrorTypes);
            }}
            disabled={!query.data?.length && errorTypes.length === 3}
          />
          {query.isSuccess && (
            <Stack gap="2.5em">
              {query.data?.length !== 0 && (
                <Button
                  size="xs"
                  variant="primary"
                  disabled={deleteLiftErrors.isLoading}
                  onClick={async () => {
                    if (confirm(t.confirmDeleteErrorHistory)) {
                      const types = errorTypes.length
                        ? errorTypes
                        : initialErrorTypes;
                      await deleteLiftErrors(id, { types });
                    }
                  }}
                >
                  {t.deleteErrorHistory}
                </Button>
              )}
              <ErrorList
                errors={query.data ?? []}
                showFilterHint={!query.data?.length && errorTypes.length !== 3}
              />
            </Stack>
          )}
        </Stack>
      )}
    </Page>
  );
}
