import React from 'react';
import styled from 'styled-components';

import {
  ClockIcon,
  CounterIcon,
  WarningIcon,
} from '../../components/common/Icons';
import { StatusType } from '../../components/common/StatusIcon';
import { useDateFormat } from '../../hooks/useDateFormat';
import { shadow } from '../../styles';
import { borderRadius, color, fontSize, fontWeight, space } from '../../tokens';
import { InstallationRun } from '../../utils/appApi';

const Card = styled.button<{ status: StatusType }>`
  border-radius: ${borderRadius.default};
  display: flex;
  flex-direction: column;
  border: 1px solid ${(p) => color[p.status]};
  padding: ${space[3]};
  background: ${(p) => color[p.status]};
  color: ${color.textOnPrimary};
  :not([disabled]) {
    color: ${color.label};
    background: ${color.background};
    ${shadow}
  }
`;

const Item = styled.div`
  display: flex;
  white-space: nowrap;
  align-items: center;
  font-size: ${fontSize.xs};
  font-weight: ${fontWeight.bold};
  svg {
    margin-right: 0.5ch;
    opacity: 0.5;
  }
`;

interface Props {
  data: Partial<InstallationRun>;
  active: boolean;
  onClick: () => void;
}

export const RunCard = React.forwardRef<HTMLButtonElement, Props>(
  (props, ref) => {
    const { data, active, onClick } = props;
    const { formatTime } = useDateFormat();
    const status = data.failures || 0 > 0 ? 'error' : 'success';

    return (
      <Card ref={ref} disabled={active} status={status} onClick={onClick}>
        <Item>
          <CounterIcon />
          {data.run}
        </Item>
        <Item>
          <ClockIcon />
          {formatTime(data.date)}
        </Item>
        <Item>
          <WarningIcon />
          {data.failures}
        </Item>
      </Card>
    );
  }
);
