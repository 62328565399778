import { DoorCycle } from './api';
import { ErrorType } from './appApi';
import { BoxError } from './types';

export type DoorCycleFrame = {
  x: number;
  doorforce: number | null;
  doorposition: number | null;
  doorspeed: number | null;

  [prop: string]: number | null;
};

export type DoorData = {
  startTime: number;
  endTime: number;
  maxForce: number;
  maxPos: number;
  maxSpeed: number;
  frames: DoorCycleFrame[];
  floor: number | null;
  cycle: number;
  measurements: {
    networkQuality: number;

    couplerFriction: string;
    doorFriction: string;
    vibration: string;
    powerReductionFactor: string;

    ambientTemperature: string;
    encoderTemperature: string;
    motorTemperature: string;
  };
  errors: Array<{
    message: string;
    type: ErrorType;
    date: string;
  }>;
};

export function prepareData(cycle: DoorCycle): DoorData {
  let graphData;
  if (cycle.time) {
    const times = cycle.time.map((s) => new Date(s).getTime());
    const startTime = Math.min(...times);
    const endTime = Math.max(...times);
    const absMax = (values: Array<number | null>) =>
      Math.max(...values.filter(Boolean).map((v) => Math.abs(v!)));
    const maxForce = absMax(cycle.doorforce);
    const maxPos = absMax(cycle.doorposition);
    const maxSpeed = absMax(cycle.doorspeed);

    const normalize = (value: number | null | undefined, max: number) =>
      typeof value === 'number' ? value / max : null;

    const frames = times.map((time, i) => ({
      x: (time - startTime) / 1000,
      doorforce: normalize(cycle.doorforce[i], maxForce),
      doorposition: normalize(cycle.doorposition[i], maxPos),
      doorspeed: normalize(cycle.doorspeed[i], maxSpeed),
    }));
    graphData = {
      maxForce,
      maxPos,
      maxSpeed,
      startTime,
      endTime,
      frames,
    };
  } else {
    graphData = {
      maxForce: 0,
      maxPos: 0,
      maxSpeed: 0,
      startTime: 0,
      endTime: 0,
      frames: [],
    };
  }

  return {
    ...graphData,
    floor: cycle.floornumber,
    cycle: cycle.doorcyclecounter,
    measurements: {
      networkQuality: cycle.networkquality,
      couplerFriction: cycle.cmcouplerfriction?.toFixed(2) ?? '-',
      doorFriction: cycle.cmdoorfriction?.toFixed(2) ?? '-',
      vibration: cycle.cmvibration?.toFixed(2) ?? '-',
      powerReductionFactor: cycle.powerreductionfactor?.toFixed(2) ?? '-',
      ambientTemperature: cycle.ambienttemperature?.toFixed(2) ?? '-',
      encoderTemperature: cycle.encodertemperature?.toFixed(2) ?? '-',
      motorTemperature: cycle.motortemperature?.toFixed(2) ?? '-',
    },
    errors: cycle.errors,
  };
}

export function appendNewData(
  cycles: DoorCycle[],
  prev?: DoorData[]
): DoorData[] {
  const data = cycles.map(prepareData);
  if (!prev) return data;
  const exists = (d: DoorData) => prev.some((p) => p.startTime === d.startTime);
  const newData = data.filter((d) => !exists(d));
  if (!newData.length) return prev;
  return [...prev, ...newData];
}

export function getLastCycleDate(data?: DoorData[]) {
  const max = data?.reduce((prev: number | undefined, cur: DoorData) => {
    const time = new Date(cur.endTime).getTime();
    if (!prev || time > prev) return time;
  }, undefined);
  return max ? new Date(max + 1).toISOString() : undefined;
}

export function appendNewErrors(
  errors?: BoxError[],
  prev?: BoxError[]
): BoxError[] {
  if (!errors) return prev ?? [];
  if (!prev) return errors;

  const exists = (err: BoxError) => {
    return prev.some((p) => p.date === err.date);
  };

  const newItems = errors.filter((err) => !exists(err));
  if (!newItems.length) return prev;

  return [...newItems, ...prev];
}

export function getLastErrorDate(errors?: BoxError[]) {
  if (errors?.length) {
    return new Date(new Date(errors[0].date).getTime() + 1).toISOString();
  }
}
