import { To } from 'history';
import React, { ReactNode, useState } from 'react';
import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';

import { borderRadius, color, space } from '../../tokens';
import buildingPlaceholderImage from './../../../public/building-placeholder.png';
import { Stack } from './Stack';
import { StatusType } from './StatusIcon';
import { CardSubTitle, CardTitle, Overline } from './typography';

const statusStyle = css<{ status?: StatusType }>`
  border-top: 10px solid
    ${(p) => (p.status !== undefined ? color[p.status] : color.cardBackground)};
`;

export const StyledCard = styled(({ navigateTo, ...props }) =>
  navigateTo ? <Link to={navigateTo} {...props} /> : <div {...props} />
)<{
  disabled?: boolean;
  status?: StatusType;
  selectedColor?: string;
  navigateTo?: To;
}>`
  display: flex;
  text-decoration: none;
  border-radius: ${borderRadius.default};

  background: ${color.cardBackground};
  color: ${color.cardText};
  opacity: ${(p) => (p.disabled ? 0.6 : 1)};
  ${({ status }) => status && statusStyle}
`;

export const Content = styled.div`
  flex: 1;
  padding: ${space[3]} ${space[4]};
  border-radius: 0 ${borderRadius.default} ${borderRadius.default} 0;
  min-height: 60px;
`;

export const Title = styled(CardTitle)`
  color: ${color.heavyCardText};
`;

const Icon = styled.div<{
  selectedColor?: string;
}>`
  display: flex;
  align-items: center;
  background-color: ${(props) =>
    props.selectedColor === 'warning'
      ? color.warning
      : props.selectedColor === 'error'
      ? color.error
      : color.success};
  color: ${color.textOnPrimary};
  padding: ${space['2']};
  border-radius: 0 ${borderRadius.default} ${borderRadius.default} 0;
`;

interface CommonProps extends React.HTMLAttributes<HTMLAnchorElement> {
  title: string;
  navigateTo?: To;
  disabled?: boolean;
  status?: StatusType;
  children?: ReactNode;
  label?: string;
  isHighlighted?: boolean;
}

export interface IconProps extends CommonProps {
  icon?: ReactNode;
  subTitle?: string;
}

export function Card({
  icon,
  title,
  subTitle,
  navigateTo,
  disabled,
  status,
  children,
  ...rest
}: IconProps) {
  return (
    <StyledCard
      navigateTo={navigateTo}
      {...rest}
      disabled={disabled}
      status={status}
    >
      <Content>
        <Stack gap={space[2]}>
          <Title>
            {title}
            {subTitle && <CardSubTitle>{subTitle}</CardSubTitle>}
          </Title>
          {children && <Overline>{children}</Overline>}
        </Stack>
      </Content>
      {icon && <Icon>{icon}</Icon>}
    </StyledCard>
  );
}

const Image = styled.img`
  width: 70px;
  object-fit: cover;
  border-radius: 0 ${borderRadius.default} ${borderRadius.default} 0;
`;

const HighlightedLabel = styled.div<{ isHighlighted?: boolean }>`
  font-size: 12px;
  color: white;
  background-color: ${(props) =>
    props.isHighlighted ? `${color.label}` : `${color.primary}`};
  padding: 4px 8px;
  border-radius: 12px;
  font-weight: bold;
  display: inline-block;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin-top: 8px;
  text-align: center;
  max-width: fit-content;
`;

interface ImageProps extends CommonProps {
  image?: string | null;
}

export function ImageCard({
  image,
  title,
  navigateTo,
  disabled,
  children,
  label,
  isHighlighted,
  ...rest
}: ImageProps) {
  const [imgSrc, setImgSrc] = useState(image || buildingPlaceholderImage);

  return (
    <StyledCard navigateTo={navigateTo} {...rest} disabled={disabled}>
      <Content>
        <Stack gap={space[2]}>
          <Title>{title}</Title>
          {children && <Overline>{children}</Overline>}
        </Stack>
        {label && (
          <HighlightedLabel isHighlighted={isHighlighted}>
            {label}
          </HighlightedLabel>
        )}
      </Content>
      <Image src={imgSrc} onError={() => setImgSrc(buildingPlaceholderImage)} />
    </StyledCard>
  );
}
