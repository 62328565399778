import React from 'react';

import { Stack } from '../../components/common/Stack';
import { useDateFormat } from '../../hooks/useDateFormat';
import { useTranslation } from '../../I18nProvider';
import { FullInstallationControl } from '../../utils/api';
import { range } from '../../utils/results';
import { ResultPanel } from './ResultPanel';

type Props = {
  installationControl: FullInstallationControl;
};
export function ResultPanels({ installationControl }: Props) {
  const t = useTranslation();
  const { formatDateTime } = useDateFormat();
  const { lift, floor_results, car_result, status } = installationControl;
  const loading = status === 'started';
  return (
    <Stack>
      {range(lift.floors).map((i) => {
        const floorResult = floor_results.find((f) => f.floor === i);

        return (
          <ResultPanel
            key={i}
            title={`${t.landingDoor} ${i}`}
            details={formatDateTime(floorResult?.date)}
            data={floorResult}
            loading={loading}
            tipContext={lift.box?.static_data}
          />
        );
      })}
      <ResultPanel
        title={t.carDoor}
        details={formatDateTime(car_result?.date)}
        data={car_result}
        loading={loading}
        tipContext={lift.box?.static_data}
      />
    </Stack>
  );
}
