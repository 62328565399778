import React, { ReactNode } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { borderRadius, color, space } from '../../tokens';
import { CommonHeader } from './CommonHeader';
import { IconButton } from './IconButton';
import { BackIcon } from './Icons';
import { SideBar } from './SideBar';
import { HeaderTitle, PageTitle, SubHeadline } from './typography';

const Root = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  background: ${color.primary};
`;

const Header = styled.header`
  color: ${color.textOnPrimary};
  padding: ${space[7]} ${space[3]} ${space[3]} ${space[3]};
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  position: relative;
`;

const Title = styled(HeaderTitle)`
  margin: auto;
`;

const Main = styled.main`
  flex-grow: 1;
  background: ${color.background};
  color: ${color.text};
  border-radius: ${borderRadius.outer} ${borderRadius.outer} 0 0;
  padding: ${space[5]} ${space[4]};
  position: relative;
  z-index: 1;
`;

const NextLink = styled.div`
  color: ${color.primary};
`;

const TopRow = styled.div`
  display: grid;
  padding-bottom: ${space[4]};
  > * {
    grid-area: 1 / 1;
    place-self: flex-start;
  }
  > ${PageTitle} {
    place-self: center;
  }
  > ${NextLink} {
    place-self: flex-end;
  }
`;

const SubRow = styled.div`
  display: grid;
  margin-top: -${space[4]};
  padding-bottom: ${space[4]};
  > ${SubHeadline} {
    place-self: center;
  }
`;

type Props = {
  pageTitle?: string;
  subHeadline?: string;
  backLink?: string;
  onBack?: () => void;
  children?: ReactNode;
  header?: ReactNode;
  nextLink?: ReactNode;
};
export function Page({
  pageTitle,
  subHeadline,
  onBack,
  backLink,
  header = <CommonHeader />,
  nextLink,
  children,
}: Props) {
  return (
    <Root>
      <Header>
        <SideBar />
        {typeof header === 'string' ? <Title>{header}</Title> : header}
      </Header>
      <Main>
        <TopRow>
          {backLink ? (
            <IconButton as={Link} to={backLink}>
              <BackIcon />
            </IconButton>
          ) : (
            onBack && (
              <IconButton onClick={onBack}>
                <BackIcon />
              </IconButton>
            )
          )}
          {pageTitle && <PageTitle>{pageTitle}</PageTitle>}
          {nextLink && <NextLink>{nextLink}</NextLink>}
        </TopRow>
        <SubRow>
          {subHeadline && <SubHeadline>{subHeadline}</SubHeadline>}
        </SubRow>
        {children}
      </Main>
    </Root>
  );
}
