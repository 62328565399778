import React from 'react';
import styled from 'styled-components';

import { Overlay } from '../../components/common/Overlay';
import { Row } from '../../components/common/Row';
import { Stack } from '../../components/common/Stack';
import {
  BodyText,
  CardTitle,
  PageTitle,
  SectionHeading,
} from '../../components/common/typography';
import { useTranslation } from '../../I18nProvider';
import { ScaleSection, TipContent, TipFigure, TipSection } from '../../tips';
import { borderRadius, color, space } from '../../tokens';
import { fullSpectrum, greenToRed, Tick } from './ResultCard';

const Container = styled.div`
  background: ${color.background};
  color: ${color.text};
  border-radius: ${borderRadius.outer} ${borderRadius.outer} 0 0;
  padding: ${space[5]} ${space[4]};
  margin-top: 56px;
`;

const Text = styled(BodyText)`
  max-width: 60ch;
  white-space: pre-wrap;
`;

const Figure = styled.figure`
  border-radius: ${borderRadius.default};
  overflow: hidden;
  max-width: fit-content;
`;

const Scale = styled.div`
  background: ${color.cardBackground};
  border-radius: ${borderRadius.default};
  padding: ${space[3]} ${space[3]} ${space[5]} ${space[3]};
`;

const Title = styled.div<{ status?: 'error' | 'ok' }>`
  color: #fff;
  background: ${(p) =>
    p.status ? (p.status === 'ok' ? color.success : color.error) : color.info};
  padding: 12px 20px;
  font-family: Roboto;
  font-weight: bold;
`;

const Img = styled.img`
  max-width: 100%;
  max-height: 60vh;
  align-self: center;
  background: ${color.cardBackground};
`;

function useTranslationFn() {
  const t = useTranslation();
  return (key?: string) => {
    if (!key) return '';
    return ((t as any)[key] as string) ?? key;
  };
}

function TextSection({ title, text }: TipSection) {
  const t = useTranslationFn();
  return (
    <>
      {title && <CardTitle>{t(title)}</CardTitle>}
      {text && <Text>{t(text)}</Text>}
    </>
  );
}

function ScaleSection({ left, right }: ScaleSection) {
  const t = useTranslationFn();
  const spectrum = left && right ? fullSpectrum : greenToRed;
  return (
    <Scale>
      <Stack gap={space[1]}>
        <Row justify="spread">
          <SectionHeading>{t(left)}</SectionHeading>
          <SectionHeading>{t(right)}</SectionHeading>
        </Row>
        <Row gap="2px" nowrap align="top">
          {spectrum.map((hex, i) => (
            <Tick
              key={i}
              style={{
                backgroundColor: hex,
                height: 2,
              }}
            />
          ))}
        </Row>
      </Stack>
    </Scale>
  );
}

function FigureSection({ title, image, status }: TipFigure) {
  const t = useTranslationFn();
  return (
    <Figure>
      {title && <Title status={status}>{t(title)}</Title>}
      {image && <Img src={image} />}
    </Figure>
  );
}

type Props = {
  error: string;
  content: TipContent[];
  tipContext?: any;
  onClose: () => void;
};
export function Tip({ error, content, tipContext, onClose }: Props) {
  const t = useTranslationFn();
  return (
    <Overlay onClose={onClose}>
      <Container>
        <Stack>
          <PageTitle>{t(error)}</PageTitle>
          {content
            .filter((c) => !('match' in c) || c.match!(tipContext))
            .map((c, i) =>
              'image' in c ? (
                <FigureSection key={i} {...c} />
              ) : 'right' in c ? (
                <ScaleSection key={i} {...c} />
              ) : (
                <TextSection key={i} {...c} />
              )
            )}
        </Stack>
      </Container>
    </Overlay>
  );
}
