import { ErrorType } from '../appApi';

export const DRIVE_ERROR_MAP = {
  AP: { message: 'ERROR AP: Absolut Position error', type: 'fatalerror' },
  BE: { message: 'ERROR BE: Balancing error electrically', type: 'error' },
  BM: { message: 'ERROR BM: Balancing error mechanically', type: 'error' },
  CF: { message: 'ERROR CF: Close Force potentiometer error', type: 'error' },
  EC: {
    message: 'ERROR EC: EC_EncoderError: Encoder counting (wrong pulses)',
    type: 'error',
  },
  EE: { message: 'ERROR EE: EEprom read/write error', type: 'error' },
  ES: { message: 'ERROR ES: Encoder hall signals out of range', type: 'error' },

  FE: { message: 'ERROR FE: Function Program edit error', type: 'fatalerror' },
  // 'FE': { message: 'ERROR FE: Function Edit (coupler width <V0356>)', type: 'fatalerror' },

  IE: { message: 'ERROR IE: Internal SW Error', type: 'fatalerror' },
  /*
  'IE': { message: 'ERROR IE: ADC current offset measurement error', type: 'error' },
  'IE': { message: 'ERROR IE: ADC IRQ Handler()', type: 'fatalerror' },
  'IE': { message: 'ERROR IE: compare values below 100% in ftmode', type: 'error' },
  'IE': { message: 'ERROR IE: digital encoder supervision', type: 'fatalerror' },
  'IE': { message: 'ERROR IE: do0() error', type: 'fatalerror' },
  'IE': { message: 'ERROR IE: extra code for full function needed <ECFFFN>', type: 'error' },
  'IE': { message: 'ERROR IE: flash check sum error', type: 'error' },
  'IE': { message: 'ERROR IE: Task Timing <com_TT_chk>', type: 'fatalerror' },
  'IE': { message: 'ERROR IE: this value should not occure (invalid error number)', type: 'fatalerror' },
  'IE': { message: 'ERROR IE: U5V signal out of range (5V supply voltage check)', type: 'error' },
  'IE': { message: 'ERROR IE: voltage model', type: 'fatalerror' },
  'IE': { message: 'ERROR IE: wrong motor type data', type: 'error' },
  'IE': { message: 'ERROR IE: wrong RAM value detected', type: 'fatalerror' },
  'IE': { message: 'ERROR IE: wrong value of FPCptr (FPC Program faulty)', type: 'fatalerror' },
  */

  LC: { message: 'ERROR LC: Lost close end', type: 'error' },
  LO: { message: 'ERROR LO: Lost open end', type: 'error' },
  MC: { message: 'ERROR MC: Motor Connection error', type: 'fatalerror' },
  MD: { message: 'ERROR MD: Wrong Movement direction', type: 'error' },
  ME: {
    message: 'ERROR ME: Motor or Encoder error (q-voltage;EMF)',
    type: 'fatalerror',
  },
  NE: { message: 'ERROR NE: No Encoder error', type: 'fatalerror' },
  OC: { message: 'ERROR OC: Overcurrent error', type: 'fatalerror' },
  RB: { message: 'ERROR RB: Rtc Battery error', type: 'error' },
  RS: { message: 'ERROR RS: Reference Switch error', type: 'error' },
  SS: { message: 'ERROR SS: Stand Still error', type: 'error' },
  TH: { message: 'ERROR TH: Temperature too High; overheated', type: 'error' },
  TS: { message: 'ERROR TS: Temperature Sensor error', type: 'fatalerror' },
};

export type DriveErrorName = keyof typeof DRIVE_ERROR_MAP;
export type DriveErrors = Record<DriveErrorName, DriveError>;
export interface DriveError {
  type: ErrorType;
  message: string;
  date?: string;
}

export function getDriveError(code: DriveErrorName): DriveError {
  const result = DRIVE_ERROR_MAP[code] as DriveError;
  return result
    ? result
    : { message: `ERROR ${code}: Unknown error`, type: 'fatalerror' };
}
