import React from 'react';
import { useParams } from 'react-router-dom';

import { Page } from '../components/common/Page';
import { Stack } from '../components/common/Stack';
import { DataGrid } from '../components/DataGrid';
import { useApiQuery } from '../hooks/useApi';

export function AdminCompanyInvites() {
  const params = useParams();
  const companyId = parseInt(params.id!);

  const invites = useApiQuery('getInvitesAsAdmin', { companyId });

  return (
    <Page header="Admin" backLink="../" pageTitle="Invites">
      <Stack>
        {invites.data?.map(({ id, email, role, date }) => (
          <DataGrid
            key={id}
            data={{
              email,
              role,
              date,
            }}
          ></DataGrid>
        ))}
      </Stack>
    </Page>
  );
}
