import styled from 'styled-components';

const justification = {
  start: 'flex-start',
  end: 'flex-end',
  center: 'center',
  spread: 'space-between',
};

const alignment = {
  top: 'flex-start',
  bottom: 'flex-end',
  center: 'center',
  baseline: 'basline',
};

type Props = {
  gap?: string;
  nowrap?: boolean;
  align?: keyof typeof alignment;
  justify?: keyof typeof justification;
};

export const Row = styled.div<Props>`
  display: flex;
  align-items: ${(p) => alignment[p.align ?? 'center']};
  justify-content: ${(p) => justification[p.justify ?? 'start']};
  flex-wrap: ${(p) => (p.nowrap ? 'nowrap' : 'wrap')};
  & > * + * {
    margin-left: ${({ gap }) => gap || '1ch'};
  }
`;
