import React from 'react';

import { ErrorCard } from '../../components/common/ErrorCard';
import { Stack } from '../../components/common/Stack';
import { useTranslation } from '../../I18nProvider';
import { space } from '../../tokens';
import { DoorData } from '../../utils/boxData';
import { ScaleCard } from './ScaleCard';

export function SensorData({ data }: { data: DoorData }) {
  const t = useTranslation();
  if (!data) {
    return null;
  }
  const {
    powerReductionFactor,
    ambientTemperature,
    encoderTemperature,
    motorTemperature,
  } = data.measurements;

  return (
    <>
      {data.errors.map((e, i) => (
        <ErrorCard
          key={i}
          status={e.type === 'fatalerror' ? 'fatal' : e.type}
          title={
            e.type === 'fatalerror'
              ? t.fatalError
              : e.type === 'error'
              ? t.error
              : t.warning
          }
        >
          {e.message}
        </ErrorCard>
      ))}

      <Stack gap={space[2]}>
        <ScaleCard
          title={t.ambientTemperature}
          bars={38}
          min={0}
          max={120}
          step1={60}
          step2={75}
          value={parseInt(ambientTemperature)}
          unit="°C"
        />
        <ScaleCard
          title={t.encoderTemperature}
          bars={38}
          min={0}
          max={120}
          step1={60}
          step2={75}
          value={parseInt(encoderTemperature)}
          unit="°C"
        />
        <ScaleCard
          title={t.motorTemperature}
          bars={38}
          min={0}
          max={120}
          step1={70}
          step2={100}
          value={parseInt(motorTemperature)}
          unit="°C"
        />
        <ScaleCard
          title={t.powerReductionFactor}
          bars={38}
          min={0}
          max={1}
          step1={0.6}
          step2={0.9}
          value={parseFloat(powerReductionFactor)}
          inverse
        />
      </Stack>
    </>
  );
}
