/**
 * Wittur ElevatorSense
 * Rev. 122541e, 13.11.24 01:21
 * DO NOT MODIFY - This file has been generated using oazapfts.
 * See https://www.npmjs.com/package/oazapfts
 */
import * as Oazapfts from "oazapfts/lib/runtime";
import * as QS from "oazapfts/lib/runtime/query";
export const defaults: Oazapfts.RequestOpts = {
    baseUrl: "http://127.0.0.1/api",
};
const oazapfts = Oazapfts.runtime(defaults);
export const servers = {
    server1: "http://127.0.0.1/api"
};
export type DoorScope = {
    areAllPartsSelected: boolean;
    lastMaintenanceScope: number[];
    doorTypes?: string[];
    doorModel: string;
    id: number;
};
export type JsonObject = {};
export type JsonArray = {};
export type PrismaJsonValue = (string | number | boolean | JsonObject | JsonArray) | null;
export type EnumsInstallationControlStatus = "initialized" | "started" | "completed" | "aborted" | "obsolete";
export type InstallationControl = {
    esn: string;
    runs: number;
    share_key: string;
    started_at: string | null;
    failures: number;
    status: EnumsInstallationControlStatus;
    starting_floor: string;
    math_id: number;
    lift_id: number;
};
export type ResultDefaultSelectionPrismaBuildingPayload = {
    photo: string;
    longitude: number;
    latitude: number;
    country: string;
    city: string;
    streetNumber: string;
    street: string;
    name: string;
    company_id: number;
    id: number;
};
export type Building = ResultDefaultSelectionPrismaBuildingPayload;
export type UpdateMode = any;
export type JsonValue = (string | number | boolean | JsonObject | JsonArray) | null;
export type ResultDefaultSelectionPrismaBoxPayload = {
    updateMode: UpdateMode;
    static_data: JsonValue;
    company_id: number;
    id: string;
};
export type Box = ResultDefaultSelectionPrismaBoxPayload;
export type FullLift = {
    isPmSetUp: boolean | null;
    carDoorCounter: number | null;
    lastTimeDoorElectronicsChange: string | null;
    lastMaintenanceScope: number[];
    doorInstallationDate: string | null;
    lastMaintenanceDate: string | null;
    elevatorInstallationDate: string | null;
    areAllPartsSelected: boolean | null;
    doorModel: string | null;
    doorType: string | null;
    groundFloor: number | null;
    static_data: (PrismaJsonValue) | null;
    isSetUp: boolean | null;
    floors: number;
    box_id: string | null;
    building_id: number;
    doorSerialNumber: string | null;
    assetNumber: string | null;
    name: string;
    id: number;
    installationControl: (InstallationControl) | null;
    building: Building;
    box: (Box) | null;
};
export type PartialLiftArgs = {
    name?: string;
    floors?: number;
    assetNumber?: string | null;
    doorSerialNumber?: string | null;
    box_id?: string | null;
    groundFloor?: number | null;
    doorType?: string | null;
    doorModel?: string | null;
    areAllPartsSelected?: boolean | null;
    elevatorInstallationDate?: string | null;
    carDoorCounter?: number | null;
    lastTimeDoorElectronicsChange?: string | null;
    lastMaintenanceScope?: number[];
    lastMaintenanceDate?: string | null;
    doorInstallationDate?: string | null;
    isPmSetUp?: boolean | null;
};
export type InstallationControlArgs = {
    door_model?: string;
    starting_floor: string;
};
export type BoxStatus = {
    "box-ready": boolean;
};
export type InitMeasurementResponse = BoxStatus & {
    "measurement-id": number;
};
export type LiftWithBoxAndBuilding = {
    isPmSetUp: boolean | null;
    carDoorCounter: number | null;
    lastTimeDoorElectronicsChange: string | null;
    lastMaintenanceScope: number[];
    doorInstallationDate: string | null;
    lastMaintenanceDate: string | null;
    elevatorInstallationDate: string | null;
    areAllPartsSelected: boolean | null;
    doorModel: string | null;
    doorType: string | null;
    groundFloor: number | null;
    static_data: (PrismaJsonValue) | null;
    isSetUp: boolean | null;
    floors: number;
    box_id: string | null;
    building_id: number;
    doorSerialNumber: string | null;
    assetNumber: string | null;
    name: string;
    id: number;
    box: (Box) | null;
    building: Building;
};
export type ResultDefaultSelectionPrismaCarResultPayload = {
    date: string;
    userProcessCorrect: boolean;
    statusCode: number;
    lift_id: number;
    failed: JsonValue;
    data: JsonValue;
};
export type CarResult = ResultDefaultSelectionPrismaCarResultPayload;
export type ResultDefaultSelectionPrismaFloorResultPayload = {
    date: string;
    setupRunStatusCode: number;
    statusCode: number;
    movementDistance: number;
    lift_id: number;
    failed: JsonValue;
    data: JsonValue;
    cycle: number;
    floor: number;
};
export type FloorResult = ResultDefaultSelectionPrismaFloorResultPayload;
export type FullInstallationControl = {
    esn: string;
    runs: number;
    share_key: string;
    started_at: string | null;
    failures: number;
    status: EnumsInstallationControlStatus;
    starting_floor: string;
    math_id: number;
    lift_id: number;
    lift: LiftWithBoxAndBuilding;
    car_result: (CarResult) | null;
    floor_results: FloorResult[];
    warrantyExtensionRequestedOn?: string;
};
export type RestartArgs = {
    floors: number[];
};
export type InstallationRun = {
    data: PrismaJsonValue;
    userLastName: string;
    userFirstName: string;
    failures: number;
    status: EnumsInstallationControlStatus;
    run: number;
    date: string;
    lift_id: number;
    lift: LiftWithBoxAndBuilding;
    car_result: (CarResult) | null;
    floor_results: FloorResult[];
};
export type DoorCycle = {
    errors: {
        date: string;
        "type": "error" | "fatalerror";
        message: string;
    }[];
    powerreductionfactor: number;
    networkquality: number;
    motortemperature: number;
    floornumber: number | null;
    encodertemperature: number;
    doorwidth: number | null;
    couplerwidth: number | null;
    ambienttemperature: number;
    doorcyclecounter: number;
    doorposition: (number | null)[];
    doorspeed: (number | null)[];
    doorforce: (number | null)[];
    cmelectronicage: number;
    cmvibration: number;
    cmdoorfriction: number;
    cmcouplerfriction: number;
    time: string[];
    box_id: string;
};
export type ErrorType = any;
export type BatchPayload = {
    count: number;
};
export type ProductStatus = {
    TRIAL: boolean;
    PREV_MAIN?: boolean;
    PRG_TOOL?: boolean;
    RT_DIAG?: boolean;
    INST_CTRL?: boolean;
};
export type EnumsProduct = "INST_CTRL" | "RT_DIAG" | "PRG_TOOL" | "PREV_MAIN";
export type Priorities = {
    ok: number;
    recommended: number;
    mandatory: number;
};
export type FloorStatistics = {
    priorities: Priorities;
    floor: string;
};
export type FloorOverview = {
    floorStatistics: FloorStatistics[];
    overallStatistics: Priorities;
};
export type Part = {
    imageSources?: string[];
    partName: string;
    partNumber: string;
};
export type Component = {
    part?: (Part) | null;
    notReplacedReason?: string;
    snoozeDates?: string[] | null;
    installationDate?: string;
    nextMaintenanceDatePred?: string;
    nextMaintenanceDateWmp?: string;
    mandatoryChangeInDoorcycles: number;
    currentCounter: number;
    priority: string;
    floor?: string;
    componentPositionName: string;
};
export type ComponentByFloor = {
    components: Component[];
    floor: string;
};
export type UpdateComponentArgs = {
    isReplaced: boolean;
    component: Component;
};
export type ComponentDetails = {
    componentPositionName: string;
};
export type ComponentParts = {
    parts: Part[];
    componentPositionName: string;
};
export type Role = "user" | "manager" | "admin";
export type UserLocale = "de" | "en" | "it" | "fr" | "es";
export type PickUserExcludeKeyofUserPassword = {
    id: number;
    company_id: number;
    firstName: string;
    lastName: string;
    email: string;
    role: Role;
    locale: UserLocale;
}
export type OmitUserPassword = PickUserExcludeKeyofUserPassword;
export type NormalisedUser = OmitUserPassword & {
    company: {
        name: string;
        id: number;
    };
};
export type ResultDefaultSelectionPrismaUserPayload = {
    dateTermsAccepted: string;
    locale: UserLocale;
    company_id: number;
    role: Role;
    password: string;
    email: string;
    lastName: string;
    firstName: string;
    id: number;
};
export type User = ResultDefaultSelectionPrismaUserPayload;
export type UserArgs = {
    role?: "user" | "manager" | "admin";
};
export type LicenseOrder = "box" | "date" | "expiry";
export type LicenseArgs = {
    maxElectronics?: number;
    maxUsage?: number;
    validUntil: string;
    validFrom: string;
    product: EnumsProduct;
    customerRef: string;
    boxId: string;
    id: string;
};
export type UpdateLicenseArgs = {
    maxElectronics?: number;
    maxUsage?: number;
    validUntil: string;
};
export type SubscriptionScope = any;
export type SubscriptionType = any;
export type LoginCredentials = {
    password: string;
    email: string;
};
export type LoginResult = {
    token: string;
    user: NormalisedUser;
};
export type CredentialErrors = {
    errors: {
        acceptTerms?: string;
        companyName?: string;
        lastName?: string;
        firstName?: string;
        password?: string;
        email?: string;
    };
};
export type AcceptInviteArgs = {
    acceptTerms: boolean;
    lastName: string;
    firstName: string;
    password: string;
};
export type ConfirmSubcontractorInviteArgs = {
    acceptTerms: boolean;
};
export type RegisterSubcontractorInviteArgs = {
    acceptTerms: boolean;
    lastName: string;
    firstName: string;
    locale: UserLocale;
    companyName: string;
    password: string;
};
export type AuthUserArgs = {
    locale: UserLocale;
};
export type RequestPasswordResetArgs = {
    email: string;
};
export type RequestPasswordResetErrors = {
    errors: {
        email: string;
    };
};
export type PasswordResetArgs = {
    password: string;
    token: string;
};
export type PasswordResetErrors = {
    errors: {
        password: string;
    };
};
export type BoxSoftwareStatus = {
    isUpdateRunning: boolean;
    isOnline: boolean;
    updateMode: string;
    currentSoftwarePackVersion: string;
    boxId: string;
};
export type SoftwareStatusResponse = {
    boxSoftwareStatuses: BoxSoftwareStatus[];
    latestSoftwarePackVersion: string;
};
export type UpdateModeArgs = {
    updateMode: UpdateMode;
    boxId: string;
};
export type BuildingArgs = {
    photo?: string | null;
    longitude?: number | null;
    latitude?: number | null;
    country: string;
    city: string;
    streetNumber: string;
    street: string;
    name: string;
};
export type PartialBuildingArgs = {
    name?: string;
    street?: string;
    streetNumber?: string;
    city?: string;
    country?: string;
    latitude?: number | null;
    longitude?: number | null;
    photo?: string | null;
};
export type LiftArgs = {
    isPmSetUp?: boolean | null;
    doorInstallationDate?: string | null;
    lastMaintenanceDate?: string | null;
    lastMaintenanceScope?: number[];
    lastTimeDoorElectronicsChange?: string | null;
    carDoorCounter?: number | null;
    elevatorInstallationDate?: string | null;
    areAllPartsSelected?: boolean | null;
    doorModel?: string | null;
    doorType?: string | null;
    groundFloor?: number | null;
    box_id?: string | null;
    doorSerialNumber?: string | null;
    assetNumber?: string | null;
    floors: number;
    name: string;
};
export type BuildingWithShared = {
    photo: string;
    longitude: number;
    latitude: number;
    country: string;
    city: string;
    streetNumber: string;
    street: string;
    name: string;
    company_id: number;
    id: number;
    isShared: boolean;
};
export type InviteArgs = {
    role?: "user" | "manager";
    email: string;
};
export type InviteErrors = {
    errors: {
        email?: string;
    };
};
export type SubscribeArgs = {
    scope: SubscriptionScope;
    "type": SubscriptionType;
};
export type SubcontractorInviteArgs = {
    email: string;
};
export type PurchaseArgs = {
    /** The maximum number of control trips the customer is allowed to execute within the validity period.
    The value is only checked for INST_CTRL products, where each control trip counts as one use.
    (optional, standard is 50) */
    controlTripLimit?: number;
    /** The maximum number of different elevators (or door elevators to be precise)
    the customer is allowed to use the digital service for within the validity period.
    The number is only checked for INST_CTRL and RT_DIAG products.
    (optional, standard is 20) */
    elevatorLimit?: number;
    /** Date until which the license is valid. */
    validTo: string;
    /** Date from where on the license is valid. */
    validFrom: string;
    /** The ElevatorSense product the customer bought. If the customer bought more than
    one product for the same box, we expect multiple license datasets. At the moment only
    the two digital products “INST_CTRL” and “RT_DIAG” exist.
    The suffix “EXT” as well as the data transmission product is not relevant for ElevatorSense */
    product: "CIS15020" | "CIS15021" | "CIS15022" | "CIS15001" | "CIS15025" | "CIS15026" | "CIS10020" | "CIS10021" | "CIS10022" | "CIS10023" | "CIS10024" | "CIS10025" | "CIS10026" | "CIS10030" | "CIS10031" | "CIS10032" | "CIS10035" | "CIS10036" | "CIS10040" | "CIS10041" | "CIS10042" | "CIS10043" | "CIS10045" | "CIS10046";
    /** The ElevatorSense box ID Wittur assigned to exactly that purchase. */
    box: string;
    /** Wittur company name. */
    witturName: string;
    /** Contract header company code (Wittur). */
    witturCode: string;
    /** Language of the webshop user that bought the license.
    The lowercase two letter ISO code of the language that should be used for communication.
    Defaults to `en`. */
    language?: string;
    /** The email address of the webshop user performing the purchase. A mail will be sent
    to this address (if this is the first purchase done by that person)
    allowing them to create an app account. */
    email: string;
    /** The company name of the business partner (customer). This field is required so that the company can be created
    in EleveatorSense if it doesn't exist yet. */
    companyName: string;
    /** The ID of the business partner (customer). Must be the same for all
    purchases done by a company. */
    customerRef: string;
    /** The license ID generated from the ERP. */
    licenseID: string;
};
export type AddPurchaseResult = {
    license: "updated" | "created";
    user: "found" | "invited";
};
export function getDoorScopes(opts?: Oazapfts.RequestOpts) {
    return oazapfts.ok(oazapfts.fetchJson<{
        status: 200;
        data: DoorScope[];
    }>("/app/lifts/door-scopes", {
        ...opts
    }));
}
export function getLift(id: number, opts?: Oazapfts.RequestOpts) {
    return oazapfts.ok(oazapfts.fetchJson<{
        status: 200;
        data: FullLift;
    }>(`/app/lifts/${encodeURIComponent(id)}`, {
        ...opts
    }));
}
export function updateLift(id: number, partialLiftArgs: PartialLiftArgs, opts?: Oazapfts.RequestOpts) {
    return oazapfts.ok(oazapfts.fetchJson<{
        status: 200;
        data: {
            success: boolean;
        };
    }>(`/app/lifts/${encodeURIComponent(id)}`, oazapfts.json({
        ...opts,
        method: "PUT",
        body: partialLiftArgs
    })));
}
export function deleteLift(id: number, opts?: Oazapfts.RequestOpts) {
    return oazapfts.ok(oazapfts.fetchText(`/app/lifts/${encodeURIComponent(id)}`, {
        ...opts,
        method: "DELETE"
    }));
}
export function initInstallationControl(id: number, installationControlArgs: InstallationControlArgs, opts?: Oazapfts.RequestOpts) {
    return oazapfts.ok(oazapfts.fetchJson<{
        status: 200;
        data: InitMeasurementResponse;
    }>(`/app/lifts/${encodeURIComponent(id)}/installation`, oazapfts.json({
        ...opts,
        method: "POST",
        body: installationControlArgs
    })));
}
export function startInstallationControl(id: number, opts?: Oazapfts.RequestOpts) {
    return oazapfts.ok(oazapfts.fetchJson<{
        status: 200;
        data: BoxStatus;
    }>(`/app/lifts/${encodeURIComponent(id)}/installation`, {
        ...opts,
        method: "PUT"
    }));
}
export function getInstallationControl(id: number, opts?: Oazapfts.RequestOpts) {
    return oazapfts.ok(oazapfts.fetchJson<{
        status: 200;
        data: FullInstallationControl;
    }>(`/app/lifts/${encodeURIComponent(id)}/installation`, {
        ...opts
    }));
}
export function abortInstallationControl(id: number, opts?: Oazapfts.RequestOpts) {
    return oazapfts.ok(oazapfts.fetchText(`/app/lifts/${encodeURIComponent(id)}/installation`, {
        ...opts,
        method: "DELETE"
    }));
}
export function updateStaticData(id: number, opts?: Oazapfts.RequestOpts) {
    return oazapfts.ok(oazapfts.fetchJson<{
        status: 200;
        data: {
            sameEsn: boolean;
        };
    }>(`/app/lifts/${encodeURIComponent(id)}/installation/static-data`, {
        ...opts,
        method: "PUT"
    }));
}
export function startSetupRun(id: number, opts?: Oazapfts.RequestOpts) {
    return oazapfts.ok(oazapfts.fetchJson<{
        status: 200;
        data: BoxStatus;
    }>(`/app/lifts/${encodeURIComponent(id)}/setup`, {
        ...opts,
        method: "PUT"
    }));
}
export function getSharedInstallationControl(id: number, key: string, opts?: Oazapfts.RequestOpts) {
    return oazapfts.ok(oazapfts.fetchJson<{
        status: 200;
        data: FullInstallationControl;
    }>(`/app/lifts/${encodeURIComponent(id)}/shared/${encodeURIComponent(key)}`, {
        ...opts
    }));
}
export function getInstallationCertificate(id: number, opts?: Oazapfts.RequestOpts) {
    return oazapfts.ok(oazapfts.fetchJson<{
        status: 200;
        data: string;
    }>(`/app/lifts/${encodeURIComponent(id)}/installation/certificate`, {
        ...opts
    }));
}
export function restartMeasurement(id: number, restartArgs: RestartArgs, opts?: Oazapfts.RequestOpts) {
    return oazapfts.ok(oazapfts.fetchJson<{
        status: 200;
        data: BoxStatus;
    }>(`/app/lifts/${encodeURIComponent(id)}/installation/restart`, oazapfts.json({
        ...opts,
        method: "PUT",
        body: restartArgs
    })));
}
export function getInstallationControlRuns(id: number, opts?: Oazapfts.RequestOpts) {
    return oazapfts.ok(oazapfts.fetchJson<{
        status: 200;
        data: {
            failures: number;
            date: string;
            run: number;
        }[];
    }>(`/app/lifts/${encodeURIComponent(id)}/installation/runs`, {
        ...opts
    }));
}
export function getInstallationControlRun(id: number, run: number, opts?: Oazapfts.RequestOpts) {
    return oazapfts.ok(oazapfts.fetchJson<{
        status: 200;
        data: InstallationRun;
    }>(`/app/lifts/${encodeURIComponent(id)}/installation/runs/${encodeURIComponent(run)}`, {
        ...opts
    }));
}
export function getDoorCycles(id: number, { since }: {
    since?: string;
} = {}, opts?: Oazapfts.RequestOpts) {
    return oazapfts.ok(oazapfts.fetchJson<{
        status: 200;
        data: DoorCycle[];
    }>(`/app/lifts/${encodeURIComponent(id)}/doorcycles${QS.query(QS.explode({
        since
    }))}`, {
        ...opts
    }));
}
export function getLiftErrors(id: number, { since, types }: {
    since?: string;
    types?: ErrorType[];
} = {}, opts?: Oazapfts.RequestOpts) {
    return oazapfts.ok(oazapfts.fetchJson<{
        status: 200;
        data: {
            lift_id: number;
            resolved: boolean;
            "type": ErrorType;
            message: string;
            date: string;
            box_id: string;
        }[];
    }>(`/app/lifts/${encodeURIComponent(id)}/errors${QS.query(QS.explode({
        since,
        types
    }))}`, {
        ...opts
    }));
}
export function deleteLiftErrors(id: number, { types }: {
    types?: ErrorType[];
} = {}, opts?: Oazapfts.RequestOpts) {
    return oazapfts.ok(oazapfts.fetchJson<{
        status: 200;
        data: BatchPayload;
    }>(`/app/lifts/${encodeURIComponent(id)}/errors${QS.query(QS.explode({
        types
    }))}`, {
        ...opts,
        method: "DELETE"
    }));
}
export function getLiftNotifications(id: number, opts?: Oazapfts.RequestOpts) {
    return oazapfts.ok(oazapfts.fetchJson<{
        status: 200;
        data: {
            lift_id: number;
            resolved: boolean;
            "type": ErrorType;
            message: string;
            date: string;
            box_id: string;
        }[];
    }>(`/app/lifts/${encodeURIComponent(id)}/notifications`, {
        ...opts
    }));
}
export function getProductStatus(id: number, opts?: Oazapfts.RequestOpts) {
    return oazapfts.ok(oazapfts.fetchJson<{
        status: 200;
        data: ProductStatus;
    }>(`/app/lifts/${encodeURIComponent(id)}/products`, {
        ...opts
    }));
}
export function startTrial(id: number, product: EnumsProduct, opts?: Oazapfts.RequestOpts) {
    return oazapfts.ok(oazapfts.fetchText(`/app/lifts/${encodeURIComponent(id)}/trial${QS.query(QS.explode({
        product
    }))}`, {
        ...opts,
        method: "POST"
    }));
}
export function getFloorOverview(id: number, selectedDate: string, opts?: Oazapfts.RequestOpts) {
    return oazapfts.ok(oazapfts.fetchJson<{
        status: 200;
        data: FloorOverview;
    }>(`/app/lifts/${encodeURIComponent(id)}/floor-overview${QS.query(QS.explode({
        selectedDate
    }))}`, {
        ...opts
    }));
}
export function getComponentsByFloor(id: number, selectedDate: string, opts?: Oazapfts.RequestOpts) {
    return oazapfts.ok(oazapfts.fetchJson<{
        status: 200;
        data: ComponentByFloor[];
    }>(`/app/lifts/${encodeURIComponent(id)}/components${QS.query(QS.explode({
        selectedDate
    }))}`, {
        ...opts
    }));
}
export function getComponent(id: number, componentId: string, floor: string, opts?: Oazapfts.RequestOpts) {
    return oazapfts.ok(oazapfts.fetchJson<{
        status: 200;
        data: Component;
    }>(`/app/lifts/${encodeURIComponent(id)}/floor/${encodeURIComponent(floor)}/components/${encodeURIComponent(componentId)}`, {
        ...opts
    }));
}
export function updateComponent(id: number, floor: string, updateComponentArgs: UpdateComponentArgs, opts?: Oazapfts.RequestOpts) {
    return oazapfts.ok(oazapfts.fetchJson<{
        status: 200;
        data: Component;
    }>(`/app/lifts/${encodeURIComponent(id)}/floor/${encodeURIComponent(floor)}/components`, oazapfts.json({
        ...opts,
        method: "PUT",
        body: updateComponentArgs
    })));
}
export function getComponentDetails(id: number, opts?: Oazapfts.RequestOpts) {
    return oazapfts.ok(oazapfts.fetchJson<{
        status: 200;
        data: ComponentDetails[];
    }>(`/app/lifts/${encodeURIComponent(id)}/components/details`, {
        ...opts
    }));
}
export function getComponentParts(id: number, componentPositionName: string, opts?: Oazapfts.RequestOpts) {
    return oazapfts.ok(oazapfts.fetchJson<{
        status: 200;
        data: ComponentParts;
    }>(`/app/lifts/${encodeURIComponent(id)}/components/${encodeURIComponent(componentPositionName)}/parts`, {
        ...opts
    }));
}
export function updateComponentParts(id: number, componentPositionName: string, part: Part, opts?: Oazapfts.RequestOpts) {
    return oazapfts.ok(oazapfts.fetchText(`/app/lifts/${encodeURIComponent(id)}/components/${encodeURIComponent(componentPositionName)}/parts`, oazapfts.json({
        ...opts,
        method: "PUT",
        body: part
    })));
}
export function extendWarranty(id: number, opts?: Oazapfts.RequestOpts) {
    return oazapfts.ok(oazapfts.fetchText(`/app/lifts/${encodeURIComponent(id)}/installation/extendWarranty`, {
        ...opts,
        method: "POST"
    }));
}
export function shareLiftWithSubcontractor(subcontractorId: number, body: {
    liftId: number;
}, opts?: Oazapfts.RequestOpts) {
    return oazapfts.ok(oazapfts.fetchText(`/app/lifts/subcontractors/${encodeURIComponent(subcontractorId)}/share`, oazapfts.json({
        ...opts,
        method: "POST",
        body
    })));
}
export function unshareLiftWithSubcontractor(subcontractorId: number, liftId: number, opts?: Oazapfts.RequestOpts) {
    return oazapfts.ok(oazapfts.fetchText(`/app/lifts/subcontractors/${encodeURIComponent(subcontractorId)}/unshare/${encodeURIComponent(liftId)}`, {
        ...opts,
        method: "DELETE"
    }));
}
export function getSharedLifts(subcontractorId: number, opts?: Oazapfts.RequestOpts) {
    return oazapfts.ok(oazapfts.fetchJson<{
        status: 200;
        data: {
            isPmSetUp: boolean;
            carDoorCounter: number;
            lastTimeDoorElectronicsChange: string;
            lastMaintenanceScope: number[];
            doorInstallationDate: string;
            lastMaintenanceDate: string;
            elevatorInstallationDate: string;
            areAllPartsSelected: boolean;
            doorModel: string;
            doorType: string;
            groundFloor: number;
            static_data: JsonValue;
            isSetUp: boolean;
            floors: number;
            box_id: string;
            building_id: number;
            doorSerialNumber: string;
            assetNumber: string;
            name: string;
            id: number;
        }[];
    }>(`/app/lifts/subcontractors/${encodeURIComponent(subcontractorId)}/shared-lifts`, {
        ...opts
    }));
}
export function getLiftsWithShareStatus(subcontractorId: number, buildingId: number, opts?: Oazapfts.RequestOpts) {
    return oazapfts.ok(oazapfts.fetchJson<{
        status: 200;
        data: {
            isPmSetUp: boolean;
            carDoorCounter: number;
            lastTimeDoorElectronicsChange: string;
            lastMaintenanceScope: number[];
            doorInstallationDate: string;
            lastMaintenanceDate: string;
            elevatorInstallationDate: string;
            areAllPartsSelected: boolean;
            doorModel: string;
            doorType: string;
            groundFloor: number;
            static_data: JsonValue;
            isSetUp: boolean;
            floors: number;
            box_id: string;
            building_id: number;
            doorSerialNumber: string;
            assetNumber: string;
            name: string;
            id: number;
            isShared: boolean;
        }[];
    }>(`/app/lifts/subcontractors/${encodeURIComponent(subcontractorId)}/buildings/${encodeURIComponent(buildingId)}/lifts`, {
        ...opts
    }));
}
export function getUsers({ companyId }: {
    companyId?: number;
} = {}, opts?: Oazapfts.RequestOpts) {
    return oazapfts.ok(oazapfts.fetchJson<{
        status: 200;
        data: (NormalisedUser[] | User[]) | null;
    }>(`/app/admin/users${QS.query(QS.explode({
        company_id: companyId
    }))}`, {
        ...opts
    }));
}
export function updateUserAsAdmin(id: number, userArgs: UserArgs, opts?: Oazapfts.RequestOpts) {
    return oazapfts.ok(oazapfts.fetchText(`/app/admin/users/${encodeURIComponent(id)}`, oazapfts.json({
        ...opts,
        method: "PUT",
        body: userArgs
    })));
}
export function deleteUser(id: number, opts?: Oazapfts.RequestOpts) {
    return oazapfts.ok(oazapfts.fetchText(`/app/admin/users/${encodeURIComponent(id)}`, {
        ...opts,
        method: "DELETE"
    }));
}
export function getCompanies(opts?: Oazapfts.RequestOpts) {
    return oazapfts.ok(oazapfts.fetchJson<{
        status: 200;
        data: {
            contractor_id: number;
            witturName: string;
            witturCode: string;
            language: string;
            sellingEntity: string;
            customerRef: string;
            name: string;
            id: number;
        }[];
    }>("/app/admin/companies", {
        ...opts
    }));
}
export function getCompany(id: number, opts?: Oazapfts.RequestOpts) {
    return oazapfts.ok(oazapfts.fetchJson<{
        status: 200;
        data: {
            contractor_id: number;
            witturName: string;
            witturCode: string;
            language: string;
            sellingEntity: string;
            customerRef: string;
            name: string;
            id: number;
        };
    }>(`/app/admin/companies/${encodeURIComponent(id)}`, {
        ...opts
    }));
}
export function getBoxes({ companyId }: {
    companyId?: number;
} = {}, opts?: Oazapfts.RequestOpts) {
    return oazapfts.ok(oazapfts.fetchJson<{
        status: 200;
        data: ({
            licenses: {
                updatedAt: string;
                createdAt: string;
                token: string;
                maxElectronics: number;
                maxUsage: number;
                usage: number;
                validUntil: string;
                validFrom: string;
                product: EnumsProduct;
                company_id: number;
                box_id: string;
                id: string;
            }[];
            company: {
                contractor_id: number;
                witturName: string;
                witturCode: string;
                language: string;
                sellingEntity: string;
                customerRef: string;
                name: string;
                id: number;
            };
            lift: {
                building: {
                    photo: string;
                    longitude: number;
                    latitude: number;
                    country: string;
                    city: string;
                    streetNumber: string;
                    street: string;
                    name: string;
                    company_id: number;
                    id: number;
                };
            } & {
                isPmSetUp: boolean;
                carDoorCounter: number;
                lastTimeDoorElectronicsChange: string;
                lastMaintenanceScope: number[];
                doorInstallationDate: string;
                lastMaintenanceDate: string;
                elevatorInstallationDate: string;
                areAllPartsSelected: boolean;
                doorModel: string;
                doorType: string;
                groundFloor: number;
                static_data: JsonValue;
                isSetUp: boolean;
                floors: number;
                box_id: string;
                building_id: number;
                doorSerialNumber: string;
                assetNumber: string;
                name: string;
                id: number;
            };
        } & {
            updateMode: UpdateMode;
            static_data: JsonValue;
            company_id: number;
            id: string;
        })[];
    }>(`/app/admin/boxes${QS.query(QS.explode({
        company_id: companyId
    }))}`, {
        ...opts
    }));
}
export function getLicenses({ order, companyId }: {
    order?: LicenseOrder;
    companyId?: number;
} = {}, opts?: Oazapfts.RequestOpts) {
    return oazapfts.ok(oazapfts.fetchJson<{
        status: 200;
        data: ({
            electronics: {
                license_id: string;
                esn: string;
            }[];
        } & {
            updatedAt: string;
            createdAt: string;
            token: string;
            maxElectronics: number;
            maxUsage: number;
            usage: number;
            validUntil: string;
            validFrom: string;
            product: EnumsProduct;
            company_id: number;
            box_id: string;
            id: string;
        })[];
    }>(`/app/admin/licenses${QS.query(QS.explode({
        order,
        company_id: companyId
    }))}`, {
        ...opts
    }));
}
export function addLicense(licenseArgs: LicenseArgs, opts?: Oazapfts.RequestOpts) {
    return oazapfts.ok(oazapfts.fetchText("/app/admin/licenses", oazapfts.json({
        ...opts,
        method: "POST",
        body: licenseArgs
    })));
}
export function getInvitesAsAdmin({ companyId }: {
    companyId?: number;
} = {}, opts?: Oazapfts.RequestOpts) {
    return oazapfts.ok(oazapfts.fetchJson<{
        status: 200;
        data: {
            company_id: number;
            date: string;
            token: string;
            role: Role;
            email: string;
            id: number;
        }[];
    }>(`/app/admin/invites${QS.query(QS.explode({
        company_id: companyId
    }))}`, {
        ...opts
    }));
}
export function updateLicense(id: string, updateLicenseArgs: UpdateLicenseArgs, opts?: Oazapfts.RequestOpts) {
    return oazapfts.ok(oazapfts.fetchText(`/app/admin/licenses/${encodeURIComponent(id)}`, oazapfts.json({
        ...opts,
        method: "PUT",
        body: updateLicenseArgs
    })));
}
export function getSubscriptions({ companyId, userId }: {
    companyId?: number;
    userId?: number;
} = {}, opts?: Oazapfts.RequestOpts) {
    return oazapfts.ok(oazapfts.fetchJson<{
        status: 200;
        data: {
            createdAt: string;
            scope: SubscriptionScope;
            "type": SubscriptionType;
            user_id: number;
            company_id: number;
            id: number;
        }[];
    }>(`/app/admin/subscriptions${QS.query(QS.explode({
        company_id: companyId,
        user_id: userId
    }))}`, {
        ...opts
    }));
}
export function deleteSubscriptions(id: number, opts?: Oazapfts.RequestOpts) {
    return oazapfts.ok(oazapfts.fetchText(`/app/admin/subscriptions/${encodeURIComponent(id)}`, {
        ...opts,
        method: "DELETE"
    }));
}
export function login(loginCredentials: LoginCredentials, opts?: Oazapfts.RequestOpts) {
    return oazapfts.ok(oazapfts.fetchJson<{
        status: 200;
        data: LoginResult;
    } | {
        status: 403;
        data: CredentialErrors;
    }>("/app/login", oazapfts.json({
        ...opts,
        method: "POST",
        body: loginCredentials
    })));
}
export function logout(opts?: Oazapfts.RequestOpts) {
    return oazapfts.ok(oazapfts.fetchText("/app/logout", {
        ...opts
    }));
}
export function getInvite(token: string, opts?: Oazapfts.RequestOpts) {
    return oazapfts.ok(oazapfts.fetchJson<{
        status: 200;
        data: {
            email: any;
            company: any;
        };
    }>(`/app/invites/${encodeURIComponent(token)}`, {
        ...opts
    }));
}
export function acceptInvite(token: string, acceptInviteArgs: AcceptInviteArgs, opts?: Oazapfts.RequestOpts) {
    return oazapfts.ok(oazapfts.fetchJson<{
        status: 200;
        data: LoginResult;
    } | {
        status: 422;
        data: CredentialErrors;
    }>(`/app/invites/${encodeURIComponent(token)}`, oazapfts.json({
        ...opts,
        method: "PUT",
        body: acceptInviteArgs
    })));
}
export function getSubcontractorInvite(token: string, opts?: Oazapfts.RequestOpts) {
    return oazapfts.ok(oazapfts.fetchJson<{
        status: 200;
        data: any;
    }>(`/app/invites/subcontractor/${encodeURIComponent(token)}`, {
        ...opts
    }));
}
export function confirmSubcontractorInvite(token: string, confirmSubcontractorInviteArgs: ConfirmSubcontractorInviteArgs, opts?: Oazapfts.RequestOpts) {
    return oazapfts.ok(oazapfts.fetchJson<{
        status: 200;
        data: any;
    } | {
        status: 422;
        data: CredentialErrors;
    }>(`/app/invites/subcontractor/confirm/${encodeURIComponent(token)}`, oazapfts.json({
        ...opts,
        method: "PUT",
        body: confirmSubcontractorInviteArgs
    })));
}
export function registerSubcontractorInvite(token: string, registerSubcontractorInviteArgs: RegisterSubcontractorInviteArgs, opts?: Oazapfts.RequestOpts) {
    return oazapfts.ok(oazapfts.fetchJson<{
        status: 200;
        data: LoginResult;
    } | {
        status: 422;
        data: CredentialErrors;
    }>(`/app/invites/subcontractor/register/${encodeURIComponent(token)}`, oazapfts.json({
        ...opts,
        method: "PUT",
        body: registerSubcontractorInviteArgs
    })));
}
export function getUser(opts?: Oazapfts.RequestOpts) {
    return oazapfts.ok(oazapfts.fetchJson<{
        status: 200;
        data: NormalisedUser;
    }>("/app/user", {
        ...opts
    }));
}
export function updateUser(authUserArgs: AuthUserArgs, opts?: Oazapfts.RequestOpts) {
    return oazapfts.ok(oazapfts.fetchJson<{
        status: 200;
        data: NormalisedUser;
    }>("/app/user", oazapfts.json({
        ...opts,
        method: "PUT",
        body: authUserArgs
    })));
}
export function requestPasswordReset(requestPasswordResetArgs: RequestPasswordResetArgs, opts?: Oazapfts.RequestOpts) {
    return oazapfts.ok(oazapfts.fetchJson<{
        status: 204;
    } | {
        status: 422;
        data: RequestPasswordResetErrors;
    }>("/app/password-reset", oazapfts.json({
        ...opts,
        method: "POST",
        body: requestPasswordResetArgs
    })));
}
export function resetPassword(passwordResetArgs: PasswordResetArgs, opts?: Oazapfts.RequestOpts) {
    return oazapfts.ok(oazapfts.fetchJson<{
        status: 200;
        data: LoginResult;
    } | {
        status: 422;
        data: PasswordResetErrors;
    }>("/app/password-reset", oazapfts.json({
        ...opts,
        method: "PUT",
        body: passwordResetArgs
    })));
}
/**
 * Triggers the auto update process for the boxes.
 * This endpoint is intended to be called by a cronjob.
 */
export function triggerAutoUpdate(opts?: Oazapfts.RequestOpts) {
    return oazapfts.ok(oazapfts.fetchText("/box-software-update/internal/auto", {
        ...opts
    }));
}
export function getBoxSoftwareStatuses(opts?: Oazapfts.RequestOpts) {
    return oazapfts.ok(oazapfts.fetchJson<{
        status: 200;
        data: SoftwareStatusResponse;
    }>("/app/box-software/statuses", {
        ...opts
    }));
}
export function changeUpdateMode(updateModeArgs: UpdateModeArgs, opts?: Oazapfts.RequestOpts) {
    return oazapfts.ok(oazapfts.fetchText("/app/box-software/update-mode", oazapfts.json({
        ...opts,
        method: "PUT",
        body: updateModeArgs
    })));
}
export function updateVersion(boxId: string, opts?: Oazapfts.RequestOpts) {
    return oazapfts.ok(oazapfts.fetchText(`/app/box-software/update-version/${encodeURIComponent(boxId)}`, {
        ...opts,
        method: "PUT"
    }));
}
export function getBuildings(opts?: Oazapfts.RequestOpts) {
    return oazapfts.ok(oazapfts.fetchJson<{
        status: 200;
        data: {
            photo: string;
            longitude: number;
            latitude: number;
            country: string;
            city: string;
            streetNumber: string;
            street: string;
            name: string;
            company_id: number;
            id: number;
        }[];
    }>("/app/buildings", {
        ...opts
    }));
}
export function addBuilding(buildingArgs: BuildingArgs, opts?: Oazapfts.RequestOpts) {
    return oazapfts.ok(oazapfts.fetchJson<{
        status: 200;
        data: {
            photo: string;
            longitude: number;
            latitude: number;
            country: string;
            city: string;
            streetNumber: string;
            street: string;
            name: string;
            company_id: number;
            id: number;
        };
    }>("/app/buildings", oazapfts.json({
        ...opts,
        method: "POST",
        body: buildingArgs
    })));
}
export function addFavoriteBuilding(id: number, opts?: Oazapfts.RequestOpts) {
    return oazapfts.ok(oazapfts.fetchJson<{
        status: 200;
        data: Building;
    }>(`/app/buildings/favorites/${encodeURIComponent(id)}`, {
        ...opts,
        method: "POST"
    }));
}
export function removeFavoriteBuilding(id: number, opts?: Oazapfts.RequestOpts) {
    return oazapfts.ok(oazapfts.fetchJson<{
        status: 200;
        data: Building;
    }>(`/app/buildings/favorites/${encodeURIComponent(id)}`, {
        ...opts,
        method: "DELETE"
    }));
}
export function getBuilding(id: number, opts?: Oazapfts.RequestOpts) {
    return oazapfts.ok(oazapfts.fetchJson<{
        status: 200;
        data: {
            photo: string;
            longitude: number;
            latitude: number;
            country: string;
            city: string;
            streetNumber: string;
            street: string;
            name: string;
            company_id: number;
            id: number;
            lifts: ({
                installationControl: {
                    esn: string;
                    runs: number;
                    share_key: string;
                    started_at: string;
                    failures: number;
                    status: EnumsInstallationControlStatus;
                    starting_floor: string;
                    math_id: number;
                    lift_id: number;
                };
            } & {
                isPmSetUp: boolean;
                carDoorCounter: number;
                lastTimeDoorElectronicsChange: string;
                lastMaintenanceScope: number[];
                doorInstallationDate: string;
                lastMaintenanceDate: string;
                elevatorInstallationDate: string;
                areAllPartsSelected: boolean;
                doorModel: string;
                doorType: string;
                groundFloor: number;
                static_data: JsonValue;
                isSetUp: boolean;
                floors: number;
                box_id: string;
                building_id: number;
                doorSerialNumber: string;
                assetNumber: string;
                name: string;
                id: number;
            })[];
            isFavorite: boolean;
        };
    }>(`/app/buildings/${encodeURIComponent(id)}`, {
        ...opts
    }));
}
export function updateBuilding(id: number, partialBuildingArgs: PartialBuildingArgs, opts?: Oazapfts.RequestOpts) {
    return oazapfts.ok(oazapfts.fetchText(`/app/buildings/${encodeURIComponent(id)}`, oazapfts.json({
        ...opts,
        method: "PUT",
        body: partialBuildingArgs
    })));
}
export function deleteBuilding(id: number, opts?: Oazapfts.RequestOpts) {
    return oazapfts.ok(oazapfts.fetchText(`/app/buildings/${encodeURIComponent(id)}`, {
        ...opts,
        method: "DELETE"
    }));
}
export function getLifts(id: number, opts?: Oazapfts.RequestOpts) {
    return oazapfts.ok(oazapfts.fetchJson<{
        status: 200;
        data: FullLift[];
    }>(`/app/buildings/${encodeURIComponent(id)}/lifts`, {
        ...opts
    }));
}
export function addLift(id: number, liftArgs: LiftArgs, opts?: Oazapfts.RequestOpts) {
    return oazapfts.ok(oazapfts.fetchJson<{
        status: 200;
        data: {
            isPmSetUp: boolean;
            carDoorCounter: number;
            lastTimeDoorElectronicsChange: string;
            lastMaintenanceScope: number[];
            doorInstallationDate: string;
            lastMaintenanceDate: string;
            elevatorInstallationDate: string;
            areAllPartsSelected: boolean;
            doorModel: string;
            doorType: string;
            groundFloor: number;
            static_data: JsonValue;
            isSetUp: boolean;
            floors: number;
            box_id: string;
            building_id: number;
            doorSerialNumber: string;
            assetNumber: string;
            name: string;
            id: number;
        };
    }>(`/app/buildings/${encodeURIComponent(id)}/lifts`, oazapfts.json({
        ...opts,
        method: "POST",
        body: liftArgs
    })));
}
export function getBuildingNotifications(id: number, opts?: Oazapfts.RequestOpts) {
    return oazapfts.ok(oazapfts.fetchJson<{
        status: 200;
        data: {
            lift_id: number;
            resolved: boolean;
            "type": ErrorType;
            message: string;
            date: string;
            box_id: string;
        }[];
    }>(`/app/buildings/${encodeURIComponent(id)}/notifications`, {
        ...opts
    }));
}
export function shareBuildingWithSubcontractor(subcontractorId: number, body: {
    buildingId: number;
}, opts?: Oazapfts.RequestOpts) {
    return oazapfts.ok(oazapfts.fetchText(`/app/buildings/subcontractors/${encodeURIComponent(subcontractorId)}/buildings`, oazapfts.json({
        ...opts,
        method: "POST",
        body
    })));
}
export function unshareBuildingWithSubcontractor(subcontractorId: number, buildingId: number, opts?: Oazapfts.RequestOpts) {
    return oazapfts.ok(oazapfts.fetchText(`/app/buildings/subcontractors/${encodeURIComponent(subcontractorId)}/shared-buildings/${encodeURIComponent(buildingId)}`, {
        ...opts,
        method: "DELETE"
    }));
}
export function getSharedBuildings(subcontractorId: number, opts?: Oazapfts.RequestOpts) {
    return oazapfts.ok(oazapfts.fetchJson<{
        status: 200;
        data: {
            lifts: {
                isPmSetUp: boolean;
                carDoorCounter: number;
                lastTimeDoorElectronicsChange: string;
                lastMaintenanceScope: number[];
                doorInstallationDate: string;
                lastMaintenanceDate: string;
                elevatorInstallationDate: string;
                areAllPartsSelected: boolean;
                doorModel: string;
                doorType: string;
                groundFloor: number;
                static_data: JsonValue;
                isSetUp: boolean;
                floors: number;
                box_id: string;
                building_id: number;
                doorSerialNumber: string;
                assetNumber: string;
                name: string;
                id: number;
            }[];
            building: {
                lifts: {
                    isPmSetUp: boolean;
                    carDoorCounter: number;
                    lastTimeDoorElectronicsChange: string;
                    lastMaintenanceScope: number[];
                    doorInstallationDate: string;
                    lastMaintenanceDate: string;
                    elevatorInstallationDate: string;
                    areAllPartsSelected: boolean;
                    doorModel: string;
                    doorType: string;
                    groundFloor: number;
                    static_data: JsonValue;
                    isSetUp: boolean;
                    floors: number;
                    box_id: string;
                    building_id: number;
                    doorSerialNumber: string;
                    assetNumber: string;
                    name: string;
                    id: number;
                }[];
            } & {
                photo: string;
                longitude: number;
                latitude: number;
                country: string;
                city: string;
                streetNumber: string;
                street: string;
                name: string;
                company_id: number;
                id: number;
            };
        }[];
    }>(`/app/buildings/subcontractors/${encodeURIComponent(subcontractorId)}/shared-buildings`, {
        ...opts
    }));
}
export function getAllBuildingsWithShareStatus(subcontractorId: number, opts?: Oazapfts.RequestOpts) {
    return oazapfts.ok(oazapfts.fetchJson<{
        status: 200;
        data: BuildingWithShared[];
    }>(`/app/buildings/subcontractors/${encodeURIComponent(subcontractorId)}/all-buildings`, {
        ...opts
    }));
}
export function getMembers(opts?: Oazapfts.RequestOpts) {
    return oazapfts.ok(oazapfts.fetchJson<{
        status: 200;
        data: NormalisedUser[] | {
            dateTermsAccepted: string;
            locale: UserLocale;
            company_id: number;
            role: Role;
            password: string;
            email: string;
            lastName: string;
            firstName: string;
            id: number;
        }[];
    }>("/app/company/members", {
        ...opts
    }));
}
export function getMember(id: number, opts?: Oazapfts.RequestOpts) {
    return oazapfts.ok(oazapfts.fetchJson<{
        status: 200;
        data: NormalisedUser;
    }>(`/app/company/members/${encodeURIComponent(id)}`, {
        ...opts
    }));
}
export function updateMember(id: number, userArgs: UserArgs, opts?: Oazapfts.RequestOpts) {
    return oazapfts.ok(oazapfts.fetchText(`/app/company/members/${encodeURIComponent(id)}`, oazapfts.json({
        ...opts,
        method: "PUT",
        body: userArgs
    })));
}
export function deleteMember(id: number, opts?: Oazapfts.RequestOpts) {
    return oazapfts.ok(oazapfts.fetchText(`/app/company/members/${encodeURIComponent(id)}`, {
        ...opts,
        method: "DELETE"
    }));
}
export function getBoxes2(opts?: Oazapfts.RequestOpts) {
    return oazapfts.ok(oazapfts.fetchJson<{
        status: 200;
        data: {
            updateMode: UpdateMode;
            static_data: JsonValue;
            company_id: number;
            id: string;
        }[];
    }>("/app/company/boxes", {
        ...opts
    }));
}
export function getBox(id: string, opts?: Oazapfts.RequestOpts) {
    return oazapfts.ok(oazapfts.fetchJson<{
        status: 200;
        data: any;
    }>(`/app/company/boxes/${encodeURIComponent(id)}`, {
        ...opts
    }));
}
export function updateBoxStaticData(id: string, opts?: Oazapfts.RequestOpts) {
    return oazapfts.ok(oazapfts.fetchText(`/app/company/boxes/${encodeURIComponent(id)}/static-data`, {
        ...opts,
        method: "PUT"
    }));
}
export function getNotifications(opts?: Oazapfts.RequestOpts) {
    return oazapfts.ok(oazapfts.fetchJson<{
        status: 200;
        data: ({
            box: {
                lift: {
                    building: {
                        photo: string;
                        longitude: number;
                        latitude: number;
                        country: string;
                        city: string;
                        streetNumber: string;
                        street: string;
                        name: string;
                        company_id: number;
                        id: number;
                    };
                } & {
                    isPmSetUp: boolean;
                    carDoorCounter: number;
                    lastTimeDoorElectronicsChange: string;
                    lastMaintenanceScope: number[];
                    doorInstallationDate: string;
                    lastMaintenanceDate: string;
                    elevatorInstallationDate: string;
                    areAllPartsSelected: boolean;
                    doorModel: string;
                    doorType: string;
                    groundFloor: number;
                    static_data: JsonValue;
                    isSetUp: boolean;
                    floors: number;
                    box_id: string;
                    building_id: number;
                    doorSerialNumber: string;
                    assetNumber: string;
                    name: string;
                    id: number;
                };
            } & {
                updateMode: UpdateMode;
                static_data: JsonValue;
                company_id: number;
                id: string;
            };
        } & {
            lift_id: number;
            resolved: boolean;
            "type": ErrorType;
            message: string;
            date: string;
            box_id: string;
        })[];
    }>("/app/company/notifications", {
        ...opts
    }));
}
export function getInvites(opts?: Oazapfts.RequestOpts) {
    return oazapfts.ok(oazapfts.fetchJson<{
        status: 200;
        data: {
            company_id: number;
            date: string;
            token: string;
            role: Role;
            email: string;
            id: number;
        }[];
    }>("/app/company/invites", {
        ...opts
    }));
}
export function invite(inviteArgs: InviteArgs, opts?: Oazapfts.RequestOpts) {
    return oazapfts.ok(oazapfts.fetchJson<{
        status: 200;
        data: any;
    } | {
        status: 422;
        data: InviteErrors;
    }>("/app/company/invites", oazapfts.json({
        ...opts,
        method: "POST",
        body: inviteArgs
    })));
}
export function cancelInvite(id: number, opts?: Oazapfts.RequestOpts) {
    return oazapfts.ok(oazapfts.fetchText(`/app/company/invites/${encodeURIComponent(id)}`, {
        ...opts,
        method: "DELETE"
    }));
}
export function getCompanyLicenses({ order, product }: {
    order?: LicenseOrder;
    product?: EnumsProduct;
} = {}, opts?: Oazapfts.RequestOpts) {
    return oazapfts.ok(oazapfts.fetchJson<{
        status: 200;
        data: {
            updatedAt: string;
            createdAt: string;
            token: string;
            maxElectronics: number;
            maxUsage: number;
            usage: number;
            validUntil: string;
            validFrom: string;
            product: EnumsProduct;
            company_id: number;
            box_id: string;
            id: string;
        }[];
    }>(`/app/company/licenses${QS.query(QS.explode({
        order,
        product
    }))}`, {
        ...opts
    }));
}
export function getSubscribedCompanyNotifications(opts?: Oazapfts.RequestOpts) {
    return oazapfts.ok(oazapfts.fetchJson<{
        status: 200;
        data: {
            createdAt: string;
            scope: SubscriptionScope;
            "type": SubscriptionType;
            user_id: number;
            company_id: number;
            id: number;
        }[];
    }>("/app/company/subscriptions", {
        ...opts
    }));
}
export function subscribeCompanyNotifications(subscribeArgs: SubscribeArgs, opts?: Oazapfts.RequestOpts) {
    return oazapfts.ok(oazapfts.fetchText("/app/company/subscriptions", oazapfts.json({
        ...opts,
        method: "POST",
        body: subscribeArgs
    })));
}
export function unsubscribeCompanyNotifications(id: number, opts?: Oazapfts.RequestOpts) {
    return oazapfts.ok(oazapfts.fetchText(`/app/company/subscriptions/${encodeURIComponent(id)}`, {
        ...opts,
        method: "DELETE"
    }));
}
export function isSubcontractor(opts?: Oazapfts.RequestOpts) {
    return oazapfts.ok(oazapfts.fetchJson<{
        status: 200;
        data: {
            contractorName: string;
            isSubcontractor: boolean;
        };
    }>("/app/company/company/is-subcontractor", {
        ...opts
    }));
}
export function getContractor(opts?: Oazapfts.RequestOpts) {
    return oazapfts.ok(oazapfts.fetchJson<{
        status: 200;
        data: {
            id: number;
            name: string;
        };
    }>("/app/company/contractor", {
        ...opts
    }));
}
export function deleteContractor(opts?: Oazapfts.RequestOpts) {
    return oazapfts.ok(oazapfts.fetchJson<{
        status: 200;
        data: {
            message: string;
        };
    }>("/app/company/contractor", {
        ...opts,
        method: "DELETE"
    }));
}
export function inviteSubcontractor(subcontractorInviteArgs: SubcontractorInviteArgs, opts?: Oazapfts.RequestOpts) {
    return oazapfts.ok(oazapfts.fetchJson<{
        status: 200;
        data: any;
    } | {
        status: 422;
        data: InviteErrors;
    }>("/app/company/subcontractors/invite", oazapfts.json({
        ...opts,
        method: "POST",
        body: subcontractorInviteArgs
    })));
}
export function getSubcontractors(opts?: Oazapfts.RequestOpts) {
    return oazapfts.ok(oazapfts.fetchJson<{
        status: 200;
        data: {
            contractor_id: number;
            witturName: string;
            witturCode: string;
            language: string;
            sellingEntity: string;
            customerRef: string;
            name: string;
            id: number;
        }[];
    }>("/app/company/subcontractors", {
        ...opts
    }));
}
export function getSubcontractorInvites(opts?: Oazapfts.RequestOpts) {
    return oazapfts.ok(oazapfts.fetchJson<{
        status: 200;
        data: {
            invited_company_id: number;
            inviting_company_id: number;
            invitedOn: string;
            token: string;
            email: string;
            id: number;
        }[];
    }>("/app/company/subcontractors/invites", {
        ...opts
    }));
}
export function cancelSubcontractorInvites(id: number, opts?: Oazapfts.RequestOpts) {
    return oazapfts.ok(oazapfts.fetchText(`/app/company/subcontractors/invites/${encodeURIComponent(id)}`, {
        ...opts,
        method: "DELETE"
    }));
}
export function deleteSubcontractor(id: number, opts?: Oazapfts.RequestOpts) {
    return oazapfts.ok(oazapfts.fetchText(`/app/company/subcontractors/${encodeURIComponent(id)}`, {
        ...opts,
        method: "DELETE"
    }));
}
export function shareBuildingWithSubcontractor2(subcontractorId: number, body: {
    buildingId: number;
}, opts?: Oazapfts.RequestOpts) {
    return oazapfts.ok(oazapfts.fetchText(`/app/company/subcontractors/${encodeURIComponent(subcontractorId)}/buildings`, oazapfts.json({
        ...opts,
        method: "POST",
        body
    })));
}
export function unshareBuildingWithSubcontractor2(subcontractorId: number, buildingId: number, opts?: Oazapfts.RequestOpts) {
    return oazapfts.ok(oazapfts.fetchText(`/app/company/subcontractors/${encodeURIComponent(subcontractorId)}/buildings/${encodeURIComponent(buildingId)}`, {
        ...opts,
        method: "DELETE"
    }));
}
export function getSharedBuildings2(opts?: Oazapfts.RequestOpts) {
    return oazapfts.ok(oazapfts.fetchJson<{
        status: 200;
        data: {
            lifts: {
                isPmSetUp: boolean;
                carDoorCounter: number;
                lastTimeDoorElectronicsChange: string;
                lastMaintenanceScope: number[];
                doorInstallationDate: string;
                lastMaintenanceDate: string;
                elevatorInstallationDate: string;
                areAllPartsSelected: boolean;
                doorModel: string;
                doorType: string;
                groundFloor: number;
                static_data: JsonValue;
                isSetUp: boolean;
                floors: number;
                box_id: string;
                building_id: number;
                doorSerialNumber: string;
                assetNumber: string;
                name: string;
                id: number;
            }[];
            building: {
                lifts: {
                    isPmSetUp: boolean;
                    carDoorCounter: number;
                    lastTimeDoorElectronicsChange: string;
                    lastMaintenanceScope: number[];
                    doorInstallationDate: string;
                    lastMaintenanceDate: string;
                    elevatorInstallationDate: string;
                    areAllPartsSelected: boolean;
                    doorModel: string;
                    doorType: string;
                    groundFloor: number;
                    static_data: JsonValue;
                    isSetUp: boolean;
                    floors: number;
                    box_id: string;
                    building_id: number;
                    doorSerialNumber: string;
                    assetNumber: string;
                    name: string;
                    id: number;
                }[];
            } & {
                photo: string;
                longitude: number;
                latitude: number;
                country: string;
                city: string;
                streetNumber: string;
                street: string;
                name: string;
                company_id: number;
                id: number;
            };
        }[];
    }>("/app/company/subcontractors/shared-buildings", {
        ...opts
    }));
}
export function addPurchase(purchaseArgs: PurchaseArgs, opts?: Oazapfts.RequestOpts) {
    return oazapfts.ok(oazapfts.fetchJson<{
        status: 200;
        data: AddPurchaseResult;
    }>("/erp/purchases", oazapfts.json({
        ...opts,
        method: "POST",
        body: purchaseArgs
    })));
}
export function sendSummaryNotificationEmails(opts?: Oazapfts.RequestOpts) {
    return oazapfts.ok(oazapfts.fetchText("/notification/internal/summary-email-notification", {
        ...opts
    }));
}
