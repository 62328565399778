import styled from 'styled-components';

export const Stack = styled.div<{ gap?: string }>`
  display: flex;
  flex-direction: column;
  & > * {
    margin-top: 0;
    margin-bottom: 0;
  }
  & > * + * {
    margin-top: ${(p) => p.gap ?? '1em'} !important;
  }
`;
