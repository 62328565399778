import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { BuildingHeader } from '../components/building/BuildingHeader';
import { ErrorList } from '../components/common/ErrorList';
import { Page } from '../components/common/Page';
import { useApiQuery } from '../hooks/useApi';
import { useTranslation } from '../I18nProvider';

export function BuildingErrors() {
  const t = useTranslation();
  const navigate = useNavigate();
  const params = useParams();
  const id = parseInt(params.buildingId!);
  const query = useApiQuery('getBuildingNotifications', id);

  return (
    <Page
      pageTitle={t.errorMessages}
      header={<BuildingHeader id={id} />}
      onBack={() => navigate(-1)}
    >
      <ErrorList errors={query.data ?? []} />
    </Page>
  );
}
