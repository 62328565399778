import React from 'react';
import { useParams } from 'react-router-dom';

import { Button } from '../components/common/Button';
import { Page } from '../components/common/Page';
import { Row } from '../components/common/Row';
import { Stack } from '../components/common/Stack';
import { Select } from '../components/form/Select';
import { useApiMutation, useApiQuery } from '../hooks/useApi';

export function AdminCompanyUsers() {
  const params = useParams();
  const companyId = parseInt(params.id!);

  const users = useApiQuery('getUsers', { companyId });

  const deleteUser = useApiMutation('deleteUser', {
    onSuccess: users.invalidate,
  });
  const updateUserAsAdmin = useApiMutation('updateUserAsAdmin', {
    onSuccess: users.invalidate,
  });

  return (
    <Page header="Admin" backLink="../" pageTitle="Users">
      <Stack>
        {users.data?.map((user) => (
          <Row key={user.id}>
            <div style={{ marginRight: 'auto' }}>{user.email}</div>
            <Select
              value={user.role!}
              onChange={(ev) =>
                updateUserAsAdmin(user.id, { role: ev.target.value as any })
              }
            >
              <option value="user">User</option>
              <option value="manager">Manager</option>
              <option value="admin">Admin</option>
            </Select>
            <Button
              size="small"
              variant="secondary"
              onClick={() => deleteUser(user.id)}
            >
              Delete
            </Button>
          </Row>
        ))}
      </Stack>
    </Page>
  );
}
