import React from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';

import { BuildingHeader } from '../components/building/BuildingHeader';
import { Button } from '../components/common/Button';
import { Page } from '../components/common/Page';
import { Row } from '../components/common/Row';
import { Stack } from '../components/common/Stack';
import { Form } from '../components/form/Form';
import { InputField } from '../components/form/InputField';
import { useApiQuery } from '../hooks/useApi';
import { useFormSubmission } from '../hooks/useFormSubmission';
import { useTranslation } from '../I18nProvider';
import { addLift, LiftArgs } from '../utils/api';

export function AddLift() {
  const t = useTranslation();
  const params = useParams();
  const buildingId = parseInt(params.buildingId!);
  const query = useApiQuery('getBuilding', buildingId);
  const navigate = useNavigate();

  const { form, register } = useFormSubmission<LiftArgs>({
    async onSubmit(values) {
      const { id } = await addLift(buildingId, values);
      navigate(`./${id}`);
    },
  });

  return (
    <Page
      header={<BuildingHeader id={buildingId} />}
      pageTitle={t.addLift}
      backLink="../../"
    >
      {query.isSuccess && (
        <Form {...form}>
          <Stack>
            <InputField
              register={register}
              name="name"
              placeholder={t.name}
              defaultValue={`${t.lift} ${query.data.lifts.length + 1}`}
              required
            />

            <InputField
              register={register}
              name="floors"
              type="number"
              defaultValue="2"
              placeholder={t.floors}
              options={{ valueAsNumber: true, min: 2 }}
            />
            <InputField
              register={register}
              name="assetNumber"
              placeholder={t.assetNumber}
            >
              {t.assetNumberHint}
            </InputField>
            <Row>
              <Button as={Link} to="../../" variant="secondary">
                {t.cancel}
              </Button>
              <Button variant="primary">{t.continue}</Button>
            </Row>
          </Stack>
        </Form>
      )}
    </Page>
  );
}
