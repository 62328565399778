export type TriggerHandler = (...args: any[]) => Promise<void> | void;

export function trigger(handler: TriggerHandler[], ...args: any[]) {
  setTimeout(() => {
    try {
      handler.forEach(async (h) => {
        const result = h(...args);
        if (result instanceof Promise) {
          await result;
        }
      });
    } catch (error) {
      // noop
    }
  });
}
