import React from 'react';
import styled from 'styled-components';

import logo from './logo.svg';

const Img = styled.img`
  margin: auto;
`;

export function Logo() {
  return <Img src={logo} width="100%" height="46" />;
}
