import React, { Fragment } from 'react';
import styled from 'styled-components';

import { HorizontalScroll } from '../../components/common/HorizontalScroll';
import { useDateFormat } from '../../hooks/useDateFormat';
import { useTranslation } from '../../I18nProvider';
import { color, fontSize, fontWeight, space } from '../../tokens';
import { InstallationRun } from '../../utils/api';
import { RunCard } from './RunCard';

const StyledHorizontalScroll = styled(HorizontalScroll)`
  padding-top: ${space[7]};
  margin-bottom: ${space[2]};
`;

const DaySeparator = styled.div`
  position: relative;
  width: 1px;
  padding-left: 1px;
  background: ${color.line};
  margin-top: -1.3em;
  align-self: stretch;
`;

const Day = styled.div`
  position: absolute;
  top: 0;
  left: 1ch;
  color: ${color.cardText};
  font-size: ${fontSize.xs};
  font-weight: ${fontWeight.bold};
`;

type RunsProps = {
  runs: Partial<InstallationRun>[];
  selected?: Partial<InstallationRun>;
  onSelect: (selected: Partial<InstallationRun>) => void;
};
export function Runs({ runs, selected, onSelect }: RunsProps) {
  const t = useTranslation();
  const { formatDate } = useDateFormat();

  if (runs.length === 0) {
    return <p>{t.installationControlHistoryEmpty}</p>;
  }

  return (
    <StyledHorizontalScroll>
      {runs.map((r, i) => {
        const date = formatDate(r.date);
        const lastDate = formatDate(runs[i - 1]?.date);
        const active = r.run === selected?.run;
        return (
          <Fragment key={i}>
            {date !== lastDate && (
              <DaySeparator>
                <Day>{formatDate(r.date)}</Day>
              </DaySeparator>
            )}
            <RunCard
              ref={active ? scrollIntoView : undefined}
              data={r}
              active={active}
              onClick={() => onSelect(r)}
            />
          </Fragment>
        );
      })}
    </StyledHorizontalScroll>
  );
}

const scrollIntoView = (el: HTMLElement | null) => {
  if (el) el.scrollIntoView({ behavior: 'smooth', inline: 'center' });
};
