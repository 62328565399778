import React from 'react';
import styled from 'styled-components';

import { fontWeight, space } from '../../tokens';
import { DriveParameterValue } from '../../utils/programming/BoxApi';

const Title = styled.span`
  margin-right: ${space[1]};
`;

const Value = styled.span`
  font-weight: ${fontWeight.bold};
`;

type Props = {
  title: string;
  param: DriveParameterValue | undefined;
};

export function DisplayParameter({ title, param }: Props) {
  return (
    <span>
      <Title>{title}:</Title>
      <Value>
        {param?.value} {param?.unit}
      </Value>
    </span>
  );
}
