import { useQuery } from '@tanstack/react-query';
import React from 'react';

import { Button } from '../../components/common/Button';
import { Stack } from '../../components/common/Stack';
import { useApiMutation } from '../../hooks/useApi';
import { useTranslation } from '../../I18nProvider';
import {
  FullInstallationControl,
  getInstallationControl,
} from '../../utils/appApi';
import { ResultPanels } from './ResultPanels';

type Props = {
  id: number;
  onFinish: (ic: FullInstallationControl) => any;
};
export function PollResults({ id, onFinish }: Props) {
  const t = useTranslation();
  const query = useQuery(
    ['installation', id],
    async () => {
      const ic = await getInstallationControl(id);
      if (ic.status !== 'started') {
        onFinish(ic);
      }
      return ic;
    },
    {
      refetchInterval: 2000,
    }
  );
  const abort = useApiMutation('abortInstallationControl', {
    onSuccess: query.refetch,
  });
  return query.data ? (
    <Stack>
      <ResultPanels installationControl={query.data} />
      <Button onClick={() => abort(id)}>{t.cancel}</Button>
    </Stack>
  ) : null;
}
