import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { color, fontSize, fontWeight, space } from '../../tokens';

export const PageTitle = styled.h2`
  font-weight: 600;
  font-size: ${fontSize.lg};
`;

export const SubHeadline = styled.h3`
  font-weight: 600;
  font-size: ${fontSize.xs};
  color: ${color.info};
`;

export const HeaderTitle = styled.h1`
  font-size: ${fontSize.xl};
  font-weight: ${fontWeight.normal};
  letter-spacing: 0.2px;
`;

export const CardTitle = styled.div`
  font-weight: ${fontWeight.bold};
  font-size: ${fontSize.base};
  line-height: 1;
`;

export const CardSubTitle = styled.span`
  font-weight: ${fontWeight.normal};
  padding-left: ${space[1]};
`;

export const BodyText = styled.div`
  font-weight: ${fontWeight.normal};
  font-size: ${fontSize.base};
  line-height: 1.2;
`;

export const Overline = styled.div`
  font-size: ${fontSize.xs};
  line-height: 1;
  letter-spacing: 0.1px;
  -webkit-font-smoothing: auto;
`;

export const SectionHeading = styled.div`
  font-size: ${fontSize.xs};
  font-weight: 600;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  -webkit-font-smoothing: auto;
`;

//Error messages --- small bold
export const TextS = styled.div`
  font-size: ${fontSize.xs};
  letter-spacing: 0.1px;
  font-weight: ${fontWeight.bold};
  -webkit-font-smoothing: auto;
`;

// --- small medium
export const TextXs = styled.div`
  font-size: ${fontSize.xs};
  font-weight: ${fontWeight.medium};
  -webkit-font-smoothing: auto;
`;

// REVISIT: Remove me
export const TextLink = styled(Link)`
  text-decoration: none;
  color: ${color.primary};
  font-weight: 600;
`;

// REVISIT: Remove me
export const TinyLink = styled(Link)`
  text-decoration: none;
  color: ${color.primary};
  font-size: ${fontSize.xs};
  font-weight: ${fontWeight.bold};
  letter-spacing: 0.1px;
  text-transform: uppercase;
`;
