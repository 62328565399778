import React, { ChangeEvent } from 'react';

import { Hint } from '../components/common/Hint';
import { Loading } from '../components/common/Loading';
import { Page } from '../components/common/Page';
import { Spacer } from '../components/common/Spacer';
import { Stack } from '../components/common/Stack';
import { Dropdown } from '../components/form/Dropdown';
import { useApiMutation, useApiQuery } from '../hooks/useApi';
import { useTranslation } from '../I18nProvider';
import {
  SubscribeArgs,
  SubscriptionScope,
  SubscriptionType,
} from '../utils/appApi';
import { Subscription } from '../utils/types';

type SubscriptionDropDownProps = {
  type: SubscriptionType;
  label: string;
  subscription?: Subscription;
  onSubscribe: (args: SubscribeArgs) => void;
  onUnsubscribe: (id: number) => void;
};

function SubscriptionDropDown({
  type,
  label,
  subscription,
  onSubscribe,
  onUnsubscribe,
}: SubscriptionDropDownProps) {
  const t = useTranslation();
  const { id, scope } = subscription || {};
  const onChange = (ev: ChangeEvent<HTMLSelectElement>) => {
    const scope = ev.target.value as SubscriptionScope | 'none';
    if (scope !== 'none') {
      onSubscribe({ type, scope });
    } else if (id) {
      onUnsubscribe(id);
    }
  };

  return (
    <Dropdown label={label} onChange={onChange} value={scope || 'none'}>
      <option value="none">{t.noSubscription}</option>
      <option value="all">{t.allBuildingsSubscription}</option>
      <option value="favorites">{t.myBuildingsSubscription}</option>
    </Dropdown>
  );
}

export function Subscriptions() {
  const t = useTranslation();

  const subscribedNotifications = useApiQuery(
    'getSubscribedCompanyNotifications'
  );
  const subscribe = useApiMutation('subscribeCompanyNotifications', {
    onSuccess: subscribedNotifications.invalidate,
  });
  const onSubscribe = async (args: SubscribeArgs) => await subscribe(args);

  const unsubscribe = useApiMutation('unsubscribeCompanyNotifications', {
    onSuccess: subscribedNotifications.invalidate,
  });
  const onUnsubscribe = async (id: number) => await unsubscribe(id);

  const getState = (type: string) =>
    subscribedNotifications.data?.find((v) => v.type === type);

  return (
    <Page pageTitle={t.subscriptions} backLink="/">
      <Stack>
        {subscribedNotifications.isLoading && <Loading />}
        {subscribedNotifications.isSuccess && (
          <>
            <p>{t.subscriptionText}</p>
            <SubscriptionDropDown
              type={'summary'}
              label={t.summarySubscription}
              subscription={getState('summary')}
              onSubscribe={onSubscribe}
              onUnsubscribe={onUnsubscribe}
            />
            <SubscriptionDropDown
              type={'instant'}
              label={t.instantSubscription}
              subscription={getState('instant')}
              onSubscribe={onSubscribe}
              onUnsubscribe={onUnsubscribe}
            />
            <span />
            <Hint>
              {t.subscriptionHint}
              <Spacer />
              {t.summarySubscriptionExplanation}
              <Spacer />
              {t.instantSubscriptionExplanation}
            </Hint>
          </>
        )}
      </Stack>
    </Page>
  );
}
