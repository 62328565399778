const addressNameFormat = {
  street_number: 'short_name',
  route: 'long_name',
  locality: 'long_name',
  administrative_area_level_1: 'short_name',
  country: 'long_name',
  postal_code: 'short_name',
} as const;

type ComponentType = keyof typeof addressNameFormat;

export function getAddressComp(
  place: google.maps.places.PlaceResult | google.maps.GeocoderResult,
  type: ComponentType
) {
  const { address_components } = place;
  if (address_components) {
    for (const component of address_components) {
      if (component.types[0] === type) {
        return component[addressNameFormat[type]];
      }
    }
  }
  return '';
}

export type Address = {
  name: string;
  street: string;
  streetNumber: string;
  city: string;
  country: string;
  latitude?: number;
  longitude?: number;
  photos: string[];
};

export function getAddress(place: google.maps.places.PlaceResult) {
  const photos =
    place.photos?.filter((p) => p.width > p.height).map(getPhotoUrl) ?? [];

  return {
    name: place.name ?? '',
    street: getAddressComp(place, 'route'),
    streetNumber: getAddressComp(place, 'street_number'),
    city: getAddressComp(place, 'locality'),
    country: getAddressComp(place, 'country'),
    latitude: place.geometry?.location?.lat(),
    longitude: place.geometry?.location?.lng(),
    photos,
  };
}

function getPhotoUrl(photo: google.maps.places.PlacePhoto) {
  const maxWidth = 800;
  const url = photo.getUrl({ maxWidth });
  return rewritePhotoServiceUrl(url, maxWidth);
}

/**
 * The URLs returned by PlacePhoto.getUrl() are only valid for a couple of days.
 * But we can extract the photo_reference and rewrite it. When adding a correct
 * key we can then place photo request with it.
 */
function rewritePhotoServiceUrl(url: string, maxWidth: number) {
  const match = /\?1s(.+?)&/.exec(url);
  if (match) {
    const [, ref] = match;
    return `https://maps.googleapis.com/maps/api/place/photo?photo_reference=${ref}&maxwidth=${maxWidth}`;
  }
  return url;
}

/**
 * Replaces or adds the correct key to the given place photo request urls/
 */
export function replaceMapKey(urlString?: string | null) {
  if (!urlString) {
    return;
  }

  const url = new URL(urlString);
  url.searchParams.set('key', import.meta.env.VITE_GOOGLE_API_KEY);
  return url.toString();
}
