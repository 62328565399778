import React from 'react';
import { Link } from 'react-router-dom';

import { Button } from '../components/common/Button';
import { Page } from '../components/common/Page';
import { Stack } from '../components/common/Stack';
import { useBoxApiMutation, useBoxApiQuery } from '../hooks/useBoxApi';
import { useTranslation } from '../I18nProvider';

export function Offline() {
  const t = useTranslation();

  const isApiConnected = useBoxApiQuery('isApiConnected');

  const disconnectApi = useBoxApiMutation('disconnectApi');

  return (
    <Page>
      <Stack>
        <p>{t.offlineConnection}</p>
        <Button as={Link} to="/programming" variant="primary">
          {t.programmingTool}
        </Button>
        {isApiConnected.data && (
          <Button onClick={disconnectApi} variant="secondary">
            {t.disconnectProgrammingTool}
          </Button>
        )}
      </Stack>
    </Page>
  );
}
