import React from 'react';
import { Navigate, useNavigate, useParams } from 'react-router-dom';

import { Loading } from '../components/common/Loading';
import { Page } from '../components/common/Page';
import { LiftHeader } from '../components/lift/LiftHeader';
import { useApiQuery } from '../hooks/useApi';
import { useTranslation } from '../I18nProvider';
import { isStatus } from '../utils/api';
import { startSetupRun } from '../utils/appApi';
import { NewForm } from './Installation/NewForm';
import { PollSetupProgress } from './Installation/PollSetupProgress';
import { TestInstructions } from './Installation/TestInstructions';

export function Setup() {
  const t = useTranslation();
  const navigate = useNavigate();
  const params = useParams();
  const id = parseInt(params.id!);
  const query = useApiQuery(
    {
      method: 'getInstallationControl',
      useErrorBoundary: false,
    },
    id
  );
  let content = null;

  if (query.isLoading) {
    content = <Loading />;
  } else if (query.isSuccess) {
    const status = query.data.status;
    if (status === 'initialized') {
      content = (
        <TestInstructions
          floor={query.data.starting_floor}
          onCloseDoor={async () => {
            await startSetupRun(id);
          }}
          onContinue={query.invalidate}
        />
      );
    }
    if (status === 'started') {
      content = <PollSetupProgress id={id} onFinish={query.invalidate} />;
    }
    if (status === 'completed') {
      if (query.data.lift.isSetUp) {
        content = (
          <Navigate
            to={`/building/${query.data.lift.building_id}/lift/${id}`}
          />
        );
      } else {
        content = <NewForm liftId={id} onSubmit={() => query.refetch()} />;
      }
    } else if (status === 'aborted') {
      content = <NewForm liftId={id} onSubmit={() => query.refetch()} />;
    }
  } else if (isStatus(query.error, 404)) {
    content = <NewForm liftId={id} onSubmit={() => query.refetch()} />;
  } else {
    throw query.error;
  }
  return (
    <Page
      header={<LiftHeader id={id} />}
      onBack={() => navigate(-1)}
      pageTitle={t.liftSetup}
    >
      {content}
    </Page>
  );
}
