import { useQuery } from '@tanstack/react-query';
import React from 'react';
import { useParams } from 'react-router-dom';

import { Page } from '../components/common/Page';
import { Stack } from '../components/common/Stack';
import { DataGrid } from '../components/DataGrid';
import { useTranslation } from '../I18nProvider';
import { getSharedInstallationControl } from '../utils/appApi';
import { ResultPanels } from './Installation/ResultPanels';

export function SharedResults() {
  const t = useTranslation();
  const params = useParams();
  const id = parseInt(params.id!);

  const { data: installationControl } = useQuery(
    ['shaderInstallation', id, params.key!],
    () => getSharedInstallationControl(id, params.key!)
  );

  return (
    <Page header={t.testResults}>
      <Stack>
        {installationControl && (
          <>
            <DataGrid
              data={{
                lift: installationControl.lift.name,
                box_id: installationControl.lift.box_id,
              }}
            />
            <ResultPanels installationControl={installationControl} />
          </>
        )}
      </Stack>
    </Page>
  );
}
