import React from 'react';
import { Link } from 'react-router-dom';

import { Button } from '../components/common/Button';
import { FullScreenDialog } from '../components/common/FullScreenDialog';
import { Logo } from '../components/common/Logo';
import { Stack } from '../components/common/Stack';
import { Form } from '../components/form/Form';
import { InputField } from '../components/form/InputField';
import { useFormSubmission } from '../hooks/useFormSubmission';
import { useToggle } from '../hooks/useToggle';
import { useTranslation } from '../I18nProvider';
import { requestPasswordReset, RequestPasswordResetArgs } from '../utils/api';

export function ForgotPassword() {
  const t = useTranslation();
  const [success, toggleSuccess] = useToggle();
  const { form, register } = useFormSubmission<RequestPasswordResetArgs>({
    async onSubmit(values) {
      await requestPasswordReset(values);
      toggleSuccess();
    },
  });

  return (
    <FullScreenDialog>
      <Logo />
      {success ? (
        t.passwordResetSent
      ) : (
        <Form {...form}>
          <Stack>
            <p>{t.forgotPasswordText}</p>
            <InputField
              register={register}
              name="email"
              type="email"
              placeholder={t.email}
              required
            />
            <Button variant="primary">{t.resetPassword}</Button>
            <Button as={Link} variant="secondary" to="/">
              {t.cancel}
            </Button>
          </Stack>
        </Form>
      )}
    </FullScreenDialog>
  );
}
