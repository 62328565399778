import React from 'react';
import styled from 'styled-components';

import {
  ClockIcon,
  CounterIcon,
  ElevatorIcon,
} from '../../components/common/Icons';
import { StatusType } from '../../components/common/StatusIcon';
import { useDateFormat } from '../../hooks/useDateFormat';
import { shadow } from '../../styles';
import { borderRadius, color, fontSize, fontWeight, space } from '../../tokens';
import { DoorData } from '../../utils/boxData';

const Card = styled.button<{ status: StatusType }>`
  border-radius: ${borderRadius.default};
  display: flex;
  flex-direction: column;
  border: 1px solid ${(p) => color[p.status]};
  padding: ${space[3]};
  background: ${(p) => color[p.status]};
  color: ${color.textOnPrimary};
  :not([disabled]) {
    color: ${color.label};
    background: ${color.background};
    ${shadow}
  }
`;

const Item = styled.div`
  display: flex;
  white-space: nowrap;
  align-items: center;
  font-size: ${fontSize.xs};
  font-weight: ${fontWeight.bold};
  svg {
    margin-right: 0.5ch;
    opacity: 0.5;
  }
`;

interface Props {
  data: DoorData;
  active: boolean;
  onClick: () => void;
}

export function DoorCycleCard({ data, active, onClick }: Props) {
  const formatTime = useDateFormat().formatTime;

  const {
    measurements: {
      powerReductionFactor,
      motorTemperature,
      encoderTemperature,
      ambientTemperature,
    },
    errors,
  } = data;
  //TODO share values with DataGrid / ScaleCard
  let status: StatusType = 'success';
  if (
    +powerReductionFactor < 0.9 ||
    +motorTemperature > 90 ||
    +encoderTemperature > 60 ||
    +ambientTemperature > 60 ||
    !!errors.some((err) => err.type == 'warning')
  ) {
    status = 'warning';
  }
  if (
    +powerReductionFactor < 0.6 ||
    +motorTemperature > 110 ||
    +encoderTemperature > 75 ||
    +ambientTemperature > 75 ||
    errors.some((err) => err.type == 'error' || err.type == 'fatalerror')
  ) {
    status = 'error';
  }
  return (
    <Card disabled={active} status={status} onClick={onClick}>
      <Item>
        <ClockIcon />
        {formatTime(data.startTime)}
      </Item>
      {typeof data.floor === 'number' && (
        <Item>
          <ElevatorIcon />
          Floor {data.floor}
        </Item>
      )}
      <Item>
        <CounterIcon />
        {data.cycle}
      </Item>
    </Card>
  );
}
