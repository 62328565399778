import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { Button } from '../../components/common/Button';
import { Hint } from '../../components/common/Hint';
import { Page } from '../../components/common/Page';
import { Stack } from '../../components/common/Stack';
import { Form } from '../../components/form/Form';
import { InputSliderField } from '../../components/form/InputSliderField';
import { useBoxApiMutation, useBoxApiQuery } from '../../hooks/useBoxApi';
import { useFormSubmission } from '../../hooks/useFormSubmission';
import { useTranslation } from '../../I18nProvider';
import { EditableDriveParameterName } from '../../utils/programming/BoxApi';
import { parameterKeyMap } from '../../utils/programming/parameterKeyMap';
import { DisplayParameter } from './DisplayParameter';

export function EditDriveParameter() {
  const t = useTranslation();
  const params = useParams();
  const navigate = useNavigate();
  const paramId = params.paramId! as EditableDriveParameterName;
  const parameterKeys = parameterKeyMap[paramId];

  const isApiConnected = useBoxApiQuery('isApiConnected');

  const driveStatus = useBoxApiQuery({
    method: 'getDriveStatus',
    enabled: !!isApiConnected.data,
    cacheTime: 0,
  });
  const driveConnected = !!driveStatus.data?.drive_info.login_status;

  // Navigate back if not connected
  if (!isApiConnected.data || (!driveStatus.isLoading && !driveConnected)) {
    navigate('..');
  }

  const driveParameters = useBoxApiQuery({
    method: 'getDriveParameters',
    enabled: driveConnected,
    cacheTime: 0,
    retry: 1,
  });

  const data = driveParameters?.data?.[paramId];

  const setDriveParameter = useBoxApiMutation('setDriveParameter', {
    onSuccess: () => driveParameters.refetch(),
  });

  const pageTitle = parameterKeys
    ? `${t[parameterKeys.title]}`
    : 'Unknown parameter';

  const { form, control } = useFormSubmission<{ value: number }>({
    async onSubmit(data) {
      await setDriveParameter(paramId, data.value);
      navigate('..');
    },
    defaultValues: {
      value: data?.value,
    },
  });

  const getStepSize = () => {
    if (parameterKeys.id === 'BrakePar') {
      return 1;
    } else if (parameterKeys.id === 'CouplerWitdh') {
      return 0.001;
    }
    return 0.01;
  };

  const EditParameter = () => (
    <>
      <InputSliderField
        control={control}
        name="value"
        min={Number(data?.min || 0)}
        max={Number(data?.max || 0)}
        // TODO: Calc precision
        step={getStepSize()}
        unit={data?.unit}
      />

      <Button
        type="submit"
        size="small"
        variant="primary"
        disabled={form.formState.isSubmitting}
      >
        {t.save}
      </Button>
    </>
  );

  return (
    <Page pageTitle={pageTitle} backLink="..">
      <Form {...form}>
        <Stack>
          {parameterKeys?.text && <p>{t[parameterKeys.text]}</p>}
          {parameterKeys?.note && <Hint>{t[parameterKeys.note]}</Hint>}

          {data?.writeable ? (
            <EditParameter />
          ) : (
            <DisplayParameter title={t[parameterKeys.title]} param={data} />
          )}
        </Stack>
      </Form>
    </Page>
  );
}
