import React, { Fragment, useEffect, useMemo, useRef } from 'react';
import styled from 'styled-components';

import { color, fontSize, fontWeight } from '../../tokens';
import { D3Chart } from './d3-chart';
import { ChartOptions } from './types';

export type ChartProps = Partial<ChartOptions>;

const Wrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

const Legend = styled.dl`
  list-style: none;
  font-size: ${fontSize.xs};
  margin: none;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  dt {
    margin: 0 0.5ch 0 2ch;
  }
  dd {
    margin: 0;
    color: ${color.label};
  }
`;

const StyledSvg = styled.svg`
  flex: 1;

  .curves {
    fill: none;
    stroke-width: 2;
    stroke: #333;
    stroke-linejoin: round;
  }

  .curve--interpolated {
    stroke-opacity: 1;
  }

  path {
    vector-effect: non-scaling-stroke;
  }

  .domain {
    stroke: none;
  }
  .axis {
    stroke: currentColor;
  }

  .tick line:not(.grid-helper) {
    stroke: #141414;
    stroke-opacity: 0.1;
  }

  .grid-helper {
    stroke-opacity: 0.1;
  }

  .inner-view .clip-rect {
    fill: none;
  }

  text {
    font-family: Roboto, sans-serif;
    font-size: ${fontSize.xs};
    font-weight: ${fontWeight.bold};
    stroke: none;
  }
`;

export function Chart(options: ChartProps) {
  const svgRef = useRef<SVGSVGElement>(null);
  const chart = useMemo(() => new D3Chart(), []);
  useEffect(() => {
    chart.init(svgRef.current, options);
    return () => {
      chart.dispose();
    };
  }, [svgRef]);

  useEffect(() => {
    if (options.data) {
      chart.options.data = options.data;
    } else {
      chart.options.data = [];
    }
    chart.updateData();
  }, [options.data]);

  useEffect(() => {
    if (options.aspectRatio) {
      chart.options.aspectRatio = options.aspectRatio;
      chart.update();
    }
  }, [options.aspectRatio]);

  useEffect(() => {
    if (options.margin) {
      chart.options.margin = options.margin;
      chart.update();
    }
  }, [options.margin]);

  useEffect(() => {
    if (options.tickSize) {
      chart.options.tickSize = options.tickSize;
      chart.update();
    }
  }, [options.tickSize]);

  useEffect(() => {
    if (options.ticks) {
      chart.options.ticks = options.ticks;
      chart.update();
    }
  }, [options.ticks]);

  useEffect(() => {
    if (options.axisConfig) {
      chart.options.axisConfig = options.axisConfig;
      chart.update();
    }
  }, [options.axisConfig]);

  useEffect(() => {
    if (options.scaleExtent && chart.zoom) {
      chart.options.scaleExtent = options.scaleExtent;
      chart.zoom?.scaleExtent(options.scaleExtent);
    }
  }, [options.scaleExtent]);

  const clipPathId = ['viewClip', options.id].filter(Boolean).join('_');
  return (
    <Wrapper>
      <StyledSvg ref={svgRef}>
        <clipPath id={clipPathId}>
          <rect className="clip-rect" />
        </clipPath>
        <g className="inner-view" clipPath={`url(#${clipPathId})`}>
          <rect className="clip-rect"></rect>
          <g className="curves"></g>
        </g>
        <g className="axis top"></g>
        <g className="axis right"></g>
        <g className="axis bottom"></g>
        <g className="axis left"></g>

        <g className="legend"></g>
      </StyledSvg>
      <Legend>
        {options.data?.map((curve) => (
          <Fragment key={curve.name}>
            <dt style={{ color: curve.color }} aria-label={curve.color}>
              ⬤
            </dt>
            <dd>{curve.name}</dd>
          </Fragment>
        ))}
      </Legend>
    </Wrapper>
  );
}
