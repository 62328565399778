import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';

import { Button } from '../../components/common/Button';
import { ErrorCard } from '../../components/common/ErrorCard';
import { IconButton } from '../../components/common/IconButton';
import { RemoteIcon } from '../../components/common/Icons';
import { Loading } from '../../components/common/Loading';
import { Page } from '../../components/common/Page';
import { Stack } from '../../components/common/Stack';
import { StatusType } from '../../components/common/StatusIcon';
import { DataGrid } from '../../components/DataGrid';
import {
  boxApi,
  useBoxApiMutation,
  useBoxApiQuery,
} from '../../hooks/useBoxApi';
import { useDateFormat } from '../../hooks/useDateFormat';
import { useTranslation } from '../../I18nProvider';
import { space } from '../../tokens';
import { ErrorType } from '../../utils/appApi';
import { getBoxSummary } from '../../utils/programming/BoxApi';
import {
  DriveError,
  DriveErrorName,
  getDriveError,
} from '../../utils/programming/driveErrorMap';

const statusMap: Record<ErrorType, StatusType> = {
  fatalerror: 'fatal',
  error: 'error',
  warning: 'warning',
  info: 'success',
};

const mapErrorType = (type: string): StatusType => statusMap[type as ErrorType];

const cloneArray = (a: Array<any> | undefined): Array<any> => [...(a || [])];

export function DriveErrors() {
  const t = useTranslation();
  const { formatDate, formatTime } = useDateFormat();

  const driveStatus = useBoxApiQuery({
    method: 'getDriveStatus',
    cacheTime: 0,
  });
  const driveConnected = !!driveStatus.data?.drive_info.login_status;

  const driveErrors = useBoxApiQuery({
    method: 'getDriveErrors',
    enabled: driveConnected,
    cacheTime: 0,
    retry: 1,
  });
  const clonedDriveErrors = cloneArray(driveErrors.data);

  const upsmartErrors = useBoxApiQuery({
    method: 'getUpsmartErrors',
    enabled: driveConnected,
    cacheTime: 0,
    retry: 1,
  });

  const clearDriveErrors = useBoxApiMutation('clearDriveErrors', {
    onSuccess: () => driveErrors.refetch(),
  });

  useEffect(() => {
    const handleUpdate = upsmartErrors.update;
    boxApi.onUpsmartErrorListChange(handleUpdate);
    return () => {
      boxApi.offUpsmartErrorListChange(handleUpdate);
    };
  }, [driveConnected]);

  // console.log('Upsmart Errors', upsmartErrors.data);
  const sortedUpsmartErrors = cloneArray(upsmartErrors.data);
  sortedUpsmartErrors.sort(
    (e1, e2) =>
      new Date(e2.date || '').getTime() - new Date(e1.date || '').getTime()
  );

  const isLoading =
    !driveStatus.data ||
    !driveErrors.data ||
    !upsmartErrors.data ||
    clearDriveErrors.isLoading;

  return (
    <Page
      pageTitle={t.programmingTool}
      subHeadline={t.errors}
      backLink=".."
      nextLink={
        <IconButton as={Link} to="../parameters">
          <RemoteIcon />
        </IconButton>
      }
    >
      <Stack gap={space[2]}>
        <DataGrid data={getBoxSummary(driveStatus.data)} />
        {isLoading && <Loading />}
        {!isLoading &&
          !sortedUpsmartErrors.length &&
          !clonedDriveErrors.length && <p>{t.errorsEmpty}</p>}

        {!isLoading &&
          (clonedDriveErrors.length > 0 || sortedUpsmartErrors.length > 0) && (
            <Button variant="secondary" onClick={clearDriveErrors}>
              {t.clearDriveErrors}
            </Button>
          )}

        {!isLoading &&
          sortedUpsmartErrors?.map((error: DriveError, i: number) => {
            return (
              <ErrorCard
                key={i}
                status={mapErrorType(error.type)}
                title={error.message}
              >
                <div>
                  <b>{formatDate(error.date)}</b> {formatTime(error.date)}
                </div>
              </ErrorCard>
            );
          })}

        {!isLoading &&
          clonedDriveErrors.map((errorCode: DriveErrorName, i: number) => {
            const error = getDriveError(errorCode);
            return (
              <ErrorCard
                key={i}
                status={mapErrorType(error.type)}
                title={error.message}
              ></ErrorCard>
            );
          })}
      </Stack>
    </Page>
  );
}
