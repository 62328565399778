import _ from 'lodash';
import React, { Fragment, useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';

import { IconButton } from '../../components/common/IconButton';
import { ChevronRightIcon, EditIcon } from '../../components/common/Icons';
import { Page } from '../../components/common/Page';
import { Spacer } from '../../components/common/Spacer';
import { Stack } from '../../components/common/Stack';
import { Dropdown } from '../../components/form/Dropdown';
import { LiftHeader } from '../../components/lift/LiftHeader';
import { useApiQuery } from '../../hooks/useApi';
import { useDateFormat } from '../../hooks/useDateFormat';
import { useTranslation } from '../../I18nProvider';
import { Component, getErrorMessage, isStatus } from '../../utils/api';
import { ActivateTrial } from './ActivateTrial';
import { ComponentCard } from './ComponentCard';
import { DoorModelNotSupported } from './DoorModelNotSupported';
import { IncompleteElevatorSettings } from './IncompleteElevatorSettings';
import { PurchaseLicense } from './PurchaseLicense';
import { ServiceUnavailable } from './ServiceUnavailable';

const ComponentsContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px;
`;

const InlineContainer = styled.div`
  padding: 2px 0px;
`;

export function ComponentsByFloorOverview() {
  const t = useTranslation();

  const params = useParams();
  const id = parseInt(params.id!);
  const buildingId = parseInt(params.buildingId!);
  const floorId = params.floorId!;
  const navigate = useNavigate();

  // there is not date picker in the first version
  const [selectedDate, setSelectedDate] = useState(new Date().toISOString());

  const query = useApiQuery(
    { method: 'getComponentsByFloor', useErrorBoundary: false },
    id,
    selectedDate
  );

  const errorMessage = getErrorMessage(query.error);
  const isLicenseInvalid = isStatus(query.error, 403);
  const isTrialEnabled = isStatus(query.error, 402);
  const isElevatorSettingsIncomplete =
    isStatus(query.error, 422) &&
    errorMessage ===
      'The elevator settings are incomplete for preventive maintenance';
  const notAllPartsSelected =
    isStatus(query.error, 422) &&
    errorMessage === 'Not all component parts have been selected';
  const isDoorModelUnsupported =
    isStatus(query.error, 422) &&
    errorMessage === 'Door model is not supported';

  const linkTo = isElevatorSettingsIncomplete
    ? '../settings'
    : notAllPartsSelected
    ? '../settings/component-details'
    : '../settings';

  const isServiceUnavailable = isStatus(query.error, 503);
  if (isStatus(query.error, 500)) {
    throw query.error;
  }

  const filteredComponents =
    query.data?.find((floor) => floor.floor === floorId)?.components || [];

  const sortingOptions = [
    { value: 'alphabetical', label: t.alphabetical },
    { value: 'priority', label: t.priority },
  ];

  const [sortedComponents, setSortedComponents] = useState(filteredComponents);
  const [sorting, setSorting] = useState('alphabetical');

  useEffect(() => {
    const sorted = sortComponents();
    setSortedComponents(sorted);
  }, [query.data, sorting, floorId]);

  const sortComponents = () => {
    if (sorting === 'priority') {
      return filteredComponents.sort((a, b) => {
        if (a.priority === 'mandatory') return -1;
        if (b.priority === 'mandatory') return 1;
        if (a.priority === 'recommended') return -1;
        if (b.priority === 'recommended') return 1;
        if (a.priority === 'ok') return -1;
        if (b.priority === 'ok') return 1;
        return 0;
      });
    } else if (sorting === 'alphabetical') {
      return [...filteredComponents].sort((a: any, b: any) =>
        a.componentPositionName.localeCompare(b.componentPositionName)
      );
    }
    return filteredComponents;
  };

  const dateFormat = useDateFormat();
  const { formatDate } = dateFormat;

  const handleFloorChange = (floor: any) => {
    navigate(`../${floor}`);
  };

  return (
    <Page
      header={
        <LiftHeader
          id={id}
          action={
            <IconButton as={Link} to="../../settings">
              <EditIcon />
            </IconButton>
          }
        />
      }
      pageTitle={t.landingCarDoorSpareParts}
      backLink="../"
      subHeadline={t.planningAndAlerts}
    >
      <Stack>
        {query.isLoading && <ServiceUnavailable showButtons={false} />}
        {isDoorModelUnsupported && <DoorModelNotSupported />}
        {isLicenseInvalid && <PurchaseLicense />}
        {isTrialEnabled && (
          <ActivateTrial liftId={id} onActivate={query.refetch} />
        )}
        {(isElevatorSettingsIncomplete || notAllPartsSelected) && (
          <IncompleteElevatorSettings linkTo={linkTo} />
        )}
        {isServiceUnavailable && <ServiceUnavailable />}
        {query.isSuccess && (
          <ComponentsContainer>
            <Dropdown
              label={t.chooseFloorOrCardoor}
              value={floorId}
              onChange={(e) => handleFloorChange(e.target.value)}
            >
              {query.data.map((queryFloor) => (
                <option value={queryFloor.floor} key={queryFloor.floor}>
                  {queryFloor.floor === 'cardoor'
                    ? t.carDoor
                    : queryFloor.floor}
                </option>
              ))}
            </Dropdown>
            <Spacer />
            <Dropdown
              label={t.sortBy}
              value={sorting}
              onChange={(e) => setSorting(e.target.value)}
            >
              {sortingOptions.map((sortingOption) => {
                return (
                  <option value={sortingOption.value} key={sortingOption.value}>
                    {sortingOption.label}
                  </option>
                );
              })}
            </Dropdown>
            <Spacer />
            {filteredComponents.length > 0 ? (
              <>
                {sortedComponents.map((component: Component) => (
                  <Fragment key={component.componentPositionName}>
                    <ComponentCard
                      title={(
                        t[
                          `${
                            _.camelCase(
                              component.componentPositionName
                            ) as keyof typeof t
                          }`
                        ] || component.componentPositionName
                      ).toString()}
                      subTitle={`${Math.round(
                        (component.currentCounter /
                          component.mandatoryChangeInDoorcycles) *
                          100
                      )} %`}
                      navigateTo={`component/${component.componentPositionName}`}
                      icon={<ChevronRightIcon />}
                      priority={component.priority}
                    >
                      <InlineContainer>
                        {t.recommendedDateOfExchange}
                        {': '}
                        {formatDate(component.nextMaintenanceDatePred)}
                      </InlineContainer>
                      <InlineContainer>
                        {t.lastExchangeDate}
                        {': '}
                        {formatDate(component.installationDate)}
                      </InlineContainer>
                    </ComponentCard>
                    <Spacer />
                  </Fragment>
                ))}
              </>
            ) : (
              <p>{t.noComponentsFound}</p>
            )}
          </ComponentsContainer>
        )}
      </Stack>
    </Page>
  );
}
