import React, { ReactNode } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { useLocation, useNavigate } from 'react-router-dom';

import { ErrorPage } from './ErrorPage';

function logError(error: any, info: any) {
  console.error('An error occured', error, {
    api: error.api,
    method: error.method,
    url: error.url,
    payload: error.payload,
    cause: error.cause,
    code: error.code,
  }),
    info;
}

type Props = {
  children?: ReactNode;
};

export function ProgrammingErrorBoundary({ children }: Props) {
  const location = useLocation();
  const navigate = useNavigate();

  return (
    <ErrorBoundary
      fallbackRender={({ error, resetErrorBoundary }) => {
        const backHome = () => {
          resetErrorBoundary();
          navigate('/');
        };

        return (
          <ErrorPage
            goBack={backHome}
            error={error}
            resetError={resetErrorBoundary}
          />
        );
      }}
      onError={(error, info) => logError(error, info)}
      resetKeys={[location.pathname]}
    >
      {children}
    </ErrorBoundary>
  );
}
