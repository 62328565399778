import { camelCase } from 'lodash';
import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import styled from 'styled-components';

import { Button } from '../../components/common/Button';
import { IconButton } from '../../components/common/IconButton';
import { EditIcon } from '../../components/common/Icons';
import { InfoContainer } from '../../components/common/InfoContainer';
import { Loading } from '../../components/common/Loading';
import { Page } from '../../components/common/Page';
import { Row } from '../../components/common/Row';
import { Stack } from '../../components/common/Stack';
import { TextXs } from '../../components/common/typography';
import { Dropdown } from '../../components/form/Dropdown';
import { Form } from '../../components/form/Form';
import { InputField } from '../../components/form/InputField';
import { LiftHeader } from '../../components/lift/LiftHeader';
import { useApiMutation, useApiQuery } from '../../hooks/useApi';
import { useDateFormat } from '../../hooks/useDateFormat';
import { useFormSubmission } from '../../hooks/useFormSubmission';
import { useTranslation } from '../../I18nProvider';
import { borderRadius, color, fontSize, fontWeight, space } from '../../tokens';
import { getErrorMessage, getLift, isStatus } from '../../utils/api';
import { getTodayDate } from '../../utils/dateUtils';
import { getFallbackImageUrl, getImageUrl } from '../../utils/partImageUtils';
import { ActivateTrial } from './ActivateTrial';
import { DoorModelNotSupported } from './DoorModelNotSupported';
import { IncompleteElevatorSettings } from './IncompleteElevatorSettings';
import { PurchaseLicense } from './PurchaseLicense';
import { ScaleCard } from './ScaleCard';
import { ServiceUnavailable } from './ServiceUnavailable';

const TextContainer = styled.div`
  padding-bottom: ${space[5]};
`;

const ButtonContainer = styled.div`
  display: flex;
  padding-top: ${space[3]};
  justify-content: center;
  flex-direction: column;
  align-items: center;
  background-color: ${color.cardBackground};
`;

const ActionButton = styled.div`
  background-color: ${color.primary};
  border: none;
  color: ${color.background};
  padding: 6px 16px;
  text-align: center;
  text-decoration: none;
  display: block;
  font-size: ${fontSize.base};
  border-radius: 3px;
  cursor: pointer;
  margin-top: ${space[3]};
  width: 40%;
  margin-bottom: ${space[3]};
`;

const PartInfoContainer = styled.div`
  background-color: ${color.cardBackground};
  border-radius: ${borderRadius.default};
  padding: ${space[4]};
  margin-bottom: ${space[4]};
  display: flex;
  align-items: center;
  gap: ${space[4]};
`;

const PartImage = styled.img`
  max-width: 150px;
  max-height: 100px;
  width: auto;
  height: auto;
  border-radius: ${borderRadius.default};
  object-fit: contain;
`;

const PartInfoTitle = styled.h3`
  font-size: ${fontSize.base};
  color: ${color.primary};
  margin-bottom: ${space[2]};
`;

const PartInfoText = styled.p`
  font-size: ${fontSize.xs};
  color: ${color.text};
  margin: ${space[1]} 0;
  strong {
    font-weight: ${fontWeight.bold};
  }
`;

interface FormType {
  reason: string;
  lastExchangeDate: string;
}

export function Component() {
  const t = useTranslation();
  const params = useParams();
  const id = parseInt(params.id!);
  const buildingId = parseInt(params.buildingId!);
  const floorParams = params.floorId!;
  const componentId = params.componentId!;
  const todayDate = getTodayDate();
  const query = useApiQuery(
    { method: 'getComponent', useErrorBoundary: false },
    id,
    componentId,
    floorParams
  );
  const updateComponent = useApiMutation('updateComponent', {
    onSuccess: () => query.refetch(),
  });

  const dateFormat = useDateFormat();
  const { formatDate } = dateFormat;
  const snoozeDates = query.data?.snoozeDates;
  let snoozeInfo;
  if (snoozeDates && snoozeDates?.length !== 0) {
    snoozeInfo =
      `${t.replacementWasPostponed} ` + snoozeDates.map(formatDate).join(', ');
  }

  const errorMessage = getErrorMessage(query.error);
  const isLicenseInvalid = isStatus(query.error, 403);
  const isTrialEnabled = isStatus(query.error, 402);
  const isElevatorSettingsIncomplete =
    isStatus(query.error, 422) &&
    errorMessage ===
      'The elevator settings are incomplete for preventive maintenance';
  const notAllPartsSelected =
    isStatus(query.error, 422) &&
    errorMessage === 'Not all component parts have been selected';
  const isDoorModelUnsupported =
    isStatus(query.error, 422) &&
    errorMessage === 'Door model is not supported';
  const isServiceUnavailable = isStatus(query.error, 503);
  if (isStatus(query.error, 500)) {
    throw query.error;
  }

  const linkTo = isElevatorSettingsIncomplete
    ? '../settings'
    : notAllPartsSelected
    ? '../settings/component-details'
    : '../settings';

  const {
    componentPositionName,
    currentCounter,
    mandatoryChangeInDoorcycles,
    installationDate: lastExchangeDate,
    priority,
    nextMaintenanceDateWmp,
  } = query.data || {};
  const { partNumber = '', partName = '' } = query.data?.part || {};

  const [imageSrc, setImageSrc] = useState(getFallbackImageUrl());
  useEffect(() => {
    setImageSrc(getImageUrl(partNumber));
  }, [partNumber]);
  const handleImageError = () => {
    setImageSrc(getFallbackImageUrl());
  };

  let currentCounterPercentage = 0;
  if (currentCounter && mandatoryChangeInDoorcycles) {
    currentCounterPercentage = Math.round(
      (currentCounter / mandatoryChangeInDoorcycles) * 100
    );
  }

  const nextMaintenanceDatePred = formatDate(
    query.data?.nextMaintenanceDatePred
  );

  const [reason, setReason] = useState(t.chooseReason);

  const reasonOptions = [
    { value: t.partIsStillOk, label: 'part is still ok' },
    { value: t.sparePartDoesNotFit, label: 'spare part does not fit' },
    { value: t.sparePartNotAvailable, label: 'spare part not available' },
    { value: t.otherReason, label: 'other reason' },
  ];

  const [exchangeDateError, setExchangeDateError] = useState<boolean>(false);
  const { form, register } = useFormSubmission<FormType>({
    async onSubmit(data: FormType) {
      if (query.data) {
        const lift = await getLift(id);
        const { elevatorInstallationDate, doorInstallationDate } = lift || {};
        const exchangeDate = data.lastExchangeDate;

        if (
          doorInstallationDate &&
          exchangeDate &&
          exchangeDate < doorInstallationDate
        ) {
          setExchangeDateError(true);
          return;
        }

        if (
          elevatorInstallationDate &&
          exchangeDate &&
          exchangeDate < elevatorInstallationDate
        ) {
          setExchangeDateError(true);
          return;
        }

        const updatedData = query.data;
        updatedData.notReplacedReason =
          reason !== t.chooseReason ? reason : undefined;
        if (
          exchangeDate !== null ||
          exchangeDate !== undefined ||
          exchangeDate !== ''
        ) {
          updatedData.installationDate = exchangeDate;
        }
        await updateComponent(id, floorParams, {
          isReplaced: true,
          component: updatedData,
        });
        setExchangeDateError(false);
      }
    },
  });

  useEffect(() => {
    form.reset();
  }, [query.data]);

  let updatedData;
  const onReplaced = async () => {
    if (query.data) {
      updatedData = query.data;
      updatedData.installationDate = new Date().toISOString();
      updatedData.currentCounter = 0;
      await updateComponent(id, floorParams, {
        isReplaced: true,
        component: updatedData,
      });
    }
  };

  const onSnooze = async () => {
    if (query.data) {
      await updateComponent(id, floorParams, {
        isReplaced: false,
        component: query.data,
      });
    }
  };

  const getDifferenceInDays = (firstDate: string, secondDate: string) => {
    return Math.floor(
      (new Date(secondDate).getTime() - new Date(firstDate).getTime()) /
        (1000 * 60 * 60 * 24)
    );
  };

  let nextMaintenanceDateWmpTimeDifference;
  let timeStep1 = 0;
  let timeStep2 = 0;
  let currentDifferenceInDays;
  if (lastExchangeDate && nextMaintenanceDateWmp) {
    nextMaintenanceDateWmpTimeDifference = getDifferenceInDays(
      lastExchangeDate,
      nextMaintenanceDateWmp
    );
    timeStep1 = nextMaintenanceDateWmpTimeDifference * 0.8;
    timeStep2 = nextMaintenanceDateWmpTimeDifference * 0.9;
    currentDifferenceInDays = getDifferenceInDays(
      lastExchangeDate,
      new Date().toString()
    );
  }

  const selectColor = (priority: string) => {
    return priority === 'recommended'
      ? color.warning
      : priority === 'mandatory'
      ? color.error
      : color.success;
  };

  return (
    <Page
      header={
        <LiftHeader
          id={id}
          action={
            <IconButton as={Link} to="../../settings">
              <EditIcon />
            </IconButton>
          }
        />
      }
      pageTitle={
        componentPositionName &&
        t[camelCase(componentPositionName) as keyof typeof t] !== undefined
          ? (t[camelCase(componentPositionName) as keyof typeof t] as string)
          : componentPositionName
      }
      subHeadline={`${
        floorParams === 'cardoor' ? t.carDoor : t.floor + ' ' + floorParams
      }`}
      backLink={`../${floorParams}`}
    >
      <Stack>
        {query.isLoading && <ServiceUnavailable showButtons={false} />}
        {isDoorModelUnsupported && <DoorModelNotSupported />}
        {updateComponent.isLoading && <Loading />}
        {isLicenseInvalid && <PurchaseLicense />}
        {isTrialEnabled && (
          <ActivateTrial liftId={id} onActivate={query.refetch} />
        )}
        {(isElevatorSettingsIncomplete || notAllPartsSelected) && (
          <IncompleteElevatorSettings linkTo={linkTo} />
        )}
        {isServiceUnavailable && <ServiceUnavailable />}
        {query.isSuccess && !updateComponent.isLoading && (
          <Stack>
            <InfoContainer
              title={t.recommendedDateOfExchange}
              text={nextMaintenanceDatePred || ''}
              color={selectColor(priority || '')}
            />
            <PartInfoContainer>
              <PartImage
                src={imageSrc}
                alt="Part Image"
                onError={handleImageError}
              />
              <div>
                <PartInfoTitle>{t.partInformation}</PartInfoTitle>
                <PartInfoText>
                  <strong>{t.partNumber}:</strong> {partNumber}
                </PartInfoText>
                <PartInfoText>
                  <strong>{t.partName}:</strong> {partName}
                </PartInfoText>
              </div>
            </PartInfoContainer>
            {lastExchangeDate && nextMaintenanceDateWmp && (
              <ScaleCard
                title={t.nextMaintenanceDate}
                bars={38}
                min={0}
                max={nextMaintenanceDateWmpTimeDifference || 0}
                step1={timeStep1}
                step2={timeStep2}
                value={currentDifferenceInDays || 0}
                isDate={true}
                maxDate={formatDate(nextMaintenanceDateWmp)}
                minDate={formatDate(lastExchangeDate)}
              ></ScaleCard>
            )}
            <ScaleCard
              title={t.doorCycles}
              bars={38}
              min={0}
              max={100}
              step1={80}
              step2={90}
              value={currentCounterPercentage || 0}
              unit="%"
            ></ScaleCard>
            <Stack gap="0">
              <ButtonContainer>
                <ActionButton onClick={onReplaced}>{t.replaced}</ActionButton>
                <TextXs>{`${t.lastExchangeDate}: ${formatDate(
                  lastExchangeDate
                )}`}</TextXs>
              </ButtonContainer>
              <ButtonContainer>
                {priority === 'mandatory' && (
                  <ActionButton onClick={onSnooze}>{t.postpone}</ActionButton>
                )}
                <TextContainer>
                  {snoozeInfo && <TextXs>{snoozeInfo}</TextXs>}
                </TextContainer>
              </ButtonContainer>
            </Stack>
            <Form {...form}>
              <Stack>
                {(priority === 'mandatory' || priority === 'recommended') && (
                  <Dropdown
                    label={t.whyNotReplaced}
                    value={reason}
                    onChange={(e) => setReason(e.target.value)}
                  >
                    <option value={t.chooseReason} key={t.chooseReason}>
                      {t.chooseReason}
                    </option>
                    {reasonOptions.map((option) => {
                      if (option.label === t.priority) return null;
                      return (
                        <option value={option.value} key={option.value}>
                          {option.value}
                        </option>
                      );
                    })}
                  </Dropdown>
                )}
                <InputField
                  register={register}
                  name="lastExchangeDate"
                  type="date"
                  max={todayDate}
                  placeholder={t.lastExchangeDate}
                  defaultValue={
                    new Date(lastExchangeDate || new Date())
                      .toISOString()
                      .split('T')[0]
                  }
                  hasError={exchangeDateError}
                  errorMessage={
                    exchangeDateError ? t.errorEarlierThanInstallationDate : ''
                  }
                />
                <Row gap=".5rem">
                  <Button
                    as={Link}
                    to={`../${floorParams}`}
                    variant="secondary"
                  >
                    {t.cancel}
                  </Button>
                  <Button type="submit" variant="primary">
                    {t.save}
                  </Button>
                </Row>
              </Stack>
            </Form>
          </Stack>
        )}
      </Stack>
    </Page>
  );
}
