import React, { forwardRef } from 'react';
import { FieldValues } from 'react-hook-form';
import styled from 'styled-components';

import { borderRadius, color, fontSize } from '../../tokens';
import arrow from './SelectArrow.svg';

const Label = styled.label<{
  labelColor: string;
  dropdownSize: string | number;
}>`
  & > div {
    position: absolute;
    top: ${(p) => size[p.dropdownSize].top || '10px'};
    left: 10px;
    font-size: ${fontSize.xs};
    pointer-events: none;
  }
  color: ${(p) =>
    p.labelColor === 'primary' ? color.primary : color.inputText};
  background: ${color.input};
  display: block;
  position: relative;
  border-radius: ${borderRadius.default};
`;

const Select = styled.select<{ dropdownSize: string | number; border: string }>`
  padding: ${(p) => size[p.dropdownSize].padding || '15px 30px 6px 10px'};
  border: ${(p) => size[p.dropdownSize].border || '1px solid #ccc'};
  font-size: ${(p) => size[p.dropdownSize].fontSize || fontSize.base};
  ::-ms-expand {
    display: none; /* remove default arrow in IE 10 + 11 */
  }

  width: 100%;
  color: ${color.inputText};
  background: url(${arrow}) 100% / 24px no-repeat ${color.input};
  border-radius: ${borderRadius.default};
  cursor: pointer;
  appearance: none;
`;

const size: {
  [key: string]: {
    padding: string;
    border: string;
    fontSize: string;
    top: string;
  };
} = {
  s: {
    padding: '15px 30px 6px 10px',
    border: '1px solid #ccc',
    top: '5px',
    fontSize: fontSize.xs,
  },
  base: {
    padding: '26px 10px 6px 10px',
    border: '1px solid #ccc',
    top: '10px',
    fontSize: fontSize.base,
  },
};

export type DropdownProps = JSX.IntrinsicElements['select'] & {
  label: string;
  register?: FieldValues;
  labelColor?: string;
  border?: string;
  dropdownSize?: keyof typeof size;
};

export const Dropdown = forwardRef<HTMLSelectElement, DropdownProps>(
  (
    {
      children,
      label,
      register,
      labelColor = 'inputText',
      dropdownSize = 'base',
      border = 'none',
      ...props
    },
    ref
  ) => (
    <Label dropdownSize={dropdownSize} labelColor={labelColor}>
      <div>{label}</div>
      <Select dropdownSize={dropdownSize} border={border} {...props} ref={ref}>
        {children}
      </Select>
    </Label>
  )
);
