import { pick } from 'lodash';
import React, { useMemo, useState } from 'react';

import { useTranslation } from '../../I18nProvider';
import { borderRadius } from '../../tokens';
import { Building, BuildingArgs } from '../../utils/api';
import { Button } from '../common/Button';
import { Hint } from '../common/Hint';
import { Row } from '../common/Row';
import { Stack } from '../common/Stack';
import { Input } from '../form/Input';
import { AddressInput } from '../map/AddressInput';
import { GoogleMap } from '../map/GoogleMap';
import { MapsApi } from '../map/MapsApi';
import { Marker } from '../map/Marker';
import { replaceMapKey } from '../map/placeUtils';
import { ImageSelect } from './ImageSelect';

interface Props {
  building?: Building;
  onSubmit: (value: BuildingArgs) => any;
  onCancel: () => any;
  showNameInputField?: boolean;
}

export function BuildingForm({
  building,
  onSubmit,
  onCancel,
  showNameInputField,
}: Props) {
  const t = useTranslation();
  const [value, setValue] = useState<BuildingArgs>(getAddress(building));
  const [photo, setPhoto] = useState<string | null | undefined>(
    replaceMapKey(building?.photo)
  );
  const [photos, setPhotos] = useState<string[]>(photo ? [photo] : []);
  const [rawAddressInput, setRawAddressInput] = useState<string>(
    formatAddress(building)
  );

  const markerProps = useMemo(() => {
    if (value?.latitude && value.longitude) {
      return {
        position: { lat: value.latitude, lng: value.longitude },
        zoom: 14,
      };
    }
  }, [value]);

  const mapProps = useMemo(() => {
    if (markerProps) {
      return { center: markerProps.position, zoom: 11 };
    }
    return { center: { lat: 48.30137, lng: 11.23963 }, zoom: 4 };
  }, [markerProps]);

  const handleRawInputChange = (input: string) => {
    setRawAddressInput(input);
  };

  const handleSubmit = () => {
    onSubmit({ ...value, name: value.name || rawAddressInput, photo });
  };

  return (
    <Stack>
      <MapsApi>
        <Hint>{t.addressHint}</Hint>
        <AddressInput
          initialInput={formatAddress(building)}
          onChange={(value) => {
            const { photos, ...address } = value;
            setPhotos(photos);
            setPhoto(photos[0] ?? null);
            setValue(address);
          }}
          onRawInputChange={handleRawInputChange}
        />
        {markerProps && (
          <GoogleMap
            {...mapProps}
            maxZoom={14}
            draggable={false}
            style={{
              width: '100%',
              height: '150px',
              borderRadius: borderRadius.default,
            }}
          >
            <Marker {...markerProps} />
          </GoogleMap>
        )}
      </MapsApi>
      {showNameInputField && (
        <Input
          placeholder={t.buildingName}
          value={value?.name ?? ''}
          onChange={(ev) => setValue((v) => ({ ...v, name: ev.target.value }))}
        />
      )}
      <ImageSelect images={photos} value={photo} onChange={setPhoto} />
      <Row>
        <Button variant="secondary" onClick={onCancel}>
          {t.cancel}
        </Button>
        <Button variant="primary" onClick={handleSubmit}>
          {t.continue}
        </Button>
      </Row>
    </Stack>
  );
}

function getAddress(building?: Building) {
  if (!building)
    return {
      name: '',
      street: '',
      streetNumber: '',
      city: '',
      country: '',
    };
  return pick(
    building,
    'name',
    'street',
    'streetNumber',
    'city',
    'country',
    'latitude',
    'longitude',
    'photo'
  );
}

function formatAddress(building?: Building) {
  if (!building) return '';
  const { name, street, streetNumber, city, country } = building;
  const addr = [street, streetNumber].filter(Boolean).join(' ');
  return [name, addr, city, country].filter(Boolean).join(', ');
}
