import React, {
  createContext,
  CSSProperties,
  ReactNode,
  useContext,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';
import styled from 'styled-components';

import { useDeepCompareEffectForMaps } from './mapUtils';
import styles from './styles.json';

const defaultMapOpts: google.maps.MapOptions = {
  fullscreenControl: false,
  mapTypeControl: false,
  streetViewControl: false,
  zoomControl: false,
  keyboardShortcuts: false,
  backgroundColor: '#c8c8c8',
  styles,
};

const MapContext = createContext<google.maps.Map | undefined>(undefined);

export function useMap() {
  const map = useContext(MapContext);
  if (!map) throw new Error('No map found in context');
  return map;
}

const MapContainer = styled.div`
  outline: none;
  &:focus-visible {
    outline: 2px solid #fff;
  }
`;

interface GoogleMapProps extends google.maps.MapOptions {
  className?: string;
  style?: CSSProperties;
  children?: ReactNode;
  onBoundsChange?: (bounds: google.maps.LatLngBounds) => void;
}

export function GoogleMap({
  className,
  style,
  children,
  onBoundsChange,
  ...options
}: GoogleMapProps) {
  const ref = useRef<HTMLDivElement>(null);
  const [map, setMap] = useState<google.maps.Map>();

  useLayoutEffect(() => {
    if (!map && ref.current) {
      setMap(
        new window.google.maps.Map(ref.current, {
          ...defaultMapOpts,
          ...options,
        })
      );
    }
  }, [ref, map]);

  useDeepCompareEffectForMaps(() => {
    if (map) {
      map.setOptions(options);
    }
  }, [map, options]);

  useEffect(() => {
    if (map && onBoundsChange) {
      const listener = google.maps.event.addListener(map, 'idle', () => {
        const bounds = map.getBounds();
        if (bounds) onBoundsChange(bounds);
      });

      return () => {
        google.maps.event.removeListener(listener);
      };
    }
  }, [map, onBoundsChange]);

  useEffect(() => {
    if (map && children) {
      const bounds = new google.maps.LatLngBounds();
      React.Children.forEach(children, (child) => {
        if (React.isValidElement(child) && child.props.position) {
          bounds.extend(child.props.position);
        }
      });

      if (!map.getBounds() || map.getBounds()?.isEmpty()) {
        map.fitBounds(bounds);
      }
    }
  }, [map]);

  return (
    <>
      <MapContainer ref={ref} className={className} style={style} />
      {map && <MapContext.Provider value={map}>{children}</MapContext.Provider>}
    </>
  );
}
