import _ from 'lodash';
import React, { useState } from 'react';
import { Link, useParams } from 'react-router-dom';

import { IconButton } from '../../components/common/IconButton';
import { DialPadIcon } from '../../components/common/Icons';
import { Loading } from '../../components/common/Loading';
import { Page } from '../../components/common/Page';
import { LiftHeader } from '../../components/lift/LiftHeader';
import { useApiQuery } from '../../hooks/useApi';
import { useTranslation } from '../../I18nProvider';
import { InstallationRun } from '../../utils/api';
import { RunDetails } from './RunDetails';
import { Runs } from './Runs';

export function History() {
  const t = useTranslation();

  const params = useParams();
  const id = parseInt(params.id!);
  const runs = useApiQuery(
    {
      method: 'getInstallationControlRuns',
      useErrorBoundary: false,
      onSuccess: (data) => {
        if (selectedRun === undefined && data.length > 0) {
          setSelectedRun(data[0]);
        }
      },
    },
    id
  );

  const [selectedRun, setSelectedRun] = useState<
    Partial<InstallationRun> | undefined
  >();

  const runDetails = useApiQuery(
    {
      method: 'getInstallationControlRun',
      useErrorBoundary: false,
      enabled: !!selectedRun?.run,
    },
    id,
    selectedRun?.run || 0
  );

  let content;
  if (runs.isLoading) {
    content = <Loading />;
  } else if (runs.data) {
    const reversedRuns = _.sortBy(runs.data, [(o) => o.date]);
    content = (
      <>
        <Runs
          runs={reversedRuns}
          onSelect={setSelectedRun}
          selected={selectedRun}
        />
        {runDetails.data && <RunDetails details={runDetails.data} />}
      </>
    );
  }

  return (
    <Page
      header={<LiftHeader id={id} />}
      pageTitle={t.installationControl}
      subHeadline={t.installationControlHistory}
      backLink="../../"
      nextLink={
        <IconButton as={Link} to="..">
          <DialPadIcon />
        </IconButton>
      }
    >
      {content}
    </Page>
  );
}
