import {
  ApiType,
  BoxConfig,
  RequestError,
  RequestTimeoutError,
  TranslatableError,
} from './BoxApi';
import { trigger, TriggerHandler } from './trigger';

async function gatheringComplete(peerConnection: RTCPeerConnection) {
  return new Promise<void>((resolve) => {
    if (peerConnection.iceGatheringState === 'complete') {
      resolve();
    } else {
      const checkState = () => {
        if (peerConnection.iceGatheringState === 'complete') {
          peerConnection.removeEventListener(
            'icegatheringstatechange',
            checkState
          );
          resolve();
        }
      };
      peerConnection.addEventListener('icegatheringstatechange', checkState);
    }
  });
}

type MessageHandlerContext = {
  bridgeUrl: string;
  peerConnection: RTCPeerConnection;
  clearPopupTimeout: () => void;
  popup: Window;
  onError: (error: any) => void;
};

type MessageHandler = (event: { data: any; origin: string }) => void;

function createMessageHandler({
  bridgeUrl,
  peerConnection,
  clearPopupTimeout,
  popup,
  onError,
}: MessageHandlerContext): MessageHandler {
  return async ({ data, origin }: any) => {
    if (origin !== bridgeUrl) {
      // Message does not come from the bridge popup, ignore it
      return;
    }

    console.log('Received', data);
    try {
      if (data.waitingForOffer) {
        // The popup is ready to receive an offer
        clearPopupTimeout();
        await peerConnection.setLocalDescription(
          await peerConnection.createOffer({ iceRestart: true })
        );
        await gatheringComplete(peerConnection);
        const offer = JSON.parse(
          JSON.stringify(peerConnection.localDescription)
        );
        popup.postMessage({ offer }, '*');
      } else if (data.answer) {
        await peerConnection.setRemoteDescription(data.answer);
      }
    } catch (error) {
      onError(
        new TranslatableError(
          'Message error',
          'programmingErrorConnection',
          error
        )
      );
    }
  };
}

const WINDOW_TIMEOUT = 5000;
const HANDSHAKE_TIMEOUT = 60000;

type HandshakeOptions = {
  url: string;
  peerConnection: RTCPeerConnection;
  createMessageHandler: (context: MessageHandlerContext) => MessageHandler;
  onError: (error: Error) => any;
  onSuccess: (config: BoxConfig, channel: RTCDataChannel) => void;
};

function initHandshake({
  url,
  peerConnection,
  createMessageHandler,
  onError,
  onSuccess,
}: HandshakeOptions) {
  const popup = window.open(`${url}/connect`);
  if (!popup) {
    onError(new TranslatableError('Popup error', 'programmingErrorNoPopup'));
    return;
  }

  const handshakeTimeout = setTimeout(() => {
    cleanup();
    onError(
      new TranslatableError(
        'Handshake timeout error',
        'programmingErrorConnection'
      )
    );
  }, HANDSHAKE_TIMEOUT);

  const windowTimeout = setTimeout(() => {
    clearTimeout(handshakeTimeout);
    cleanup();
    onError(
      new TranslatableError(
        'Window timeout error',
        'programmingErrorConnection'
      )
    );
  }, WINDOW_TIMEOUT);

  const channel = peerConnection.createDataChannel('init');

  channel.onmessage = ({ data }: any) => {
    // console.log('Data', data);
    clearTimeout(handshakeTimeout);
    // Unregister this handler
    channel.onmessage = null;

    let parsed;
    try {
      parsed = JSON.parse(data);
    } catch (error) {
      onError(
        new TranslatableError(
          'JSON init message error',
          'programmingErrorConnection',
          error
        )
      );
    }

    const { init, error } = parsed;
    if (init && init.deviceName) {
      onSuccess(init, channel);
    } else {
      onError(
        new TranslatableError(
          'Proxy init error',
          'programmingErrorConnection',
          error
        )
      );
    }
  };

  const cleanup = () => {
    popup.close();
    window.removeEventListener('message', handleMessage);
  };

  channel.onopen = () => {
    console.log('Channel established', channel);
    channel.send(JSON.stringify({ init: { timestamp: Date.now() } }));
    cleanup();
  };

  const handleMessage = createMessageHandler({
    bridgeUrl: url,
    peerConnection,
    clearPopupTimeout: () => clearTimeout(windowTimeout),
    popup,
    onError,
  });

  window.addEventListener('message', handleMessage);
}

export type BridgeRequestMethod = 'GET' | 'POST';

const REQUEST_TIMEOUT = 12000;

export class WebRtcBridge {
  private pc?: RTCPeerConnection;

  private connected = false;
  private connectionHandler: TriggerHandler[] = [];

  private boxConfig?: BoxConfig;

  private boxUpdateChannel?: RTCDataChannel;
  private boxUpdateHandler: TriggerHandler[] = [];
  constructor(private bridgeUrl: string, private iceServers?: RTCIceServer[]) {}

  getBoxConfig() {
    return this.boxConfig;
  }

  isConnected() {
    return this.connected;
  }

  async connect() {
    if (this.isConnected()) {
      return;
    }

    return new Promise<void>((resolve, reject) => {
      if (!this.pc) {
        this.pc = new RTCPeerConnection({
          iceServers: this.iceServers,
        });

        this.pc.addEventListener('signalingstatechange', console.log);
      }

      const onSuccess = (
        boxConfig: BoxConfig,
        updateChannel: RTCDataChannel
      ) => {
        this.connected = true;
        this.boxConfig = boxConfig;
        this.boxUpdateChannel = updateChannel;
        this.boxUpdateChannel.onmessage = ({ data }: any) => {
          try {
            const update = JSON.parse(data);
            trigger(this.boxUpdateHandler, update);
          } catch (err: any) {
            console.warn('Received unparsable box update', data);
          }
        };
        this.boxUpdateChannel.onclose = (event) => {
          this.close();
        };

        trigger(this.connectionHandler, this.connected);
        console.info(`Successfully connected to '${boxConfig.deviceName}'`);
        resolve();
      };

      const onError = (error: any) => {
        this.close();
        console.error(error);
        reject(error);
      };

      initHandshake({
        url: this.bridgeUrl,
        peerConnection: this.pc,
        createMessageHandler,
        onError,
        onSuccess,
      });
    });
  }

  onConnectionChange(handler: TriggerHandler): void {
    this.connectionHandler.push(handler);
  }

  offConnectionChange(handler: TriggerHandler): void {
    this.connectionHandler = this.connectionHandler.filter(
      (h) => h !== handler
    );
  }

  onBoxUpdates(handler: TriggerHandler): void {
    this.boxUpdateHandler.push(handler);
  }

  offBoxUpdates(handler: TriggerHandler): void {
    this.boxUpdateHandler = this.boxUpdateHandler.filter((h) => h !== handler);
  }

  async request(
    api: ApiType,
    method: BridgeRequestMethod,
    url: string,
    json?: object,
    token?: string
  ): Promise<any> {
    return new Promise<object>((resolve, reject) => {
      if (!this.pc) {
        throw new Error('Bridge not connected - use connect');
      }

      const channel = this.pc.createDataChannel('api');
      channel.onerror = reject;

      const timeout = setTimeout(() => {
        channel.close();
        reject(new RequestTimeoutError(api, method, url, json));
      }, REQUEST_TIMEOUT);

      channel.onopen = () => {
        //console.log('Channel established', channel);

        const handleMessage = ({ data }: any) => {
          clearTimeout(timeout);

          try {
            // console.log('Data', data);
            const { result, error } = JSON.parse(data);
            if (error) {
              reject(
                new RequestError('Bridge error', api, method, url, json, error)
              );
              return;
            }

            console.log(`Received for ${method} ${api}${url}`, result);
            resolve(result);
          } catch (error) {
            reject(
              new RequestError('Invalid JSON', api, method, url, json, error)
            );
          } finally {
            channel.close();
          }
        };

        channel.onmessage = handleMessage;

        console.log(`Requesting ${method} ${api}${url}`, json);
        channel.send(
          JSON.stringify({
            fetch: {
              token,
              api,
              method,
              url,
              json: json && JSON.stringify(json),
            },
          })
        );
      };
    });
  }

  close() {
    console.warn('Box channel closed');
    this.pc?.close();
    this.pc = undefined;
    this.connected = false;
    this.boxConfig = undefined;
    this.boxUpdateChannel = undefined;
    trigger(this.connectionHandler, this.connected);
  }
}
