export class PathBuilder {
  commands: string[] = [];

  static fromPoints(points: { x: number; y: number }[], closed = false) {
    const pb = new PathBuilder();
    if (points.length > 0) {
      let first = true;
      const lineTo: number[] = [];
      for (const point of points) {
        if (first) {
          first = false;
          pb.moveTo(point.x, point.y);
          continue;
        }
        lineTo.push(point.x, point.y);
      }
      pb.lineTo(...lineTo);
    }
    if (closed) {
      pb.closePath();
    }
    return pb;
  }

  moveTo(x: number, y: number) {
    this.commands.push(`M${x} ${y}`);
    return this;
  }

  moveToRel(x: number, y: number) {
    this.commands.push(`m${x} ${y}`);
    return this;
  }

  lineTo(...args: number[]) {
    this.commands.push('L' + args.join(' '));
    return this;
  }

  lineToRel(...args: number[]) {
    this.commands.push('l' + args.join(' '));
    return this;
  }

  quadraticCurveTo(...args: number[]) {
    this.commands.push('Q' + args.join(' '));
    return this;
  }

  quadraticCurveToRel(...args: number[]) {
    this.commands.push('q' + args.join(' '));
    return this;
  }

  closePath() {
    this.commands.push('Z');
  }

  toString() {
    return this.commands.join('');
  }
}
