import React, {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from 'react';

import { useOffline } from './OfflineProvider';

const VersionStateContext = createContext<string | undefined>(undefined);

type Props = {
  children: ReactNode;
};
export function VersionProvider({ children }: Props) {
  const appVersion = '__VERSION__';

  const [version, setVersion] = useState(appVersion);

  const offline = useOffline();

  useEffect(() => {
    if (!offline) {
      const fetchVersion = async () => {
        const data = await (await fetch('/api/app/version')).json();
        const apiVersion = data.version;
        const version =
          appVersion === apiVersion || !apiVersion
            ? appVersion
            : `${appVersion} (${apiVersion})`;

        setVersion(version);
      };
      fetchVersion().catch((e) => {});
    }
  }, [offline]);

  return (
    <VersionStateContext.Provider value={version}>
      {children}
    </VersionStateContext.Provider>
  );
}

export function useVersion() {
  const state = useContext(VersionStateContext);
  if (!state) throw new Error('Missing VersionProvider');
  return state;
}
