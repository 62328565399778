import React from 'react';
import { Link, useParams } from 'react-router-dom';
import styled from 'styled-components';

import { useApiMutation, useApiQuery } from '../../hooks/useApi';
import { useTranslation } from '../../I18nProvider';
import { space } from '../../tokens';
import { BuildingWithShared } from '../../utils/appApi';
import { Button } from '../common/Button';
import { ImageCard } from '../common/Card';
import { Loader } from '../common/Loader';
import { Page } from '../common/Page';
import { Spacer } from '../common/Spacer';
import { Stack } from '../common/Stack';
import { replaceMapKey } from '../map/placeUtils';

const BuildingInfo = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 10px;
`;

const ButtonContainer = styled.div`
  display: flex;
  gap: ${space[4]};
`;

export function ManageSharedBuildings() {
  const { subcontractorId } = useParams<{ subcontractorId: string }>();
  const t = useTranslation();

  const parsedSubcontractorId = subcontractorId ? Number(subcontractorId) : 0; // Default to 0 to avoid undefined

  const allBuildingsQuery = useApiQuery(
    'getAllBuildingsWithShareStatus',
    parsedSubcontractorId
  );

  const shareBuildingMutation = useApiMutation(
    'shareBuildingWithSubcontractor',
    {
      onSuccess: allBuildingsQuery.invalidate,
    }
  );
  const unshareBuildingMutation = useApiMutation(
    'unshareBuildingWithSubcontractor',
    {
      onSuccess: allBuildingsQuery.invalidate,
    }
  );

  if (parsedSubcontractorId === 0) {
    return <p>{t.subcontractorNotFound}</p>;
  }

  const handleToggleBuilding = (isShared: boolean, buildingId: number) => {
    if (isShared) {
      unshareBuildingMutation(parsedSubcontractorId, buildingId);
    } else {
      shareBuildingMutation(parsedSubcontractorId, { buildingId });
    }
  };

  if (allBuildingsQuery.isLoading) {
    return <Loader />;
  }

  return (
    <Page
      pageTitle={t.manageSharedBuildingsAndElevator}
      backLink="/subcontractors"
    >
      <Spacer />
      <Stack gap={space[4]}>
        {allBuildingsQuery.data?.map((building: BuildingWithShared) => {
          const isShared = building.isShared;
          return (
            <ImageCard
              key={building.id}
              title={building.name}
              image={replaceMapKey(building.photo)}
            >
              <BuildingInfo>
                {`${building.street} ${building.streetNumber}`}
              </BuildingInfo>
              <ButtonContainer>
                <Button
                  variant={isShared ? 'secondary' : 'primary'}
                  size="small"
                  onClick={() =>
                    handleToggleBuilding(building.isShared, building.id)
                  }
                >
                  {isShared ? t.unshare : t.share}
                </Button>
                <Button
                  variant="primary"
                  size="small"
                  as={Link}
                  to={`/subcontractors/${subcontractorId}/buildings/${building.id}`}
                >
                  {t.manageElevators}
                </Button>
              </ButtonContainer>
            </ImageCard>
          );
        })}
      </Stack>
    </Page>
  );
}
