import React from 'react';
import styled from 'styled-components';

import { useApiMutation } from '../hooks/useApi';
import { useFormSubmission } from '../hooks/useFormSubmission';
import { useToggle } from '../hooks/useToggle';
import { useTranslation } from '../I18nProvider';
import { space } from '../tokens';
import { Button } from './common/Button';
import { FullScreenDialog } from './common/FullScreenDialog';
import { Overlay } from './common/Overlay';
import { Stack } from './common/Stack';
import { CardTitle } from './common/typography';
import { Form } from './form/Form';
import { InputField } from './form/InputField';
import { ToggleField } from './form/ToggleField';

const Title = styled(CardTitle)`
  margin: ${space[3]} auto ${space[1]};
`;

type FormValues = {
  email: string;
  manager: boolean;
};
function InviteUsersDialog({
  onClose,
  onSuccess,
}: {
  onClose: () => void;
  onSuccess?: () => void;
}) {
  const t = useTranslation();
  const invite = useApiMutation('invite', {
    onSuccess,
  });

  const { form, register } = useFormSubmission<FormValues>({
    async onSubmit({ email, manager }) {
      await invite({ email, role: manager ? 'manager' : 'user' });
      onClose();
    },
  });

  return (
    <Overlay onClose={onClose}>
      <FullScreenDialog>
        <Title>{t.inviteCoworkers}</Title>
        <Form {...form}>
          <Stack>
            <InputField
              register={register}
              name="email"
              placeholder={t.email}
              required
            />
            <ToggleField register={register} name="manager">
              {t.inviteAsManager}
            </ToggleField>
            <Button type="submit" variant="primary" disabled={invite.isLoading}>
              {t.send}
            </Button>
            <Button type="button" onClick={onClose}>
              {t.cancel}
            </Button>
          </Stack>
        </Form>
      </FullScreenDialog>
    </Overlay>
  );
}

type Props = {
  onSuccess?: () => void;
};
export function InviteUsers(props: Props) {
  const t = useTranslation();
  const [showOverlay, toggleOverlay] = useToggle();
  return (
    <>
      <Button onClick={toggleOverlay}>{t.inviteCoworkers}</Button>
      {showOverlay && <InviteUsersDialog {...props} onClose={toggleOverlay} />}
    </>
  );
}
