import React, { ReactNode } from 'react';

import { Stack } from '../../components/common/Stack';
import { SectionHeading } from '../../components/common/typography';

type DriveParameterSectionProps = {
  title: string;
  children: ReactNode;
};
export function DriveParameterSection({
  title,
  children,
}: DriveParameterSectionProps) {
  return (
    <Stack>
      <SectionHeading>{title}</SectionHeading>
      {children}
    </Stack>
  );
}
