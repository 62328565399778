import * as d3 from 'd3';
import { ZoomTransform } from 'd3';

import { interpolatePoints } from './interpolation';
import { PathBuilder } from './path-builder';
import { AllSides, ChartOptions, DataCurve, Dimensions } from './types';

const DEFAULT_ASPECT_RATIO = 16 / 9;
const ALL_SIDES = Object.freeze(['top', 'right', 'bottom', 'left']);

export class D3Chart {
  hostElement: SVGSVGElement | null = null;
  currentZoomTransform: ZoomTransform | null = null;

  options: ChartOptions = {
    aspectRatio: DEFAULT_ASPECT_RATIO,
    data: [],
    scaleExtent: [0.5, 30],
    margin: {
      top: 20,
      right: 30,
      bottom: 20,
      left: 30,
    },
  };

  axisRanges: Partial<AllSides<[number, number]>> = {};

  scale: AllSides<d3.ScaleLinear<number, number, never>> = {
    left: d3.scaleLinear(),
    right: d3.scaleLinear(),
    top: d3.scaleLinear(),
    bottom: d3.scaleLinear(),
  };

  axis: AllSides<d3.Axis<d3.NumberValue>> = {
    left: d3.axisLeft(this.scale.left),
    right: d3.axisRight(this.scale.right),
    top: d3.axisTop(this.scale.top),
    bottom: d3.axisBottom(this.scale.bottom),
  };

  axisGroup: Partial<
    AllSides<d3.Selection<SVGGElement, unknown, null, undefined>>
  > = {};

  svg: d3.Selection<SVGSVGElement, unknown, null, undefined> | null = null;
  zoom: d3.ZoomBehavior<SVGSVGElement, unknown> | null = null;

  dimensions: Dimensions = {
    width: 0,
    height: 0,
  };

  get isInitialized(): boolean {
    return !!this.hostElement;
  }

  init(
    hostElement: SVGSVGElement | null,
    options: Partial<ChartOptions>
  ): void {
    if (hostElement === null) {
      return;
    }
    if (options) {
      Object.assign(this.options, options);
    }
    this.hostElement = hostElement;

    this.svg = d3.select(hostElement);
    const { svg } = this;
    for (const side of ALL_SIDES) {
      const group = svg.select<SVGGElement>(`.axis.${side}`);
      this.axisGroup[side] = group;
      group.html('');
    }
    this.zoom = d3
      .zoom<SVGSVGElement, unknown>()
      .scaleExtent(this.options.scaleExtent)
      .filter(this.zoomEventFilter)
      .on('zoom', this.onZoom);

    this.update();
    d3.select(window).on('resize', this.onResize, false);
    svg.call(this.zoom).call(this.zoom.transform, d3.zoomIdentity);
  }

  private updateViewbox() {
    if (!this.hostElement) {
      return;
    }
    const svg = d3.select(this.hostElement);
    const isLandscape = innerWidth > innerHeight;
    const aspectRatio =
      isLandscape && this.options.aspectRatioLandscape
        ? this.options.aspectRatioLandscape
        : this.options.aspectRatio;
    const width = this.hostElement.parentElement?.clientWidth || 400;
    const height = width / aspectRatio;
    Object.assign(this.dimensions, { width, height });
    svg.attr('viewBox', [0, 0, width, height].join(' '));
  }

  private updateRanges(): void {
    const { min, max, abs } = Math;
    const absMax = (...values: number[]) => max(...values.map((x) => abs(x)));
    const { data } = this.options;
    if (!data) {
      return;
    }
    const ranges: Partial<AllSides<[number, number]>> = {};
    for (const curve of data) {
      const xAxis = curve.xAxis || 'bottom';
      const yAxis = curve.yAxis || 'left';
      const x = curve.points.map((p) => p.x);
      const y = curve.points.map((p) => p.y);
      const xMin = min(...x);
      const xMax = max(...x);
      const yMax = absMax(...y) * 1.2;
      const xRange = ranges[xAxis];
      const yRange = ranges[yAxis];
      if (typeof xRange === 'undefined') {
        ranges[xAxis] = [xMin, xMax];
      } else {
        const [xMinOld, xMaxOld] = xRange;
        ranges[xAxis] = [min(xMin, xMinOld), max(xMax, xMaxOld)];
      }
      if (!yRange) {
        ranges[yAxis] = [-yMax, yMax];
      } else {
        const [, yMaxOld] = yRange;
        const yMaxNew = absMax(yMaxOld, yMax);
        ranges[yAxis] = [-yMaxNew, yMaxNew];
      }
    }
    this.axisRanges = ranges;
  }

  updateData(): void {
    const { svg, scale } = this;
    if (!svg) {
      return;
    }
    this.updateScales();
    const container = d3.select('.curves');
    const collection = container
      .selectAll<SVGGElement, unknown>('g')
      .data(this.options.data);

    const applyScales = (d: DataCurve) =>
      d.points.map((p) => {
        const scaleX = scale[d.xAxis || 'bottom'];
        const scaleY = scale[d.yAxis || 'left'];
        return { x: scaleX(p.x), y: scaleY(p.y) };
      });

    const updateInterpolatedPath = (
      path: d3.Selection<SVGPathElement, DataCurve, d3.BaseType, unknown>
    ) =>
      path
        .attr('stroke', (d) => d.color || false)
        .attr('d', (d) =>
          PathBuilder.fromPoints(interpolatePoints(applyScales(d))).toString()
        );

    const updateGroup = (
      g: d3.Selection<SVGGElement, DataCurve, d3.BaseType, unknown>
    ) => {
      g.select<SVGPathElement>('path.curve--interpolated').call(
        updateInterpolatedPath
      );
      return g;
    };

    collection.join(
      (enter) => {
        const g = enter.append('g');
        g.attr(
          'class',
          ({ name }: DataCurve) =>
            `curve-group ${name.toLowerCase().replace(/\s/g, '-')}`
        );
        g.append('path').attr('class', `curve curve--interpolated`);
        g.call(updateGroup);
        return g;
      },
      (update) => {
        return update.call(updateGroup);
      },
      (exit) => {
        exit.remove();
      }
    );
  }

  /**
   * updates the inner clipping rect of the diagram
   */
  private updateInnerView(): void {
    const { width, height } = this.dimensions;
    const { top, right, bottom, left } = this.options.margin;
    const clipRect = d3.selectAll('.clip-rect');
    clipRect.attr('x', left);
    clipRect.attr('y', top);
    clipRect.attr('width', width - left - right);
    clipRect.attr('height', height - top - bottom);
  }

  private addGridHelpers(): void {
    const { width, height } = this.dimensions;
    const { left, right, top, bottom } = this.options.margin;

    if (this.axisGroup.left) {
      const ticks = this.axisGroup.left.selectAll('.tick');
      ticks.selectAll('.grid-helper').remove();
      if (this.options.axisConfig?.left?.helpers === true) {
        ticks
          .append('line')
          .attr('class', 'grid-helper')
          .attr('x1', 0)
          .attr('x2', width - left - right);
      }
    }

    if (this.axisGroup.bottom) {
      const ticks = this.axisGroup.bottom.selectAll('.tick');
      ticks.selectAll('.grid-helper').remove();
      if (this.options.axisConfig?.bottom?.helpers === true) {
        ticks
          .append('line')
          .attr('class', 'grid-helper')
          .attr('y1', 0)
          .attr('y2', -height + top + bottom);
      }
    }

    if (this.axisGroup.right) {
      const ticks = this.axisGroup.right.selectAll('.tick');
      ticks.selectAll('.grid-helper').remove();
      if (this.options.axisConfig?.right?.helpers === true) {
        ticks
          .append('line')
          .attr('class', 'grid-helper')
          .attr('x1', 0)
          .attr('x2', -width + left + right);
      }
    }

    if (this.axisGroup.top) {
      const ticks = this.axisGroup.top.selectAll('.tick');
      ticks.selectAll('.grid-helper').remove();
      if (this.options.axisConfig?.top?.helpers === true) {
        ticks
          .append('line')
          .attr('class', 'grid-helper')
          .attr('y1', 0)
          .attr('y2', height - top - bottom);
      }
    }
  }

  /**
   * updates the scales of the diagram.
   * Internally called by updateData
   */
  private updateScales(): void {
    this.updateRanges();
    const { width, height } = this.dimensions;
    const { left, right, top, bottom } = this.options.margin;
    const ticksY = Math.max(3, Math.floor(height) / 80);
    const ticksX = Math.max(3, Math.floor(width) / 80);
    const { currentZoomTransform, axisGroup, axis, scale } = this;
    const pixelRanges: AllSides<[number, number]> = {
      bottom: [left, width - right],
      top: [left, width - right],
      left: [height - bottom, top],
      right: [height - bottom, top],
    };
    for (const side of ALL_SIDES) {
      const s = scale[side];
      const a = axis[side];
      const pixels = pixelRanges[side];
      const range = this.axisRanges[side];
      if (!range) {
        this.axisGroup[side]?.html('');
      }
      if (!s || !a || !pixels || !range) {
        continue;
      }
      s.domain(range).range(pixels);
      if (this.options.ticks) {
        a.ticks(this.options.ticks);
      } else {
        a.ticks(side === 'top' || side === 'bottom' ? ticksX : ticksY);
      }
      if (this.options.tickSize) {
        a.tickSize(this.options.tickSize);
      }
    }

    if (currentZoomTransform) {
      const zBottom = currentZoomTransform
        .rescaleX(scale.bottom)
        .interpolate(d3.interpolateRound);
      const zLeft = currentZoomTransform
        .rescaleY(scale.left)
        .interpolate(d3.interpolateRound);
      const zTop = currentZoomTransform
        .rescaleX(scale.top)
        .interpolate(d3.interpolateRound);
      const zRight = currentZoomTransform
        .rescaleY(scale.right)
        .interpolate(d3.interpolateRound);
      axis.left.scale(zLeft);
      axis.bottom.scale(zBottom);
      axis.right.scale(zRight);
      axis.top.scale(zTop);
    } else {
      axis.left.scale(scale.left);
      axis.top.scale(scale.top);
      axis.right.scale(scale.right);
      axis.bottom.scale(scale.bottom);
    }
    if (axisGroup.left && this.axisRanges.left) {
      axisGroup.left.attr('transform', `translate(${left}, 0)`);
      if (this.options.axisConfig?.left?.tickFormat) {
        axis.left.tickFormat(this.options.axisConfig.left.tickFormat);
      }
      if (this.options.axisConfig?.left?.color) {
        axisGroup.left.attr('color', this.options.axisConfig?.left?.color);
      }
      axisGroup.left.call(axis.left);
    }
    if (axisGroup.right && this.axisRanges.right) {
      axisGroup.right.attr('transform', `translate(${width - right}, 0)`);
      if (this.options.axisConfig?.right?.tickFormat) {
        axis.right.tickFormat(this.options.axisConfig.right.tickFormat);
      }
      if (this.options.axisConfig?.right?.color) {
        axisGroup.right.attr('color', this.options.axisConfig.right.color);
      }
      axisGroup.right.call(axis.right);
    }
    if (axisGroup.top && this.axisRanges.top) {
      axisGroup.top.attr('transform', `translate(0, ${top})`);
      if (this.options.axisConfig?.top?.tickFormat) {
        axis.top.tickFormat(this.options.axisConfig.top.tickFormat);
      }
      if (this.options.axisConfig?.top?.color) {
        axisGroup.top.attr('color', this.options.axisConfig.top.color);
      }
      axisGroup.top.call(axis.top);
    }
    if (axisGroup.bottom && this.axisRanges.bottom) {
      axisGroup.bottom.attr('transform', `translate(0, ${height - bottom})`);
      if (this.options.axisConfig?.bottom?.tickFormat) {
        axis.bottom.tickFormat(this.options.axisConfig.bottom.tickFormat);
      }
      if (this.options.axisConfig?.bottom?.color) {
        axisGroup.bottom.attr('color');
      }
      axisGroup.bottom.call(axis.bottom);
    }
    this.addGridHelpers();
  }

  onResize = () => {
    this.update();
  };

  update() {
    this.updateViewbox();
    this.updateInnerView();
    this.updateData();
  }

  zoomEventFilter = (event: any) => {
    // handle touch events only when 2 fingers used
    if (event?.touches && event.touches.length === 1) {
      return false;
    }

    // handle wheel event only when [CTRL] is pressed
    if (event.type === 'wheel' && !event.ctrlKey) {
      return false;
    }
    return true;
  };

  onZoom = ({ transform }: { transform: ZoomTransform }) => {
    this.currentZoomTransform = transform;
    const { svg, scale, axis, axisGroup } = this;
    if (!svg) {
      return;
    }
    for (const side of ALL_SIDES) {
      const currentScale = scale[side];
      const currentAxis = axis[side];
      const currentAxisGroup = axisGroup[side];
      if (!currentScale || !currentAxis || !currentAxisGroup) {
        continue;
      }
      const rescaled =
        side === 'top' || side === 'bottom'
          ? transform.rescaleX(currentScale).interpolate(d3.interpolateRound)
          : transform.rescaleY(currentScale).interpolate(d3.interpolateRound);
      currentAxis.scale(rescaled);
      if (this.axisRanges[side]) {
        currentAxisGroup.call(currentAxis);
      }
    }
    this.addGridHelpers();
    svg.select('.curves').attr('transform', transform.toString());
  };

  dispose() {
    if (this.hostElement === null) {
      return;
    }
    console.log('dispose', this.hostElement);
    d3.select(window).on('resize', null);
    if (this.zoom) {
      this.zoom.on('zoom', null);
    }
    this.hostElement = null;
  }
}
