import React, { ReactNode } from 'react';
import styled from 'styled-components';

import { shadow } from '../../styles';
import { borderRadius, color, space } from '../../tokens';
import { Stack } from './Stack';

const Root = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: ${space[3]};
  background: ${color.primary};
`;

const Box = styled.div`
  width: 100%;
  max-width: 480px;
  color: ${color.text};
  background: ${color.background};
  padding: ${space[3]};
  border-radius: ${borderRadius['outer']};
  ${shadow};
`;

type Props = {
  children?: ReactNode;
};
export function FullScreenDialog({ children }: Props) {
  return (
    <Root>
      <Box>
        <Stack gap={space[4]}>{children}</Stack>
      </Box>
    </Root>
  );
}
