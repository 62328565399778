import React from 'react';
import { useNavigate } from 'react-router-dom';

import { ErrorList } from '../components/common/ErrorList';
import { Page } from '../components/common/Page';
import { useApiQuery } from '../hooks/useApi';
import { useTranslation } from '../I18nProvider';

export function CompanyErrors() {
  const t = useTranslation();
  const navigate = useNavigate();
  const query = useApiQuery('getNotifications');

  return (
    <Page pageTitle={t.errorMessages} onBack={() => navigate(-1)}>
      <ErrorList errors={query.data ?? []} />
    </Page>
  );
}
