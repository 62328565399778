import React, {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from 'react';

import { boxApi } from '../../hooks/useBoxApi';
import { useLicenses } from '../../LicensesProvider';
import { BoxConfig } from '../../utils/programming/BoxApi';
import { NoLicenseError } from '../../utils/programming/NoLicenseError';
import { License } from '../../utils/types';

type ValidLicense = License | undefined;
const ValidLicenceContext = createContext<ValidLicense>(undefined);

type Props = {
  children: ReactNode;
};
export function ValidLicenseProvider({ children }: Props) {
  const validLicense = useDetermineValidLicence();

  return (
    <ValidLicenceContext.Provider value={validLicense}>
      {children}
    </ValidLicenceContext.Provider>
  );
}

function useDetermineValidLicence() {
  const [validLicense, setValidLicense] = useState<License | undefined>(
    undefined
  );

  const licenses = useLicenses();

  const [boxConfig, setBoxConfig] = useState<BoxConfig | undefined>(undefined);

  useEffect(() => {
    const handler = () => {
      setBoxConfig(boxApi.getBoxConfig());
    };

    if (boxApi.isApiConnected()) {
      handler();
    }

    boxApi.onConnectionChange(handler);

    return () => {
      boxApi.offConnectionChange(handler);
    };
  }, []);

  useEffect(() => {
    if (licenses && boxConfig) {
      const { deviceName } = boxConfig;

      const validLicense = licenses
        .filter((e) => e.product === 'PRG_TOOL')
        .filter((l) => l.box_id === deviceName)
        .pop();

      // console.log('Found valid license', validLicense);

      boxApi.setToken(validLicense?.token || undefined);
      if (!validLicense) {
        boxApi.disconnectApi();
        throw new NoLicenseError();
      }

      setValidLicense(validLicense);
    }
  }, [licenses, boxConfig]);

  return validLicense;
}

export const useValidLicense = () => useContext(ValidLicenceContext);
