import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { Button } from '../components/common/Button';
import { Hint } from '../components/common/Hint';
import { DeleteIcon, EditIcon } from '../components/common/Icons';
import { Page } from '../components/common/Page';
import { Row } from '../components/common/Row';
import { Stack } from '../components/common/Stack';
import { CardTitle } from '../components/common/typography';
import { InviteSubcontractor } from '../components/InviteSubcontractor';
import { useApiMutation, useApiQuery } from '../hooks/useApi';
import { useTranslation } from '../I18nProvider';
import { space } from '../tokens';

const UserDetails = styled.div`
  margin-right: auto;
  padding-bottom: ${space[4]};
`;

const ButtonContainer = styled.div`
  display: flex;
  gap: ${space[4]};
  padding-bottom: ${space[4]};
  @media screen and (max-width: 550px) {
    width: 100%;
    justify-content: center;
    margin-left: 0;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  }
`;

export function SubcontractorManagement() {
  const navigate = useNavigate();
  const t = useTranslation();
  const subcontractorInvites = useApiQuery('getSubcontractorInvites');
  const subcontractors = useApiQuery('getSubcontractors');
  const { data: subcontractorStatus } = useApiQuery('isSubcontractor');

  const contractorQuery = useApiQuery('getContractor');
  const contractor = contractorQuery.data;
  const contractorSuccess = contractorQuery.isSuccess;
  const deleteContractor = useApiMutation('deleteContractor', {
    onSuccess: () => {
      subcontractors.invalidate();
      contractorQuery.invalidate();
    },
  });

  const cancelSubcontractorInvite = useApiMutation(
    'cancelSubcontractorInvites',
    {
      onSuccess: subcontractorInvites.invalidate,
    }
  );
  const deleteSubcontractor = useApiMutation('deleteSubcontractor', {
    onSuccess: subcontractors.invalidate,
  });

  return (
    <Page pageTitle={t.subcontractorManagement} backLink="/">
      <Stack gap={space[7]}>
        {!subcontractors.data?.length && !contractor && (
          <Hint>{t.noSubcontractorMessage}</Hint>
        )}

        {!subcontractorStatus?.isSubcontractor && (
          <InviteSubcontractor onSuccess={subcontractorInvites.invalidate} />
        )}

        {contractorSuccess && contractor && (
          <Stack>
            <CardTitle>{t.contractors}</CardTitle>
            <Row>
              <UserDetails>
                <p>{contractor.name}</p>
              </UserDetails>
              <ButtonContainer>
                <Button
                  variant="secondary"
                  size="small"
                  minWidth="small"
                  onClick={() => deleteContractor()}
                >
                  <DeleteIcon />
                </Button>
              </ButtonContainer>
            </Row>
          </Stack>
        )}

        {subcontractors.data && subcontractors.data.length > 0 && (
          <>
            <CardTitle>{t.subcontractors}</CardTitle>
            {subcontractors.data.map((subcontractor: any) => (
              <Row key={subcontractor.id}>
                <UserDetails>
                  <p>{subcontractor.name}</p>
                </UserDetails>
                <ButtonContainer>
                  <Button
                    variant="secondary"
                    size="small"
                    onClick={() => deleteSubcontractor(subcontractor.id)}
                  >
                    <DeleteIcon />
                  </Button>
                  <Button
                    variant="secondary"
                    size="small"
                    as={Link}
                    to={`/subcontractors/${subcontractor.id}`}
                  >
                    <EditIcon />
                    {t.manageAccess}
                  </Button>
                </ButtonContainer>
              </Row>
            ))}
          </>
        )}

        {subcontractorInvites.isSuccess &&
          subcontractorInvites.data.length > 0 && (
            <Stack>
              <CardTitle>{t.pendingSubcontractorInvites}</CardTitle>
              {subcontractorInvites.data.map((invite: any) => (
                <Row key={invite.id}>
                  <div style={{ marginRight: 'auto' }}>{invite.email}</div>
                  <Button
                    size="small"
                    onClick={() => cancelSubcontractorInvite(invite.id)}
                  >
                    {t.revoke}
                  </Button>
                </Row>
              ))}
            </Stack>
          )}
      </Stack>
    </Page>
  );
}
