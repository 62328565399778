import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { Loading } from '../../components/common/Loading';
import { Page } from '../../components/common/Page';
import { LiftHeader } from '../../components/lift/LiftHeader';
import { useApiQuery } from '../../hooks/useApi';
import { useTranslation } from '../../I18nProvider';
import { Activate } from './Activate';
import { NewForm } from './NewForm';
import { PurchaseLicense } from './PurchaseLicense';

export function New() {
  const t = useTranslation();
  const params = useParams();
  const id = parseInt(params.id!);
  const { data, refetch } = useApiQuery('getProductStatus', id);
  const navigate = useNavigate();
  let content = null;
  if (!data) {
    content = <Loading />;
  } else {
    if (data.INST_CTRL)
      content = <NewForm liftId={id} onSubmit={() => navigate('..')} />;
    else if (data.TRIAL)
      content = <Activate liftId={id} onActivate={refetch} />;
    else content = <PurchaseLicense />;
  }
  return (
    <Page
      header={<LiftHeader id={id} />}
      pageTitle={t.newInstallationControl}
      backLink="../../"
    >
      {content}
    </Page>
  );
}
