import React from 'react';
import { useParams } from 'react-router-dom';

import { Card } from '../../components/common/Card';
import { ChartIcon, WarningIcon } from '../../components/common/Icons';
import { Loading } from '../../components/common/Loading';
import { Page } from '../../components/common/Page';
import { Stack } from '../../components/common/Stack';
import { LiftHeader } from '../../components/lift/LiftHeader';
import { useApiQuery } from '../../hooks/useApi';
import { useTranslation } from '../../I18nProvider';
import { space } from '../../tokens';

export function Realtime() {
  const params = useParams();
  const id = parseInt(params.id!);
  const liftQuery = useApiQuery('getLift', id);
  const productQuery = useApiQuery('getProductStatus', id);
  const t = useTranslation();

  const hasRTLicense = !!productQuery.data?.RT_DIAG;

  return (
    <Page
      header={<LiftHeader id={id} />}
      pageTitle={`${t.lift} ${t.realtimeMonitoringTitle}`}
      backLink={`/building/${params.buildingId}/lift/${id}`}
    >
      {liftQuery.isLoading && <Loading />}
      {liftQuery.isSuccess && (
        <Stack gap={space[7]}>
          <Stack>
            <Card
              navigateTo="monitoring"
              icon={<ChartIcon />}
              title={t.monitoring}
              disabled={!hasRTLicense}
            >
              {t.realtimeMonitoringDescription}
            </Card>
            <Card
              navigateTo={hasRTLicense ? 'errors' : ''}
              icon={<WarningIcon />}
              title={t.errorMessages}
              disabled={!hasRTLicense}
            >
              {t.errorMessagesDescription}
            </Card>
          </Stack>
        </Stack>
      )}
    </Page>
  );
}
