import React, { CSSProperties, ReactNode } from 'react';
import styled from 'styled-components';

import { borderRadius, color } from '../../tokens';
import { Row } from './Row';
import { Stack } from './Stack';
import { HeaderTitle, Overline } from './typography';

const Root = styled.div`
  display: flex;
  flex: 1;
  align-items: flex-start;
  margin-left: 3rem;
  justify-content: space-between;
  padding: 1rem 0;
  z-index: 1;
`;

const Background = styled.div`
  position: absolute;
  inset: 0;
  background-color: ${color.primary};
  margin-bottom: calc(-1 * ${borderRadius.outer});
  z-index: 0;
  ::before {
    content: '';
    position: absolute;
    inset: 0;
    background-color: #000;
    mix-blend-mode: screen;
    z-index: 1;
  }
  img {
    filter: grayscale(100%);
    mix-blend-mode: multiply;
    object-fit: cover;
    object-position: center top;
    opacity: 0.5;
    position: absolute;
    width: 100%;
    height: 100%;
  }
`;

type Props = {
  image?: string;
  background?: string | null;
  title?: string;
  subTitle?: string;
  action?: ReactNode;
  style?: CSSProperties;
};
export function AppHeader({
  image,
  background,
  title,
  subTitle,
  action,
  style,
}: Props) {
  return (
    <>
      {background && (
        <Background>
          <img src={background} />
        </Background>
      )}
      <Root style={style}>
        <Row>
          {image && <img src={image} />}
          <Stack gap="0">
            {subTitle && <Overline>{subTitle}</Overline>}
            <HeaderTitle>{title}</HeaderTitle>
          </Stack>
        </Row>
        {action}
      </Root>
    </>
  );
}
