import React, { useState } from 'react';
import styled from 'styled-components';

import { color, space } from '../../tokens';

const Container = styled.div`
  position: relative;
  display: flex;
  align-items: center;
`;

const ImageWrapper = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Image = styled(({ isSelected, ...rest }) => <img {...rest} />)<{
  isSelected: boolean;
}>`
  max-width: 100%;
  max-height: 30vh;
  align-self: center;
  ${({ isSelected }) =>
    isSelected ? `border: ${space[1]} solid ${color.primary}` : ''}
`;

const Arrow = styled.div`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 40px;
  height: 40px;
  background-color: rgba(0, 0, 0, 0.3);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  z-index: 1;
  color: #fff;
  font-size: 24px;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: rgba(0, 0, 0, 0.6);
  }
`;

const LeftArrow = styled(Arrow)`
  left: 10px;
`;

const RightArrow = styled(Arrow)`
  right: 10px;
`;

type Props = {
  images: string[];
  isSelected: boolean;
  partNumber: string;
  setSelectedPart: (partNumber: string) => void;
};

export function ImageCarousel({
  images,
  isSelected,
  partNumber,
  setSelectedPart,
}: Props) {
  const [selectedImage, setSelectedImage] = useState(0);

  const handlePrevImage = () => {
    setSelectedImage((prevIndex) =>
      prevIndex === 0 ? images.length - 1 : prevIndex - 1
    );
  };

  const handleNextImage = () => {
    setSelectedImage((prevIndex) =>
      prevIndex === images.length - 1 ? 0 : prevIndex + 1
    );
  };

  return (
    <Container>
      <LeftArrow onClick={handlePrevImage}>{'<'}</LeftArrow>
      <ImageWrapper>
        <Image
          src={images[selectedImage]}
          isSelected={isSelected}
          onClick={() => setSelectedPart(partNumber)}
        />
      </ImageWrapper>
      <RightArrow onClick={handleNextImage}>{'>'}</RightArrow>
    </Container>
  );
}
