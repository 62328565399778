import { TranslationKey } from '../i18n';
import beltTensionEVO from './belt-tension-evo.svg';
import beltTensionHydra from './belt-tension-hydra.svg';
import beltTensionSGV from './belt-tension-sgv.svg';
import closingDevice1 from './closing-device-failure-1.jpg';
import closingDevice2 from './closing-device-failure-2.jpg';
import closingDevice3 from './closing-device-failure-3.jpg';
import counterRollersAdjustment from './counter-rollers-adjustment.svg';
import counterRollersError from './counter-rollers-error.svg';
import counterRollersOk from './counter-rollers-ok.svg';
import misalignmentCdVsLdError from './misalignment-cd-vs-ld-error.svg';
import misalignmentCdVsLdOk from './misalignment-cd-vs-ld-ok.svg';
import misalignmentSilGapError from './misalignment-sil-gap-error.svg';
import misalignmentSilGapOk from './misalignment-sil-gap-ok.svg';
import zeroPosition from './zero-position.jpg';

export type TipContent = TipSection | ScaleSection | TipFigure;

export type TipSection = {
  title?: TranslationKey;
  text?: TranslationKey;
  match?: (args?: any) => boolean;
};

export type TipFigure = {
  title?: TranslationKey;
  image: string;
  status?: 'ok' | 'error';
  match?: (args: any) => boolean;
};

export type ScaleSection = {
  left?: TranslationKey;
  right: TranslationKey;
};

export const tips: Record<string, TipContent[]> = {
  misalignment_cd_vs_ld: [
    {
      title: 'errorDescription',
      text: 'misalignment_cd_vs_ld_description',
    },
    {
      left: 'left',
      right: 'right',
    },
    {
      title: 'recommendedActions',
      text: 'misalignment_cd_vs_ld_actions',
    },
    {
      image: misalignmentCdVsLdError,
      title: 'figureNotOk',
      status: 'error',
    },
    {
      image: misalignmentCdVsLdOk,
      title: 'figureOk',
      status: 'ok',
    },
  ],
  misalignment_cd_vs_ld_sill_gap: [
    {
      title: 'errorDescription',
      text: 'misalignment_cd_vs_ld_sill_gap_description',
    },
    {
      left: 'left',
      right: 'right',
    },
    {
      title: 'recommendedActions',
      text: 'misalignment_cd_vs_ld_sill_gap_actions',
    },
    {
      image: misalignmentSilGapError,
      title: 'figureNotOk',
      status: 'error',
    },
    {
      image: misalignmentSilGapOk,
      title: 'figureOk',
      status: 'ok',
    },
  ],
  ld_counter_rollers_installation: [
    {
      title: 'errorDescription',
      text: 'ld_counter_rollers_installation_description',
    },
    {
      left: 'tooLoose',
      right: 'tooTight',
    },
    {
      title: 'recommendedActions',
      text: 'ld_counter_rollers_installation_actions',
    },
    {
      image: counterRollersError,
      title: 'figureNotOk',
      status: 'error',
    },
    {
      image: counterRollersOk,
      title: 'figureOk',
      status: 'ok',
    },
    {
      image: counterRollersAdjustment,
      title: 'figureAdjustment',
    },
  ],
  cd_counter_rollers_installation: [
    {
      title: 'errorDescription',
      text: 'cd_counter_rollers_installation_description',
    },
    {
      left: 'tooLoose',
      right: 'tooTight',
    },
    {
      title: 'recommendedActions',
      text: 'cd_counter_rollers_installation_actions',
    },
    {
      image: counterRollersError,
      title: 'figureNotOk',
      status: 'error',
    },
    {
      image: counterRollersOk,
      title: 'figureOk',
      status: 'ok',
    },
    {
      image: counterRollersAdjustment,
      title: 'figureAdjustment',
    },
  ],
  closing_device_failure: [
    {
      title: 'errorDescription',
      text: 'closing_device_failure_description',
    },
    {
      right: 'tooHigh',
    },
    {
      title: 'recommendedActions',
      text: 'closing_device_failure_actions',
    },
    {
      image: closingDevice1,
      title: 'figureSpirator',
    },
    {
      image: closingDevice2,
      title: 'figureSpring',
    },
    {
      image: closingDevice3,
      title: 'figureClosingWeight',
    },
  ],
  zero_position: [
    {
      title: 'errorDescription',
      text: 'zero_position_description',
    },
    {
      left: 'left',
      right: 'right',
    },
    {
      title: 'recommendedActions',
      text: 'zero_position_eco_actions',
      match: (box: any) => /ECO\+/i.test(box.drivesoftware),
    },
    {
      title: 'recommendedActions',
      text: 'zero_position_midi_actions',
      image: zeroPosition,
      match: (box: any) => /MIDI/i.test(box.drivesoftware),
    },
  ],
  belt_tension: [
    {
      title: 'errorDescription',
      text: 'belt_tension_description',
    },
    {
      left: 'tooLow',
      right: 'tooHigh',
    },
    {
      image: beltTensionSGV,
      title: 'figureSGV',
    },
    {
      image: beltTensionHydra,
      title: 'figureHydra',
    },
    {
      image: beltTensionEVO,
      title: 'figureAugustaEVO',
    },
  ],
  pulley_is_touching_belt: [
    {
      title: 'errorDescription',
      text: 'pulley_is_touching_belt_description',
    },
    {
      right: 'touching',
    },
    {
      title: 'recommendedActions',
      text: 'pulley_is_touching_belt_actions',
    },
  ],
};
